import React, {useEffect, useState} from 'react';
import * as DataApi from "../../api/Data";
import DatasetListIntegrated from '../../components/DatasetListIntegrated';
import {getParameterByName, getParamsFromQueryString, serialize} from '../../util/Url';
//import Pagination from "../../components/Pagination";
import Pagination from '../new-dataset/Pagination';
import DatasetFilterNewIntegrated from "../../components/DatasetFilterNewIntegrated";
import Tour from "reactour";
import TourMessage from "../common/tourMessage";
import { disableBodyScroll, enableBodyScroll} from "body-scroll-lock";
import {useDispatch, useSelector} from "react-redux";
import {setTourBtn} from "../../store/actions/UserAction";
import ImgCity from "../../assets/images/img_data_city@2x.png";
import SubNavNew from "../../components/SubNavNew";

let oldParam = {};
let newParam = {};

const ListDataset = (props) => {

    const getPage = () => {
        let qs = getParamsFromQueryString(props.history.location.search);
        let page = qs.hasOwnProperty("page") ? JSON.parse(JSON.stringify(Number(qs.page[0]))) : 1;
        return page;
    };

    const dispatch = useDispatch()

    let [dataList, setDataList] = useState([]);
    let [isLoaded, setIsLoaded] = useState(false);
    let [page, setPage] = useState(getPage());
    let [maxPage, setMaxPage] = useState(20);
    let [tags, setTags] = useState([]);
    let [formats, setFormats] = useState([]);
    let [category, setCategory] = useState([]);
    let [paytag, setPayTags] = useState([]);
    let [filters, setFilters] = useState([]);
    let [totalCount, setTotalCount] = useState(0);
    let [limit] = useState(10);

    // 투어
    const isTourOpen = useSelector(state => state.user.isTourOpen)
    const datasetTourMsg = new TourMessage.DataSetListPageTourMessage()
    let disableBody = target => disableBodyScroll(target)
    let enableBody = target => enableBodyScroll(target)

    const searchData = (params = false, page = 1) => {
        let p = {};
        if(params){
            p = params;
            oldParam = p;
        }else{
            p = oldParam;
        }
        p.page = getPage();
        p.limit = limit;
        DataApi.readSearchIntegratedData(p).then(res => {
            setTotalCount(res.result.totalPages);
            setDataList(res.result.datamaps);
            setPage(p.page + 1);
        });
    };

    useEffect(() => {
        setMaxPage(Math.ceil(totalCount / limit));
    }, [totalCount]);

    useEffect(() => {
        searchData(false, page);
    }, [page]);

    const onPageMove = (page) => {

        let qs = getParamsFromQueryString(props.history.location.search);
        qs.page = [String(page)];

        props.history.push({
            pathname: "/integratedDataset",
            search: serialize(qs)
        });

        setPage(page);
    };
    const subNav = [
        {
            display: "데이터셋",
            name: "/new-dataset"
        }, {
            display: "융합 데이터",
            name: "/new-datasetMix"
        }, {
            display: "통합 데이터 검색",
            name: "/integratedDataset"
        }
    ];
    const closeTour = () => {
        dispatch(setTourBtn(false))
    }

    const handleFilterChange = (params) => {

        if((oldParam.hasOwnProperty("tag") ? oldParam.tag.length : 0) +
            (oldParam.hasOwnProperty("category") ? oldParam.category.length : 0) +
            (oldParam.hasOwnProperty("word") ? oldParam.word.length : 0) +
            (oldParam.hasOwnProperty("format") ? oldParam.format.length : 0) +
            (oldParam.hasOwnProperty("paytag") ? oldParam.paytag.length : 0)
            !=
            (params.hasOwnProperty("tag") ? params.tag.length : 0) +
            (params.hasOwnProperty("category") ? params.category.length : 0) +
            (params.hasOwnProperty("word") ? params.word.length : 0) +
            (params.hasOwnProperty("format") ? params.format.length : 0) +
            (params.hasOwnProperty("paytag") ? params.paytag.length : 0)){
            params.page = String(1);
        }else params.page = String(getPage());


        if(isLoaded){
            props.history.push({
                pathname: "/integratedDataset",
                search: serialize(params)
            });
        }
        else {
            props.history.replace({
                pathname: "/integratedDataset",
                search: serialize(params)
            });
            setIsLoaded(true);
        }

        oldParam = JSON.parse(JSON.stringify(params));


        searchData(params);
    };

    return (
        <>
            <Tour
                steps={datasetTourMsg.tourConfig}
                isOpen={isTourOpen}
                onRequestClose={closeTour}
                rounded={5}
                accentColor={"#004c9d"}
                onAfterOpen={disableBody}
                onBeforeClose={enableBody}

            />
            <div className="renewal">
            <SubNavNew nav={subNav} history={props.history}>
            </SubNavNew>
            </div>
            {/*<div className={"AnalysisService-wrap"}>*/}
                <div className={"AnalysisService-inner"} style={{"paddingTop":"0px"}}>
                    <h1></h1>
                    <p>공공-민간 통합 데이터 지도상에 연계되어있는 방대하고 다양한 데이터를 검색할 수 있습니다.</p>

                    {/*<img src={ImgCity} alt={"ImgCity"} className={"ImgCity"}/>*/}
                </div>
            {/*</div>*/}

            <DatasetFilterNewIntegrated page={page} tags={tags} formats={formats} totalCount={totalCount} category={category} paytag={paytag} onChange={(params) => handleFilterChange(params)} history={props.history}/>
            <DatasetListIntegrated list={dataList} totalCount={totalCount}/>
            <Pagination page={getPage()}
                        maxPage={maxPage}
                        padding={2}
                        onPageMove={num => onPageMove(num)}
            />
        </>
    )

};

export default ListDataset;
