import React from 'react';
import {Link} from "react-router-dom";
import JoinComponent from '../../components/JoinForm';
import * as UserApi from "../../api/User";
import LoginImg from "../../assets/images/img_bg_signin.png";
import swal from "sweetalert";
import logo_nia_grey from "../../assets/images/logo_nia_default@2x.png";
import logo_ict_grey from "../../assets/images/logo_ict_default@2x.png";
import logo_gg_grey from "../../assets/images/logo_gg_default@2x.png";

function Join(props) {
    const joinProcess = (joinInfo, callback) => {
        let registerParam = {
            id: joinInfo.id,
            password: joinInfo.password,
            email: joinInfo.email,
            emailAgree: joinInfo.emailAgree,
            name: joinInfo.name,
            address: joinInfo.address,
            phone: joinInfo.phone,
            detail_address: joinInfo.detail_address,
            postcode: joinInfo.postcode,
            user_type: joinInfo.user_type,
            termsAndConditions: joinInfo.termsAndConditions,
            privacyPolicy: joinInfo.privacyPolicy,
            privacyAgreePolicy: joinInfo.privacyAgreePolicy,
            privacyOption: joinInfo.privacyOption,
            user_desc: joinInfo.user_desc,
            only_company_sortation: joinInfo.only_company_sortation,
            only_company_type: joinInfo.only_company_type
        };

        /*
        * 임의로 코드 넣어놓음
        * 100 = ID중복
        * 101 = 비밀번호 자리
        * 102 = 이메일 형식
        */

        UserApi.createUser(registerParam)
            .then(res => {
                if (res.result.join_code === 200){
                    // swal(res.result.email + '으로 확인 메일을 보냈습니다. \n 이메일을 확인해주세요.').then(() => {
                    //     props.history.push("/login");
                    // });
                    props.history.push("/login");
                    swal("회원가입이 완료되었습니다.\n 로그인 후 이용해주시길 바랍니다.");

                }

                callback();
            })
            .catch(res => {
                let json_res = JSON.parse(res.response);
                console.log(json_res);
                if (json_res.result.join_code === 100) swal('이미 사용중인 ID입니다.');
                else if (json_res.result.join_code === 101) swal('비밀번호는 8자 이상이어야 합니다.');
                else if (json_res.result.join_code === 102) swal('이메일 형식이 올바르지 않습니다.');

                callback();
        }) ;
    };


    return (
        <div className={"scroll-wrap"}>
        <div className={"login-wrap"}>
            <div className={"img-wrap join-img"}>
              {/* <img src={LoginImg} alt={"background image"} style={{position:"fixed", width:"50%"}}/> */}
              <img src={LoginImg} alt={"background image"} style={{width:"100%"}}/>
                <div className={"WhatWeDo-banner"}>
                    <img src={logo_ict_grey} alt={"과학기술정보통신부"}/>
                    <img src={logo_nia_grey} alt={"한국지능정보사회진흥원"}/>
                    <img src={logo_gg_grey} alt={"세계속의 경기도"}/>
                </div>
            </div>
            <div className={"login-form join-wrap"}>
                <div className={"form-wrap"} style={{width:"90%"}}>
                    <h1>
                        <Link to="/">
                            <strong>경기지역</strong>
                            <strong>경제포털</strong>
                        </Link>
                    </h1>
                    <h2 className={"tit-login"}>회원 가입</h2>
                    <JoinComponent history={props.history} joinProcess={(joinInfo, callback) => joinProcess(joinInfo, callback)}/>
                </div>
            </div>

        </div>
        </div>
    );
}

export default Join;
