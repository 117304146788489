import React, {useState, useEffect} from 'react';
import {useDispatch} from "react-redux";
import AppRouter from "./AppRouter";
import {getCookie, setCookie} from "./util/Cookie";
import * as UserApi from "./api/User";
import {
    setCategoryCodeDemand,
    setCategoryCodePurpose, setCategoryCodeSortation, setSurveyLink, setCategoryCodeDataReport,
    setUserInfo,
    setUserInfoIsLogin,
    setTourBtn
} from "./store/actions/UserAction";

import LoadingCheckPage from "./pages/LoadingCheckPage";
import * as DataApi from "./api/Data";
import {setMetaInfo} from "./store/actions/DataAction";

const App = () => {

    let token = getCookie("token");

    const dispatch = useDispatch();

    const [isLoginChk, setIsLoginChk] = useState(false);
    const [isLoadMeta, setIsLoadMeta] = useState(false);
    const [isUserCodeDemand, setIsUserCodeDemand] = useState(false);
    const [isUserCodePurpose, setIsUserCodePurpose] = useState(false);
    const [isUserCodeSortation, setIsUserCodeSortation] = useState(false);
    const [isSurveyLink, setIsSurveyLink] = useState(false);

    const [isContinue, setIsContinue] = useState(false);

    useEffect(() => {

        if(isLoginChk && isLoadMeta && isUserCodeDemand && isUserCodePurpose && isUserCodeSortation)
            setIsContinue(true);

    }, [isLoginChk, isLoadMeta, isUserCodeDemand, isUserCodePurpose, isUserCodeSortation, isSurveyLink]);

    useEffect(() => {
        const publicIp = require('public-ip');
        (
            async () => {
               setCookie('user_ip', await publicIp.v4())
        })();

        // DataApi.readDatasetAllTitle().then(res => {
        //     dispatch(setDataTitle(res.result))
        //     setIsDataSetTitle(true)
        // }).catch(()=> {
        //     setIsDataSetTitle(true)
        // });
        DataApi.readMeta().then(res => {
            dispatch(setMetaInfo(res.result));
            setIsLoadMeta(true);
        }).catch(() => {
            setIsLoadMeta(true);
        });
        UserApi.categoryListByDict({
            type: "order_demand"
        }).then(res => {
            dispatch(setCategoryCodeDemand(res.result.category_list));
            setIsUserCodeDemand(true);
        }).catch(() => {
            setIsUserCodeDemand(true);
        });
        UserApi.categoryListByDict({
            type: "order_purpose"
        }).then(res => {
            dispatch(setCategoryCodePurpose(res.result.category_list));
            setIsUserCodePurpose(true);
        }).catch(() => {
            setIsUserCodePurpose(true);
        });
        UserApi.categoryListByDict({
            type: "user_sortation"
        }).then(res => {
            dispatch(setCategoryCodeSortation(res.result.category_list));
            setIsUserCodeSortation(true);
        }).catch(() => {
            setIsUserCodeSortation(true);
        });
        UserApi.categoryListByDict({
            type: "data_report"
        }).then(res => {
            dispatch(setCategoryCodeDataReport(res.result.category_list));
            setIsUserCodeSortation(true);
        }).catch(() => {
            setIsUserCodeSortation(true);
        });
        UserApi.readSurvey().then(res => {
            dispatch(setSurveyLink(res.result.survey_link));
            setIsSurveyLink(true);
        }).catch(() => {
            setIsSurveyLink(true);
        });

        dispatch(setTourBtn(false));

        if(!isLoginChk && token){
            const params = {
                token: token
            };

            UserApi.readUserInfoByToken(params)
                .then(res => {
                    const user_info = res.result;
                    dispatch(setUserInfo({
                        id_key: user_info.id,
                        id: user_info.name,
                        email: user_info.email,
                        emailAgree: user_info.emailAgree === "true",
                        point: user_info.point,
                        open_api_key: user_info.open_api_key,
                        appServiceId: user_info.link_target_user_id,
                        address: user_info.address,
                        phone: user_info.phone,
                        fullname: user_info.fullname,
                        apikey: user_info.apikey,
                        number_created_packages: user_info.number_created_packages,
                        number_of_edits: user_info.number_of_edits,
                        created: user_info.created,
                        token: user_info.id,
                        role: user_info.role,
                        center_code: user_info.center_code,
                        postcode: user_info.postcode,
                        detail_address: user_info.detail_address,
                        user_type: user_info.user_type,
                        user_desc: user_info.user_desc,
                        only_company_sortation: user_info.only_company_sortation,
                        only_company_type: user_info.only_company_type
                    }));

                    dispatch(setUserInfoIsLogin(true));

                    setIsLoginChk(true);
                })
                .catch(res => {
                    setIsLoginChk(true);
                });
        }else{
            setIsLoginChk(true);
        }
    }, [dispatch, isLoginChk, token]);

    if(!isContinue) return <LoadingCheckPage/>;
    else return <AppRouter/>

};

export default App;
