import React, {useState, useEffect, useRef} from 'react';
import ImgCity from "../../assets/images/img_data_city@2x.png";
import {useSelector} from "react-redux";
import swal from "sweetalert";
import swal2 from "sweetalert2";
import * as OpenAPI from "../../api/OpenApi";
import * as PointAPI from "../../api/User";
import Modal from "react-modal";
import ReactJson from 'react-json-view'
import SpinnerLoader from "../../components/Spinner"
import * as DataApi from "../../api/Data";


const AnalysisService = (props) => {

    const OPEN_API_URL = process.env.REACT_APP_OPENAPI_URL;
    const userInfo = useSelector(state => state.user.info);
    const isLogin = useSelector(state => state.user.isLogin);

    const [apiExample, setApiExample] = useState("")
    const [responseExample, setResponseExample] = useState(null)
    const [openApiModalTitle, setOpenApiModalTitle] = useState("")
    const [pointFlag, setPointFlag] = useState(false);
    let [spinnerLoader, setSpinnerLoader] = useState(false);

    let [exampleParams, setExampleParams] = useState({
        auth_key: '',
        center_code: '',
        data_name: '',
        page_num: 1,
        page_size: 10,
    });
    // 포인트 활성화 체크
    useEffect(() => {
        DataApi.readIkanConfig().then(res => {
            setPointFlag(res.result.config_list.filter(config => {return config.function_type === "point"})[0].function_active)
        })
    })
    const authKeyApply = () => {

        if(isLogin === false){
            swal("로그인 이후 신청 가능합니다.");
            return
        }
        let params = {
            user_id : userInfo.id,
            user_id_key: userInfo.id_key,
        };
        // 포인트 차감
        if (pointFlag) {
            let use_point = 30;
            let confirm_msg = use_point+" 포인트가 차감 됩니다. \n OpenAPI 키를 발급 받으시겠어요?";
            swal2.fire({
                html: '<pre>' + confirm_msg + '</pre>',
                confirmButtonText:"확인",
                cancelButtonText:"취소",
                showCancelButton: true
            }).then((result) => {
                if (result.value) {
                    let point_params = {
                        portal_user_id_key: userInfo.id_key,
                        action_type: "openapi",
                        point: use_point
                    }
                    OpenAPI.readApiKey({"user_id_key": userInfo.id_key}).then(res => {
                        if (res.result === null) {
                            PointAPI.usePoint(point_params).then(res => {
                                OpenAPI.createApiKey(params).then(res => {
                                    swal("신청이 완료되었습니다.\n신청내역은 마이페이지에서 확인 가능합니다.");
                                }).catch(res => {
                                    swal("오류가 발생했습니다. \n잠시 후 다시 시도해주세요.");

                                })
                            }).catch(res => {
                                let msg = JSON.parse(res.response);
                                if (msg.result === "not enough points" || msg.result === "not exist point") {
                                    swal("포인트가 부족 합니다. \n 잔여 포인트를 확인해주세요.")
                                }
                                else {
                                    swal("오류가 발생했습니다. \n잠시 후 다시 시도해주세요.");
                                }
                            })
                        }else{
                            swal("키가 이미 발급되었습니다. \n 마이페이지를 확인해주세요.")
                        }
                    })

                }
            });
        }
        // 포인트 X
        else{
            OpenAPI.createApiKey(params).then(res => {
                swal("신청이 완료되었습니다.\n신청내역은 마이페이지에서 확인 가능합니다.");
            }).catch(res => {
                let msg = JSON.parse(res.response);
                console.log(msg.result)
                if (msg.result === "key info exist") {
                    swal("키가 이미 발급되었습니다. \n 마이페이지를 확인해주세요.")
                }
                else{
                    swal("오류가 발생했습니다. \n잠시 후 다시 시도해주세요.");
                }
            })
        }
    };

    const showRequest = (api_type) => {
        setApiExample(api_type)
    }

    const closeRequest = () => {
        setApiExample("")
    }
    const exampleRequest = (example_type) => {
        if(isLogin === false){
            swal("로그인 이후 사용 가능 합니다.");
            return
        }
        if (example_type === "read_center_list") {
            setSpinnerLoader(true);
            OpenAPI.readCenterList(exampleParams).then(res => {
                setResponseExample(res)
                setOpenApiModalTitle("제공기관 목록 조회 결과")
                openModal()
            })
        }
        else if (example_type === "read_data_list") {
            setSpinnerLoader(true);
            OpenAPI.readDataList(exampleParams).then(res => {
                setResponseExample(res)
                setOpenApiModalTitle("데이터 목록 조회 결과")
                openModal()
            })
        }
        else if (example_type === "read_data") {
            setSpinnerLoader(true);
            OpenAPI.readData(exampleParams).then(res => {
                setResponseExample(res)
                setOpenApiModalTitle("데이터 조회 결과")
                openModal()
            })
        }
        else if (example_type === "read_remain_key") {
            setSpinnerLoader(true);
            OpenAPI.readRemainKey(exampleParams).then(res => {
                setResponseExample(res)
                setOpenApiModalTitle("잔여 요청 건수 조회 결과")
                openModal()
            })
        }
    }
    //Modal
    const [modalIsOpen,setIsOpen] = useState(false);
    const openModal = () => {
        setSpinnerLoader(false);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };
    const customStyles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            zIndex: 1060
        },
        content : {
            top                   : '50%',
            left                  : '50%',
            right                 : 'auto',
            bottom                : 'auto',
            marginRight           : '-50%',
            transform             : 'translate(-50%, -50%)',
            width:  '50%',
            height: '75%'
        }
    };

    const downloadExSrc = (apiExamples) => {
        let download_url = "https://ggdata.kr:6443/uc_images/" + apiExamples + ".py"
        let t = window.open(download_url, "_blank");
        t.focus();
    }

    return (
        <>
            {spinnerLoader && <SpinnerLoader loading={spinnerLoader}/>}
            <Modal
                isOpen={modalIsOpen}
                aria={{
                    labelledby: "heading",
                    describedby: "full_description"
                }}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Inline Styles Modal Example"
            >
                <div style={{"width": "100%", "border-bottom": "1px solid #cad0dd", "padding-bottom": "20px"}}>
                    <span id="heading" style={{"font-size": "24px", "font-weight": "500"}}>{openApiModalTitle}</span>
                    <span style={{"float":"right", "margin-top": "5px"}} onClick={() => closeModal()}> <p className={"sitemap-close"}></p> </span>
                </div>
                <div style={{marginTop: "20px"}}>
                    <ReactJson src={responseExample} />
                </div>

            </Modal>
            <div className={"AnalysisService-wrap"}>
                <div className={"AnalysisService-inner"}>
                    <h1>Open API 서비스</h1>
                    <p>Open API 서비스는, 방대하고 다양한 데이터를 누구나 사용할 수 있는 API 형태로 제공하는 서비스 입니다.</p>
                    {/*<img src={ImgCity} alt={"ImgCity"} className={"ImgCity"}/>*/}
                </div>
            </div>
            <div className={"AnalysisService-wrap2"}>
                <div className={"AnalysisService-notice-inner"}>
                    {/*<h2><img src={NoticeIco} width={"28px"} height={"28px"}/> 주의 사항</h2>*/}
                    <h2>Open API 소개</h2>
                    <div>
                        <p style={{'font-size': '18px'}}> 주의사항 </p>
                        <ul>
                            <li>- 인증 키 발급 신청 일자 기준 00:00 시부터 7일간 사용 가능 합니다.</li>
                            <li>- 하루 최대 요청 건수는 1000건으로 제한 됩니다.</li>
                            <li>- 1회 최대 요청 건수는 10000건으로 제한 됩니다. (데이터 조회 기능)</li>
                            <li>- 상세기능 미리보기를 이용할 시 요청 건수가 차감 됩니다.</li>
                        </ul>
                        <br/>
                    </div>
                </div>
                <div className="btn-group ac wrap2-btn-wrap">
                    <button onClick={e => authKeyApply()}>키 발급 신청</button>
                </div>
            </div>
            <div className={"AnalysisService-wrap3"}>
                <div className={"AnalysisService-notice-inner"}>
                    {/*<h2><img src={NoticeIco} width={"28px"} height={"28px"}/> 주의 사항</h2>*/}
                    <h2>Open API 제공기능정보</h2>
                    <div className={"openapi-table example-list"}>
                        <table>
                            <colgroup>
                                <col style={{width:"165px"}}/>
                                <col/>
                                <col style={{width:"100px"}}/>
                                <col style={{width:"100px"}}/>
                            </colgroup>
                            <thead>
                            <tr>
                                <th scope={"col"}>기능명</th>
                                <th scope={"col"}>End Point</th>
                                <th scope={"col"}>Method</th>
                                <th scope={"col"}>응답 유형</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>제공기관 목록 조회</td>
                                <td>{OPEN_API_URL+'/read/center_list'}</td>
                                <td>GET</td>
                                <td>JSON</td>
                            </tr>
                            <tr>
                                <td>데이터 목록 조회</td>
                                <td>{OPEN_API_URL+'/read/data_list'}</td>
                                <td>GET</td>
                                <td>JSON</td>
                            </tr>
                            <tr>
                                <td>데이터 조회</td>
                                <td>{OPEN_API_URL+'/read/data'}</td>
                                <td>GET</td>
                                <td>JSON</td>
                            </tr>
                            <tr>
                                <td>잔여 요청 건수 조회</td>
                                <td>{OPEN_API_URL+'/read/remain_request'}</td>
                                <td>GET</td>
                                <td>JSON</td>
                            </tr>
                            <tr>
                                <td>API 키 갱신</td>
                                <td>{OPEN_API_URL+'/update/auth_key'}</td>
                                <td>PUT</td>
                                <td>JSON</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className={"AnalysisService-wrap3"}>
                <div className={"AnalysisService-notice-inner"}>
                    {/*<h2><img src={NoticeIco} width={"28px"} height={"28px"}/> 주의 사항</h2>*/}
                    <h2>Open API 상세기능정보</h2>
                        <div className={"openapi-table example-list"}>
                            <table>
                                <colgroup>
                                    <col style={{width:"165px"}}/>
                                    <col/>
                                    <col style={{width:"100px"}}/>
                                    <col style={{width:"100px"}}/>
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope={"col"}>상세기능</th>
                                        <th scope={"col"}>설명</th>
                                        <th scope={"col"}>일일 트래픽</th>
                                        <th scope={"col"}>미리보기</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>제공기관 목록 조회</td>
                                        <td>Open API로 개방된 데이터 제공기관 리스트를 가져 옵니다.</td>
                                        <td>없음</td>
                                        <td>
                                            <div className="btn-group ac wrap2-btn-wrap" style={{marginTop: 0}}>
                                                <button style={{minWidth: "60px", height: "30px", fontSize:"12px", backgroundColor: "#3b5877"}} onClick={e => showRequest("read_center_list")}>열기</button>
                                            </div>
                                        </td>
                                    </tr>
                                        {apiExample === "read_center_list" &&
                                            <tr style={{backgroundColor: "#f9f9f9"}}>
                                                <td colSpan={4}>
                                                    <div style={{padding: "25px 20px"}}>
                                                        <p style={{fontSize: '18px', marginBottom: "15px", color:"black"}}>필요 파라미터</p>
                                                        <table style={{textAlign: "center", borderCollapse: "collapse", border:"1px solid #dcddde"}}>
                                                            <colgroup>
                                                                <col style={{width:"150px"}}/>
                                                                <col style={{width:"250px"}}/>
                                                                <col/>
                                                            </colgroup>
                                                            <thead>
                                                            <tr>
                                                                <th>파라미터명</th>
                                                                <th>입력값</th>
                                                                <th>설명</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr>
                                                                <td>auth_key</td>
                                                                <td>
                                                                    <input style={{"font-size":"16px", "border":"1px solid #ccc", "color":"#999", "min-width":"128px", "height":"35px", "padding":"0 15px"}}
                                                                           type={"text"} value={exampleParams.auth_key} onChange={ e => setExampleParams({...exampleParams,["auth_key"]: e.target.value})}/>
                                                                </td>
                                                                <td>Open API 인증키</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                        <div className="btn-group ac wrap2-btn-wrap">
                                                            <button style={{minWidth: "60px", height: "30px", fontSize:"12px", backgroundColor: "#3b5877"}} onClick={e => exampleRequest(apiExample)}>요청</button>
                                                            <button style={{minWidth: "60px", height: "30px", fontSize:"12px", backgroundColor: "#3b5877"}} onClick={e => downloadExSrc(apiExample)}>Python 예제</button>
                                                            <button style={{minWidth: "60px", height: "30px", fontSize:"12px", backgroundColor: "#3b5877"}} onClick={e => closeRequest()}>닫기</button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                <tr>
                                    <td>데이터 목록 조회</td>
                                    <td>Open API로 개방된 데이터 제공기관 별 데이터 목록을 가져 옵니다.</td>
                                    <td>없음</td>
                                    <td>
                                        <div className="btn-group ac wrap2-btn-wrap" style={{marginTop: 0}}>
                                            <button style={{minWidth: "60px", height: "30px", fontSize:"12px", backgroundColor: "#3b5877"}} onClick={e => showRequest("read_data_list")}>열기</button>
                                        </div>
                                    </td>
                                </tr>
                                    {apiExample === "read_data_list" &&
                                        <tr style={{backgroundColor: "#f9f9f9"}}>
                                            <td colSpan={4}>
                                                <div style={{padding: "25px 20px"}}>
                                                    <p style={{fontSize: '18px', marginBottom: "15px", color:"black"}}>필요 파라미터</p>
                                                    <table style={{textAlign: "center", borderCollapse: "collapse", border:"1px solid #dcddde"}}>
                                                        <colgroup>
                                                            <col style={{width:"150px"}}/>
                                                            <col style={{width:"250px"}}/>
                                                            <col/>
                                                        </colgroup>
                                                        <thead>
                                                        <tr>
                                                            <th>파라미터명</th>
                                                            <th>입력값</th>
                                                            <th>설명</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td>auth_key</td>
                                                            <td>
                                                                <input style={{"font-size":"16px", "border":"1px solid #ccc", "color":"#999", "min-width":"128px", "height":"35px", "padding":"0 15px"}}
                                                                        type={"text"} value={exampleParams.auth_key} onChange={ e => setExampleParams({...exampleParams,["auth_key"]: e.target.value})}/>
                                                            </td>
                                                            <td>Open API 인증키</td>
                                                        </tr>
                                                        <tr>
                                                            <td>center_code</td>
                                                            <td>
                                                                <input style={{"font-size":"16px", "border":"1px solid #ccc", "color":"#999", "min-width":"128px", "height":"35px", "padding":"0 15px"}}
                                                                       type={"text"} value={exampleParams.center_code} onChange={ e => setExampleParams({...exampleParams,["center_code"]: e.target.value})}/>
                                                            </td>
                                                            <td>데이터 제공기관 코드 ( 제공기관 목록 조회 API를 통해 응답 받은 코드 데이터 )</td>
                                                        </tr>
                                                        <tr>
                                                            <td>page_num</td>
                                                            <td>
                                                                <input style={{"font-size":"16px", "border":"1px solid #ccc", "color":"#999", "min-width":"128px", "height":"35px", "padding":"0 15px"}}
                                                                       type={"text"} value={exampleParams.page_num} onChange={ e => setExampleParams({...exampleParams,["page_num"]: e.target.value})}/>
                                                            </td>
                                                            <td>페이지 번호 ( 최소 1 )</td>
                                                        </tr>
                                                        <tr>
                                                            <td>page_size</td>
                                                            <td>
                                                                <input style={{"font-size":"16px", "border":"1px solid #ccc", "color":"#999", "min-width":"128px", "height":"35px", "padding":"0 15px"}}
                                                                       type={"text"} value={exampleParams.page_size} onChange={ e => setExampleParams({...exampleParams,["page_size"]: e.target.value})}/>
                                                            </td>
                                                            <td>한 페이지 결과 수 ( 최대 20 )</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    <div className="btn-group ac wrap2-btn-wrap">
                                                        <button style={{minWidth: "60px", height: "30px", fontSize:"12px", backgroundColor: "#3b5877"}} onClick={e => exampleRequest(apiExample)}>요청</button>
                                                        <button style={{minWidth: "60px", height: "30px", fontSize:"12px", backgroundColor: "#3b5877"}} onClick={e => downloadExSrc(apiExample)}>Python 예제</button>
                                                        <button style={{minWidth: "60px", height: "30px", fontSize:"12px", backgroundColor: "#3b5877"}} onClick={e => closeRequest()}>닫기</button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                <tr>
                                    <td>데이터 조회</td>
                                    <td>Open API로 개방된 데이터를 페이지 형태로 가져 옵니다.</td>
                                    <td>1000</td>
                                    <td>
                                        <div className="btn-group ac wrap2-btn-wrap" style={{marginTop: 0}}>
                                            <button style={{minWidth: "60px", height: "30px", fontSize:"12px", backgroundColor: "#3b5877"}} onClick={e => showRequest("read_data")}>열기</button>
                                        </div>
                                    </td>
                                </tr>
                                    {apiExample === "read_data" &&
                                        <tr style={{backgroundColor: "#f9f9f9"}}>
                                            <td colSpan={4}>
                                                <div style={{padding: "25px 20px"}}>
                                                    <p style={{fontSize: '18px', marginBottom: "15px", color:"black"}}>필요 파라미터</p>
                                                    <table style={{textAlign: "center", borderCollapse: "collapse", border:"1px solid #dcddde"}}>
                                                        <colgroup>
                                                            <col style={{width:"150px"}}/>
                                                            <col style={{width:"250px"}}/>
                                                            <col/>
                                                        </colgroup>
                                                        <thead>
                                                        <tr>
                                                            <th>파라미터명</th>
                                                            <th>입력값</th>
                                                            <th>설명</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td>auth_key</td>
                                                            <td>
                                                                <input style={{"font-size":"16px", "border":"1px solid #ccc", "color":"#999", "min-width":"128px", "height":"35px", "padding":"0 15px"}}
                                                                       type={"text"} value={exampleParams.auth_key} onChange={ e => setExampleParams({...exampleParams,["auth_key"]: e.target.value})}/>
                                                            </td>
                                                            <td>Open API 인증키</td>
                                                        </tr>
                                                        <tr>
                                                            <td>center_code</td>
                                                            <td>
                                                                <input style={{"font-size":"16px", "border":"1px solid #ccc", "color":"#999", "min-width":"128px", "height":"35px", "padding":"0 15px"}}
                                                                       type={"text"} value={exampleParams.center_code} onChange={ e => setExampleParams({...exampleParams,["center_code"]: e.target.value})}/>
                                                            </td>
                                                            <td>데이터 제공기관 코드 ( 제공기관 목록 조회 API를 통해 응답 받은 코드 데이터 )</td>
                                                        </tr>
                                                        <tr>
                                                            <td>data_name</td>
                                                            <td>
                                                                <input style={{"font-size":"16px", "border":"1px solid #ccc", "color":"#999", "min-width":"128px", "height":"35px", "padding":"0 15px"}}
                                                                       type={"text"} value={exampleParams.data_name} onChange={ e => setExampleParams({...exampleParams,["data_name"]: e.target.value})}/>
                                                            </td>
                                                            <td>데이터명 ( 데이터 목록 조회 API를 통해 응답 받은 데이터 )</td>
                                                        </tr>
                                                        <tr>
                                                            <td>page_num</td>
                                                            <td>
                                                                <input style={{"font-size":"16px", "border":"1px solid #ccc", "color":"#999", "min-width":"128px", "height":"35px", "padding":"0 15px"}}
                                                                       type={"text"} value={exampleParams.page_num} onChange={ e => setExampleParams({...exampleParams,["page_num"]: e.target.value})}/>
                                                            </td>
                                                            <td>페이지 번호 ( 최소 1 )</td>
                                                        </tr>
                                                        <tr>
                                                            <td>page_size</td>
                                                            <td>
                                                                <input style={{"font-size":"16px", "border":"1px solid #ccc", "color":"#999", "min-width":"128px", "height":"35px", "padding":"0 15px"}}
                                                                       type={"text"} value={exampleParams.page_size} onChange={ e => setExampleParams({...exampleParams,["page_size"]: e.target.value})}/>
                                                            </td>
                                                            <td>한 페이지 결과 수 ( 최대 10000 )</td>
                                                        </tr>

                                                        </tbody>
                                                    </table>
                                                    <div className="btn-group ac wrap2-btn-wrap">
                                                        <button style={{minWidth: "60px", height: "30px", fontSize:"12px", backgroundColor: "#3b5877"}} onClick={e => exampleRequest(apiExample)}>요청</button>
                                                        <button style={{minWidth: "60px", height: "30px", fontSize:"12px", backgroundColor: "#3b5877"}} onClick={e => downloadExSrc(apiExample)}>Python 예제</button>
                                                        <button style={{minWidth: "60px", height: "30px", fontSize:"12px", backgroundColor: "#3b5877"}} onClick={e => closeRequest()}>닫기</button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                <tr>
                                    <td>잔여 요청 건수 조회</td>
                                    <td>발급 받은 키의 잔여 요청 건수를 조회 합니다.</td>
                                    <td>없음</td>
                                    <td>
                                        <div className="btn-group ac wrap2-btn-wrap" style={{marginTop: 0}}>
                                            <button style={{minWidth: "60px", height: "30px", fontSize:"12px", backgroundColor: "#3b5877"}} onClick={e => showRequest("read_remain_key")}>열기</button>
                                        </div>
                                    </td>
                                </tr>
                                    {apiExample === "read_remain_key" &&
                                        <tr style={{backgroundColor: "#f9f9f9"}}>
                                            <td colSpan={4}>
                                                <div style={{padding: "25px 20px"}}>
                                                    <p style={{fontSize: '18px', marginBottom: "15px", color:"black"}}>필요 파라미터</p>
                                                    <table style={{textAlign: "center", borderCollapse: "collapse", border:"1px solid #dcddde"}}>
                                                        <colgroup>
                                                            <col style={{width:"150px"}}/>
                                                            <col style={{width:"250px"}}/>
                                                            <col/>
                                                        </colgroup>
                                                        <thead>
                                                        <tr>
                                                            <th>파라미터명</th>
                                                            <th>입력값</th>
                                                            <th>설명</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td>auth_key</td>
                                                            <td>
                                                                <input style={{"font-size":"16px", "border":"1px solid #ccc", "color":"#999", "min-width":"128px", "height":"35px", "padding":"0 15px"}}
                                                                       type={"text"} value={exampleParams.auth_key} onChange={ e => setExampleParams({...exampleParams,["auth_key"]: e.target.value})}/>
                                                            </td>
                                                            <td>Open API 인증키</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    <div className="btn-group ac wrap2-btn-wrap">
                                                        <button style={{minWidth: "60px", height: "30px", fontSize:"12px", backgroundColor: "#3b5877"}} onClick={e => exampleRequest(apiExample)}>요청</button>
                                                        <button style={{minWidth: "60px", height: "30px", fontSize:"12px", backgroundColor: "#3b5877"}} onClick={e => downloadExSrc(apiExample)}>Python 예제</button>
                                                        <button style={{minWidth: "60px", height: "30px", fontSize:"12px", backgroundColor: "#3b5877"}} onClick={e => closeRequest()}>닫기</button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                <tr>
                                    <td>API 키 갱신</td>
                                    <td>발급 받은 키의 사용기간을 갱신 합니다. ( 7일 연장 )</td>
                                    <td>없음</td>
                                    <td>
                                        <div className="btn-group ac wrap2-btn-wrap" style={{marginTop: 0}}>
                                            <button style={{minWidth: "60px", height: "30px", fontSize:"12px", backgroundColor: "#3b5877"}} onClick={e => showRequest("extend_key")}>열기</button>
                                        </div>
                                    </td>
                                </tr>
                                    {apiExample === "extend_key" &&
                                        <tr style={{backgroundColor: "#f9f9f9"}}>
                                            <td colSpan={4}>
                                                <div style={{padding: "25px 20px"}}>
                                                    <p style={{fontSize: '18px', marginBottom: "15px", color:"black"}}>필요 파라미터</p>
                                                    <table style={{textAlign: "center", borderCollapse: "collapse", border:"1px solid #dcddde"}}>
                                                        <colgroup>
                                                            <col style={{width:"150px"}}/>
                                                            <col/>
                                                        </colgroup>
                                                        <thead>
                                                            <tr>
                                                                <th>파라미터명</th>
                                                                <th>설명</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>auth_key</td>
                                                                <td>Open API 인증키</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div className="btn-group ac wrap2-btn-wrap">
                                                        <button style={{minWidth: "60px", height: "30px", fontSize:"12px", backgroundColor: "#3b5877"}} onClick={e => downloadExSrc(apiExample)}>Python 예제</button>
                                                        <button style={{minWidth: "60px", height: "30px", fontSize:"12px", backgroundColor: "#3b5877"}} onClick={e => closeRequest()}>닫기</button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
        </>
    )
};

export default AnalysisService;

