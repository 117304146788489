/**
 * 메인 페이지 -> 이용 안내 메세지
 */
class MainPageTourMessage {
    constructor() {
        this.tourConfig =  [
            {
                selector: '[data-tut="search_helper"]',
                content: '찾고싶은 데이터를 여기에 입력하여 검색해 보세요!'
            },
            {
                selector: '[data-tut="pop_keyword_helper"]',
                content: `가장 많이 검색된 키워드 클릭시 키워드로 데이터 검색이 가능해요!`
            },
            {
                selector: '[data-tut="category_helper"]',
                content: `찾고싶은 데이터의 유형을 클릭해서 데이터를 찾아 보세요!`
            },
            {
                selector: '[data-tut="data_rank_helper"]',
                content: `인기 조회/다운로드 데이터와 최신 데이터를 확인할 수 있어요! 클릭시 해당 데이터로 이동 됩니다!`
            },

        ]
    }
}

/**
 * 데이터셋 -> 데이터 리스트
 */
class DataSetListPageTourMessage {
    constructor() {
        this.tourConfig =  [
            {
                selector: '[data-tut="dataset_filter"]',
                content: '다양한 검색 필터를 활용하여 원하는 데이터를 쉽게 검색이 가능하고 데이터 검색 결과를 정렬하는것도 가능해요!'
            },
            {
                selector: '[data-tut="dataset_list"]',
                content: `검색된 데이터의 항목은 한눈에 보기쉽게 데이터셋의 제목, 설명, 제공기관, 가격, 사이즈, 갱신일, 등록일, 데이터 포맷이 표시 됩니다!`
            },
            {
                selector: '[data-tut="dataset_list_component1"]',
                content: `해당 컴포넌트는 데이터의 미리보기 제공 유무를 표시 합니다!`
            },
            {
                selector: '[data-tut="dataset_list_component2"]',
                content: `해당 컴포넌트는 데이터의 파일 포맷을 표시 합니다!`
            },
        ]
    }
}

/**
 * 데이터셋 -> 데이터 상세
 */
class DataSetDetailPageTourMessage {
    constructor() {
        this.tourConfig =  [
            {
                selector: '[data-tut="dataset_subject"]',
                content: '데이터셋의 제목과 설명을 한눈에 확인 할 수 있으며 데이터셋 링크 공유, 관심데이터 등록, 데이터 오류 신고 기능을 사용 할 수 있습니다.'
            },
            {
                selector: '[data-tut="dataset_meta"]',
                content: `데이터셋을 제공한 기관, 데이터 생성일자, 가격(무료/유료(VAT 포함 가격), 데이터셋을 나타내는 대표 키워드 등을 통해 데이터셋에 전체적인 정보를 확인 할 수 있습니다.`
            },
            {
                selector: '[data-tut="dataset_info"]',
                content: `데이터 이용 시 '데이터 이용신청' 버튼을 통해 이용신청 후 '데이터 다운' 버튼을 통해 이용하면 되고 유료 데이터인 경우 '데이터 이용신청' 및 결제 후 데이터 다운을 통해 사용 가능합니다. '샘플 데이터 다운'은 데이터셋 중 일부 확인 가능하며, '데이터 정보 다운'은 데이터셋에 관한 컬럼, 한글컬럼, 컬럼설명 등을 볼 수 있습니다.`
            }
        ]
    }
}

/**
 * 활용 -> 활용갤러리
 */
class UserGalleryPageTourMessage {
    constructor() {
        this.tourConfig =  [
            {
                selector: '[data-tut="use_gallery_search"]',
                content: '활용갤러리에 등록 된 게시물을 제목 또는 제목+소개 형식으로 검색이 가능하며, 로그인 후 게시물 등록이 가능합니다!'
            },
            {
                selector: '[data-tut="use_gallery_body"]',
                content: `활용갤러리에 등록 된 게시물들을 볼 수 있으며 게시물 클릭 시 상세내역을 확인 할 수 있습니다!`
            }
        ]
    }
}

/**
 * 활용 -> 데이터 시각화
 */
class VisualizationTourMessage {
    constructor() {
        this.tourConfig =  [
            {
                selector: '[data-tut="visualization_search"]',
                content: '데이터 시각화에 등록 된 게시물을 제목 또는 제목+설명 형식으로 검색이 가능하며, 로그인 후 데이터 시각화 서비스 이용이 가능합니다!'
            },
            {
                selector: '[data-tut="visualization_body"]',
                content: `데이터 시각화에 등록 된 게시물들을 볼 수 있으며 썸네일 이미지 다운 및 Iframe으로 구성 된 소스를 확인 할 수 있으며 게시물 클릭 시 상세내역을 확인 할 수 있습니다!`
            }
        ]
    }
}

/**
 * 게시판 묻고답하기
 */
class BoardTourMessage {
    constructor() {
        this.tourConfig =  [
            {
                // 묻고 답하기
                selector: '[data-tut="board_search_1"]',
                content: '묻고 답하기 게시판 내 게시글을 제목 또는 제목+내용 형식으로 검색이 가능합니다.'
            },
            {
                selector: '[data-tut="board_body_1"]',
                content: `묻고 답하기 게시글 리스트를 나타내고 포털 내 사용법 문의를 할 수 있는 곳입니다.`
            }
        ]
    }
}

/**
 * 게시판 묻고답하기(데이터문의)
 */
class BoardTourMessage2 {
    constructor() {
        this.tourConfig =  [
            {
                // 데이터 문의
                selector: '[data-tut="board_search_2"]',
                content: '데이터 문의 게시판 내 게시글을 제목 또는 제목+내용 형식으로 검색이 가능합니다.'
            },
            {
                selector: '[data-tut="board_body_2"]',
                content: `데이터 문의 게시글 리스트를 나타내고 포털 내 업로드 되어 있는 데이터에 대한 문의를 할 수 있는 곳입니다.`
            }
        ]
    }
}

/**
 * 게시판 묻고답하기(통합게시판)
 */
class BoardTourMessage3 {
    constructor() {
        this.tourConfig =  [
            {
                // 데이터 문의
                selector: '[data-tut="board_search_3"]',
                content: '데이터 문의 게시판 내 게시글을 제목 또는 제목+내용 형식으로 검색이 가능합니다.'
            },
            {
                selector: '[data-tut="board_body_3"]',
                content: `전체 게시판에 게시글 리스트를 나타내며 게시판 종류 및 게시글을 볼 수 있습니다.`
            }
        ]
    }
}

/**
 * 게시판 묻고답하기(공지사항)
 */
class BoardTourMessage4 {
    constructor() {
        this.tourConfig =  [
            {
                // 데이터 문의
                selector: '[data-tut="board_search_4"]',
                content: '공지사항 내 게시글을 제목 또는 제목+내용 형식으로 검색이 가능합니다.'

            },
            {
                selector: '[data-tut="board_body_4"]',
                content: `공지사항 리스트를 나타내며 포털 내 공모전, 점검안내 등을 나타냅니다.`
            }
        ]
    }
}

/**
 * 게시판 묻고답하기(자주하는질문)
 */
class BoardTourMessage5 {
    constructor() {
        this.tourConfig =  [
            {
                // 데이터 문의
                selector: '[data-tut="board_search_5"]',
                content: '자주하는질문 게시판 내 게시글을 제목 또는 제목+내용 형식으로 검색이 가능합니다.'
            },
            {
                selector: '[data-tut="board_body_5"]',
                content: `자주하는 질문 리스트를 나타내며 포털 운영 시 자주하는 질문을 나타냅니다.`
            }
        ]
    }
}
export default {MainPageTourMessage, DataSetListPageTourMessage, UserGalleryPageTourMessage, VisualizationTourMessage,
    BoardTourMessage, BoardTourMessage2, BoardTourMessage3, BoardTourMessage4, BoardTourMessage5, DataSetDetailPageTourMessage}

