import React, {useEffect, useState} from 'react';

const Tab = ({children, initTab, ...props}) => {

    let [position, setPosition] = useState(initTab);

    useEffect(() => {
        if (position === "") {
            setPosition(children[0].props.id)
        }
    }, [position, children]);

    const getChildren = () => {
        return children.find(child => {
            return child?.props.id === position;
        });
    };

    const getButtons = () => {
        return children.map(child => {
            if(child){
                let addClass = "";
                if(child.props.id === position)
                    addClass = " clicked";
                return (
                    <button onClick={() => setPosition(child.props.id)}
                            key={child.props.id}
                            className={"ikan-tab-button" + addClass}
                    >
                        {child.props.name}
                    </button>
                )
            }
        });
    };

    return (
        <div className={"ikan-tab"}>
            <div className={"ikan-tab-button-wrap"}>
                {getButtons()}
            </div>
            <div className={"ikan-tab-child-wrap"}>
                {getChildren()}
            </div>
        </div>
    )
};

Tab.defaultProps = {
    initTab: ""
};

export default Tab;
