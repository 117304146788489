import React from 'react';
import SubNav from "../../components/SubNav";


const LocationLanding = (props) => {

    let subNav = [
        {
            display: "활용 갤러리",
            name: "/use"
        },{
            name: "데이터 시각화",
            onClick:()=>{
                let link="http://visualization.bigdata-region.kr:80";
                window.open(link)
            }
        },{
            display: "기업입지 추천 서비스",
            name: "/landing"
        }
    ];


    return (
        <>
            <SubNav nav={subNav} history={props.history}>
                <iframe src="http://loc.bigdata-region.kr/location/landing" width={1100} height={1080}  margin={362}/>
            </SubNav>
        </>
    )
};

export default LocationLanding;
