export const yyyymmdd = (date=new Date(), glue = "-") => {
    let mm = date.getMonth() + 1;
    let dd = date.getDate();

    return [date.getFullYear(),
        (mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd
    ].join(glue);
};

export const yyyymmddhhiiss = (date=new Date(), glue = "-", glue2 = ":") => {
    let mm = date.getMonth() + 1;
    let dd = date.getDate();

    let hh = date.getHours();
    let ii = date.getMinutes();
    let ss = date.getSeconds();

    return [date.getFullYear(),
        (mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd
    ].join(glue) + " " + [
        (hh > 9 ? '' : '0') + hh,
        (ii > 9 ? '' : '0') + ii,
        (ss > 9 ? '' : '0') + ss].join(glue2);
};

export const timeSince = (date) => {

    let seconds = Math.floor((new Date() - date) / 1000);

    let interval = Math.floor(seconds / (60 * 60 * 24 * 30 * 12));
    if (interval >= 1) return interval + " 년 전";

    interval = Math.floor(seconds / (60 * 60 * 24 * 30));
    if (interval >= 1) return interval + " 개월 전";

    interval = Math.floor(seconds / (60 * 60 * 24));
    if (interval >= 1) return interval + " 일 전";

    interval = Math.floor(seconds / (60 * 60));
    if (interval >= 1) return interval + " 시간 전";

    interval = Math.floor(seconds / 60);
    if (interval >= 1) return interval + " 분 전";

    return Math.floor(seconds) + " 초 전";
};

export const timestampJavaScriptToPython = (timestamp) => {
    // 자바스크립트의 TimeStamp는 MicroSecond 를 정수로 받아들이지만 (최대 3자리)
    // 파이썬의 TimeStamp는 소숫점 이하로 받아들임.
    // 따라서 1000을 나눔으로써 Python과의 호환성을 확보.
    return timestamp / 1000;
}