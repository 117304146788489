import 'core-js/es';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// reset and normalize
import './assets/css/reset.css';
import './assets/css/normalize.css';
import './assets/css/style_data.css';
import './assets/css/renewal.scss';
import 'swiper/swiper-bundle.min.css';

import {Provider} from "react-redux";
import {createStore} from "redux";
import Reducers from "./store/reducers";

const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
const store = createStore(Reducers, devTools);

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>
    , document.getElementById('root'));
