import React, {useEffect, useState, useRef} from 'react';
import {useSelector} from "react-redux";
import * as DataApi from "../../api/Data";
import * as OrderApi from "../../api/Order"
import * as FavoriteDataApi from "../../api/FavoriteData"
import swal from "sweetalert";
import MetaInfoView from "../../components/MetaInfoView";
import DatasetResource from "../../components/DatasetResourceCompetition";
import {yyyymmdd} from "../../util/Date";
import Indicator from "../../components/Indicator";
import {delCookie} from "../../util/Cookie";
import {terms} from "../../util/Terms";
import ErrorPage404 from "../common/ErrorPage404";
import * as UserAPI from "../../api/User";
// import {Link} from "react-router-dom";
// import DatasetMetaView from "../../components/DatasetMetaView";
// import swal2 from "sweetalert2";
// import {TabItem} from "../../components/Tab";
// import {addComma} from "../../util/Number";
// import CheckBox from "../../components/CheckBox";
// import SelectBox from "../../components/SelectBox";
// import ShareSNS from "../../components/SNS/ShareSNS";
// import shareImg from "../../assets/images/ico_share.png";
// import DatasetComment from "../../components/DatasetComment";
// import {checkCompetition} from "../../api/User";

let paramsId = null;
const Item = ({match, ...props}) => {
    const userInfo = useSelector(state => state.user.info);
    let [checkCompetition, setCheckCompetition] = useState(true);

    let [dataInfo, setDataInfo] = useState(null);
    let [show404, setShow404] = useState(false);
    const isLogin = useSelector(state => state.user.isLogin);

    if(!isLogin) {
        swal("로그인 후 이용 가능한 서비스입니다.")
        window.location.href = "/#/login"
    }

    let competition_params = {
        'user_id': userInfo.id,
        'token': userInfo.id_key
    };

    useEffect(() => {
        UserAPI.checkCompetition(competition_params)
            .then(res => {
                if (!res.result) {
                    swal("공모전 참가 신청 후 이용해주시길 바랍니다.")
                    window.location.href = "/#/"
                }
            });
    },[]);

    useEffect(() => {
        if(paramsId !== match.params.id || paramsId === match.params.id){
            let params = {
                id: match.params.id
            };
            DataApi.readDataByCompetition(params).then(res => {
                // console.log(res);
                setDataInfo(res.result);
            }).catch(res => {
                setShow404(true);
            });

            paramsId = match.params.id;
        }
    }, [match.params.id]);

    return (
        <>
            {dataInfo !== null ? <ItemInfo key={dataInfo.id} dataInfo={dataInfo} {...props}/> : <div><Indicator /></div>}
            {show404 && <ErrorPage404/>}
        </>
    )

};

export default Item;

const ItemInfo = ({dataInfo, ...props}) => {

    const userInfo = useSelector(state => state.user.info);
    const isLogin = useSelector(state => state.user.isLogin);

    let [relationship, setRelationship] = useState([]);
    let [linkedFrom, setLinkedFrom] = useState([]);
    let [targetId, setTargetId] = useState(dataInfo.id);

    let [purpose, setPurpose] = useState('');
    let [plan, setPlan] = useState('');
    let [isCheckPurpose, setCheckPurpose] = useState(false);
    let [isCheckPlan, setCheckPlan] = useState(false);
    let [isShowOrder, setIsShowOrder] = useState(false);
    let [isShowReport, setIsShowReport] = useState(false);
    let [isShowShare, setIsShowShare] = useState(false);

    let [spinner1, setSpinner1] = useState(false);
    let [spinner2, setSpinner2] = useState(false);
    let [spinner3, setSpinner3] = useState(false);

    let [isSubmit, setIsSubmit] = useState(false);

    const downloadFrame = useRef(null);
    const paymentFrame = useRef(null);

    const [policyAgree, setPolicyAgree] = useState(true);
    const [readPolicy, setReadPolicy] = useState(false);

    const [selectCategoryDemand, setSelectCategoryDemand] = useState(userInfo.user_type);
    const categoryDemand = useSelector(state => {
        let rst = [];

        for (let [name, display] of Object.entries(state.user.categoryDemand)) {
            rst.push({
                display: display,
                name: name
            })
        }

        return rst;
    });

    // 이용신청 카테고리
    const [selectCategoryPurpose, setSelectCategoryPurpose] = useState(false);
    const [selectCategoryEtc, setSelectCategoryEtc] = useState(null);
    const [inputCategoryEtc, setInputCategoryEtc] = useState("");

    const categoryPurpose = useSelector(state => {
        let rst = [];

        for (let [name, display] of Object.entries(state.user.categoryPurpose)) {
            rst.push({
                display: display,
                name: name
            })
        }

        return rst;
    });

    // 데이터 오류 신고 카테고리
    const [selectCategoryReportType, setSelectCategoryReportType] = useState(false);
    const [selectCategoryReportEtc, setSelectCategoryReportEtc] = useState(null);
    const [inputCategoryReportEtc, setInputCategoryReportEtc] = useState("");

    const categoryReportType = useSelector(state => {
        let rst = [];

        for (let [name, display] of Object.entries(state.user.categoryReportType)) {
            rst.push({
                display: display,
                name: name
            })
        }

        return rst;
    });

    useEffect(() => {
        let params = {
            id: targetId
        };
        DataApi.readRelationship(params).then(res => {
            let relation_data = res.result.relation_data.map(item => item.result);
            let linked_from = res.result.linked_from.map(item => item.result);

            setLinkedFrom(linked_from);
            setRelationship(relation_data);
        });
    }, [targetId]);

    const addFavoriteData = (user_id, data_info) => {
        if(!isLogin) {
            swal({
                text: "로그인 후 이용 가능한 서비스입니다.",
                buttons: [
                    "확인",
                    "로그인"
                ]
            }).then(function (isConfirm) {
                if(isConfirm) {
                    delCookie("token");
                    window.location.href = "/#/login";
                } else {
                    swal.close();
                }
            })
        }
        // swal2.fire({text:"로그인 후 이용 가능한 서비스입니다.",
        //     confirmButtonText:"로그인",
        //     cancelButtonText:"확인",
        //     showCancelButton: true
        // }).then((result) => {
        //     if(result.value) {
        //         delCookie("token");
        //         window.location.href = "/#/login";
        //     }
        // });
        else{
            let params = {
                user_id_key: user_id,
                package_id: data_info.id,
                package_name: data_info.title
            };

            FavoriteDataApi.addFavoriteData(params)
                .then(res => {
                    swal('관심데이터 등록이 성공하였습니다.')
                })
                .catch(res => {
                    let rst = JSON.parse(res.response);
                    if(rst.message === "session_expire"){

                    }else swal('관심데이터가 이미 등록이 되어있습니다.');
                })
        }
    };


    let Catalog_Catalog = [];
    let DataService_DataService = [];
    let DataSet_DataSet = [];
    let DataSet_Distribution = [];
    let Ikan_Extra = [];

    let dataExtras = dataInfo.extras;

    let metaInfoWithRedux = useSelector(state => state.data.metaInfo);

    let metaInfo = JSON.parse(JSON.stringify(metaInfoWithRedux));


    dataExtras.map(meta => {
        // dataInfo.extras.map(meta => {
        let keyArr = meta.key.split("_");
        let target;
        if(keyArr[0]+"_"+keyArr[1] === "Catalog_Catalog") target = Catalog_Catalog;
        else if(keyArr[0]+"_"+keyArr[1] === "DataService_DataService") target = DataService_DataService;
        else if(keyArr[0]+"_"+keyArr[1] === "DataSet_DataSet") target = DataSet_DataSet;
        else if(keyArr[0]+"_"+keyArr[1] === "DataSet_Distribution") target = DataSet_Distribution;
        else if(keyArr[0]+"_"+keyArr[1] === "Ikan_Extra") target = Ikan_Extra;

        let keyText = meta.key.replace(keyArr[0]+"_"+keyArr[1]+"_", "");

        if(target !== undefined)
            target.push({
                key: keyText,
                display: meta.display,
                value: meta.value
            });
    });

    const searchInfoToWhat=(info,what)=>{
        let date="";
        info.map(i=> {
                if (i.key === what) {
                    date=i.value.replaceAll("-",".")
                    date=date.replace("년",".")
                    date=date.replace("월",".")
                    date=date.replace("일","")
                }
            }
        )
        return date
    }

    Object.keys(metaInfo).map((key, index) => {

        let target;
        if(key === "Catalog_Catalog") target = Catalog_Catalog;
        else if(key === "DataService_DataService") target = DataService_DataService;
        else if(key === "DataSet_DataSet") target = DataSet_DataSet;
        else if(key === "DataSet_Distribution") target = DataSet_Distribution;
        else if(key === "Ikan_Extra") target = Ikan_Extra;

        if(target !== undefined)
            metaInfo[key].map((meta, idx) => {
                target.some(t => {
                    if(t.key === meta.key)
                        metaInfo[key][idx] = {
                            ...metaInfo[key][idx],
                            value: t.value
                        };

                    return t.key === meta.key;
                });

            });
    });

    metaInfo.view = [];

    let meta_ikan_extra = JSON.parse(JSON.stringify(metaInfo.Ikan_Extra));

    // 가격문의 메타 설정
    let Ikan_Extra_priceInquiry = undefined;
    meta_ikan_extra.some(row => {
        if(row.key === "priceInquiry") {
            Ikan_Extra_priceInquiry = row.value.toLowerCase();
        }
    });

    // let metas = ["creator_name", "accrualPeriodicity", "issued", "modified", "license", "language", "version", "ds_price", "keyword"];
    // 200717 수정
    // custom_modfied = ckan에서 제공하는 최초등록일 및 갱신일자
    let metas = ["creator_name", "accrualPeriodicity", "issued", "custom_modified", "license", "language", "version", "priceInfo", "keyword", "contactPoint_phone"];
    let dataInfo_metadata_created = dataInfo.metadata_created;
    let dataInfo_metadata_modified =searchInfoToWhat(dataInfo.extras,"DataSet_DataSet_modified")

    metas.map(key => {
        let meta_dataset = JSON.parse(JSON.stringify(metaInfo.DataSet_DataSet));
        let meta_dataservice = JSON.parse(JSON.stringify(metaInfo.DataService_DataService));

        if (key === "custom_modified") {
            let custom_metadata = {
                "display": "갱신일자" + "<small>(최초등록일)</small>",
                "key": "DataSet_DataSet_modified",
                "value": yyyymmdd(new Date(
                    (dataInfo_metadata_modified)?dataInfo_metadata_modified:dataInfo_metadata_created
                )) + "<br/> <small>" + yyyymmdd(new Date(dataInfo_metadata_created)) + "</small>"
            };
            metaInfo.view.push(custom_metadata)
        }

        meta_dataservice.some(row => {
            if (key === row.key) {
                // priceInfo가 공급가로 바뀜 따로 원가 저장하는 메타데이터 필드 생성 됨
                if(row.key === "priceInfo") {
                    /*
                        Ikan_Extra_priceInquiry
                        true 가격문의
                        false 가격있음
                    */
                    row.value = "-";

                    metaInfo.view.push(row)
                }
            }
        });

        meta_dataset.some(row => {
            if(key === row.key){
                if(row.value === undefined)
                    row.value = "";

                // let check_col = metaInfo.DataService_DataService.filter((v) => (v.key === 'priceInfo'));
                // let price_tmp = check_col[0];
                // if(row.key === "ds_price"){
                //     if (Number.isInteger(Number(price_tmp.value)) && price_tmp.value < 0) {
                //         // row.value = addComma(row.value) + "원";
                //         price_tmp.value = "가격문의";
                //     }
                //     else if (!price_tmp.value || Number(price_tmp.value) === 0) {
                //         price_tmp.value = "무료";
                //     }
                //     row.value = price_tmp.value;
                // }

                if(row.key === "language")
                    row.value = "한국어"; // 2019-12-17 JSJ - 한국어 고정

                if(row.value === "")
                    row.value = "-";

                metaInfo.view.push(row);
            }
            return key === row.key;
        });
    });
    // console.log(metaInfo)

    const fileDownload = (resourceId) => {
        // swal("경기지역경제포털이 곧 정식 오픈됩니다.\n조금만 기다려 주세요");

        setSpinner2(true);
        let params = {
            resource_id: resourceId,
            user_id: userInfo.id_key,
            package_name: dataInfo.title
        };
        DataApi.downloadDataByCompetition(params).then(res => {
            let download_url = res.result.download_url + "?fn=" + res.result.file_name;
                let varExt = res.result.file_name.split('.');
                if (varExt[varExt.length - 1] === "txt" || varExt[varExt.length - 1] === "pdf") {
                    window.open(download_url);
                } else {
                    downloadFrame.current.src = download_url;
            }
            setSpinner2(false);
        }).catch(res => {
            setSpinner2(false);
        });
        // DataApi.downloadPreviewData(params).then(res => {
        //     downloadFrame.current.src = res.result;
        //     setSpinner2(false);
        // }).catch(res => {
        //     setSpinner2(false);
        // });
    };
    const sampleFileDownload = (sampleId) => {
        // swal("경기지역경제포털이 곧 정식 오픈됩니다.\n조금만 기다려 주세요");

        setSpinner1(true);
        let params = {
            sample_id: sampleId,
        };
        DataApi.downloadPreviewData(params).then(res => {
            downloadFrame.current.src = res.result;
            setSpinner1(false);
        }).catch(res => {
            swal('등록된 샘플파일이 없습니다.')
            setSpinner1(false);
        });
    };

    const schemaFileDownload = (schemaId) => {
        // swal("경기지역경제포털이 곧 정식 오픈됩니다.\n조금만 기다려 주세요");

        setSpinner3(true);
        let params = {
            schema_id: schemaId,
        };
        DataApi.downloadSchemaInfoData(params).then(res => {
            downloadFrame.current.src = res.result;
            setSpinner3(false);
        }).catch(res => {
            swal('등록된 스키마 정보 파일이 없습니다.')
            setSpinner3(false);
        });
    };

    const handleOrderRequest = () => {
        // swal("경기지역경제포털이 곧 정식 오픈됩니다.\n조금만 기다려 주세요");
        if(!isLogin) {
            swal({
                text: "로그인 후 이용 가능한 서비스입니다.",
                buttons: [
                    "확인",
                    "로그인"
                ]
            }).then(function (isConfirm) {
                if(isConfirm) {
                    delCookie("token");
                    window.location.href = "/#/login";
                } else {
                    swal.close();
                }
            })
        }
        // swal2.fire({text:"로그인 후 이용 가능한 서비스입니다.",
        //     confirmButtonText:"로그인",
        //     cancelButtonText:"확인",
        //     showCancelButton: true
        // }).then((result) => {
        //     if(result.value) {
        //         delCookie("token");
        //         window.location.href = "/#/login";
        //     }
        // });

        else {
            setIsShowOrder(true);
        }
    };
    const handleDataReport = () => {
        if(!isLogin) {
            swal({
                text: "로그인 후 이용 가능한 서비스입니다.",
                buttons: [
                    "확인",
                    "로그인"
                ]
            }).then(function (isConfirm) {
                if(isConfirm) {
                    delCookie("token");
                    window.location.href = "/#/login";
                } else {
                    swal.close();
                }
            })
        }
        else{
            setIsShowReport(true)
        }
    }
    const checkEmptyValue = (target, setValue, value) => {
        if (value.length !== 0) {
            target(true);
            setValue(value);
        }
        else {
            target(false);
        }
    };

    const isPassValidate = () => {
        let validateArr = [isCheckPurpose, isCheckPlan, policyAgree];

        return validateArr.indexOf(false) < 0;
    };

    const isPayment = (dataInfo) => {
        if(selectCategoryPurpose === false){
            swal("활용목적을 선택해주세요.");

            return
        }
        if (selectCategoryEtc === "기타") {
            if(inputCategoryEtc == null || inputCategoryEtc === "" || inputCategoryEtc === " ") {
                swal ("활용목적을 입력해주세요.")

                return
            }
        }

        if(true){
            let price = 0;
            dataInfo.extras.map(row => {
                //임시 수정
                if(row.key === "DataService_DataService_priceInfo"){
                    price = Number(row.value);
                }
            });

            if(Ikan_Extra_priceInquiry === "true" || price < 0){
                let params = {
                    user_id_key: userInfo.id_key,
                    package_id: dataInfo.id,
                    quantity: 1,
                    price: 0,
                    payment: "Inquiry",
                    user_name: userInfo.id,
                    package_name: dataInfo.title,
                    purpose: selectCategoryPurpose,
                    plan: selectCategoryDemand,
                    etc_desc: inputCategoryEtc
                };
                OrderApi.addOrder(params)
                    .then(res => {
                        swal('안녕하세요 경기지역경제포털 입니다.\n' +
                            '해당 데이터는 제공 기관 담당자에게 문의해주시기 바랍니다. \n' +
                            '감사합니다.');
                        setIsShowOrder(false);
                        setIsSubmit(false);
                    })
                    .catch(res => {
                        setIsShowOrder(false);
                        setIsSubmit(false);
                    });
            }
            else if(price >= 1000){

                let userAgent = navigator.userAgent.toLowerCase();
                var params = {
                    "itemname": dataInfo.title,
                    "itemid": dataInfo.id,
                    "username": userInfo.id,
                    "useremail": userInfo.email,
                    "userphone": userInfo.phone,
                    "useridkey": userInfo.id_key,
                    "purpose": selectCategoryPurpose,
                    "plan": selectCategoryDemand,
                    "etc_desc": inputCategoryEtc,
                    "url": window.location.href
                };
                var form = document.createElement("form");
                form.method = "POST";
                form.target = "paymentFrame";
//                form.action = process.env.REACT_APP_PAYMENT_API_URL;

                // 모바일 구분
                if (userAgent.indexOf("android") > -1 || userAgent.indexOf("iphone") > -1 || userAgent.indexOf("ipad") > -1 || userAgent.indexOf("ipod") > -1) {
                    form.action = process.env.REACT_APP_PAYMENT_MOBILE_API_URL;
                } else {
                    form.action = process.env.REACT_APP_PAYMENT_API_URL;
                }

                for(let key in params){
                    let hiddenField = document.createElement("input");
                    hiddenField.setAttribute("type", "hidden");
                    hiddenField.setAttribute("name", key);
                    hiddenField.setAttribute("value", params[key]);
                    form.appendChild(hiddenField);
                }

                document.body.appendChild(form);
                form.submit();
                form.parentNode.removeChild(form);

                setIsSubmit(true);

                let receiveMessage = (event) => {
                    let response = event.data;

                    if(response.type === "cancel"){
                        swal("취소되었습니다.");
                        setIsSubmit(false);
                    }else if(response.type === "error"){
                        swal(response.returnMsg + "\n\n문제가 계속될 경우 고객센터로 문의 바랍니다.");
                        setIsSubmit(false);
                    }else if(response.type === "success"){
                        swal('이용신청이 완료되었습니다.');
                        setIsShowOrder(false);
                        setIsSubmit(false);
                    }
                };

                window.addEventListener("message", receiveMessage, false);
            }else{
                let params = {
                    user_id_key: userInfo.id_key,
                    package_id: dataInfo.id,
                    quantity: 1,
                    price: 0,
                    payment: "FREE",
                    user_name: userInfo.id,
                    package_name: dataInfo.title,
                    purpose: selectCategoryPurpose,
                    plan: selectCategoryDemand,
                    etc_desc: inputCategoryEtc
                };
                OrderApi.addOrder(params)
                    .then(res => {
                        swal('이용신청이 완료되었습니다.');
                        setIsShowOrder(false);
                        setIsSubmit(false);
                    })
                    .catch(res => {
                        setIsShowOrder(false);
                        setIsSubmit(false);
                    });
            }
            setInputCategoryEtc(null);
            setSelectCategoryEtc(null);
            setSelectCategoryPurpose(false);
        }
    };

    let paymentFrameStyle = {};
    let paymentFrameClass = "";
    if(isSubmit){
        paymentFrameStyle = {};
        paymentFrameClass = " ds-go";
    }else{
        paymentFrameStyle = {
            position: "fixed",
            left: "-99999px",
            width: "1px",
            height: "1px"
        };
        paymentFrameClass = "";
    }

    //임시
    const isReadPolicy = () => {
        setReadPolicy(true);
        terms("orderPolicy");
    };

    const isCancelPopup = () => {
        setIsShowOrder(false)
        setIsShowReport(false);
        setIsShowShare(false);
        setReadPolicy(false)
        setInputCategoryEtc(null);
        setSelectCategoryEtc(null);
        setSelectCategoryPurpose(false);
        setSelectCategoryReportType(false);
        setSelectCategoryReportEtc(null);
    };

    const dataReportRequest = (dataInfo) => {
        if(selectCategoryReportType === false){
            swal("신고유형을 선택해주세요.");
            return
        }
        if(inputCategoryReportEtc == null || inputCategoryReportEtc === "" || inputCategoryReportEtc === " ") {
            swal ("신고내용을 입력해주세요.")
            return
        }

        if(true) {
            const package_center_code = dataInfo.extras.filter(ext => {return ext.key === "Ikan_Extra_centerCode"})[0].value;
            const creator_mail = dataInfo.extras.filter(ext => {return ext.key === "DataSet_DataSet_creator_mail"})[0].value;
            //console.log(creator_mail)
            let params = {
                package_id: dataInfo.id,
                package_name: dataInfo.title,
                creator_mail: creator_mail,
                center_code: package_center_code,
                user_id: userInfo.id,
                user_name: userInfo.fullname,
                user_email: userInfo.email,
                report_type: selectCategoryReportType,
                user_memo: inputCategoryReportEtc,
            };
            //console.log(params)
            DataApi.reportData(params)
                .then(res => {
                    swal('데이터 오류신고가 완료되었습니다.');
                    setIsShowReport(false);
                })
                .catch(res => {
                    setIsShowReport(false);
                });
        }
        setInputCategoryReportEtc(null);
        setSelectCategoryReportEtc(null);
        setSelectCategoryReportType(false);
    }

    return (
        <>
            <div className={"ds_payment-wrap" + paymentFrameClass}>
                <iframe ref={paymentFrame} title={"paymentFrame"} name={"paymentFrame"} style={paymentFrameStyle}/>
            </div>
            <iframe ref={downloadFrame} title={"downloadFrame"} name={"downloadFrame"} width={1} height={1} style={{
                position: "fixed",
                left: "-99999px"
            }}/>
            <div className={"wrap-dataset-view"}>
                <div className="title-view">
                    <h1>
                        {dataInfo.title}
                    </h1>


                    <p dangerouslySetInnerHTML={{__html:dataInfo.notes.replace(/\r\n|\r|\n/g, "<br />")}} />
                    {/*<div style={{"width":"auto", "margin":"0 auto", "overflow": "hidden"}}>*/}
                    {/*    <div style={{"float":"left", "width":"auto"}}>*/}
                    {/*        <h1>{dataInfo.title}</h1>*/}
                    {/*    </div>*/}
                    {/*    <div style={{"cursor":"pointer"}} onClick={() => setIsShowShare(true)}>*/}
                    {/*        <div style={{"float":"left", "width":"auto", "margin-left": "20px"}}>*/}
                    {/*            <img style={{'margin': "25px 0px"}} src={shareImg} width={"35px"} height={"35px"}/>*/}
                    {/*        </div>*/}
                    {/*        <div style={{"float":"left", "width":"auto","margin-left": "10px"}}>*/}
                    {/*            <div style={{'margin': "30px 0px"}}>공유하기</div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                <div className={"dataset-info"}>
                    <div className={"dataset-meta"}>
                        <MetaInfoView initialMetaInfo={metaInfo.view} key={JSON.stringify(metaInfo.view)}/>
                    </div>
                    <div className={"dataset-data"}>
                        {dataInfo.resources.map(resource => <DatasetResource key={resource.id}
                                                                             resource={resource}
                                                                             sampleInfo={dataInfo.sample_info}
                                                                             schemaInfo={dataInfo.schema_info}
                                                                             fileDownload={fileDownload}
                                                                             sampleFileDownload={sampleFileDownload}
                                                                             schemaFileDownload={schemaFileDownload}
                                                                             sampleDownloadLoading={spinner1}
                                                                             downloadLoading={spinner2}
                                                                             schemaDownloadLoading={spinner3}
                                                                             dataInfo={dataInfo}
                                                                             handleOrderRequest={handleOrderRequest}
                                                                             addFavoriteData={addFavoriteData}
                                                                             handleDataReport={handleDataReport}
                        />)}
                    </div>
                    {/*<div className={"btn-group"}>*/}
                    {/*    /!*추가*!/*/}
                    {/*    /!*<Link to={"/order/" + dataInfo.id}>*!/*/}
                    {/*    /!*    <button>*!/*/}
                    {/*    /!*        주문*!/*/}
                    {/*    /!*    </button>*!/*/}
                    {/*    /!*</Link>*!/*/}
                    {/*    <button onClick={() => handleOrderRequest()}>*/}
                    {/*        이용신청하기*/}
                    {/*    </button>*/}
                    {/*    <button onClick={() => addFavoriteData(userInfo.id_key, dataInfo)}> 찜하기</button>*/}
                    {/*    <button onClick={() => handleDataReport()} className={"data-report-button"}>오류신고</button>*/}
                    {/*</div>*/}

                </div>
            </div>
        </>
    )
};
