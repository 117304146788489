import React from 'react';
import {Link} from "react-router-dom";
import LoginComponent from '../../components/LoginForm';
import {setUserInfo, setUserInfoIsLogin} from "../../store/actions/UserAction";
import {useDispatch} from "react-redux";
import {getParameterByName} from "../../util/Url";
import * as UserApi from "../../api/User";
import {setCookie} from "../../util/Cookie";
import LoginImg from "../../assets/images/img_bg_signin.png";
import swal from "sweetalert";
import logo_nia_grey from "../../assets/images/logo_nia_default@2x.png";
import logo_ict_grey from "../../assets/images/logo_ict_default@2x.png";
import logo_gg_grey from "../../assets/images/logo_gg_default@2x.png";
import parser from 'ua-parser-js';

const Login = (props) => {
    const dispatch = useDispatch();
    const loginProcess = (id, password) => {
        const ua = parser(window.navigator.userAgent); 
        let params = {
            id: id,
            password: password,
            sessionId: sessionStorage.getItem('_sessionId') || '',
            device: ua.device?.type || 'desktop',
            os: ua.os?.name || '',
            browser: ua.browser?.name || '',
            path: props.location.pathname,
            isSite: 'site'
        };
        UserApi.readLogin(params).then(res => {
                const user_info = res.result;
                dispatch(setUserInfo({
                    id_key: user_info.id, // 아이디에 대한 key
                    id: user_info.name, // 로그인할 때 ID
                    email: user_info.email, // 이메일
                    emailAgree: user_info.emailAgree === "true",
                    address: user_info.address, // 주소 ( 아직 static )
                    phone: user_info.phone, // 번호 ( 아직 static )
                    fullname: user_info.fullname, // 유저 이름
                    apikey: user_info.apikey, // 각 유저에 apikey
                    number_created_packages: user_info.number_created_packages, // 데이터 수정 횟수
                    number_of_edits: user_info.number_of_edits, // CKAN 홈페이지 내에 작업 횟수
                    created: user_info.created, // 생성시간
                    token: user_info.id, // redis 키
                    role: user_info.role, // 권한
                    point: user_info.point, // 포인트
                    open_api_key: user_info.open_api_key,
                    appServiceId: user_info.link_target_user_id,
                    center_code: user_info.center_code, // 센터코드 없으면 null
                    detail_address: user_info.detail_address,
                    postcode: user_info.postcode,
                    user_type: user_info.user_type,
                    user_desc: user_info.user_desc,
                    only_company_sortation: user_info.only_company_sortation,
                    only_company_type: user_info.only_company_type,
                }));

                dispatch(setUserInfoIsLogin(true));
                setCookie("token", res.result.id);

                /**
                 * redirect URL 있을 경우 redirect 해주고,
                 * 없을 경우 메인화면으로 redirect 함.
                 * queryString > redirectUri
                 */
                let uriInQueryString = getParameterByName('redirectUri');
                let redirect = uriInQueryString == null ? "/new-main" : uriInQueryString;
                props.history.push(redirect);
        }).catch(res => {
            let json_res = JSON.parse(res.response);
            if (json_res.result.message === 'disable account') {
                swal({
                    text: "계정이 활성화 되지 않았습니다. \n재전송 버튼을 통해 메일을 다시 수신하여 활성화 해주세요.",
                    buttons: [
                        "취소",
                        "재전송",
                    ],
                    }).then(function (isConfirm) {
                        if(isConfirm) {
                            swal("메일을 전송중입니다.", {
                                button: false
                            });
                            let retry_params = {
                                'user_name': id
                            };
                        UserApi.createRetryEmailAuth(retry_params).then(res => {
                            swal(res.result.email + '으로 확인 메일을 보냈습니다. \n 이메일을 확인해주세요.')
                                .then(() => {
                                props.history.push("/login");
                            });
                        })
                    } else {
                        swal.close();
                    }
                })
                // Swal.fire({
                //     html: "계정이 활성화 되지 않았습니다.<br>재전송 버튼을 통해 메일을 다시 수신하여 활성화 해주세요.",
                //     showLoaderOnConfirm: true,
                //     showCancelButton: true,
                //     showConfirmButton: true,
                //     confirmButtonText: '재전송',
                //     cancelButtonText: '취소',
                //     preConfirm: (login) => {
                //         let retry_params = {
                //             'user_name': id
                //         };
                //
                //         return UserApi.createRetryEmailAuth(retry_params).then(res => {
                //             swal(res.result.email + '으로 확인 메일을 보냈습니다. \n 이메일을 확인해주세요.').then(() => {
                //                 props.history.push("/login");
                //             });
                //         })
                //     },
                //     allowOutsideClick: () => !Swal.isLoading()
                // });
            }
            /**
             *  2022 보안감사 로그인 정보 오입력 시 가입여부 식별로 수정
             */            
            //else if (json_res.result.message === 'incorrect pw') {
                /**
                 *  2022 보안감사 로그인 정보 오입력 시 가입여부 식별로 수정
                 */
                // swal('비밀번호가 정확하지 않습니다.').then( res => {
                //     document.getElementById("InputPW").focus();
                // })
            //}
            //else {
                // swal('아이디가 정확하지 않습니다.').then( res => {
                //     document.getElementById("InputID").focus();
                // })
            //}
            else{
                swal('아이디(로그인 전용 아이디) 또는 비밀번호를 잘못 입력하셨습니다. \n 입력하신 내용을 다시 확인해주세요.').then( res => {
                    document.getElementById("InputID").focus();
                })                
            }
        });

        // UserApi.readUserData();

    };


    return (
        <div className={"scroll-wrap"}>
        <div className={"login-wrap real-login"}>
            <div className={"img-wrap"}>
                <img src={LoginImg} alt={"login background"}/>
                <div className={"WhatWeDo-banner"}>
                    <img src={logo_ict_grey} alt={"과학기술정보통신부"}/>
                    <img src={logo_nia_grey} alt={"한국지능정보사회진흥원"}/>
                    <img src={logo_gg_grey} alt={"세계속의 경기도"}/>
                </div>
            </div>
            <div className={"login-form"}>
                <div className={"form-wrap"}>
                    <h1>
                        <Link to="/new-main">
                            <strong>경기지역</strong>
                            <strong>경제포털</strong>
                        </Link>
                    </h1>
                    <LoginComponent loginProcess={(id, password) => loginProcess(id, password)} {...props}/>
                    <div className={"login-text"}>
                        <Link to="/forgot_id">아이디 찾기</Link>
                        <Link to="/forgot_password">비밀번호 찾기</Link>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
};

export default Login;
