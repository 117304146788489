import {get, post, postNew, getNew} from '../util/HttpRequest';

const ICAN_API_URL = process.env.REACT_APP_ICAN_API_URL;

/**
 * 주문 목록 조회
 * /read/list_order
 */
export const readListOrder = (params = {}) => {
    let target = ICAN_API_URL + "/read/list_order";
    return getNew(target, params);
};

/**
 * 주문 조회
 * /read/list_order
 */
export const readOrder = (params = {}) => {
    let target = ICAN_API_URL + "/read/order";
    return getNew(target, params);
};

/**
 * 주문 생성
 * /create/order
 */
export const addOrder = (params = {}) => {
    let target = ICAN_API_URL + "/create/order";
    return postNew(target, params);
};

/**
 * 주문 삭제
 * /delete/order
 */
export const deleteOrder = (params = {}) => {
    let target = ICAN_API_URL + "/delete/order";
    return postNew(target, params);
};
