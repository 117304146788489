import React from 'react';
import FaqList from "./board/FaqList";
import SubNavNew from "../components/SubNavNew";
import centers20 from "../assets/images/img_centers_20.png";
import centers19 from "../assets/images/img_centers_19.png";
import centers18 from "../assets/images/img_centers_18.png";
import centers17 from "../assets/images/img_centers_17.png";
import centers16 from "../assets/images/img_centers_16.png";
import centers15 from "../assets/images/img_centers_15.png";
import centers14 from "../assets/images/img_centers_14.png";
import centers13 from "../assets/images/img_centers_13.png";
import centers12 from "../assets/images/img_centers_12.png";
import centers11 from "../assets/images/img_centers_11.png";
import centers10 from "../assets/images/img_centers_10.png";
import centers09 from "../assets/images/img_centers_09.png";

const styleCenterBox = {
    "display":"flex",
    "width":"100%",
    "maxWidth":"1180px",
    "margin":"0 auto",
    "flexWrap":"wrap"
}
const styleCenterName = {
    "fontSize":"28px",
    "fontWeight":"border",
    "marginBottom":"50px",
    "marginTop":"50px",
    "fontWeight":"500"
}
const styleDl = {
    "height":"100px",
    "borderTop":"3px solid #333",
    "width":"100%"
}
const styleDt = {
    "float":"left",
    "margin":"0 20px",
    "marginBottom":"0px"
}
const Centers = (props) => {
    let subNav = [
    ];

    return (
        <>
            <div className="renewal">
                <SubNavNew nav={subNav} history={props.history} />
            </div>
            <div className={"mobile-bg-allocation"}/>
            <div className={"bg-WhatWeDo bg-centers"} style={{
                backgroundColor: "#fff"
            }}>
                <div className={"allocation-text-area-wrap"} style={{"paddingLeft":"10px"}}>
                    <div className={"centers-text-area"} style={{"maxWidth":"700px"}}>
                        <h1 className={"centers-text"}>
                            <strong>경기지역경제포털 빅데이터 플랫폼 컨소시엄</strong>
                        </h1>
                        <p>지역경제 활성화를 위한 데이터를 활용하는 빅데이터 분야 전문기업/공공기관으로 구성되어 있으며, 공공과 민간이 협업하여 데이터 기반의 생태계를 조성합니다.</p>
                    </div>
                </div>
            </div>
            <div className={"box-gray"} style={{backgroundColor: "#FFF"}}>
                <div className="centers-box" style={styleCenterBox}>
                    <div className="left" style={{"width":"50%", "padding":"20px"}}>
                        <div style={{"width":"100%", "textAlign":"center"}}>
                            <img src={centers20} width={"50%"} alt={"센터 내부"} style={{}}/>
                        </div>
                        <dl style={styleDl}>
                            <dt style={styleDt}>
                                <p style={{"margin":"10px 0"}}>주소</p>
                            </dt>
                            <dd style={{"fontSize":"16px", "borderBottom":"1px solid #333"}}>
                                <p style={{"margin":"10px 0"}}>(16229) 경기도 수원시 영통구 광교로 107 2~3층</p>
                            </dd>
                            <dt style={styleDt}>
                                <p style={{"margin":"10px 0"}}>전화</p>
                            </dt>
                            <dd style={{"borderBottom":"3px solid #333"}}>
                                <p style={{"margin":"10px 0"}}>1577-5900</p>
                            </dd>
                        </dl>
                    </div>
                    <div className="right" style={{"width":"50%", "padding":"20px"}}>
                        <dl>
                            <dt style={styleCenterName}>경기신용보증재단</dt>
                            <dd style={{"fontSize":"18px"}}>
                                <p>『지역신용보증재단법』에 의해 1996년 설립되어 사업성과 기술력은 있지만 담보력이 부족하여 금융기관으로부터 자금 조달이 어려운 중소기업, 소상공인에 대해 자금을 지원하는 비영리 공공법인</p>
                            </dd>
                        </dl>
                    </div>
                    <div className="left" style={{"width":"50%", "padding":"20px"}}>
                        <dl>
                            <dt style={styleCenterName}>경기도경제과학진흥원</dt>
                            <dd style={{"fontSize":"18px"}}>
                                <p>경기도에 소재하고 있는 중소기업에 대한 종합적이고 체계적인 지원사업을 통하여 중소기업의 경영여건 개선과 중소기업의 경쟁력을 강화함으로써 지역산업의 고도화와 경제발전에 기여함</p>
                            </dd>
                        </dl>
                    </div>
                    <div className="right" style={{"width":"50%", "padding":"20px"}}>
                        <div style={{"width":"100%", "textAlign":"center"}}>
                            <img src={centers19} width={"50%"} alt={"센터 내부"} style={{}}/>
                        </div>
                        <dl style={styleDl}>
                            <dt style={styleDt}>
                                <p style={{"margin":"10px 0"}}>주소</p>
                            </dt>
                            <dd style={{"fontsize":"16px", "borderBottom":"1px solid #333"}}>
                                <p style={{"margin":"10px 0"}}>(16229) 경기도 수원시 영통구 광교로 107 경기도경제과학진흥원</p>
                            </dd>
                            <dt style={styleDt}>
                                <p style={{"margin":"10px 0"}}>전화</p>
                            </dt>
                            <dd style={{"borderBottom":"3px solid #333"}}>
                                <p style={{"margin":"10px 0"}}>031-259-6000</p>
                            </dd>
                        </dl>
                    </div>
                    <div className="left" style={{"width":"50%", "padding":"20px"}}>
                        <div style={{"width":"100%", "textAlign":"center"}}>
                            <img src={centers18} width={"50%"} alt={"센터 내부"} style={{}}/>
                        </div>
                        <dl style={styleDl}>
                            <dt style={styleDt}>
                                <p style={{"margin":"10px 0"}}>주소</p>
                            </dt>
                            <dd style={{"fontsize":"16px", "borderBottom":"1px solid #333"}}>
                                <p style={{"margin":"10px 0"}}>(14557) 경기도 부천시 부천로 198번길 18(춘의동) 춘의테크노파크Ⅱ 202동 9층</p>
                            </dd>
                            <dt style={styleDt}>
                                <p style={{"margin":"10px 0"}}>전화</p>
                            </dt>
                            <dd style={{"borderBottom":"3px solid #333"}}>
                                <p style={{"margin":"10px 0"}}>032-623-8000</p>
                            </dd>
                        </dl>
                    </div>
                    <div className="right" style={{"width":"50%", "padding":"20px"}}>
                        <dl>
                            <dt style={styleCenterName}>경기콘텐츠진흥원</dt>
                            <dd style={{"fontSize":"18px"}}>
                                <p>경기 콘텐츠 진흥원은 경기도 산하 기관으로 북부경기문화창조허브, 판교경기문화창조허브 등 경기창조문화허브, 경기게임문화센터등을 운영하고 있으며, 콘텐츠 창업, 스타트업을 지원</p>
                            </dd>
                        </dl>
                    </div>
                    <div className="left" style={{"width":"50%", "padding":"20px"}}>
                        <dl>
                            <dt style={styleCenterName}>경기도일자리재단</dt>
                            <dd style={{"fontSize":"18px"}}>
                                <p>경기도민을 위한 다양한 일자리 정보의 제공 및 양질의 직업알선 그리고 다양한 직업교육 제공</p>
                            </dd>
                        </dl>
                    </div>
                    <div className="right" style={{"width":"50%", "padding":"20px"}}>
                        <div style={{"width":"100%", "textAlign":"center"}}>
                            <img src={centers17} width={"50%"} alt={"센터 내부"} style={{}}/>
                        </div>
                        <dl style={styleDl}>
                            <dt style={styleDt}>
                                <p style={{"margin":"10px 0"}}>주소</p>
                            </dt>
                            <dd style={{"fontsize":"16px", "borderBottom":"1px solid #333"}}>
                                <p style={{"margin":"10px 0"}}>(14566) 경기도 부천시 부천로 136번길 27 원미어울마당(구 원미구청) 3층</p>
                            </dd>
                            <dt style={styleDt}>
                                <p style={{"margin":"10px 0"}}>전화</p>
                            </dt>
                            <dd style={{"borderBottom":"3px solid #333"}}>
                                <p style={{"margin":"10px 0"}}>031-270-9600</p>
                            </dd>
                        </dl>
                    </div>
                    <div className="left" style={{"width":"50%", "padding":"20px"}}>
                        <div style={{"width":"100%", "textAlign":"center"}}>
                            <img src={centers16} width={"50%"} alt={"센터 내부"} style={{}}/>
                        </div>
                        <dl style={styleDl}>
                            <dt style={styleDt}>
                                <p style={{"margin":"10px 0"}}>주소</p>
                            </dt>
                            <dd style={{"fontsize":"16px", "borderBottom":"1px solid #333"}}>
                                <p style={{"margin":"10px 0"}}>(42250) 대구광역시 수성구 알파시티1로 35길 17</p>
                            </dd>
                            <dt style={styleDt}>
                                <p style={{"margin":"10px 0"}}>전화</p>
                            </dt>
                            <dd style={{"borderBottom":"3px solid #333"}}>
                                <p style={{"margin":"10px 0"}}>053-744-0707</p>
                            </dd>
                        </dl>
                    </div>
                    <div className="right" style={{"width":"50%", "padding":"20px"}}>
                        <dl>
                            <dt style={styleCenterName}>더아이엠씨</dt>
                            <dd style={{"fontSize":"18px"}}>
                                <p>빅데이터, AI 사업을 하고 있으며 해당기술 기반으로 다양한 빅데이터 활용 서비스를 제공</p>
                            </dd>
                        </dl>
                    </div>
                    <div className="left" style={{"width":"50%", "padding":"20px"}}>
                        <dl>
                            <dt style={styleCenterName}>코나아이</dt>
                            <dd style={{"fontSize":"18px"}}>
                                <p>스마트카드 및 IC칩 공급과 코나카드 및 지역사랑상품권 카드 시스템 운용을 주력으로 서비스 제공</p>
                            </dd>
                        </dl>
                    </div>
                    <div className="right" style={{"width":"50%", "padding":"20px"}}>
                        <div style={{"width":"100%", "textAlign":"center"}}>
                            <img src={centers15} width={"50%"} alt={"센터 내부"} style={{}}/>
                        </div>
                        <dl style={styleDl}>
                            <dt style={styleDt}>
                                <p style={{"margin":"10px 0"}}>주소</p>
                            </dt>
                            <dd style={{"fontsize":"16px", "borderBottom":"1px solid #333"}}>
                                <p style={{"margin":"10px 0"}}>(07237) 서울 영등포구 은행로 3 익스콘벤처타워 (8층)</p>
                            </dd>
                            <dt style={styleDt}>
                                <p style={{"margin":"10px 0"}}>전화</p>
                            </dt>
                            <dd style={{"borderBottom":"3px solid #333"}}>
                                <p style={{"margin":"10px 0"}}>02-2168-7500</p>
                            </dd>
                        </dl>
                    </div>
                    <div className="left" style={{"width":"50%", "padding":"20px"}}>
                        <div style={{"width":"100%", "textAlign":"center"}}>
                            <img src={centers14} width={"50%"} alt={"센터 내부"} style={{}}/>
                        </div>
                        <dl style={styleDl}>
                            <dt style={styleDt}>
                                <p style={{"margin":"10px 0"}}>주소</p>
                            </dt>
                            <dd style={{"fontsize":"16px", "borderBottom":"1px solid #333"}}>
                                <p style={{"margin":"10px 0"}}>(04763) 서울특별시 성동구 왕십리로 222</p>
                            </dd>
                            <dt style={styleDt}>
                                <p style={{"margin":"10px 0"}}>전화</p>
                            </dt>
                            <dd style={{"borderBottom":"3px solid #333"}}>
                                <p style={{"margin":"10px 0"}}>02-2220-0114</p>
                            </dd>
                        </dl>
                    </div>
                    <div className="right" style={{"width":"50%", "padding":"20px"}}>
                        <dl>
                            <dt style={styleCenterName}>한양대학교</dt>
                            <dd style={{"fontSize":"18px"}}>
                                <p>학교법인 한양학원 산하의 4년제 종합 사립대학으로 서울특별시 성동구 본교인 서울캠퍼스, 경기도 안산시 상록구에 분교인 ERICA캠퍼스 소재</p>
                            </dd>
                        </dl>
                    </div>
                    <div className="left" style={{"width":"50%", "padding":"20px"}}>
                        <dl>
                            <dt style={styleCenterName}>한국생산기술연구원</dt>
                            <dd style={{"fontSize":"18px"}}>
                                <p>산업계, 그 중에서도 특히 중소기업과 중견기업 지원을 목적으로 설립된 공공기관</p>
                            </dd>
                        </dl>
                    </div>
                    <div className="right" style={{"width":"50%", "padding":"20px"}}>
                        <div style={{"width":"100%", "textAlign":"center"}}>
                            <img src={centers13} width={"50%"} alt={"센터 내부"} style={{}}/>
                        </div>
                        <dl style={styleDl}>
                            <dt style={styleDt}>
                                <p style={{"margin":"10px 0"}}>주소</p>
                            </dt>
                            <dd style={{"fontsize":"16px", "borderBottom":"1px solid #333"}}>
                                <p style={{"margin":"10px 0"}}>(31056) 충청남도 천안시 서북구 입장면 양대기로길 89 한국생산기술연구원</p>
                            </dd>
                            <dt style={styleDt}>
                                <p style={{"margin":"10px 0"}}>전화</p>
                            </dt>
                            <dd style={{"borderBottom":"3px solid #333"}}>
                                <p style={{"margin":"10px 0"}}>041-589-8114</p>
                            </dd>
                        </dl>
                    </div>
                    <div className="left" style={{"width":"50%", "padding":"20px"}}>
                        <div style={{"width":"100%", "textAlign":"center"}}>
                            <img src={centers12} width={"50%"} alt={"센터 내부"} style={{}}/>
                        </div>
                        <dl style={styleDl}>
                            <dt style={styleDt}>
                                <p style={{"margin":"10px 0"}}>주소</p>
                            </dt>
                            <dd style={{"fontsize":"16px", "borderBottom":"1px solid #333"}}>
                                <p style={{"margin":"10px 0"}}>(30147) 세종특별자치시 국책연구원로 5 (반곡동)</p>
                            </dd>
                            <dt style={styleDt}>
                                <p style={{"margin":"10px 0"}}>전화</p>
                            </dt>
                            <dd style={{"borderBottom":"3px solid #333"}}>
                                <p style={{"margin":"10px 0"}}>044-960-0114</p>
                            </dd>
                        </dl>
                    </div>
                    <div className="right" style={{"width":"50%", "padding":"20px"}}>
                        <dl>
                            <dt style={styleCenterName}>국토연구원</dt>
                            <dd style={{"fontSize":"18px"}}>
                                <p>국토자원의 효율적인 이용 및 개발과 보전에 관하여 연구개발을 위해 설립</p>
                            </dd>
                        </dl>
                    </div>
                    <div className="left" style={{"width":"50%", "padding":"20px"}}>
                        <dl>
                            <dt style={styleCenterName}>한국평가데이터</dt>
                            <dd style={{"fontSize":"18px"}}>
                                <p>한국평가데이터는 신뢰도 높은 신용평가정보를 제공함으로써 기업에 대한 신용대출 활성화와 선전한 신용 상거래촉진을 위해 국책기관 및 민간 금융기관이 공동으로 출자하여 설립한 국내 최고의 기업신용조사·평가 전문기관</p>
                            </dd>
                        </dl>
                    </div>
                    <div className="right" style={{"width":"50%", "padding":"20px"}}>
                        <div style={{"width":"100%", "textAlign":"center"}}>
                            <img src={centers11} width={"50%"} alt={"센터 내부"} style={{}}/>
                        </div>
                        <dl style={styleDl}>
                            <dt style={styleDt}>
                                <p style={{"margin":"10px 0"}}>주소</p>
                            </dt>
                            <dd style={{"fontsize":"16px", "borderBottom":"1px solid #333"}}>
                                <p style={{"margin":"10px 0"}}>(07237) 서울시 영등포구 의사당대로 21, 코데이터 빌딩</p>
                            </dd>
                            <dt style={styleDt}>
                                <p style={{"margin":"10px 0"}}>전화</p>
                            </dt>
                            <dd style={{"borderBottom":"3px solid #333"}}>
                                <p style={{"margin":"10px 0"}}>1811-8883</p>
                            </dd>
                        </dl>
                    </div>
                    <div className="left" style={{"width":"50%", "padding":"20px"}}>
                        <div style={{"width":"100%", "textAlign":"center"}}>
                            <img src={centers10} width={"50%"} alt={"센터 내부"} style={{}}/>
                        </div>
                        <dl style={styleDl}>
                            <dt style={styleDt}>
                                <p style={{"margin":"10px 0"}}>주소</p>
                            </dt>
                            <dd style={{"fontsize":"16px", "borderBottom":"1px solid #333"}}>
                                <p style={{"margin":"10px 0"}}>(06133) 서울시 강남구 테헤란로 127, 10층</p>
                            </dd>
                            <dt style={styleDt}>
                                <p style={{"margin":"10px 0"}}>전화</p>
                            </dt>
                            <dd style={{"borderBottom":"3px solid #333"}}>
                                <p style={{"margin":"10px 0"}}>-</p>
                            </dd>
                        </dl>
                    </div>
                    <div className="right" style={{"width":"50%", "padding":"20px"}}>
                        <dl>
                            <dt style={styleCenterName}>한국신용데이터</dt>
                            <dd style={{"fontSize":"18px"}}>
                                <p>소상공인 경영관리 앱, 캐시노트를 통해 사장님들이 사업시작, 운영 그리고 성장까지 도와줍니다. 사업의 모든 순간 마주하는 문제를 데이터와 연결로 풀어냄</p>
                            </dd>
                        </dl>
                    </div>
                    <div className="left" style={{"width":"50%", "padding":"20px"}}>
                        <dl>
                            <dt style={styleCenterName}>펌프킨</dt>
                            <dd style={{"fontSize":"18px"}}>
                                <p>e-Mobility, 스마트시티, 친환경에너지 전문 기업. 국내 최고의 전기자동차 충전인프라 기업</p>
                            </dd>
                        </dl>
                    </div>
                    <div className="right" style={{"width":"50%", "padding":"20px"}}>
                        <div style={{"width":"100%", "textAlign":"center"}}>
                            <img src={centers09} width={"50%"} alt={"센터 내부"} style={{}}/>
                        </div>
                        <dl style={styleDl}>
                            <dt style={{"float":"left", "fontsize":"18px", "margin":"0 20px", "marginBottom":"0px"}}>
                                <p style={{"margin":"10px 0"}}>주소</p>
                            </dt>
                            <dd style={{"fontsize":"16px", "borderBottom":"1px solid #333"}}>
                                <p style={{"margin":"10px 0"}}>(12179) 경기도 남양주시 화도읍 경춘로 1955 R&D센터</p>
                            </dd>
                            <dt style={{"float":"left", "fontsize":"18px", "margin":"0 20px"}}>
                                <p style={{"margin":"10px 0"}}>전화</p>
                            </dt>
                            <dd style={{"borderBottom":"3px solid #333"}}>
                                <p style={{"margin":"10px 0"}}>031-591-6633</p>
                            </dd>
                        </dl>
                    </div>                        
                </div>
            </div>
        </>
    )
};

export default Centers;
