import React from 'react';
import { index as privacyPolicy } from "../util/Terms/privacyPolicy";
import { index as privacyPolicy_old_211117 } from "../util/Terms/privacyPolicy_old_211117";
import { index as privacyPolicy_old_231117 } from "../util/Terms/privacyPolicy_old_231117";
import { index as privacyPolicy_old_240208 } from "../util/Terms/privacyPolicy_old_240208";
import SubNavNew from "../components/SubNavNew";


const Policy = ({match, ...props}) => {
    let subNav = [
    ];

    //이용약관 테이블
    const policy2=()=>{
        let privacyPolicyString = '';
        const version = match.params.version;

        if(version === '211117'){
            privacyPolicyString = privacyPolicy_old_211117;
        }else if(version === '231117'){
            privacyPolicyString = privacyPolicy_old_231117;
        }else if(version === '240208'){
            privacyPolicyString = privacyPolicy_old_240208;            
        }else {
            privacyPolicyString = privacyPolicy;
        }        
        privacyPolicyString= privacyPolicyString.replace(/\n/g, '<br/>');

        /**
         * 2022 보안감사 개인정보 수집시 고지하여야할 4가지 고지사항 안내
         */         
        privacyPolicyString = privacyPolicyString.replace(`INFORMPRIVACY_TABLE`,
        `<table style="width:100%;">
            <colgroup>
                <col width="25%"/>
                <col width="50%"/>
                <col width="25%"/>
            </colgroup>            
            <tr style="text-align:center;">
                <th>위탁업체</th>
                <th>위탁업무</th>
                <th>위탁기간</th>
            </tr>
            <tr>
                <td>
                    경기경제과학진흥원<br/>
                    선도소프트<br/>
                    한국평가데이터<br/>
                    씨씨미디어서비스
                </td>
                <td style="vertical-align:middle;">경기지역경제포털 플랫폼 구축 및 데이터 협업체계 강화</td>
                <td style="vertical-align:middle;">2023.05.30.~계약 종료 시</td>
            </tr>
        </table>`)    
        
        return privacyPolicyString
    }

    return(
        <>
            <div className="renewal">
            <SubNavNew nav={subNav} history={props.history} />
            </div>        
            <div style={{maxWidth:"1280px", margin:"0 auto"}}>
                <div dangerouslySetInnerHTML={{__html: policy2()}} style={{width:"100%", fontSize: "1rem", overflow:"auto"}}/>
            </div>
        </>
    )
};
export default Policy;
