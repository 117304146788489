import React from 'react';
import * as UserApi from "../../api/User";
import swal from "sweetalert"
import {delUserInfo, logout} from "../../store/actions/UserAction";
import {useSelector, useDispatch} from "react-redux";

const DeleteUser = ({match, ...props}) => {
    const userInfo = useSelector(state => state.user.info);    
    const dispatch = useDispatch();
    const HOME_URL = '/';
    let user_id_key = match.params.user_id_key;
    let delete_key = match.params.delete_key;

    const isDeleteUser = () => {
        swal({
            text: "정말 탈퇴하시겠습니까?",
            buttons: [
                "취소",
                "확인"
            ],
        }).then(function (isConfirm) {
            if(isConfirm) {
                const userDeleteParam = {
                    'id': user_id_key,
                    'delete_key' : delete_key
                };
                UserApi.deleteUser(userDeleteParam)
                    .then(res => {
                        dispatch(delUserInfo());
                        swal('정상적으로 탈퇴하였습니다.').then(() => {
                            dispatch(logout(userInfo));
                            dispatch(logout(userInfo));
                            window.location.assign(HOME_URL);
                        });
                    });
            }
            else {
                swal.close();
                window.location.assign(HOME_URL);
            }
        })
    };
    return (
        <>
            <div className={"wrap-delete-user"}>
                <div className={"inner-delete-user"}>
                    <h1> 회원탈퇴 </h1>
                    <div className={"btn-group ac"}>
                        <li> 지금까지 경기지역경제포털을 이용해주셔서 감사합니다. </li>
                        <button onClick={() => isDeleteUser()}> 회원탈퇴 </button>
                    </div>
                </div>
            </div>
        </>
    )
};

export default DeleteUser;
