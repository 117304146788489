import React, { useRef, useState, useEffect} from 'react';
import {getDistance} from "geolib";
import clusterMarkerPng from "../../assets/images/renewal/common/i-radio.png";

const ServiceKakaoMap = ({pUserLocation, pList, pMapCenter, pSelectStore, handleMapCenter}) => {
    const [mapCenter, setMapCenter] = useState([pMapCenter[0], pMapCenter[1]]);
    const [userLocation, setUserLocation] = useState([pUserLocation[0],pUserLocation[1]]);
    const [list, setList] = useState([]);
    const [serviceMap, setServiceMap] = useState(null);
    const [serviceMakers, setServiceMarkers] = useState([]);
    const [serviceInfoWindows, setServiceInfoWindows] = useState([]);
    const [selectStore, setSelectStore] = useState("");
    const [level, setLevel] = useState();
    const mapContainer = useRef(null);
    let centerLat = pMapCenter[0];
    let centerLon = pMapCenter[1];

    const {kakao} = window;
    
    useEffect(()=>{
        setList(pList);
    })

    let map = serviceMap;
    let markers = serviceMakers;
    let infoWindows = serviceInfoWindows;

    const htmlMarker1 = {
        content: `<div 
                    style="cursor:pointer;
                           width:40px;
                           height:40px;
                           line-height:42px;
                           font-size:10px;
                           color:white;
                           text-align:center;
                           font-weight:bold;
                           background:url(${clusterMarkerPng});background-size:contain;"
                 >
                 </div>`,
        // size: N.Size(40, 40),
        // anchor: N.Point(20, 20)
    }

    //지도 중심으로부터 가맹점 거리 
    const mapCenterDistance = (sLat, sLon) => {
        let mapCenterdist = {latitude:centerLat, longitude:centerLon};
        let store = {latitude:sLat, longitude:sLon};
        return getDistance(mapCenterdist,store);
    }    

    /** 지도  */
    useEffect(() => {
        /** 사용자 위치 */
        // navigator.geolocation.getCurrentPosition(
        //     (position) => {
        //         setUserLocation(position.coords.latitude,position.coords.longitude);
        //         setMapCenter(position.coords.latitude,position.coords.longitude);
        //     },
        //     (e) => {
        //     }
        // );
        markers = [];
        infoWindows = [];
        let mapOptions = {
            center: new kakao.maps.LatLng(centerLat, centerLon),
            level: level||4,
            maxLevel: 5
        }
        

        map = new kakao.maps.Map(mapContainer.current, mapOptions);
        kakao.maps.event.addListener(map, 'dragend', function() { 
            const mapLatLng = map.getCenter();
            handleMapCenter(mapLatLng.getLat(), mapLatLng.getLng());
        })
        setServiceMap(map);

        kakao.maps.event.addListener(map, 'zoom_changed', function() {        
    
            // 지도의 현재 레벨을 얻어옵니다
            var level = map.getLevel();
            setLevel(level);
            
        });

        //현재위치 
        let position = new kakao.maps.LatLng(userLocation[0],userLocation[1]);
        let marker = new kakao.maps.Marker({
            map: map,
            position: position,
            animation: 0,
            title: "현재 위치"
        });
      
        let clusterer = new kakao.maps.MarkerClusterer({
            map:map,
            averageCenter: true,
            minLevel: 3
        })

        list.forEach(store => {
            position = new kakao.maps.LatLng(store.latitude, store.longitude);
            marker = new kakao.maps.Marker({
            map: map,
            position: position,
            title: store.store_name,
            });

            /** 마커클릭시 정보창*/
            let infoWindow = makeInfoWindow(store);
            
            kakao.maps.event.addListener(marker, 'click', makeClickListener(map, marker, infoWindow));
            kakao.maps.event.addListener(marker, 'mouseout', makeOutListener(infoWindow));

            clusterer.addMarker(marker);
            markers.push(marker);
            infoWindows.push(infoWindow);
        })

        setServiceMarkers(markers);
        setServiceInfoWindows(infoWindows)
    }, [list]);

    const makeClickListener = (map, marker, infoWindow) => {
        return function(){
            infoWindow.open(map, marker);
        }
    }

    const makeOutListener = (infowindow) => {
        return function(){
            infowindow.close();
        }
    }

    /** infoWindow 만들기 */
    const makeInfoWindow = (store) => {
        return new kakao.maps.InfoWindow({
            content: `<div style="width:200px;text-align:center;padding:10px;">
                        <div style="text-align:left">
                            <b>${store.store_name}</b>
                        </div>
                        <div style="text-align:left;line-height:80%;">
                            <span style="font-size:12px;">${store.category}</span><br/>
                            <span style="font-size:10px;text-align:left;">${store.road_address}</span><br/>
                            <span style="font-size:10px;text-align:left;">우편번호 ${store.postal_code}</span><br/>
                            <span style="font-size:10px;text-align:left;">사업자번호 ${store.business_no}</span><br/>
                        </div>
                     </div>`
        });
    }
    // 전화번호 제거
    {/* <span style="font-size:10px;text-align:left;">전화번호 ${store.phone_number}</span><br/> */}

    //리스트 클릭 시 해당 가맹점으로 지도 이동
    useEffect(() => {
        if(pSelectStore !== null && pSelectStore !== undefined){
            if(pSelectStore.store_name !== selectStore){
                map.panTo(new kakao.maps.LatLng(centerLat, centerLon), 100);
                
                // 기존 인포 닫기
                infoWindows.map(m => m.close())
                // 클릭한 인포 열기
                const index = markers.findIndex((m, idx, arr) => m.Gb === pSelectStore.store_name);
                infoWindows[index].open(map, markers[index])

                list.find((store, idx) => {
                    if(store.latitude == centerLat && store.longitude == centerLon && store.store_name === pSelectStore.store_name){
                        setSelectStore(pSelectStore.store_name);
                        centerLat = store.latitude;
                        centerLon = store.longitude;
                        setMapCenter([store.latitude, store.longitude]);
                    }
                })                
            }
        }
    },[pSelectStore])

    return (
        <div id="map" style={{width:"100%", height:"100%"}} ref={mapContainer}>

        </div>
    );
}
export default ServiceKakaoMap;