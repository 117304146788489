export const SET_BOARD_INFO = 'SET_BOARD_INFO';
export const SET_BOARD_MENU_LIST = 'SET_BOARD_MENU_LIST';

export function setBoardInfo(boardInfo) {
    return {
        type: SET_BOARD_INFO,
        boardInfo: boardInfo
    };
}

export function setBoardMenuList(boardMenuList) {
    return {
        type: SET_BOARD_MENU_LIST,
        boardMenuList: boardMenuList
    };
}
