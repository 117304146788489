import React from 'react';
import logo_nia_grey from "../assets/images/logo_nia_white@2x.png";
import logo_ict_grey from "../assets/images/logo_ict_white@2x.png";
import logo_gg_grey from "../assets/images/logo_gg_white@2x.png";
import SubNavNew from "../components/SubNavNew";

const WhatWeDo = (props) => {
    let subNav = [
    ];
    return (
        <>
            <div className="renewal">
                <SubNavNew nav={subNav} history={props.history} />
            </div>
            <div className={"bg-WhatWeDo"}>
                <div className={"text-area"}>
                    <strong>지역경제활성화를 위한 <br/>데이터 선순환의 시작 "경기지역경제포털"</strong>
                </div>
                <div className={"WhatWeDo-banner"}>
                    <img src={logo_ict_grey} alt={"과학기술정보통신부"}/>
                    <img src={logo_nia_grey} alt={"한국지능정보사회진흥원"}/>
                    <img src={logo_gg_grey} alt={"세계속의 경기도"}/>
                </div>
            </div>
            <div className={"box-white"}>
                <div className={"text-box"}>경기지역경제포털은</div>
                <div className={"box-white-inner"}>
                    <dl>
                        <dt className={"icon-bg1"}></dt>
                        <dd>
                            <strong>데이터 개방</strong>
                            <p>경기지역경제포털은 데이터 센터를 통해 수집된 데이터를 사용자에게 개방합니다.</p>
                        </dd>
                    </dl>
                    <dl>
                        <dt className={"icon-bg2"}></dt>
                        <dd>
                            <strong>데이터 공유</strong>
                            <p>경기지역경제포털은 데이터 센터를 통해 수집된 데이터를 사용자가 활용할 수 있도록 투명하게 공유합니다.</p>
                        </dd>
                    </dl>
                    {/* 
                        <dl>
                        <dt className={"icon-bg4"}></dt>
                        <dd>
                            <strong>데이터 배당</strong>
                            <p>경기지역경제포털은 사용자가 데이터에 기여한 만큼, 그 가치를 일정금액으로 환산해 지급합니다.</p>
                        </dd>
                        </dl> 
                    */}
                    <dl>
                        <dt className={"icon-bg3"}></dt>
                        <dd>
                            <strong>참여 소통</strong>
                            <p>경기지역경제포털은 데이터 센터를 통해 수집된 데이터를 통해 사용자가 소통하여 자발적 참여로 폭 넓게 활용할 수 있도록 합니다.</p>
                        </dd>
                    </dl>
                </div>
            </div>
            <div className={"box-gray"}>
                <div className={"img-box bg1"}>
                    <div className={"inner-text1"}>
                        <dl>
                            <dt>데이터를 탐색하세요</dt>
                            <dd>
                                <p>모든 데이터는 각 데이터 센터를 통해 수집된 데이터를 경기지역경제포털에서 통합하여 제공합니다. </p>
                                <p>사용자는 기존에 얻을 수 없었던 자신이 원하는 데이터를 발견하고 이 것을 적극 활용할 수 있습니다.</p>
                            </dd>
                        </dl>
                    </div>
                </div>
                <div className={"img-box bg2"}>
                    <div className={"inner-text2"}>
                        <dl>
                            <dt>데이터를 분석하세요</dt>
                            <dd>
                                <p>원하는 데이터를 탐색하여 발견하고 데이터를 분석하십시오. </p>
                            </dd>
                        </dl>
                    </div>
                </div>
                <div className={"img-box bg4"}>
                    <div className={"inner-text3"}>
                        <dl>
                            <dt>데이터를 활용하세요</dt>
                            <dd>
                                <p>데이터를 데이터 시각화를 통해 알아보기 쉽게 활용할 수 있습니다.</p>
                            </dd>
                        </dl>
                    </div>
                </div>
{/* 
                <div className={"img-box bg5"}>
                    <div className={"inner-text4"}>
                        <dl>
                            <dt>데이터 배당을 받으세요</dt>
                            <dd>
                                <p>사용자의 데이터 기여도를 파악하여, 데이터 거래 일부를 지역화폐로 배당해 드립니다.</p>
                            </dd>
                        </dl>
                    </div>
                </div> 
*/}

            </div>
            <div className={"box-blue"}>
                <div className={"text-area2"}>
                    <strong>지역경제 활성을 위한 다양한 데이터</strong>
                    <span>지역경제 데이터를 통한 미래 먹거리 생산</span>
                </div>
            </div>
            <div className={"data-center"}>
                <div className={"inner-text"}>
                    <dl>
                        <dt>빅데이터 센터</dt>
                        <dd>
                            <p>경기지역경제포털에서 제공되는 모든 데이터는 다음 데이터 센터들과 연결되어 방대한 데이터를 수집하여 제공하고 있습니다.</p>
                        </dd>
                    </dl>
                </div>
                <div className={"inner-logo"}></div>
            </div>
        </>
    )
};

export default WhatWeDo;
