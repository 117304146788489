import React, {useState, useRef, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import Button from "../components/Button";
import {logout, setUserInfo} from "../store/actions/UserAction";
import {terms} from "../util/Terms";
import logo_nia_grey from "../assets/images/logo_nia_grey@2x.png";
import logo_gg_grey from "../assets/images/logo_gg_default@2x.png";
import logo_ict_grey from "../assets/images/logo_ict_grey@2x.png";
import Modal from "react-modal";
import swal from "sweetalert"
import * as DataApi from "../api/Data";

import new_login_btn from "../assets/images/ic_new_login.svg"
import new_join_btn from "../assets/images/ic_new_join_btn.svg"
import NewSearchForm from "../components/NewSearchForm";
import new_logo_gg from "../assets/images/logo_new_gyeonggi.svg"
import new_logo_nia from "../assets/images/logo_new_nia.svg"
import new_logo_ict from "../assets/images/logo_new_science.svg"

import facebookicon from "../assets/images/facebookicon.svg"
import instagramicon from "../assets/images/instagramicon.svg"
import {setChangeMain} from "../store/actions/DataAction";
import NewHeader from './common/NewHeader';
import NewFooter from './common/NewFooter';

const Layout = ({children, ...props}) => {
        const userInfo = useSelector(state => state.user.info);
        const isLogin = useSelector(state => state.user.isLogin);
        const changeMain = useSelector(state => state.data.changeMain);

        const dispatch = useDispatch();

        const refContainer = useRef(null);
        const menuChkRef = useRef(null);

        const facebook_link = " https://www.facebook.com/%EA%B2%BD%EA%B8%B0%EC%A7%80%EC%97%AD%EA%B2%BD%EC%A0%9C%ED%8F%AC%ED%84%B8-191099372986676/?hc_ref=ARQcmuzKkXEQxwuR2NB-xaSvuFrfoUNrRQin4opc_WNlmAcZ-CyYkJ0231uK4deA81I&fref=nf&__tn__=kC-R"
        const instagram_link = "https://www.instagram.com/bigdata_region/"
        let isMainClass = "";

        const mainFlag = useSelector(state => state.data.changeMain);

        if (props.location.pathname === "/" && changeMain === false) {
            isMainClass = " ds-is-main";
        } else if (props.location.pathname === "/" && changeMain === true) {
            isMainClass = " new-ds-is-main"
        }
        let menuActive = {
            dataset: props.location.pathname === "/dataset" || props.location.pathname.indexOf("/dataset/") > -1 ||props.location.pathname === "/integratedDataset" || props.location.pathname === "/datasetMix" > -1 || props.location.pathname.indexOf("/datasetMix") > -1 ,
            use: props.location.pathname.indexOf("/use") > -1 ,
            analysisService: props.location.pathname === "/analysisService" || props.location.pathname.indexOf("/openapiService") > -1 || props.location.pathname === "/creditanalysis",
            board: props.location.pathname.indexOf("/board") > -1 || props.location.pathname.indexOf("/event") > -1,
            introduce: props.location.pathname === "/whatwedo" || props.location.pathname === "/allocation"
        };


        if (menuChkRef !== null && menuChkRef.current !== null) {
            // console.log(menuChkRef.current);
            menuChkRef.current.checked = false;
        }


        //모달
        const [modalIsOpen, setIsOpen] = useState(false);

        const customStyles = {
            overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                zIndex: 1060
            },
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '50%'
            }
        };
        let subtitle = undefined;

        const openModal = () => {
            setIsOpen(true);
        };

        const closeModal = () => {
            setIsOpen(false);
        };

        const goToLink = (link) => {
            window.location.href = link;
        }

        return (
            // <div className="renewal">
            // <NewHeader />
            //     {/*메인*/}
            //     <div className={"new-ds-main-wrap" + isMainClass}>
            //         <div className={"new-ds-main"} ref={refContainer}>
            //             {children}
            //         </div>
            //     </div>
            // <NewFooter />
            // </div>
            <>
            <div className="renewal">
                <NewHeader />
            </div>
                {/*메인*/}
                <div className={"new-ds-main-wrap" + isMainClass}>
                    <div className={"new-ds-main"} ref={refContainer}>
                        {children}
                    </div>
                </div>            
            <div className="renewal">
                <NewFooter />
            </div>
            </>
            // <>
            //     {setIsOpen &&
            //     <Modal
            //         isOpen={modalIsOpen}
            //         // onAfterOpen={afterOpenModal}
            //         aria={{
            //             labelledby: "heading",
            //             describedby: "full_description"
            //         }}
            //         onRequestClose={closeModal}
            //         style={customStyles}
            //         contentLabel="Inline Styles Modal Example"
            //     >
            //         <div style={{"width": "100%", "border-bottom": "1px solid #cad0dd", "padding-bottom": "20px"}}>
            //             <span id="heading" style={{"font-size": "24px", "font-weight": "500"}}>경기지역경제포털</span>
            //             <span style={{"float": "right", "margin-top": "5px"}} onClick={() => closeModal()}> <p
            //                 className={"sitemap-close"}></p> </span>
            //         </div>
            //         <div id="full_description">
            //             <div>
            //                 <ul className={"sitemap-wrap"}>
            //                     <li className={"sitemap-item"} style={{"margin-left": "0"}}>
            //                         <p>데이터셋</p>
            //                         <ul>
            //                             <li>
            //                                 <Link to={"/dataset"} onClick={() => closeModal()}>데이터셋</Link>
            //                             </li>
            //                             <li>
            //                                 <Link to={"/integratedDataset"} onClick={() => closeModal()}>통합 데이터 검색</Link>
            //                             </li>
            //                         </ul>
            //                     </li>
            //                     <li className={"sitemap-item"}>
            //                         <p>융합데이터</p>
            //                         <ul>
            //                             <li>
            //                                 <Link to={"/datasetMix"} onClick={() => closeModal()}>분야별 데이터</Link>
            //                             </li>
            //                         </ul>
            //                     </li>
            //                     <li className={"sitemap-item"}>
            //                         <p>활용</p>
            //                         <ul>
            //                             <li>
            //                                 <Link to={"/use"} onClick={() => closeModal()}>활용 갤러리</Link>
            //                             </li>
            //                             <li>
            //                                 <a href="http://visualization.bigdata-region.kr:80" target="_blank">데이터 시각화</a>
            //                             </li>
            //                             <li>
            //                                 <Link to={"/analysisService"} onClick={() => closeModal()}>분석 서비스 신청</Link>
            //                             </li>
            //                         </ul>
            //                     </li>
            //                     <li className={"sitemap-item"} style={{"margin-left": "0", "margin-top": "10px"}}>
            //                         <p>참여소통</p>
            //                         <ul>
            //                             <li>
            //                                 <Link to={"/board/board_14ab0a7f-d410-48d7-91ca-e264d5121794"}
            //                                       onClick={() => closeModal()}>공지사항</Link>
            //                             </li>
            //                             <li>
            //                                 <Link to={"/board/board_942f2cfa-3294-42c5-ac68-b4f3abbed510"}
            //                                       onClick={() => closeModal()}>묻고답하기</Link>
            //                             </li>
            //                             <li>
            //                                 <Link to={"/board/board_a2eb004d-73ca-4014-a9d2-c402675cf83f"}
            //                                       onClick={() => closeModal()}>자주하는 질문</Link>
            //                             </li>
            //                             <li>
            //                                 <Link to={"/board/board_87b9692c-8959-4b3a-83c5-28e209ceca1f"}
            //                                       onClick={() => closeModal()}>데이터 문의</Link>
            //                             </li>
            //                         </ul>
            //                     </li>
            //                     <li className={"sitemap-item"} style={{"margin-top": "10px"}}>
            //                         <p>소개</p>
            //                         <ul>
            //                             <li>
            //                                 <Link to={"/whatwedo"} onClick={() => closeModal()}>소개</Link>
            //                             </li>
            //                             <li>
            //                                 <Link to={"/allocation"} onClick={() => closeModal()}>데이터 배당</Link>
            //                             </li>
            //                             <li>
            //                                 <Link to={"/event"} onClick={() => closeModal()}>이벤트</Link>
            //                             </li>
            //                         </ul>
            //                     </li>
            //                 </ul>
            //             </div>
            //         </div>
            //     </Modal>
            //     }
            //     {
            //         mainFlag ?
            //             <>
            //                 <div className={"new-ds-header-wrap" + isMainClass}>
            //                     <div className={"top-bar"}>
            //                         <div className={"top-bar-inner"}>
            //                             <Link to={"/"} tabIndex={"-1"}>
            //                                 <div className={"new-ds-header-title"}>
            //                                     <strong>경기지역</strong>
            //                                     <strong>경제포털</strong>
            //                                 </div>
            //                             </Link>
            //                             {isMainClass === " new-ds-is-main" ? <NewSearchForm/> : null}
            //                             {!isLogin ? <div className={"new-ds-header-right-menu"}>
            //                                     <Link to={"/login"}>
            //                                         <button className={"new-top-right-login-btn"}>
            //                                             <img src={new_login_btn} className={"new-top-right-login-img"}/>
            //                                             <p className={"new-top-right-login-text"}> 로그인 </p>
            //                                         </button>
            //                                     </Link>
            //                                     <Link to={"/join"}>
            //                                         <button className={"new-top-right-join-btn"}>
            //                                             <img src={new_join_btn} className={"new-top-right-join-img"}/>
            //                                             <p className={"new-top-right-join-text"}> 회원가입 </p>
            //                                         </button>
            //                                     </Link>
            //                                 </div>
            //                                 :
            //                                 <div className={"new-ds-header-right-menu"}>
            //                                     <Link onClick={() => dispatch(logout())}>
            //                                         <button className={"new-top-right-login-btn"}>
            //                                             <img src={new_login_btn} className={"new-top-right-login-img"}/>
            //                                             <p className={"new-top-right-login-text"}> 로그아웃 </p>
            //                                         </button>
            //                                     </Link>
            //                                     <Link to={"/mypage"}>
            //                                         <button className={"new-top-right-join-btn"}>
            //                                             <img src={new_join_btn} className={"new-top-right-join-img"}/>
            //                                             <p className={"new-top-right-join-text"}> 나의정보 </p>
            //                                         </button>
            //                                     </Link>
            //                                 </div>
            //                             }
            //                         </div>
            //                     </div>
            //                     <div className={"new-ds-header"}>
            //                         <div className={"new-ds-header-menu-wrap "}>
            //                             <div className={"new-ds-header-menu"}>
            //                                 <div className={"new-accordion"}>
            //                                     <ul className={"new-menu-1depth"}>
            //                                         <li className={(menuActive.dataset ? "active" : '')} tabIndex="0"
            //                                             onClick={e => goToLink("/#/dataset?datecolumn=created&page=1")}
            //                                             onKeyDown={e => e.keyCode === 13 && goToLink("/#/dataset")}>
            //                                             <Link to={"/dataset"} tabIndex={"-1"}>
            //                                                 <span>데이터셋</span>
            //                                             </Link>
            //                                         </li>

            //                                         <li className={(menuActive.use ? "active" : '')} tabIndex="0"
            //                                             onClick={e => goToLink("/#/use")}
            //                                             onKeyDown={e => e.keyCode === 13 && goToLink("/#/use")}>
            //                                             <Link to={"/use"} tabIndex={"-1"}>
            //                                                 <span>활용</span>
            //                                             </Link>
            //                                         </li>
            //                                         <li className={(menuActive.board ? "active" : '')} tabIndex="0"
            //                                             onClick={e => goToLink("/#/board/board_14ab0a7f-d410-48d7-91ca-e264d5121794")}
            //                                             onKeyDown={e => e.keyCode === 13 && goToLink("/#/board/board_14ab0a7f-d410-48d7-91ca-e264d5121794")}>
            //                                             <Link to={"/board/board_14ab0a7f-d410-48d7-91ca-e264d5121794"}
            //                                                   tabIndex={"-1"}>
            //                                                 <span>참여소통</span>
            //                                             </Link>
            //                                         </li>
            //                                         <li className={(menuActive.introduce ? "active" : '')} tabIndex="0"
            //                                             onClick={e => goToLink("/#/whatwedo")}
            //                                             onKeyDown={e => e.keyCode === 13 && goToLink("/#/whatwedo")}>
            //                                             <Link to={"/whatwedo"} tabIndex={"-1"}>
            //                                                 <span>소개</span>
            //                                             </Link>
            //                                         </li>
            //                                     </ul>
            //                                     <div className={"new-ds-header-menu-with-sub new-accordion-content"}>
            //                                         <div className={"new-menu-sub-position"}>
            //                                             <NewSubMenu where={"header"} key={"header"}/>
            //                                         </div>
            //                                     </div>
            //                                 </div>
            //                             </div>
            //                         </div>
            //                     </div>
            //                 </div>

            //                 {/*메인*/}
            //                 <div className={"new-ds-main-wrap" + isMainClass}>
            //                     <div className={"new-ds-main"} ref={refContainer}>
            //                         {children}
            //                     </div>
            //                 </div>

            //                 {/*푸터*/}
            //                 <div className={"new-main-footer-full"}>
            //                     <div className={"new-main-footer-section"}>
            //                         <div className={"new-main-left-box"}>
            //                             <p>
            //                                 경기도 수원시 영통구 도청로 30 | 고객센터 : 02-3215-1213
            //                             </p>
            //                             <p style={{"margin-top": "20px", "width": "100%"}}>
            //                                 © Gyeonggi Province. All Rights Reserved.
            //                             </p>
            //                             <p style={{"margin-top": "20px", "cursor": "pointer", "color": "rgba(0,0,0,0.9)"}}
            //                                onClick={() => openModal()}>
            //                                 사이트맵
            //                             </p>
            //                         </div>
            //                         <div className={"new-main-right-box"}>
            //                             <img src={new_logo_gg} alt={"세계속의 경기도"}/>
            //                             <img src={new_logo_nia} alt={"한국지능정보사회진흥원"}
            //                                  style={{"margin-left": "30px", "margin-right": "30px"}}/>
            //                             <img src={new_logo_ict} alt={"과학기술정보통신부"}/>
            //                         </div>
            //                     </div>
            //                 </div>
            //             </>
            //             :
            //             <>
            //                 <div className={"ds-header-wrap" + isMainClass}>
            //                     <div className={"top-bar"}>
            //                         <div className={"top-bar-inner"}>
            //                             <span className={"top-bar-message"}>방대한 데이터를 쉽고 빠르게 찾아보세요!</span>
            //                             <div className={"ds-header-user-wrap"}>
            //                                 {!isLogin ? <div className={"top-btn-group"}>
            //                                         <Link to={"/join"}>
            //                                             <Button type={"empty"}>회원가입</Button>
            //                                         </Link>
            //                                         <Link to={"/login"}>
            //                                             <Button type={"round"}>로그인</Button>
            //                                         </Link>
            //                                         <a href="#sitemap" onClick={(e) => { e.preventDefault(); openModal(); }}>
            //                                             <Button type={"round"}>사이트맵</Button>
            //                                         </a>
            //                                     </div>
            //                                     :
            //                                     <div className={"top-bar-inner"}>
            //                                         <ul className={"top-btn-group2"}>
            //                                             <li>
            //                                                 <Link to={"/mypage"}>
            //                                                     나의 정보
            //                                                 </Link>
            //                                             </li>
            //                                             <li>
            //                                 <span onClick={() => dispatch(logout())}>
            //                                     로그아웃
            //                                 </span>
            //                                             </li>
            //                                             <li>
            //                                 <span onClick={() => openModal()}>
            //                                     사이트맵
            //                                 </span>
            //                                             </li>
            //                                         </ul>
            //                                     </div>
            //                                 }
            //                             </div>
            //                         </div>
            //                     </div>
            //                     <div className={"ds-header"}>
            //                         <div className={"ds-header-menu-wrap "}>
            //                             <div className={"ds-header-menu"}>
            //                                 <Link to={"/"} tabIndex={"-1"}>
            //                                     <div className={"ds-header-title"}>
            //                                         <strong>경기지역</strong>
            //                                         <strong>경제포털</strong>
            //                                     </div>
            //                                 </Link>
            //                                 {/* 모바일 사이드 메뉴 start */}
            //                                 <nav role="navigation">
            //                                     <div id="menuToggle">
            //                                         <input type="checkbox" ref={menuChkRef} title={"menu checkbox"}/>
            //                                         <span></span>
            //                                         <span></span>
            //                                         <span></span>

            //                                         <div id="menu">
            //                                             <div className={"menu-inner"}>
            //                                                 <div className={"menu-inner-top"}></div>
            //                                                 {!isLogin && <ul className={"menu-left"}>
            //                                                     <li className={"icon1"}><Link to={"/login"}>로그인</Link></li>
            //                                                     <li className={"icon2"}><Link to={"/join"}>회원가입</Link></li>
            //                                                 </ul>}
            //                                                 <ul className={"menu-left"}>
            //                                                     <li className={"icon3"}><Link to={"/"}>홈</Link></li>
            //                                                     <li className={"icon4"}>
            //                                                         <Link to={"/dataset"}>데이터셋</Link>
            //                                                         <ol>
            //                                                             <li><Link to={"/datasetMix"}>융합 데이터 검색</Link></li>
            //                                                             <li><Link to={"/integratedDataset"}>통합 데이터 검색</Link></li>
            //                                                         </ol>
            //                                                     </li>
            //                                                     <li className={"icon5"}>
            //                                                         <Link to={"/datasetMix"}>데이터 활용</Link>
            //                                                         <ol>

            //                                                             <li><Link to={"/use"}>활용 갤러리</Link></li>
            //                                                         </ol>
            //                                                     </li>
            //                                                     <li className={"icon6"}>
            //                                                         <Link to={"/analysisService"}>분석 서비스 요청</Link>
            //                                                         <ol>
            //                                                         {/*<li><Link to={"/openapiService"}>OpenAPI 서비스</Link>*/}
            //                                                         {/*</li>*/}
            //                                                             <li><a href="http://visualization.bigdata-region.kr" target="_blank">데이터 시각화</a></li>
            //                                                         </ol>
            //                                                     </li>
            //                                                     <li className={"icon8"}><Link to={"/whatwedo"}>플랫폼 소개</Link>
            //                                                         <ol>
            //                                                             <li><Link to={"/allocation"}>데이터 배당</Link></li>
            //                                                             <li><Link to={"/event"}>이벤트</Link></li>
            //                                                         </ol>
            //                                                     </li>
            //                                                     <li className={"icon7"}>
            //                                                         <Link
            //                                                             to={"/board/board_14ab0a7f-d410-48d7-91ca-e264d5121794"}>참여소통</Link>
            //                                                         <ol>
            //                                                             <li><Link
            //                                                                 to={"/board/board_14ab0a7f-d410-48d7-91ca-e264d5121794"}>공지사항</Link>
            //                                                             </li>
            //                                                             <li><Link
            //                                                                 to={"/board/board_942f2cfa-3294-42c5-ac68-b4f3abbed510"}>묻고
            //                                                                 답하기</Link></li>
            //                                                             <li><Link
            //                                                                 to={"/board/board_a2eb004d-73ca-4014-a9d2-c402675cf83f"}>자주하는
            //                                                                 질문</Link></li>
            //                                                             <li><Link
            //                                                                 to={"/board/board_87b9692c-8959-4b3a-83c5-28e209ceca1f"}>데이터
            //                                                                 문의</Link></li>
            //                                                         </ol>
            //                                                     </li>
            //                                                 </ul>
            //                                                 <ul className={"menu-left"}>
            //                                                     <li className={"icon9"}>
            //                                                         {!isLogin && <Link to={"/login"}>로그인</Link>}
            //                                                         {isLogin && <ol>
            //                                                             <li><Link to={"/mypage"}>나의 정보</Link></li>
            //                                                             <li><Link to={"/mypage/orderlist"}>나의 구매내역</Link>
            //                                                             </li>
            //                                                             <li><Link to={"/mypage/favoritedata"}>나의
            //                                                                 관심데이터</Link></li>
            //                                                             <li><Link to={"/mypage/myfaq"}>나의 문의</Link></li>
            //                                                             <li><Link to={"/mypage/download_history"}>나의
            //                                                                 다운로드내역</Link></li>
            //                                                             <li><Link
            //                                                                 to={"/mypage/proposal_analysis"}>분석신청</Link>
            //                                                             </li>
            //                                                             <li><Link
            //                                                                 to={"/mypage/event"}>이벤트 참여 내역</Link>
            //                                                             </li>
            //                                                             <li><a onClick={() => dispatch(logout())}>로그아웃</a>
            //                                                             </li>
            //                                                         </ol>}
            //                                                     </li>
            //                                                 </ul>
            //                                             </div>
            //                                         </div>
            //                                     </div>
            //                                 </nav>
            //                                 {/* 모바일 사이드 메뉴 end */}
            //                                 {/*액션 설정*/}
            //                                 <div className={"accordion"}>
            //                                     <ul className={"menu-1depth"}>
            //                                         <li className={(menuActive.dataset ? "active" : '')} tabIndex="0"
            //                                             onClick={e => goToLink("/#/dataset?datecolumn=created&page=1")}
            //                                             onKeyDown={e => e.keyCode === 13 && goToLink("/#/dataset")}>
            //                                             <Link to={"/dataset"} tabIndex="-1">
            //                                                 <span>데이터셋</span>
            //                                             </Link>
            //                                         </li>
            //                                         <li className={(menuActive.use ? "active" : '')} tabIndex="0"
            //                                             onClick={e => goToLink("/#/use")}
            //                                             onKeyDown={e => e.keyCode === 13 && goToLink("/#/use")}>
            //                                             <Link to={"/use"} tabIndex="-1">
            //                                                 <span>데이터 활용</span>
            //                                             </Link>
            //                                         </li>
            //                                         <li className={(menuActive.analysisService ? "active" : '')} tabIndex="0"
            //                                             onClick={e => goToLink("/#/analysisService")}
            //                                             onKeyDown={e => e.keyCode === 13 && goToLink("/#/analysisService")}>
            //                                             <Link to={"/analysisService"} tabIndex={"-1"}>
            //                                                 <span>서비스</span>
            //                                             </Link>
            //                                         </li>
            //                                         <li className={(menuActive.introduce ? "active" : '')} tabIndex="0"
            //                                             onClick={e => goToLink("/#/whatwedo")}
            //                                             onKeyDown={e => e.keyCode === 13 && goToLink("/#/whatwedo")}>
            //                                             <Link to={"/whatwedo"}
            //                                                   tabIndex={"-1"}>
            //                                                 <span>플랫폼 소개</span>
            //                                             </Link>
            //                                         </li>
            //                                         <li className={(menuActive.board ? "active" : '')} tabIndex="0"
            //                                             onClick={e => goToLink("/#/board/board_14ab0a7f-d410-48d7-91ca-e264d5121794")}
            //                                             onKeyDown={e => e.keyCode === 13 && goToLink("/#/board/board_14ab0a7f-d410-48d7-91ca-e264d5121794")}>
            //                                             <Link to={"/board/board_14ab0a7f-d410-48d7-91ca-e264d5121794"}
            //                                                   tabIndex={"-1"}>
            //                                                 <span>이용 안내</span>
            //                                             </Link>
            //                                         </li>
            //                                     </ul>
            //                                     <div className={"ds-header-menu-with-sub accordion-content"}>
            //                                         <div className={"menu-sub-position"}>
            //                                             <SubMenu where={"header"} key={"header"}/>
            //                                         </div>
            //                                     </div>
            //                                 </div>
            //                             </div>

            //                         </div>

            //                     </div>
            //                 </div>
            //                 <div className={"ds-main-wrap" + isMainClass}>
            //                     <div className={"ds-main"} ref={refContainer}>
            //                         {children}
            //                     </div>
            //                 </div>
            //                 <div className={"ds-footer-wrap" + isMainClass}>
            //                     <div className={"ds-footer"}>
            //                         {/*<div className={"ds-footer-inner-wrap category"}>*/}
            //                         {/*    <div className={"footer-logo"}>*/}
            //                                 {/*<strong>경기지역</strong>*/}
            //                                 {/*<strong>경제포털</strong>*/}
            //                             {/*</div>*/}
            //                             {/*<SubMenu where={"footer"}/>*/}
            //                         {/*</div>*/}
            //                     </div>
            //                     <div className={"ds-footer-inner-wrap copyright"}>
            //                         <div className={"copyright-inner"}>
            //                             <div className={"company-image"}>
            //                                 {/*<img src={logo_ict_grey} alt={"과학기술정보통신부"}/>*/}
            //                                 {/*<img src={logo_nia_grey} alt={"한국지능정보사회진흥원"}/>*/}
            //                                 <br/>
            //                                 <img src={logo_gg_grey} alt={"세계속의 경기도"}/>
            //                             </div>
            //                             <ul className={"copyright-txt"}>
            //                                 <li>경기도 수원시 영통구 도청로 30 | 고객센터 : 02-3215-1213
            //                                     <a href={instagram_link} target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px', float: 'right' }}>
            //                                         <img src={facebookicon}  alt={"페이스북"} style={{ verticalAlign: 'middle' }}/>
            //                                     </a>
            //                                     <a href={facebook_link} target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px', float: 'right' }}>
            //                                         <img src={instagramicon}  alt={"인스타그램"} style={{ verticalAlign: 'middle' }}/>
            //                                     </a>
            //                                 </li>
            //                                 <li className={"txt-copy"} style={{padding:"0 -30px"}}>© Gyeonggi Province. All Rights Reserved.</li>
            //                                 <li className={"txt-policy"}><Footer/></li>
            //                             </ul>
            //                         </div>
            //                     </div>
            //                 </div>
            //             </>
            //     }
            // </>

        );
    }
;

export default Layout;

const Footer=()=>{
    const m = [
        {
            name: "이용 약관",
            onClick: () => {
                terms("termsAndConditions")
            }
        }, {
            name: "개인정보처리방침",
            onClick: () => {
                terms("privacyPolicy")
            },
            style: {color:"#001bbb"}
        }
    ]

    return (
        m.map(i => {
                let to = {};

                return (
                    <p key={JSON.stringify(i)} style={{marginRight:"40px", fontWeight:"500"}}>
                        <span onClick={i.onClick} style={i.style}>{i.name}</span>
                    </p>
                )
            }
        )
    )
}

const SubMenu = (
        {
            where
        }
    ) =>
    {
        const dispatch = useDispatch();

        let m = [];
        let [boardMenuList, setBoardMenuList] = useState([]);

        useSelector(state => {
            if (state.board.boardMenuList !== boardMenuList)
                setBoardMenuList(state.board.boardMenuList);
        });

        const [competitionFlag, setCompetitionFlag] = useState(false);
        const [openapiFlag, setOpenApiFlag] = useState(false);
        useEffect(() => {
            DataApi.readIkanConfig()
                .then(res => {
                    setCompetitionFlag(res.result.config_list.filter(config => {
                        return config.function_type === "competition"
                    })[0].function_active)
                    setOpenApiFlag(res.result.config_list.filter(config => {
                        return config.function_type === "openapi"
                    })[0].function_active)
                })
        }, []);

        const userInfo = useSelector(state => state.user.info);
        const isLogin = useSelector(state => state.user.isLogin);
        const SurveyLink = useSelector(state => state.user.surveyLink);

        // const popupX = (window.screen.width / 2) - ((500 / 2) + 10);
        // const popupY= (window.screen.height / 2) - ((500 / 2) + 50);
        let popupX = (document.body.offsetWidth / 2) - (500 / 2);
        popupX += window.screenLeft;
        const popupY = (window.screen.height / 2) - (500 / 2);
        if (where === "header") {
// 네비게이션
            m = [
                {
                    name: "데이터셋",
                    child: [
                        {
                            name: "데이터 셋",
                            link: "/dataset"
                        },
                        {
                            name: "융합 데이터",
                            link: "/datasetMix"
                        },
                        {
                            name: "통합 데이터 검색",
                            link: "/integratedDataset"
                        }
                    ]
                }, {
                    name: "데이터 활용",
                    child: [
                        {
                            name: "활용 갤러리",
                            link: "/use"

                        },

                    ]
                }, {
                    name: "서비스 요청",
                    child: [
                        {
                            name: "분석 서비스 신청",
                            link: "/analysisService"
                        },
                        {
                            name: "기업입지 추천 서비스",
                            onClick: () => {
                                let service_link = "http://loc.bigdata-region.kr/location";

                                window.open(service_link);
                            }
                        },
                        {
                            name: "소상공인 투자환경 분석",
                            onClick: () => {
                                if (!isLogin) {
                                    swal("로그인 후 이용 가능한 서비스입니다.")
                                    window.location.href = "/#/login"
                                } else {

                                    let user_id = userInfo.id;
                                    let user_token = userInfo.token;
                                    let target = "3";

                                    let service_link = "http://inno.bigdata-region.kr:8080/inno/ssn.do?user_id=" + user_id + "&token=" + user_token + "&target=" + target;

                                    window.open(service_link);
                                }
                            }
                        },
                        {
                            name: "분석경험 공유",
                            onClick: () => {
                                if (!isLogin) {
                                    swal("로그인 후 이용 가능한 서비스입니다.")
                                    window.location.href = "/#/login"
                                } else {

                                    let user_id = userInfo.id;
                                    let user_token = userInfo.token;
                                    let target = "2";

                                    let service_link = "http://inno.bigdata-region.kr:8080/inno/ssn.do?user_id=" + user_id + "&token=" + user_token + "&target=" + target;

                                    window.open(service_link);
                                }
                            }
                        },
                        {
                            name: "기업 신용 분석",
                            // link: "/creditanalysis"
                            onClick: () => {
                                let service_link = "https://www.kreport.co.kr/";

                                window.open(service_link);
                            }
                        }, {
                            name: "데이터 시각화",
                            onClick:()=>{
                                let link="http://visualization.bigdata-region.kr:80";
                                window.open(link)
                            }
                        },


                    ]
                }, {
                    name: "플랫폼 소개",
                    child: [
                        {
                            name: "소개",
                            link: "/whatwedo"
                        },
                        // {
                        //     name: "데이터 배당",
                        //     link: "/allocation"
                        // }
                        // {
                        //     name: "설문조사",
                        //     onClick: () => window.open(SurveyLink, 'window_name', 'scrollbars=1, width=500,height=500,left=' + popupX + ',top=' + popupY)
                        // }
                    ]
                }, {
                    name: "이용 안내",
                    child: [
                        ...boardMenuList,
                        {
                            name: "이벤트",
                            link: "/event"
                        }
                    ]
                }
            ];
            // openapi 메뉴
            if (openapiFlag) {
                m[2]['child'].push(
                    {
                        name: "Open API 서비스",
                        link: "/openapiService"
                    }
                )
            }

            if (competitionFlag) {
                m[1]['child'].push(
                    {
                        name: "공모전",
                        onClick: () => {
                            if (isLogin) {
                                window.location.href = "/#/competition"
                            } else {
                                swal("로그인 후 이용 가능한 서비스입니다.");
                                window.location.href = "/#/login"
                            }
                        }
                    }
                )
            }
        }

        if (where === "footer") {
            m = [
                {
                    name: "데이터셋",
                    child: [
                        {
                            name: "데이터셋",
                            link: "/dataset"
                        },
                        {
                            name: "통합 데이터 검색",
                            link: "/integratedDataset"
                        },
                        {
                            name: "분야별 데이터",
                            link: "/datasetMix"
                        }
                    ]
                }, {
                    name: "활용",
                    child: [
                        {
                            name: "활용 갤러리",
                            link: "/use"
                        }, {
                            name: "데이터 시각화",
                            onClick:()=>{
                                let link="http://visualization.bigdata-region.kr:80";
                                window.open(link)
                            }
                        }, {
                            name: "분석 서비스 신청",
                            link: "/analysisService"
                        }
                    ]
                }, {
                    name: "참여 소통",
                    child: [
                        ...boardMenuList
                    ]
                }
            ];
            // openapi 메뉴
            if (openapiFlag) {
                m[1]['child'].push(
                    {
                        name: "Open API 서비스",
                        link: "/openapiService"
                    }
                )
            }
        }


        if (where === "footer" && isLogin)
            m.push({
                name: "기타",
                child: [
                    {
                        name: "이용 약관",
                        onClick: () => {
                            terms("termsAndConditions")
                        }
                    }, {
                        name: "개인정보처리방침",
                        onClick: () => {
                            terms("privacyPolicy")
                        }
                    }
                ]
            }, {
                name: "마이페이지",
                child: [
                    {
                        name: "나의 정보",
                        link: "/mypage"
                    }, {
                        name: "나의 구매 내역",
                        link: "/mypage/orderlist"
                    }, {
                        name: "나의 문의",
                        link: "/mypage/myfaq"
                    }, {
                        name: "다운로드내역",
                        link: "/mypage/download_history"
                    }, {
                        name: "분석신청 내역",
                        link: "/mypage/proposal_analysis"
                    }, {
                        name: "이벤트 참여 내역",
                        link: "/mypage/event"
                    }, {
                        name: "로그아웃",
                        onClick: () => dispatch(logout(userInfo))
                    }
                ]
            });
        else if (where === "footer" && !isLogin)
            m.push({
                name: "기타",
                child: [
                    {
                        name: "이용 약관",
                        onClick: () => {
                            terms("termsAndConditions")
                        }
                    }, {
                        name: "개인정보처리방침",
                        onClick: () => {
                            terms("privacyPolicy")
                        }
                    }, {
                        name: "회원가입",
                        link: "/join"
                    }, {
                        name: "로그인",
                        link: "/login"
                    }
                ]
            });


        return (
            <>
                <ul className={"footer-category-wrap"}>
                    {m.map(l => <li key={JSON.stringify(l)}>
                        <dl>
                            {/*<dt className={"footer-category-tit"}>{l.name}</dt>*/}
                            <dd className={"footer-category-list"}>
                                <ul>
                                    {l.child.map(i => (
                                        <li key={JSON.stringify(i)} style={{width: '100%'}}>
                                            {i.link ?
                                                <Link
                                                    to={i.link}
                                                    onClick={(e) => {
                                                        const wrapper = e.currentTarget.closest('.accordion-content');
                                                        if (wrapper) {
                                                            wrapper.style.display = 'none';
                                                            requestAnimationFrame(() => wrapper.style.display = '');
                                                        }
                                                        if (i.onClick) i.onClick(e);
                                                    }}
                                                    tabIndex="-1"
                                                >{i.name}</Link>
                                                :
                                                <span onClick={i.onClick}>{i.name}</span>
                                            }
                                        </li>
                                    ))}
                                </ul>
                            </dd>
                        </dl>
                    </li>)}
                </ul>
            </>
        )
    }
;


const NewSubMenu = (
        {
            where
        }
    ) =>
    {
        const dispatch = useDispatch();

        let m = [];
        let [boardMenuList, setBoardMenuList] = useState([]);

        useSelector(state => {
            if (state.board.boardMenuList !== boardMenuList)
                setBoardMenuList(state.board.boardMenuList);
        });

        const [competitionFlag, setCompetitionFlag] = useState(false);
        const [openapiFlag, setOpenApiFlag] = useState(false);
        useEffect(() => {
            DataApi.readIkanConfig()
                .then(res => {
                    setCompetitionFlag(res.result.config_list.filter(config => {
                        return config.function_type === "competition"
                    })[0].function_active)
                    setOpenApiFlag(res.result.config_list.filter(config => {
                        return config.function_type === "openapi"
                    })[0].function_active)
                })
        }, []);

        const userInfo = useSelector(state => state.user.info);
        const isLogin = useSelector(state => state.user.isLogin);
        const SurveyLink = useSelector(state => state.user.surveyLink);

        // const popupX = (window.screen.width / 2) - ((500 / 2) + 10);
        // const popupY= (window.screen.height / 2) - ((500 / 2) + 50);
        let popupX = (document.body.offsetWidth / 2) - (500 / 2);
        popupX += window.screenLeft;
        const popupY = (window.screen.height / 2) - (500 / 2);
        if (where === "header") {

            m = [
                {
                    name: "데이터셋",
                    child: [
                        {
                            name: "데이터셋",
                            link: "/dataset"
                        },

                        {
                            name: "통합 데이터 검색",
                            link: "/integratedDataset"
                        }
                    ]
                }, {
                    name: "활용",
                    child: [
                        {
                            name: "활용 갤러리",
                            link: "/use"
                        }, {
                            name: "분석 서비스 신청",
                            link: "/analysisService"
                        }
                    ]
                }, {
                    name: "참여 소통",
                    child: [
                        ...boardMenuList,
                        {
                            name: "설문조사",
                            onClick: () => window.open(SurveyLink, 'window_name', 'scrollbars=1, width=500,height=500,left=' + popupX + ',top=' + popupY)
                        }
                    ]
                }, {
                    name: "소개",
                    child: [
                        {
                            name: "소개",
                            link: "/whatwedo"
                        },
                        {
                            name: "데이터 배당",
                            link: "/allocation"
                        },
                        {
                            name: "이벤트",
                            link: "/event"

                        }
                    ]
                }
            ];
            // openapi 메뉴
            if (openapiFlag) {
                m[2]['child'].push(
                    {
                        name: "Open API 서비스",
                        link: "/openapiService"
                    }
                )
            }

            if (competitionFlag) {
                m[1]['child'].push(
                    {
                        name: "공모전",
                        onClick: () => {
                            if (isLogin) {
                                window.location.href = "/#/competition"
                            } else {
                                swal("로그인 후 이용 가능한 서비스입니다.");
                                window.location.href = "/#/login"
                            }
                        }
                    }
                )
            }
        }

        if (where === "footer") {
            m = [
                {
                    name: "데이터셋",
                    child: [
                        {
                            name: "데이터셋",
                            link: "/dataset"
                        },
                        {
                            name: "통합 데이터 검색",
                            link: "/integratedDataset"
                        }
                    ]
                }, {
                    name: "활용",
                    child: [
                        {
                            name: "활용 갤러리",
                            link: "/use"
                        }, {
                            name: "데이터 시각화",
                            onClick:()=>{
                                let link="http://visualization.bigdata-region.kr:80";
                                window.open(link)
                            }
                        }, {
                            name: "분석 서비스 신청",
                            link: "/analysisService"
                        }
                    ]
                }, {
                    name: "참여 소통",
                    child: [
                        ...boardMenuList
                    ]
                }
            ];
            // openapi 메뉴
            if (openapiFlag) {
                m[1]['child'].push(
                    {
                        name: "Open API 서비스",
                        link: "/openapiService"
                    }
                )
            }
        }


        if (where === "footer" && isLogin)
            m.push({
                name: "기타",
                child: [
                    {
                        name: "이용 약관",
                        onClick: () => {
                            terms("termsAndConditions")
                        }
                    }, {
                        name: "개인정보처리방침",
                        onClick: () => {
                            terms("privacyPolicy")
                        }
                    }
                ]
            }, {
                name: "마이페이지",
                child: [
                    {
                        name: "나의 정보",
                        link: "/mypage"
                    }, {
                        name: "나의 구매 내역",
                        link: "/mypage/orderlist"
                    }, {
                        name: "나의 문의",
                        link: "/mypage/myfaq"
                    }, {
                        name: "다운로드내역",
                        link: "/mypage/download_history"
                    }, {
                        name: "분석신청 내역",
                        link: "/mypage/proposal_analysis"
                    }, {
                        name: "이벤트 참여 내역",
                        link: "/mypage/event"
                    }, {
                        name: "로그아웃",
                        onClick: () => dispatch(logout(userInfo))
                    }
                ]
            });
        else if (where === "footer" && !isLogin)
            m.push({
                name: "기타",
                child: [
                    {
                        name: "이용 약관",
                        onClick: () => {
                            terms("termsAndConditions")
                        }
                    }, {
                        name: "개인정보처리방침",
                        onClick: () => {
                            terms("privacyPolicy")
                        }
                    }, {
                        name: "회원가입",
                        link: "/join"
                    }, {
                        name: "로그인",
                        link: "/login"
                    }
                ]
            });


        return (
            <>
                <ul className={"new-footer-category-wrap"}>
                    {m.map(l => <li key={JSON.stringify(l)}>
                        <dl>
                            {/*<dt className={"footer-category-tit"}>{l.name}</dt>*/}
                            <dd className={"new-footer-category-list"}>
                                <ul>
                                    {l.child.map(i => {
                                        let to = {};
                                        if (i.link)
                                            to.to = i.link;

                                        return (
                                            <li key={JSON.stringify(i)} style={{width: '100%'}}>
                                                {i.link ?
                                                    <Link to={i.link} onClick={i.onClick && i.onClick}
                                                          tabIndex={"-1"}>{i.name}</Link>
                                                    :
                                                    <span onClick={i.onClick && i.onClick}>{i.name}</span>
                                                }
                                            </li>
                                        )
                                    })}
                                </ul>
                            </dd>
                        </dl>
                    </li>)}
                </ul>
            </>
        )
    }
;
