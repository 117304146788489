import React, {useEffect, useState} from 'react';

const MetaInfo = ({initialMetaInfo, ...props}) => {

    let initial = initialMetaInfo;

    return (
        <>
            <table className="info-table meta-list">
                <tbody>
                    {initial.map((item, no) => {
                        if(no % 2 === 0){
                            return <tr key={no}>
                                <ListItem item={item} key={no}/>
                                <ListItem item={initial[no + 1]} key={no + 1}/>
                            </tr>
                        }else return null;
                    })}
                </tbody>
            </table>
        </>
    )
};

export default MetaInfo;

const ListItem = ({item, onChange, onRemove}) => {
    const [key, setKey] = useState(item.key);
    const [display, setDisplay] = useState(item.display);
    const [value, setValue] = useState((item.value) ? item.value : "");

    useEffect(() => {
        const item = {
            key: key,
            value: value,
            display: display
        };
        onChange(item);
    }, [key, display, value]);

    let isKeyword = item.key === "keyword";
    return (
        <>
            <th dangerouslySetInnerHTML={{__html: display}}></th>
            <td dangerouslySetInnerHTML={{__html: value}}></td>
        </>
    );
};
ListItem.defaultProps = {
    item: {},
    onChange: () => {
    }
};
