import React, {useState, useEffect, useRef} from 'react';
import { Link } from 'react-router-dom';
import * as DataApi from "../../api/Data";

export default function AutocompleteSearchDataset({search}){
    // 검색관련
    const [word, setWord] = useState("");
    const [searchResult, setSearchResult] = useState([]);
    const [isAutoSearch, setIsAutoSearch] = useState(false);
    const [autoSearchKeyWord, setAutoSearchKeyword] = useState("");
    const [focusIndex, setFocusIndex] = useState(-1);
    const [data, setData] = useState([]);
    const focusRef = useRef(null);
    const listRef = useRef(null);
    const scrollRef = useRef(null);

    const keyIntervalRef = useRef(null);

    useEffect(() => {
      DataApi.readDatasetAllTitle().then(res => {
        setData(res.result.title_list||[])
      });      
    }, [])

    const handleInputWord = (e) => {
      if(isAutoSearch){
        const enteredValue = e.nativeEvent.inputType === "deleteContentBackward" ? "" : e.nativeEvent.data;
        focusIndex >= 0 && setWord(autoSearchKeyWord + enteredValue);
        setIsAutoSearch(false);
        setFocusIndex(-1);
      }else{
        setWord(e.target.value);
      }
    }     

    useEffect(() => {
      // 검색어 입력 후 300ms 후에 데이터 로드
      const timeoutId = setTimeout(() => {
        if (word !== '') {
          const searchData = data.filter(item => item.keyword.includes(word) === true);
          setSearchResult([...searchData]);
        } else {
          setSearchResult([]);
        }
      }, 300);
      // cleanup 함수를 이용하여 이전 타이머 제거
      return () => clearTimeout(timeoutId);        
    }, [word])

    const KeyEvent = {
        Enter: () => {
          const searchWord = isAutoSearch ? autoSearchKeyWord : word;
          setSearchResult([]);
          return search(searchWord);
        },
        ArrowDown: () => {
          if (searchResult.length === 0) {
            return;
          }
          if (listRef.current.childElementCount === focusIndex + 1) {
            setFocusIndex(() => 0);
            handleScroll("init");
            return;
          }
          if (focusIndex === -1) {
            setIsAutoSearch(true);
          }
          setFocusIndex((index) => index + 1);
          setAutoSearchKeyword(searchResult[focusIndex + 1].keyword);
          handleScroll("down");
        },
        ArrowUp: () => {
          if (focusIndex === -1) {
            return;
          }
          if (focusIndex === 0) {
            setAutoSearchKeyword("");
            setFocusIndex((index) => index - 1);
            setIsAutoSearch(false);
            return;
          }
    
          setFocusIndex((index) => index - 1);
          setAutoSearchKeyword(searchResult[focusIndex - 1].keyword);
          handleScroll("up");
        },
        Escape: () => {
          setAutoSearchKeyword("");
          setFocusIndex(-1);
          setIsAutoSearch(false);
        },
    };

    const handleScroll = (type) => {
      if (!scrollRef.current) {
        return;
      }
      if (type === "init") {
        scrollRef.current.scrollTop = 0;
        return;
      }

      const top = scrollRef.current.scrollTop;
      let newIndex = focusIndex;

      if (type === "down") {
        newIndex += 1;
      } else if (type === "up") {
        newIndex -= 1;
      }

      const focusedItem = listRef.current.children[newIndex];
      const offset = focusedItem.offsetTop - 275;

      if(offset !== 0 && !((top+275) >= focusedItem.offsetTop && top < focusedItem.offsetTop)){
        scrollRef.current.scrollTop = type === "down" ? offset : focusedItem.offsetTop;
      }
    }

    const handleKeyDown  = (e) => {
        if(KeyEvent[e.key]) {
          if(keyIntervalRef.current){
            clearInterval(keyIntervalRef.current);
          }
          keyIntervalRef.current = setInterval(KeyEvent[e.key](), 1000);
        }
    };

    const handleKeyUp = () => {
      if(keyIntervalRef.current){
        clearInterval(keyIntervalRef.current);
        keyIntervalRef.current = null;
      }
    };

    const highlightKeyword = (title) => {
      const regex = new RegExp(`(${word})`, 'gi');
      return title.replace(regex, '<strong style="color: #379ac4;">$1</strong>');
    }

    const handleSearch = (e, word) => {
      e.preventDefault();
      setSearchResult([]);
      setWord(word);
      return search(word);      
    }
    
    return (
      <div className="search-area">
        <div style={{width:"100%",position:"relative"}}>
          <input 
            type="text" 
            placeholder="데이터를 검색해보세요." 
            onKeyDown={e => {handleKeyDown(e);}}
            value={isAutoSearch ? autoSearchKeyWord : word}
            onChange={handleInputWord}
            onKeyUp={handleKeyUp}
            title={"데이터셋 검색"} 
            autoComplete={"off"}
          />
          {searchResult && searchResult.length > 0 && (
            <div className='autocompleteWrap' ref={scrollRef}>
              <ul className="autocomplete" ref={listRef}>
                {searchResult.map((item, listIndex) => (
                  <li 
                    key={listIndex} 
                    ref={listIndex === focusIndex ? focusRef : undefined}
                    className={listIndex === focusIndex ? "focused" : ""}
                  >
                    <Link 
                      key={JSON.stringify(item)} 
                      to={"/new-dataset?word=" + item.keyword} 
                      onClick={e => handleSearch(e, item.keyword)}
                    >
                      <div style={{display:"flex",justifyContent:"space-between"}}>
                        <p>
                          <span dangerouslySetInnerHTML={{ __html: highlightKeyword(item.keyword) }} />
                        </p>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <button onClick={() => search(isAutoSearch ? autoSearchKeyWord : word)}>
          검색
        </button>
      </div>
    )
}
