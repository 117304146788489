import React from 'react';
import Cell from './Cell';

const Row = ({no, item, head, onRowClick}) => {

    return (
        <tr onClick={e => onRowClick(item)}>
            {head.map((row, i) => <Cell
                key={i}
                name={row.name}
                value={item[row.name]}
                cellType={row.type}
                render={row.render}
                item={item}
                no={no}
            />)}
        </tr>
    )
};

export default Row;
