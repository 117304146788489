import React from 'react';
import {Link} from "react-router-dom";
import {yyyymmdd} from "../util/Date";
import {addComma, byte} from "../util/Number";

const DatasetList = ({list, totalCount}) => {
    return (
        <>
            {list.length > 0 ? <ul className={"dataset-list"}>
                {list.map(item => <DatasetItem key={item.id} info={item}/>)}
            </ul> : <div className={"ds-2-no-result"} style={{textAlign: "center", padding: 0}}>검색 결과가 없습니다.</div>}
        </>
    );
};

const DatasetItem = ({info}) => {
    let displayCreatedDate = yyyymmdd(new Date(info.issued), ".");
    return (
        <li key={info.id} data-tut={"dataset_list"}>
            <dl>
                <Link to={"/integratedDataset/"+info.id}>
                <dt>
                    {info.title}
                    <div className={"createdDate"}>
                        등록일 {displayCreatedDate}
                    </div>
                    {/*<div className={"date"}>*/}
                    {/*</div>*/}
                </dt>
                <dd>
                    <div className={"explanation"}>데이터 유형: { (info.type === "dataset") ? "데이터셋" : "데이터 서비스"}</div>
                    <div className={"info"}>

                        <div className={"dataset-list-add-info-wrap"}>
                            <span className={"dataset-list-add-info-color"}>제공기관: {info.platform.title} </span>
                        </div>
                    </div>
                </dd>
                </Link>
            </dl>
        </li>
    );
};

export default DatasetList;
