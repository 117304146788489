import {SET_META_INFO, SET_DATA_TITLE, SET_CHANGE_MAIN} from "../actions/DataAction";

const initialState = {
    metaInfo: {},
    dataTitle: {},
    changeMain: false
};

const DataReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_META_INFO:
            return Object.assign({}, state, {
                metaInfo: {
                    ...state.metaInfo,
                    ...action.metaInfo
                }
            });

        case SET_DATA_TITLE:
            return Object.assign({}, state, {
                dataTitle: {
                    ...state.dataTitle,
                    ...action.dataTitle
                }
            });

        case SET_CHANGE_MAIN:
            return Object.assign({}, state, {
                changeMain: action.changeMain
            })

        default:
            return state;
    }
};

export default DataReducer;
