import React, { Component } from "react"

export default class CopyToClipBoard extends Component {
    constructor(props) {
        let dataUrl;
        dataUrl = props.dataUrl
        super(props)
        this.state = {
            dataUrl: dataUrl
        }
    }
    copyCodeToClipboard = () => {
        const el = this.input
        el.select()
        document.execCommand("copy")
    }
    render() {
        return (
            <div>
                <div>
                    <input className={"order-etc-input"} ref={(input) => this.input = input} value={this.state.dataUrl}/>
                </div>
                <div className="btn-group"style={{"margin-top":"10px"}}>
                    <button onClick={() => this.copyCodeToClipboard()} style={{"min-width":"120px", "height":"38px"}}>
                        링크복사
                    </button>
                </div>
            </div>
        )
    }
}