import React from 'react';
import Button from "./Button";
import ResourcePreview from "./ResourcePreview";
import {byte} from "../util/Number";
import {useSelector} from "react-redux";

const DatasetResource = ({resource, sampleInfo, schemaInfo, fileDownload, sampleFileDownload, schemaFileDownload, downloadLoading, sampleDownloadLoading, schemaDownloadLoading, dataInfo, handleOrderRequest, addFavoriteData, handleDataReport}) => {
    const userInfo = useSelector(state => state.user.info);
    return (
        <>
            <ul className={"info-table"}>
                <li>
                    {/*<strong>{resource.format.toUpperCase()}</strong>*/}
                    <strong>다운로드</strong>
                    <span>
                    <div className={"ikan-button-wrap ikan-button-line"}>
                        <Button onClick={() => fileDownload(resource.id)}>{downloadLoading ? <div className="lds-facebook">
                            <div />
                            <div />
                            <div />
                        </div> : "데이터"}</Button>
                        <Button onClick={() => sampleFileDownload(sampleInfo.id)}>{sampleDownloadLoading ? <div className="lds-facebook">
                            <div />
                            <div />
                            <div />
                        </div> : "샘플 데이터"}</Button>
                        <Button onClick={() => schemaFileDownload(schemaInfo.id)}>{schemaDownloadLoading ? <div className="lds-facebook">
                            <div />
                            <div />
                            <div />
                        </div> : "데이터 정보"}</Button>
                    </div>
                </span>
                </li>
                <li>
                    <strong>이 름</strong>
                    <span>{resource.name}</span>
                </li>
                {/*<li>*/}
                {/*    <strong>등록일자</strong>*/}
                {/*    <span>{yyyymmddhhiiss(new Date(resource.created), "-")}</span>*/}
                {/*</li>*/}
                <li>
                    <strong>포 맷</strong>
                    <span>{resource.format}(UTF-8)</span>
                </li>
                <li>
                    <strong>사이즈</strong>
                    <span>{byte(resource.size, 0)}</span>
                </li>
                <li>
                    <strong>설 명</strong>
                    <span>UTF-8을 지원하는 에디터로 열어야합니다.(예: Notepad++)</span>
                </li>
            </ul>
            <ResourcePreview sampleId={sampleInfo.id}/>
        </>
    )
};

export default DatasetResource;
