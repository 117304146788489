import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import * as DataApi from "../../api/Data";
import MetaInfoView from "../../components/MetaInfoView";
import DatasetResourceIntegrated from "../../components/DatasetResourceIntegrated";
import {yyyymmdd} from "../../util/Date";
import Indicator from "../../components/Indicator";
import ErrorPage404 from "../common/ErrorPage404";
import TourMessage from "../common/tourMessage";
import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";
import {setTourBtn} from "../../store/actions/UserAction";
import Tour from "reactour";
import SubNavNew from "../../components/SubNavNew";
import { decodeHtml } from '../../util/DecodeHtml';

let paramsId = null;
const Item = ({match, ...props}) => {
    let [dataInfo, setDataInfo] = useState(null);
    let [downloadCount, setDownloadCount] = useState(0);
    let [show404, setShow404] = useState(false);

    const subNav = [
        {
            display: '데이터셋',
            name: '/new-dataset'
        },
        {
            display: '융합 데이터',
            name: '/datasetMix'
        },
        {
            display: '통합 데이터 검색',
            name: '/integratedDataset'
        }
    ];

    useEffect(() => {
        if(paramsId !== match.params.id || paramsId === match.params.id){
            const params = {
                data_id: match.params.id
            };
            DataApi.readDataIntegrated(params).then(res => {
                console.log(res);
                setDataInfo(res.result);
                setDownloadCount(res.result.downloadCount);
            }).catch(res => {
                setShow404(true);
            });
            paramsId = match.params.id;
        }
    }, [match.params.id]);

    return (
        <>
            <div className="renewal">
                <SubNavNew nav={subNav} history={props.history} />
            </div>
            {dataInfo !== null ? <ItemInfo key={dataInfo.id} dataInfo={dataInfo} downloadCount={downloadCount} {...props}/> : <div><Indicator /></div>}
            {show404 && <ErrorPage404/>}
        </>
    )

};

export default Item;

const ItemInfo = ({dataInfo, downloadCount, ...props}) => {

    let metaInfo = {};
    metaInfo.view = [];

    // let metas = ["creator_name", "accrualPeriodicity", "issued", "modified", "license", "language", "version", "ds_price", "keyword"];
    // 200717 수정
    // custom_modfied = ckan에서 제공하는 최초등록일 및 갱신일자
    let metas = ["creator_name", "issued", "custom_modified", "license", "language", "dataType", "modiType", "version", "priceInfo", "keyword", "publisher", "download_count"];
    let dataInfo_metadata_created = dataInfo.regDate;
    let dataInfo_metadata_modified = dataInfo.updateDate;
    metas.map(key => {
        if (key === "creator_name") {
            let custom_metadata = {
                "display": "제공처",
                "key": "creator_name",
                "value": dataInfo.platform.title
            };
            metaInfo.view.push(custom_metadata)
        }
        if (key === "issued") {
            let custom_metadata = {
                "display": "생성 일자",
                "key": "issued",
                "value": yyyymmdd(new Date(dataInfo.issued))
            };
            metaInfo.view.push(custom_metadata)
        }
        if (key === "custom_modified") {
            let custom_metadata = {
                "display": "갱신일자" + "<small>(최초등록일)</small>",
                "key": "custom_modified",
                "value": yyyymmdd(new Date(dataInfo_metadata_modified)) + "<br/> <small>" + yyyymmdd(new Date(dataInfo_metadata_created)) + "</small>"
            };
            metaInfo.view.push(custom_metadata)
        }
        if (key === "download_count") {
            metaInfo.view.push({
                "display": "다운로드 횟수",
                "key": "download_count",
                "value": String(dataInfo.downloadCount)
            })
        }
        if (key === "keyword") {
            metaInfo.view.push({
                "display": "키워드",
                "key": "keyword",
                "value": dataInfo.keywords.join(',')
            })
        }
        if (key === "language") {
            let lang = dataInfo.language
            if (dataInfo.language === "ko") {
                lang = "한국어"
            }
            metaInfo.view.push({
                "display": "사용 언어",
                "key": "language",
                "value": lang
            })
        }
        if (key === "license") {
            metaInfo.view.push({
                "display": "라이센스",
                "key": "license",
                "value": dataInfo.license
            })
        }
        if (key === "dataType") {
            metaInfo.view.push({
                "display": "데이터 유형",
                "key": "dataType",
                "value": dataInfo.dataType
            })
        }
        if (key === "modiType") {
            metaInfo.view.push({
                "display": "가공 유형",
                "key": "modiType",
                "value": dataInfo.modiType
            })
        }
        if (key === "priceInfo") {
            metaInfo.view.push({
                "display": "가격 정보",
                "key": "priceInfo",
                "value": dataInfo.priceInfo
            })
        }
        if (key === "publisher") {
            metaInfo.view.push({
                "display": "등록자 이름",
                "key": "publisher_name",
                "value": dataInfo.publisher.name
            })
            metaInfo.view.push({
                "display": "등록자 이메일",
                "key": "publisher_email",
                "value": dataInfo.publisher.email
            })
            metaInfo.view.push({
                "display": "등록자 연락처",
                "key": "publisher_phone",
                "value": dataInfo.publisher.phone
            })
            metaInfo.view.push({
                "display": "등록자 홈페이지",
                "key": "publisher_homepage",
                "value": dataInfo.publisher.homepage
            })
        }

    });
    // console.log(metaInfo)


    const dispatch = useDispatch();

    // 투어
    const dataDetailTour = new TourMessage.DataSetDetailPageTourMessage()
    const isTourOpen = useSelector(state => state.user.isTourOpen)
    let disableBody = target => disableBodyScroll(target)
    let enableBody = target => enableBodyScroll(target)

    const closeTour = () => {
        dispatch(setTourBtn(false))
    }
    //
    return (
        <>
            <Tour
                steps={dataDetailTour.tourConfig}
                isOpen={isTourOpen}
                onRequestClose={closeTour}
                rounded={5}
                accentColor={"#004c9d"}
                onAfterOpen={disableBody}
                onBeforeClose={enableBody}
            />
            <div className={"wrap-dataset-view"}>
                <div className="title-view" data-tut={"dataset_subject"}>
                    <h1>
                        {dataInfo.title}
                    </h1>
                    <p>
                        {decodeHtml(dataInfo.description)}
                    </p>
                </div>
                <div className={"dataset-info"}>
                    <div className={"dataset-meta"} data-tut={"dataset_meta"}>
                        <MetaInfoView initialMetaInfo={metaInfo.view} key={JSON.stringify(metaInfo.view)}/>
                    </div>
                    <div className={"dataset-data"} data-tut={"dataset_info"}>
                        {dataInfo.files.map(file => <DatasetResourceIntegrated key={file.id}
                                                                               resource={file}
                                                                               dataInfo={dataInfo}
                        />)}
                    </div>
                </div>
            </div>
        </>
    )
};
