import React, {useState} from 'react';
import * as UserApi from "../../api/User";
import swal from "sweetalert"

const ResetPassword = ({match, ...props}) => {
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [isSamePassword, setIsSamePassword] = useState(true);
    const passwordInput = null;
    let user_id_key = match.params.user_id_key;
    let reset_key = match.params.reset_key;

    const changePassword = (target, value) => {
        if (target === "password") {
            setPassword(value);

            if (passwordConfirm === value)
                setIsSamePassword(true);
            else setIsSamePassword(false);

        } else if (target === "passwordConfirm") {
            setPasswordConfirm(value);

            if (password === value)
                setIsSamePassword(true);
            else setIsSamePassword(false);
        }
    };

    const isChangePassword = () =>{
        const params = {
            user_id_key: user_id_key,
            reset_key: reset_key,
            password: password
        };

        if (password?.length < 8 || !(password.search(/[A-Z]/) >= 0 && password.search(/[a-z]/) >= 0 && password.search(/\d/) >= 0 && password.search(/[#?!@$%^&*-]/) >= 0)) {
            swal("비밀번호는 8자 이상이어야 하며, 숫자/대문자/소문자/특수문자를 모두 포함해야 합니다.").then( res => {
                passwordInput.current.focus()
            })
            return;
        }

        UserApi.readForgotPassword(params).then(res => {
            swal('비밀번호 변경이 완료되었습니다.')
            props.history.push("/login");
        }).catch(res => {
            const json_res = JSON.parse(res.response);
            if (json_res.result === 101) {
                swal('비밀번호는 8자 이상이어야 합니다.')
            }
            else {
                swal('잘못된 경로로 접근하였습니다.')
            }
        });
    };

    return (
        <>
            <div className={"wrap-cahnge-password"}>
                <div className={"inner-cahnge-password"}>
                    <h1> 비밀번호 변경 </h1>
                    <ul className={"ikan-text-field-default"}>
                        <li>
                            <input
                                type={"password"}
                                placeholder={"PASSWORD"} 
                                ref={passwordInput}
                                value={password} 
                                onChange={e => changePassword("password", e.target.value)}
                            />
                        </li>
                        <li>
                            <input 
                                type={"password"} 
                                placeholder={"PASSWORD CONFIRM"}
                                value={passwordConfirm}
                                onChange={e => changePassword("passwordConfirm", e.target.value)}
                            />
                            <span className={"error-text"}>{!isSamePassword && "비밀번호가 일치하지 않습니다."}</span>
                        </li>
                    </ul>
                    <p className={"join-warning-text"}>&#8251; 비밀번호는 8자 이상이어야 하며<br/>영문대문자/영문소문자/숫자/특수문자를 모두 포함해야 합니다.</p><br/>
                    <div className={"btn-group ac"}>
                        <button onClick={() => isChangePassword()}> 비밀번호수정 </button>
                    </div>
                </div>
            </div>
        </>
    )
};

export default ResetPassword;
