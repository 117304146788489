import React, {useState, useEffect, useRef} from 'react';
import * as DataApi from "../api/Data";
import Indicator from "./Indicator";
import {getParameterByName} from "../util/Url";

const ResourcePreview = ({sampleId}) => {

    let [preview, setPreview] = useState(null);

    const refHideTable = useRef(null);
    const refShowTable = useRef(null);
    const previewRef = useRef(null);

    useEffect(() => {
        let params = {
            sample_id: sampleId
        };
        DataApi.previewData(params).then(res => {
            let data = res.result;
            setPreview(data);
            // SHEET 버튼 눌렀을 때 미리보기로 이동
            // if (getParameterByName("preview") === "true") {
            //     if (previewRef === null || document.getElementById('ds-resource-preview-id') === null) {
            //         return ;
            //     } else {
            //         const absoluteTop = window.pageYOffset + document.getElementById('ds-resource-preview-id').getBoundingClientRect().top;
            //         document.getElementById("root").scrollTo(0, absoluteTop)
            //     }
            // }
        })



    }, []);

    // console.log(refShowTable.current);

    const handleScroll = (e) => {
        refHideTable.current.scrollLeft = e.target.scrollLeft;
    };

    if(!preview) return <div><Indicator/></div>;

    else{
        let thead = <Row idx={0} row={preview[0]} key={JSON.stringify(preview[0]) + 0}/>;


        let tbody = (
            <tbody>
            {preview.map((row, idx) => {
                if(idx !== 0)
                    return <Row idx={idx} row={row} key={JSON.stringify(row) + idx}/>;
            })}
            </tbody>
        )
        return (
            <div id={"ds-resource-preview-id"} className={"ds-resource-preview-wrap"}
            ref={previewRef}>
                <div className={"ds-resource-preview"}>
                    <div className={"hideTable"} ref={refHideTable}>
                        <table>
                            {thead}
                            {tbody}
                        </table>
                    </div>
                    <div className={"showTable"} ref={refShowTable} onScroll={e => handleScroll(e)}>
                        <div>
                            <table>
                                {thead}
                                {tbody}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

};
export default ResourcePreview;


const mapValue=(i,idx)=>{
    if (isNaN(i)) {
        return <td key={idx+i}>{i}</td>
    } else {
        return <td align="right" key={i + idx}>{i}</td>
    }
}

const Row = ({row, idx}) => {
    if(idx === 0)
        return (
            <thead>
            <tr>
                <th key={"no" + idx}>No</th>
                {row.map(i => <th key={i}>{i}</th>)}
            </tr>
            </thead>
        )
    else
        return (
            <tr>
                <td key={"no" + idx}>{idx}</td>
                {row.map(i => mapValue(i,idx))}
            </tr>
        )
};
