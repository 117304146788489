import React from 'react';
import SubNav from "../../components/SubNav";


const LocationLanding = (props) => {

    let subNav = [
        {
            display: "분석 서비스 신청",
            name: "/analysisService"}
        // }, {
        //     display: "기업 신용 분석",
        //     onClick: () => {
        //         let service_link = "http://www.kreport.co.kr/index.do";
        //
        //         window.open(service_link);
        //     }
        // }
    ];


    return (
        <>
            <SubNav nav={subNav} history={props.history}>

            </SubNav>
            <iframe src="https://www.kreport.co.kr/" width={1920} height={1080}  margin={362}/>
        </>
    )
};

export default LocationLanding;
