import React, {useState, useEffect} from 'react';
import {getDistance} from "geolib";
import clusterMarkerPng from "../../assets/images/renewal/common/i-radio.png";
import {MarkerClustering} from "./MarkerClustering";

const ServiceNaverMap = ({pUserLocation, pList, pMapCenter, pSelectStore}) => {
    let [mapCenter, setMapCenter] = useState([pMapCenter[0], pMapCenter[1]]);
    let [userLocation, setUserLocation] = useState([pUserLocation[0],pUserLocation[1]]);
    let [list, setList] = useState(pList);
    let [serviceMap, setServiceMap] = useState(null);
    let [serviceMakers, setServiceMarkers] = useState([]);
    let [serviceInfoWindows, setServiceInfoWindows] = useState([]);
    let [selectStore, setSelectStore] = useState("");
    let centerLat = mapCenter[0];
    let centerLon = mapCenter[1];

    const {naver} = window;

    let map = serviceMap;
    let markers = serviceMakers;
    let infoWindows = serviceInfoWindows;

    const htmlMarker1 = {
        content: `<div 
                    style="cursor:pointer;
                           width:40px;
                           height:40px;
                           line-height:42px;
                           font-size:10px;
                           color:white;
                           text-align:center;
                           font-weight:bold;
                           background:url(${clusterMarkerPng});background-size:contain;"
                 >
                 </div>`,
        // size: N.Size(40, 40),
        // anchor: N.Point(20, 20)
    }

    //지도 중심으로부터 가맹점 거리 
    const mapCenterDistance = (sLat, sLon) => {
        let mapCenterdist = {latitude:centerLat, longitude:centerLon};
        let store = {latitude:sLat, longitude:sLon};
        return getDistance(mapCenterdist,store);
    }    

    // useEffect(() => {
    //     let newList = pList.filter((store, idx) => mapCenterDistance(store.latitude, store.longitude) > 100);
    //     setList(newList)
    // }, [mapCenter])

    // const distanceList = (map) => {
    //     const mapBound = map.getBounds();
    //     centerLat = map.getCenter().x;
    //     centerLon = map.getCenter().y;
    //     const newCenter = new naver.maps.LatLng(centerLat, centerLon);
    //     setMapCenter((mapCenter) => mapCenter = [centerLat,centerLon]);
    //     setList(pList.filter(store => mapCenterDistance(store.latitude, store.longitude) < 300));
    // }

    /** 지도  */
    useEffect(() => {
        /** 사용자 위치 */
        // navigator.geolocation.getCurrentPosition(
        //     (position) => {
        //         setUserLocation(position.coords.latitude,position.coords.longitude);
        //         setMapCenter(position.coords.latitude,position.coords.longitude);
        //     },
        //     (e) => {
        //     }
        // );

        markers = [];
        infoWindows = [];
        let mapOptions = {
            center: new naver.maps.LatLng(centerLat, centerLon),
            zoom:19,
            maxZoom: 20,
            minZoom: 13
        }
        map = new naver.maps.Map('map', mapOptions);
        setServiceMap(map);

        let bounds = map.getBounds(),
            southWest = bounds.getSW(),
            northEast = bounds.getNE(),
            lngSpan = northEast.lng() - southWest.lng(),
            latSpan = northEast.lat() - southWest.lat();

        //현재위치 
        let position = new naver.maps.LatLng(userLocation[0],userLocation[1]);
        let marker = new naver.maps.Marker({
            map: map,
            position: position,
            animation: 0,
            title: "현재 위치"
        });
        //const newList = list.filter(store => mapCenterDistance(store.latitude, store.longitude) < 500);
        list.forEach(store => {
            position = new naver.maps.LatLng(
            store.latitude, store.longitude
            );
            marker = new naver.maps.Marker({
            map: map,
            position: position,
            title: store.store_name,
            clickable: true,
            animation: 0,
            zIndex: 100
            });

            /** 마커클릭시 정보창*/
            let infoWindow = makeInfoWindow(store);

            markers.push(marker);
            infoWindows.push(infoWindow);

        })
        // markers.push(setMarkers(map, list, markers));
        markers.forEach((marker, idx) => {
            naver.maps.Event.addListener(marker,'click', () => getClickHandler(idx));
        })

        /** 지도에 보이는 부분만 마커 표시할 경우*/
        // naver.maps.Event.addListener(map, 'dragend' , () => updateMarkers(map, markers));
        // naver.maps.Event.addListener(map, 'dragend' , () => distanceList(map))
        // naver.maps.Event.addListener(map, 'zoom_changed' , () => updateMarkers(map, markers));
        // updateMarkers(map, markers);

        /** 마커클러스터링 */
        // let markerClustering = naver.maps.MarkerClustering({
	    //     minClusterSize: 2,
	    //     maxZoom: 17,
	    //     map: map,
	    //     markers: markers,
	    //     disableClickZoom: false,
	    //     gridSize: 120,
	    //     icons: [htmlMarker1],
	    //     indexGenerator: [10, 100, 200, 500, 1000],
	    //     stylingFunction: function(clusterMarker, count) {
	    //         clusterMarker.getElement().find('div:first-child').innerText = count;
	    //     }
        // })

        //naver.maps.Event.addListener(map, 'dragend' , () => moveMapCenter(map, markers));

        setServiceMarkers(markers);
        setServiceInfoWindows(infoWindows)

    }, [list]);

    /** 마커클릭시 정보창 표출*/
    const getClickHandler = (idx) => {
        let marker = markers[idx];
        let infoWindow = infoWindows[idx];
        if(infoWindow.getMap()){
            return infoWindow.close();
        }else{
            return infoWindow.open(map, marker);
        }
    }

    /** 지도에 보이는 부분만 마커 표시할 경우*/
    const updateMarkers = (map, markers) => {
        let mapBounds = map.getBounds();
        let position;
        markers.forEach((marker, idx) => {
            position = marker.getPosition();

            if(mapBounds.hasLatLng(position)){
                showMarker(map, marker);
            }else{
                hideMarker(map, marker);
            }
        })
    }

    const showMarker = (map, marker) => {
        if(marker.getMap()) return;
        marker.setMap(map);
    }

    const hideMarker = (map, marker) => {
        if(!marker.getMap()) return;
        marker.setMap(null);
    }

    /** infoWindow 만들기 */
    const makeInfoWindow = (store) => {
        return new naver.maps.InfoWindow({
            content: `<div style="width:200px;text-align:center;padding:10px;">
                        <div style="text-align:left">
                            <b>${store.store_name}</b>
                        </div>
                        <div style="text-align:left;line-height:80%;">
                            <span style="font-size:12px;">${store.category}</span><br/>
                            <span style="font-size:10px;text-align:left;">${store.road_address}</span><br/>
                            <span style="font-size:10px;text-align:left;">우편번호 ${store.postal_code}</span><br/>
                            <span style="font-size:10px;text-align:left;">전화번호 ${store.phone_number}</span><br/>
                            <span style="font-size:10px;text-align:left;">사업자번호 ${store.business_no}</span><br/>
                        </div>
                     </div>`
        });
    }

    //리스트 클릭 시 해당 가맹점으로 지도 이동
    useEffect(() => {
        if(pSelectStore !== null && pSelectStore !== undefined){
            if(pSelectStore.store_name !== selectStore){
                map.panTo(new naver.maps.LatLng(centerLat, centerLon), 100);
                list.find((store, idx) => {
                    if(store.latitude == centerLat && store.longitude == centerLon && store.store_name === pSelectStore.store_name){
                        getClickHandler(idx);
                        setSelectStore(pSelectStore.store_name);
                        centerLat = store.latitude;
                        centerLon = store.longitude;
                        setMapCenter([store.latitude, store.longitude]);
                    }
                })
                /** 지도에 보이는 부분만 마커 표시할 경우*/
                updateMarkers(map, markers);
                
            }
        }
    })

    return (
        <div
            id='map'
            style={{
                "width": '100%', // 네이버지도 가로 길이
                "height": '100%', // 네이버지도 세로 길이
                "border": "1px solid #00000033",
                "borderRadius": "10px"
            }}
        >    
        </div>
    );
}
export default ServiceNaverMap;