import React from 'react';
import infographicImg from '../assets/images/infographic.jpg'
import SubNavNew from "../components/SubNavNew";

const Infographic = (props) => {
    let subNav = [
    ];

    return(
        <>
            <div className="renewal">
            <SubNavNew nav={subNav} history={props.history} />
            </div>        
            <div style={{textAlign:"center"}}>
                <img src={infographicImg} className={"ds-terms-infographic-img"} alt="인포그래픽" />
            </div>
        </>
    )
};
export default Infographic;
