import {SET_EVENT_LINK} from "../actions/EventAction";

const InitialState={
    filter: "",
}
const EventReducer=(state=InitialState,action)=>{
    switch (action.type){
        case SET_EVENT_LINK:
            return Object.assign({},state,{
                filter:action.filter
            });
        default:
            return state;
    }
}
export default EventReducer