import React, {useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Swiper, { Pagination, Navigation, Autoplay } from 'swiper';

import swal from "sweetalert"

import * as DataApi from "../../api/Data";
import * as IboardApi from "../../api/Board";
import * as UserAPI from "../../api/User";

import slideImage1 from '../../assets/images/renewal/main/slide_01.png';
import slideImage2 from '../../assets/images/renewal/main/slide_02.png';
import slideImage3 from '../../assets/images/renewal/main/slide_03.png';

import innoServiceImage1 from '../../assets/images/renewal/main/innoServiceSlide/slide_image-01.png'
import innoServiceImage2 from '../../assets/images/renewal/main/innoServiceSlide/slide_image-02.png'
import innoServiceImage3 from '../../assets/images/renewal/main/innoServiceSlide/slide_image-03.png'
import categoryImage1 from '../../assets/images/renewal/main/datatab-01.png'
import categoryImage2 from '../../assets/images/renewal/main/datatab-02.png'
import categoryImage3 from '../../assets/images/renewal/main/datatab-03.png'
import recommendImage1 from '../../assets/images/renewal/main/datatab2-01.png'
import recommendImage2 from '../../assets/images/renewal/main/datatab2-02.png'
import recommendImage3 from '../../assets/images/renewal/main/datatab2-03.png'
import slidePopImage1 from '../../assets/images/renewal/slidepop/slidepop_220824_1.jpg'
import slidePopImage2 from '../../assets/images/renewal/slidepop/slidepop_220824_2.jpg'
import slidePopImage3 from '../../assets/images/renewal/slidepop/slidepop_220824_3.jpg'
import pauseImage from '../../assets/images/renewal/main/pause.png'
import playImage from '../../assets/images/renewal/main/play.png'
import { Link } from 'react-router-dom';
import AutocompleteSearch from '../../components/search/AutocompleteSearch';
// import SearchFormNew from '../../components/SearchFormNew';
import Home from "../Home"

function NewMain(props){
    const [playSlide, setPlaySlide] = useState(true);
    const [mainSwiper, setMainSwiper] = useState(null);
    const [innoSwiper, setInnoSwiper] = useState(null);
    const [dataActiveTab, setDataActiveTab] = useState(1);
    const [activeTab, setActiveTab] = useState(1);

    const recommendDataDummy = require('./RecommendData.json');
    const [recommendData, setRcommendData] = useState(recommendDataDummy);    
    const [popularKeyword, setPopularKeyword] = useState([]);
    const [popularDataset, setPopularDataset] = useState([]);
    const [noticeList, setNoticeList] = useState([]);
    const [useGalleryList, setUseGalleryList] = useState([]);
    const [faqList, setFaqList] = useState([]);

    const today = new Date();
    const userInfo = useSelector(state => state.user.info);
    const isLogin = useSelector(state => state.user.isLogin);
    const isTourOpen = useSelector(state => state.user.isTourOpen)

    const faq_board_id = "board_a2eb004d-73ca-4014-a9d2-c402675cf83f";
    const notice_board_id = "board_14ab0a7f-d410-48d7-91ca-e264d5121794";
    const description1 = "2019년 경기도 시군구 남녀별 월 단위 소비패턴 현황";
    const description2 = "2019년 경기도 시군구 연령별 월 단위 지역화폐 사용 빈도 현황";
    const description3 = "2019년 경기도 시군구 연령별 거주인구 대비 월 단위 지역화폐 사용금액 현황";

    let dispatch = useDispatch();      

    useEffect(() => {
        new Swiper('.visual-slider', {
            modules: [Pagination, Autoplay],
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
            },            
        }       
        ); 
    });
    // main
    useEffect(() => {
        const mainSwiper = new Swiper('.keyvisual .swiper-container', {
            modules: [Pagination, Navigation, Autoplay],
            loop:true,
            slidesPerView:1,
            autoplay: {
                delay: 2000,
                disableOnInteraction: false,
            },
            pagination:{
                el: '.swiper-pagination',
                type: 'custom',
                renderCustom: function (mainwiper, current, total) {
                    // var customPaginationHtml = "";
                    // for(var i = 0; i < total; i++) {
                    //     if(i === (current - 1)) {
                    //         customPaginationHtml += '<span className="visual-pagination-customs visual-pagination-customs-active"></span>';
                    //     }else{
                    //         customPaginationHtml += '<span className="visual-pagination-customs"></span>';
                    //     }
                    // }
                    var fraction =  current + '<span className="space">/</span><span className="num_total">'+total+'</span>';
                    return fraction;
                }
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
        setMainSwiper(mainSwiper);

    }, [setMainSwiper]);
    
    // innoService
    useEffect(() => {
        new Swiper('.innoService', {
            modules: [Pagination, Autoplay],
            pagination: {
                el: '.inno-swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
            },            
        }); 
    }, []);

    useEffect(() => {
        let ketwordParam = {
            time_unit: "d",
            howmany: 5
        };        
        DataApi.readSearchPopularKeyword(ketwordParam).then(res => {
            setPopularKeyword(res.result.popular_list);
        })
        let popularDatasetParam = {
            type: "view",
            time_unit: "d",
            howmany: 3
        };
        DataApi.readSearchPopularData(popularDatasetParam).then(res => {
            setPopularDataset(res.result.popular_countlist);
        });
        let notice_params = {
            board_id: notice_board_id,
            page_size: "5",
            page: 0,
            user_id: "",
            search_value: "",
            search_type: "subject"
        };
        IboardApi.readTextList(notice_params).then(res => {
            let textList = res.result.textlist;
            setNoticeList(textList);
        })
        let gallery_params = {
            'page_num': 0,
            'page_limit': 5,
            'value': "",
            'filter': "subject"
        };

        UserAPI.readUseGalleryList(gallery_params).then(res => {
            setUseGalleryList(res.result.list);
        })        
        let faq_params = {
            board_id: faq_board_id,
            page_size: "5",
            page: 0,
            user_id: "",
            search_value: "",
            search_type: "subject"
        };
        IboardApi.readTextList(faq_params).then(res => {
            let textList = res.result.textlist;
            setFaqList(textList);
        })                  
    }, [])   

    useEffect(() => {
        if (mainSwiper?.autoplay) {
            if (playSlide)
                mainSwiper.autoplay.start();
            else
                mainSwiper.autoplay.stop();
        }
    }, [playSlide, mainSwiper]);

    const dateFormat = (info) => {
        let date="";
        date=info.replaceAll("-",". ")
        date=date.replace("년",".")
        date=date.replace("월",".")
        date=date.replace("일","")
        date=date.substring(0,12)
        return date
    }
    const categoryImage = (idx) => {
        if(idx == 0){
            return categoryImage1;
        }else if(idx == 1){
            return categoryImage2;
        }else{
            return categoryImage3;
        }
    }
    // 카테고리 인기 데이터 설명 임시
    const categoryDescription = (idx) => {
        if(idx == 0){
            return description1;
        }else if(idx == 1){
            return description2;
        }else{
            return description3;
        }
    }

    const recommendImage = (idx) => {
        if(idx == 0){
            return recommendImage1;
        }else if(idx == 1){
            return recommendImage2;
        }else{
            return recommendImage3;
        }
    }

    const inno = (target) => {
        if (!isLogin) {
            swal("로그인 후 이용 가능한 서비스입니다.")
            window.location.href = "/#/login"
        } else {
            const user_id = userInfo.id;
            const user_token = userInfo.token;            

            const service_link = "http://inno.bigdata-region.kr:8080/inno/ssn.do?user_id=" + user_id + "&token=" + user_token + "&target=" + target;

            window.open(service_link);
        }
    }

    const handleSearch = (val) => {
        return props.history.push("/new-dataset?word=" + val);
    }

    return (
        <div className="renewal">
            {/* <NewHeader /> */}
            
            <section className="main">
                <Home > </Home>

                {/* 슬라이드 */}
                <article className="viSlide">
                    <div className="swiper mySwiper visual-slider">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div className="slideTxt">
                                    <p style={{"color":"#143061"}}>지역경제 데이터를 한곳에서 확인해 보세요!</p>
                                    <h3 style={{"color":"#333535"}}>지역경제 빅데이터 플랫폼</h3>
                                </div>
                                <img src={slideImage1} alt="슬라이드" />
                            </div>
                            <div className="swiper-slide">
                                <div className="slideTxt">
                                    <p style={{"color":"#143061"}}>지역에 희망을, 주민에게 행복을!</p>
                                    <h3 style={{"color":"#333535"}}>지역경제 빅데이터 플랫폼</h3>
                                </div>
                                <img src={slideImage2} alt="슬라이드" />
                            </div>
                            <div className="swiper-slide">
                                <div className="slideTxt">
                                    <p style={{"color":"#143061"}}>지역데이터를 활용한 혁신서비스를 확인해 보세요!</p>
                                    <h3 style={{"color":"#333535"}}>지역경제 빅데이터 플랫폼</h3>
                                </div>
                                <img src={slideImage3} alt="슬라이드" />
                            </div>
                        </div>
                        <div className="swiper-pagination"></div>
                    </div>
                </article>{/* //슬라이드 */}

                <div className="w1280">
                
                    {/* 검색바 */}
                    <article className="search">
                        <div className="keyword">
                            <ul>
                                {popularKeyword.length > 0 && <>
                                    {popularKeyword.map(keyword =>
                                        <li key={keyword.keyword}>
                                            <Link key={JSON.stringify(keyword)} to={"/new-dataset?word=" + keyword.keyword}>
                                                #{keyword.keyword}
                                            </Link>                                            
                                        </li>
                                    )}
                                </>
                                }                                                        
                            </ul>
                        </div>
                        <AutocompleteSearch search={handleSearch}/>
                        {/* <div className="search-area">
                            <input 
                                type="text" 
                                placeholder="데이터를 검색해보세요." 
                                onKeyDown={e => {
                                    if(e.keyCode === 13){
                                        const searchWord = isAutoSearch ? autoSearchKeyWord : word;
                                        props.history.push("/new-dataset?word=" + searchWord)
                                    }}}
                                value={isAutoSearch ? autoSearchKeyWord : word}
                                onChange={handleInputWord}
                                onKeyUp={handleKeyUp}
                                title={"데이터셋 검색"} 
                                autoComplete={"off"}
                            />
                            {searchResult && searchResult.length > 0 && (
                                <div className='autocompleteWrap'>
                                    <ul className="autocomplete" ref={listRef}>
                                        {searchResult.map((item, listIndex) => (
                                            <li 
                                                key={item.keyword} 
                                                ref={listIndex === focusIndex ? focusRef : undefined}
                                                className={listIndex === focusIndex ? "focused" : ""}
                                            >
                                                <Link key={JSON.stringify(item)} to={"/new-dataset?word=" + item.keyword}>
                                                    <div style={{display:"flex",justifyContent:"space-between"}}>
                                                        <p>
                                                            {item.keyword}
                                                        </p>
                                                    </div>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                        <Link to={"/new-dataset?word=" + (isAutoSearch ? autoSearchKeyWord : word)}>
                            <button>검색</button>
                        </Link> */}
                    </article>
                    {/* //검색바 */}

                    
                    {/* 퀵메뉴 */}
                    <article className="quick">
                        <ul>
                            <li><Link to="/new-dataset?category=지역현황"><span>아이콘</span></Link><p>지역현황</p></li>
                            <li><Link to="/new-dataset?category=지역트랜드"><span>아이콘</span></Link><p>지역트랜드</p></li>
                            <li><Link to="/new-dataset?category=지역화폐"><span>아이콘</span></Link><p>지역화폐</p></li>
                            <li><Link to="/new-dataset?category=기업"><span>아이콘</span></Link><p>기업</p></li>
                            <li><Link to="/new-dataset?category=기업신용보증"><span>아이콘</span></Link><p>기업신용보증</p></li>
                            <li><Link to="/new-dataset?category=소상공인"><span>아이콘</span></Link><p>소상공인</p></li>
                            <li><Link to="/new-dataset?category=취업"><span>아이콘</span></Link><p>취업</p></li>
                            <li><Link to="/new-dataset?category=영상"><span>아이콘</span></Link><p>영상</p></li>
                            <li><Link to="/new-dataset?category=친환경충전인프라"><span>아이콘</span></Link><p>친환경인프라</p></li>
                        </ul>
                    </article>{/* //퀵메뉴 */}
                        
                    <div className="conWrap">
                        {/* 데이터 탭 */}
                        <article className="dataWrap w70p">
                            <div className="dataContain">
                                <div className="tabWrap data">
                                    <ul>
                                        <li className={`tab-link ${dataActiveTab === 1 ? 'on' : ''}`} onClick={() => setDataActiveTab(1)}><p>카테고리별 인기데이터</p></li>
                                        <li className={`tab-link ${dataActiveTab === 2 ? 'on' : ''}`} onClick={() => setDataActiveTab(2)}><p>이달의 추천데이터</p></li>                       
                                    </ul>
                                    <span className="mo">좌·우 드래그 할 수 있습니다.</span>
                                </div>
                                <div className="tabInner">
                                    {/* 카테고리별 인기데이터 */}
                                    <div id="dataTab-1" className={`tab-content ${dataActiveTab === 1 ? 'on' : ''}`}>
                                        <ul>
                                            {popularDataset.filter((d, idx) => idx < 3).map((item, idx) =><li key={JSON.stringify(item)}>
                                                <Link to={"/dataset/"+item.data_id}>
                                                    <img src={categoryImage(idx)} alt={item.title + " 이미지"} />
                                                    <h4>{item.title}</h4>
                                                    {/* <p>{item.description}</p> */}
                                                    <p>{categoryDescription(idx)}</p>
                                                </Link>    
                                            </li>)}
                                        </ul>
                                    </div>
                                    {/* 이달의 추천데이터 */}
                                    <div id="dataTab-2" className={`tab-content ${dataActiveTab === 2 ? 'on' : ''}`}>
                                        <ul>
                                            {recommendData.filter((d, idx) => idx < 3).map((item, idx) => <li key={JSON.stringify(item)}>
                                                <Link to={"/dataset/"+item.package_id}>
                                                    {/* <DatasetCard key={idx}
                                                                title={row.title}
                                                                description={row.description}
                                                                // image={row.image}
                                                                image={row.image}
                                                    /> */}
                                                    <img src={recommendImage(idx)} alt={item.title + " 이미지"} />
                                                    <h4>{item.title}</h4>
                                                    <p>{item.description}</p>                                                    
                                                </Link>
                                            </li>)}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </article>{/* //데이터 탭 */}
                        
                        <article className="map w30p">
                            <div className="map-wrapper">
                                <p>혁신서비스</p>
                                <div className="mapInner swiper innoService">
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            <a href="http://loc.bigdata-region.kr/location" target="_blank">
                                                <img src={innoServiceImage1} alt="기업 입지 추천"/>
                                            </a>
                                        </div>
                                        <div className="swiper-slide">
                                            <Link to="" onClick={(e)=>{e.preventDefault(); inno(3)}}>
                                                <img src={innoServiceImage2} alt="소상공인 투자환경 분석 서비스" />
                                            </Link>
                                        </div>
                                        <div className="swiper-slide">
                                            <Link to="" onClick={(e)=> {e.preventDefault();swal("서비스 준비 중 입니다.");}}>
                                                <img src={innoServiceImage3} alt="지역화폐 가맹점" />
                                            </Link>    
                                        </div>                                                                                
                                    </div>
                                    <div className="inno-swiper-pagination"></div>
                                </div>
                            </div>
                        </article>
                        
                    </div>
                    
                    
                    <div className="conWrap">
                        {/* 공지사항 탭 */}
                        <div className="container notice w70p">
                            <div className="noticeWrap">
                                <ul className="tabs">
                                    <li className={`tab-link ${activeTab === 1 ? 'current' : ''}`} onClick={() => setActiveTab(1)}><p>공지사항</p></li>
                                    <li className={`tab-link ${activeTab === 2 ? 'current' : ''}`} onClick={() => setActiveTab(2)}><p>활용갤러리</p></li>
                                    <li className={`tab-link ${activeTab === 3 ? 'current' : ''}`} onClick={() => setActiveTab(3)}><p>이벤트</p></li>
                                    <li className={`tab-link ${activeTab === 4 ? 'current' : ''}`} onClick={() => setActiveTab(4)}><p>FAQ</p></li>
                                </ul>
                                <Link to="/board/all" title="더 보기" className="more">&nbsp;</Link>
                                {/* 공지사항 */}
                                <div id="tab-1" className={`tab-content ${activeTab === 1 ? 'current' : ''}`}>
                                    <ul>
                                        {
                                            noticeList.map((item) =>
                                                <li key={item.id}>
                                                    <Link to={"/board/" + notice_board_id + "/article/" + item.id}>
                                                        {/* {item.subject.length < 45 ? item.subject : item.subject.slice(0,45)+"..."} */}
                                                        {item.subject}
                                                    </Link>
                                                    <span>{dateFormat(item.date)}</span>
                                                </li>
                                            )
                                        }                                        
                                    </ul>
                                </div>
                                {/* 활용갤러리 */}
                                <div id="tab-2" className={`tab-content ${activeTab === 2 ? 'current' : ''}`}>
                                    <ul>
                                        {
                                            useGalleryList.map((item) =>
                                                <li key={item.id}><Link to={"/use/" + item.id}>{item.title}</Link><span>{dateFormat(item.date)}</span></li>
                                            )
                                        }
                                    </ul>
                                </div>
                                {/* 이벤트 */}
                                <div id="tab-3" className={`tab-content ${activeTab === 3 ? 'current' : ''}`}>
                                    <ul>
                                        <li><Link to="#void">진행 중인 이벤트가 없습니다</Link></li>
                                        {/* 
                                        {new Date('2023-11-22 10:00') < today && new Date('2023-11-27 23:59') > today &&
                                            <li>
                                                <Link to="/event/013">한국신용데이터 데이터 다운로드 이벤트</Link>
                                                <span>{dateFormat('2023-11-22')}</span>
                                            </li>
                                        }                             
                                        */}
                                    </ul>
                                </div>
                                {/* FAQ */}
                                <div id="tab-4" className={`tab-content ${activeTab === 4 ? 'current' : ''}`}>
                                    <ul>
                                        {
                                            faqList.map((item) =>
                                                <li key={item.id}><Link to={"/board/" + notice_board_id + "/article/" + item.id}>{item.subject}</Link><span>{dateFormat(item.date)}</span></li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>{/* //공지사항 탭 */}


                        {/* 슬라이드 팝업 */}
                        <article className="keyvisual spopupWrap w30p">
                            <div className="swiper-container spopup">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                        <div className="box_1 box">
                                            <Link to="#void">
                                                <img src={slidePopImage1} alt="취업 성공 전략 이미지" />
                                                <p>취업 성공 전략</p>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="box_2 box">
                                            <Link to="#void">
                                                <img src={slidePopImage2} alt="코로나19 백신접종 혜택 이미지" />
                                                <p>코로나19 백신접종 혜택</p>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="box_3 box">
                                            <Link to="#void">
                                                <img src={slidePopImage3} alt="미래 혁신 포럼 이미지" />
                                                <p>미래 혁신 포럼</p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                {/* Add Pagination */}
                                <div className="pagination">
                                    <div className="swiper-pagination popBullets"></div>
                                    <div className="popArrow">
                                        <div className="swiper-button-next"></div>
                                        <div className="swiper-button-prev"></div>
                                    </div>
                                    <button
                                        className="swiper-button-pause"
                                        onClick={() => setPlaySlide(!playSlide)}
                                    >
                                        {playSlide && <img src={pauseImage} alt="멈춤" />}
                                        {!playSlide && <img src={playImage} alt="재생" />}
                                    </button>
                                </div>
                            </div>
                        </article>
                        {/* //팝업존 */}
                    </div>

                </div>{/* //w1180 */}
            </section>{/* //메인 */}

            {/* <NewFooter /> */}

        </div>
    );
}

export default NewMain;
