import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import parser from 'ua-parser-js';
import { accessLog } from '../api/User.js';

let lastLocation;
const runAccessLog = process.env.REACT_ACCESS_LOG !== 'false';

/**
 * 방문자 기록 컴포넌트
 * @param {Object} args location, history 등을 가지고 있는 개체
 * @returns 
 */
const AccessLog = (args) => {
    const userInfo = useSelector(state => state.user.info);

    if (!runAccessLog) return null;

    if (lastLocation !== args.location.pathname) {
        lastLocation = args.location.pathname;
        const ua = parser(window.navigator.userAgent);

        const log = {
            sessionId: sessionStorage.getItem('_sessionId') || '',
            ckanId: userInfo?.id_key || '',
            username: userInfo?.id || '',
            device: ua.device?.type || 'desktop',
            os: ua.os?.name || '',
            browser: ua.browser?.name || '',
            path: lastLocation,
            state: userInfo?.state || '',
            isSite: 'site'
        }

        accessLog(log).then((result) => {
            if (result.status_code === 'SUCCESS')
                sessionStorage.setItem('_sessionId', result.result.sessionId);
        });
    }

    return '';
}

export default withRouter(AccessLog);
