import React from 'react';

const Num = ({num, page, onPageMove}) => {

    let cn = "btn btn-white";
    if(num === page) cn = cn + " imc-active";
    return (
        <button  className={cn} onClick={e => onPageMove(num)}>
            {num}
        </button>
    )
};

export default Num;
