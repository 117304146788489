import {delCookie, getCookie} from "./Cookie";
import swal from 'sweetalert';
import { accessLog } from '../api/User.js';

const get = (url, params = []) => {
    return new Promise(function (resolve, reject) {
        let paramArr = [];
        Object.keys(params).map(key => {
            paramArr.push(key + "=" + encodeURI(params[key]));
            return null;
        });

        let target = url + "?" + paramArr.join("&");
        let xhr = new XMLHttpRequest();

        xhr.open("GET", target, true);
        xhr.setRequestHeader("token", getCookie("token"));
        xhr.setRequestHeader("client", "portal");
        xhr.setRequestHeader("sessionId", sessionStorage.getItem('_sessionId')||'');

        xhr.onreadystatechange = () => {
            response(xhr, resolve, reject);
        };

        xhr.send(null);
    });
};

const getNew = (url, params = []) => {
    return new Promise(function (resolve, reject) {
        let paramArr = [];
        Object.keys(params).map(key => {
            paramArr.push(key + "=" + encodeURI(params[key]));
            return null;
        });

        let target = url + "?" + new Date().getTime() + "&" + paramArr.join("&");
        let xhr = new XMLHttpRequest();

        xhr.open("GET", target, true);
        xhr.setRequestHeader("token", getCookie("token"));
        xhr.setRequestHeader("client", "portal");
        xhr.setRequestHeader("user-ip", getCookie('user_ip'));
        xhr.setRequestHeader("sessionId", sessionStorage.getItem('_sessionId')||'');

        xhr.onreadystatechange = () => {
            responseNew(xhr, resolve, reject);
        };

        xhr.send(null);
    });
};

const post = (url, params = {}, header = {}) => {
    return new Promise(function (resolve, reject) {
        let target = url;
        let xhr = new XMLHttpRequest();

        xhr.open("POST", target, true);
        xhr.setRequestHeader("token", getCookie("token"));
        xhr.setRequestHeader("client", "portal");
        xhr.setRequestHeader("sessionId", sessionStorage.getItem('_sessionId')||'');

        Object.keys(header).map(key => {
            xhr.setRequestHeader(key, params[key]);
            return null;
        });

        xhr.onreadystatechange = () => {
            response(xhr, resolve, reject);
        };

        let formData = new FormData();
        Object.keys(params).map(key => {
            formData.append(key, params[key]);
            return null;
        });

        xhr.send(formData);
    });
};

const postNew = (url, params = {}, header = {}) => {
    return new Promise(function (resolve, reject) {
        let target = url + "?" + new Date().getTime();
        let xhr = new XMLHttpRequest();

        xhr.open("POST", target, true);
        xhr.setRequestHeader("token", getCookie("token"));
        xhr.setRequestHeader("client", "portal");
        xhr.setRequestHeader("user-ip", getCookie('user_ip'));
        xhr.setRequestHeader("sessionId", sessionStorage.getItem('_sessionId')||'');

        Object.keys(header).map(key => {
            xhr.setRequestHeader(key, params[key]);
            return null;
        });

        xhr.onreadystatechange = () => {
            responseNew(xhr, resolve, reject);
        };

        let formData = new FormData();
        Object.keys(params).map(key => {
            formData.append(key, params[key]);
            return null;
        });

        xhr.send(formData);
    });
};

const response = (xhr, resolve, reject) => {
    if (xhr.readyState === 4) {
        if(xhr.status === 200){
            let response = JSON.parse(xhr.response);
            if(response.status_code.toLowerCase() === 'fail') reject(xhr);
            else resolve(response);
        }else if(xhr.status === 401){
            swal({
                title: "알림",
                text: "장시간 움직임이 없어 로그아웃 되었습니다. 다시 로그인 해주세요.",
            }).then(() => {
                const params = {
                    sessionId: sessionStorage.getItem('_sessionId') || '',
                    path: '/login',
                    state: '세션끊김',
                    isSite: 'site'
                }
                accessLog(params);                
                reject(xhr)
                delCookie("token");
                window.location.href = "/#/login";
            });
        }else if(xhr.status === 402){
            swal({
                title: "알림",
                text: "이용 신청 후 가능한 서비스입니다.",
            });
            reject(xhr);
        }else if(xhr.status === 400){
            reject(xhr);            
        }else{
            swal({
                title: "서버 에러",
                text: "잠시 후 다시 시도해주세요.",
            });
            reject(xhr)
        }
    }
};

const responseNew = (xhr, resolve, reject) => {
    if (xhr.readyState === 4) {
        if(xhr.status === 200){
            let response = JSON.parse(xhr.response);
            if(response.status_code.toLowerCase() === 'fail') reject(xhr);
            else resolve(response);

        }else if(xhr.status === 402){
            swal({
                title: "알림",
                text: "이용 신청 후 가능한 서비스입니다.",
            });
            reject(xhr);
        }else if(xhr.status === 401){
            console.log(xhr)
            swal({
                title: "알림",
                text: "장시간 움직임이 없어 로그아웃 되었습니다. 다시 로그인 해주세요.",
            }).then(() => {
                const params = {
                    sessionId: sessionStorage.getItem('_sessionId') || '',
                    path: '/login',
                    state: '세션끊김',
                    isSite: 'site'
                }
                accessLog(params);                
                reject(xhr)
                delCookie("token");
                window.location.href = "/#/login";
            });
        }else if(xhr.status === 406){
            swal({
                title: "알림",
                text: "담당자 문의 후 이용해주시길 바랍니다.",
            });
            reject(xhr);
        }else if(xhr.status === 400){
            reject(xhr);
        }else if(xhr.status === 409){
            swal({
                title: "알림",
                text: "해당 상품에 가격정보가 변경되었습니다.\n 이용신청을 다시 해주시길 바랍니다.",
                // text: "가격정보가 변경되어 이용신청을 다시 해주시길 바랍니다.",
            });
            reject(xhr);
        }else if(xhr.status === 403){
            swal({
                title: "알림",
                text: "권한이 없습니다.",
            }).then(() => {
                const params = {
                    sessionId: sessionStorage.getItem('_sessionId') || '',
                    path: xhr.responseURL,
                    state: '권한 없는 접근',
                    isSite: 'site'
                }
                accessLog(params);                
                reject(xhr)
                window.location.href = "/";
            });
        }else{
            swal({
                title: "서버 에러",
                text: "잠시 후 다시 시도해주세요.",
            });
            reject(xhr)
        }
    }
};

const getOpenApi = (url, params = []) => {
    return new Promise(function (resolve, reject) {
        let paramArr = [];
        Object.keys(params).map(key => {
            paramArr.push(key + "=" + encodeURI(params[key]));
            return null;
        });

        let target = url + "?" + new Date().getTime() + "&" + paramArr.join("&");
        let xhr = new XMLHttpRequest();

        xhr.open("GET", target, true);
        xhr.setRequestHeader("token", getCookie("token"));
        xhr.setRequestHeader("client", "portal");
        xhr.setRequestHeader("user-ip", getCookie('user_ip'));

        xhr.onreadystatechange = () => {
            responseOpenApi(xhr, resolve, reject);
        };

        xhr.send(null);
    });
};

const responseOpenApi = (xhr, resolve, reject) => {
    if (xhr.readyState === 4) {
        if(xhr.status === 200){
            let response = JSON.parse(xhr.response);
            resolve(response);

        }else if(xhr.status === 401){
            swal({
                title: "알림",
                text: "OpenAPI 인증 키가 만료되었거나 입력된 키 값이 잘못 되었습니다. 인증 키를 확인 해주세요.",
            }).then(() => {
                return
            });
        }else if(xhr.status === 402){
            swal({
                title: "알림",
                text: "이용 신청 후 가능한 서비스입니다.",
            });
            reject(xhr);
        }else if(xhr.status === 406){
            swal({
                title: "알림",
                text: "담당자 문의 후 이용해주시길 바랍니다.",
            });
            reject(xhr);
        }
        else if(xhr.status === 409){
            swal({
                title: "알림",
                text: "해당 상품에 가격정보가 변경되었습니다.\n 이용신청을 다시 해주시길 바랍니다.",
                // text: "가격정보가 변경되어 이용신청을 다시 해주시길 바랍니다.",
            });
            reject(xhr);
        }else{
            swal({
                title: "서버 에러",
                text: "잠시 후 다시 시도해주세요.",
            });
            reject(xhr)
        }
    }
};

export {get, post, getNew, postNew, getOpenApi}
