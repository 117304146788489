export const index = `경기지역경제포털 이용약관

제1장 [총칙]
제1조 [목적]
 본 약관은 경기지역경제플랫폼이 운영하는 서비스 이용을 위해 가입하는 회원간의 계약관계에서 발생하는 관리와 의무, 그 밖에 필요한 기본적인 사항을 규정함을 목적으로 합니다.

제2조 [이용약관의 효력 및 변경]
 ① 본 이용약관은 서비스의 이용을 위하여 회원 또는 비회원이 동의를 함으로써 효력이 발생합니다.
 ② 경기지역경제포털에서는 합리적인 사유가 발생할 경우 본 약관을 변경할 수 있으며, 이 경우 일정한 기간을 정하여 적용일자 및 변경사유를 명시한 사항을 회원 또는 비회원에게 공지 또는 통지합니다.
 ③ 제2항에 따른 약관의 변경은 회원 또는 비회원이 동의함으로써 그 효력이 발생됩니다. 다만, 제2항에 따른 통지를 하면서 회원 또는 비회원에게 일정한 기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확히 전달하였음에도 회원 또는 비회원이 명시적으로 거부의 의사표시를 하지 아니한 경우에는 회원 또는 비회원이 개정약관에 동의한 것으로 봅니다.
 ④ 회원 또는 비회원은 경기지역경제포털 서비스를 이용할 시 주기적으로 공지사항을 확인하여야 할 의무가 있습니다.
 ⑤ 약관의 변경 사실 및 내역을 확인하지 못하여 발생한 모든 손해에 대한 책임은 회원 또는 비회원에게 귀속됩니다.

제3조 [용어의 정의]
 ① 이 약관에서 사용하는 용어의 정의는 다음 각 호와 같습니다.
  가. ‘서비스’란, 회원이 이용 가능한 경기지역경제포털이 제공하는 제반의 혜택을 말합니다.
  나. ‘회원’이란, 경기지역경제포털에 회원등록을 말합니다.
  다. ‘제공기관’이란, 경기지역경제포털 서비스를 위해 보유한 데이터를 제공하는 센터를 말합니다.
  라. ‘공지사항’이란, 경기지역경제포털이 서비스 이용자에게 전산망을 통하여 고지하는 내용을 게시하는 서비스 상의 영역을 말합니다. 
  
 ② 이 약관에서 명시되지 않은 사항에 대해서는 공공데이터의 제공 및 이용 활성화에 관한 법률 등 관계법령 및 공공데이터의 제공 및 이용 활성화에 관한 법률 시행령, 공공데이터의 제공 및 이용 활성화에 관한 법률 시행규칙에 따르며, 그 외에는 일반 관례에 따릅니다.

제4조 [적용 범위]
 ① 본 약관은 경기지역경제포털 서비스 회원에 가입한 후, 본 약관에 동의한 자(이하 ‘회원’이라 합니다)에 대하여 적용합니다. 회원으로 가입하시면 경기지역경제포털 서비스를 제한 없이 자유롭게 활용하실 수 있습니다.
 ② 본 약관은 회원 또는 비회원에 대한 경기지역경제포털 서비스 제공행위 및 회원 또는 비회원의 경기지역경제포털 서비스 이용행위에 대하여 우선적으로 적용됩니다.

제2장 [서비스 이용]
제5조 [경기지역경제포털 서비스의 이용]
 경기지역경제포털의 모든 서비스는 본 약관에 동의한 회원 또는 비회원에 한하여 제공합니다.

제6조 [경기지역경제포털 서비스 이용 시 주의사항]
 ① 경기지역경제포털 플랫폼은 관계법령의 제·개정 및 기타 정책적 사유 등에 따라 경기지역경제포털 서비스를 변경하거나 중단할 수 있습니다.
 ② 경기지역경제포털 플랫폼은 경기지역경제포털 서비스를 운영함에 있어 데이터의 특정 범위를 분할하거나 또는 전체에 대하여 별도의 이용가능 시간 또는 이용가능 횟수를 지정할 수 있으며 이를 사전에 고지하여야 합니다.
 ③ 회원 또는 비회원은 경기지역경제포털 서비스를 이용한 검색결과를 노출함에 있어 선정적, 폭력적, 혐오적인 내용을 포함하여 반사회적, 비도덕적, 불법적인 내용과 결합 또는 연계하거나 인접하도록 구성할 수 없으며, 검색결과의 공공성을 준수하여야 합니다.
 ④ 경기지역경제포털은 개인정보 보호정책을 공시하고 준수합니다.

제7조 [회원 또는 비회원의 의무]
 ① 회원 또는 비회원은 경기지역경제포털 서비스를 이용함에 있어서 본 약관에서 규정하는 사항과 기타 경기지역경제포털이 정한 제반 규정, 공지사항 및 관계법령을 준수하여야 합니다.
 ② 경기지역경제포털 서비스를 이용함에 있어서 회원 또는 비회원의 행위에 대한 모든 책임은 당사자가 부담하며, 회원은 경기지역경제포털을 대리하는 것으로 오해가 될 수 있는 행위를 해서는 안됩니다.

제3장 [손해배상 및 책임의 제한]
제8조 [손해배상]
 ① 경기지역경제포털은 회원 또는 비회원이 본 이용약관을 준수하지 않는 경우 서비스 사용 중지 및 이용자격을 박탈할 수 있습니다.
 ② 경기지역경제포털 서비스 이용상 회원 또는 비회원의 귀책사유로 인하여 경기지역경제포털에 손해가 발생한 경우 경기지역경제포털은 본 약관에 따른 계약의 해지와는 별도로 손해배상을 청구할 수 있습니다.
 ③ 경기지역경제포털 서비스의 이용으로 경기지역경제포털과 회원 또는 비회원간에 발생한 분쟁에 관하여 소송이 제기되는 경우 각 당사자는 자신의 주소지를 관할하는 법원에 소송을 제기할 수 있습니다.

제9조 [서비스 저작권]
 ① 회원 또는 비회원은 경기지역경제포털 서비스 이용 시 경기지역경제포털 및 제 3자의 지식재산권을 침해해서는 안됩니다.
 ② 회원 또는 비회원은 경기지역경제포털 서비스를 이용하여 검색결과를 노출할 경우, 해당 페이지에 ‘경기지역경제포털 데이터’를 사용한 결과임을 명시해야 합니다. 다만, 경기지역경제포털에서 별도의 표시방식을 정한 경우에는 그에 따라야 합니다.

제10조 [책임의 제한]
 ① 경기지역경제포털에서 제공하는 서비스 및 데이터에 대한 책임은 데이터를 보유한 제공기관에게 귀속됩니다. 경기지역경제포털 플랫폼에서는 경기지역경제포털 서비스에 관하여 약관, 서비스별 안내, 기타 플랫폼이 정한 이용기준 및 관계법령을 준수하지 않은 이용으로 인한 결과에 대하여 책임을 지지 않습니다.
 ② 경기지역경제포털 플랫폼은 경기지역경제포털 서비스의 사용불능으로 인하여 회원 또는 비회원에게 발생한 손해에 대하여 책임을 지지 않습니다.
 ③ 경기지역경제포털 플랫폼은 회원 또는 비회원이 경기지역경제포털 서비스를 이용하여 기대하는 수익을 얻지 못하거나 상실한 것에 대하여 책임을 지지 않습니다.
 ④ 경기지역경제포털 플랫폼은 회원·비회원·제 3자 상호 간에 경기지역경제포털 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며, 이로 인한 손해를 배상할 책임도 없습니다.
 ⑤ 천재지변, 사변, 기타 이에 준하는 사태로 인하여 ‘서비스’제공이 중지된 경우에 경기지역경제포털은 책임을 지지 않습니다.

부칙
 (시행일) 이 약관은 2020년 1월  21일부터 시행합니다.
  `;
