import React, {useState, useEffect} from 'react';
import {yyyymmddhhiiss} from "../../util/Date";
import * as IboardApi from "../../api/Board";
import 'froala-editor/css/froala_style.min.css';
import Indicator from "../../components/Indicator";

const FaqItem = ({data, page, limit, i}) => {

    let [isOpen, setIsOpen] = useState(false);
    let [contents, setContents] = useState(null);
    let [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(isOpen && !contents){
            setIsLoading(true);
            let params = {
                text_id: data.id
            };
            IboardApi.readText(params).then(res => {
                let rst = res.result;
                setContents(rst.text);
            }).catch(() => {setIsLoading(false)});
            setIsLoading(false)
        }
    }, [isOpen]);

    data.date = data.date.replace(" ", "T");
    
    return (
        <li>
            <dl className={"notice-list"}>
                <dt onClick={() => setIsOpen(!isOpen)}>
                    <div className={"notice-post"}><span className={"notice-no"}>{(page - 1) * limit + i + 1}</span> {data.subject}</div>
                    <div className={"date"}>
                        {yyyymmddhhiiss(new Date(data.date))}
                    </div>
                </dt>
            </dl>
            {isOpen && isLoading && <div>
            <Indicator/>
            </div>}
            {isOpen && contents && <div className={"faq-text-box"}>
                <div className={"fr-view"} dangerouslySetInnerHTML={{__html: contents}}/>
            </div>}
        </li>
    )
};

export default FaqItem;
