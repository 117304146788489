import React from 'react';
import Head from './Head';
import Body from './Body';

const Table = ({showLoading, list, loading, head, onRowClick, onHeadClick, onSort}) => {

    return (
        <table className={"datatable-table"}>
            {head.length > 0 && <Head items={head} onHeadClick={onHeadClick} onSort={onSort}/>}
            <Body
                showLoading={showLoading}
                items={list}
                loading={loading}
                head={head}
                onRowClick={onRowClick}
            />
        </table>
    )
};

Table.defaultProps = {
    list: [],
    head: [],
    loading: 10
};

export default Table;
