import React, {useState, useEffect} from "react";
import Froala from "../../components/Froala";
import {useDispatch, useSelector} from "react-redux";
import {delCookie, getCookie} from "../../util/Cookie";
import SubNavNew from "../../components/SubNavNew";
import swal from "sweetalert";
import SelectSearch from 'react-select-search';
import ImageUploader from 'react-images-upload';
import * as DataApi from "../../api/Data";
import * as UserAPI from "../../api/User";
import ErrorPage404 from "../common/ErrorPage404";

const UseWrite = ({match, ...props}) => {

    let [ServiceType, setServiceType] = useState("");
    let [ServiceDate, setServiceDate] = useState("");
    let [content, setContent] = useState("");
    let [serviceTitle, setServiceTitle] = useState("");
    let [serviceCreator, setServiceCreator] = useState("");
    let [serviceLink, setServiceLink] = useState("");
    let [serviceThumbnail, setServiceThumbnail] = useState("");
    let [serviceDataset, setServiceDataset] = useState("");
    let [packageList, setPackageList] = useState([]);

    let [loading, setLoading] = useState(true);
    let [initUpdateFlag, setInitUpdateFlag] = useState(true);
    let [pictures, setPictures] = useState("");

    const isLogin = useSelector(state => state.user.isLogin);
    const userInfo = useSelector(state => state.user.info);

    const ServiceTypeOptions = [
        {
            name: "모바일앱",
            value: "app"
        },
        {
            name: "웹 서비스",
            value: "web"
        },
        {
            name: "아이디어",
            value: "idea"
        }
    ];

    const ServiceDateOptions = [
        {
            name: "일주일 이상",
            value: "week"
        },
        {
            name: "한달 이상",
            value: "month"
        },
        {
            name: "일년 이상",
            value: "year"
        }
    ];

    if (!isLogin) {
        swal({
            text: "로그인 후 이용 가능한 서비스입니다.",
            buttons: [
                "확인",
                "로그인"
            ]
        }).then(function (isConfirm) {
            if(isConfirm) {
                delCookie("token");
                window.location.href = "/#/login";
            } else {
                swal.close();
            }
        })
    }

    let subNav = [
        {
            display: "활용 갤러리",
            name: "/use"
        }
        // {
        //     name: "기업입지 추천 서비스",
        //     onClick: () => {
        //         let service_link = "http://loc.bigdata-region.kr/location";
        //
        //         window.open(service_link);
        //     }
        // }
    ];
    
    useEffect(() => {
        let params = {
            'user_id': userInfo.id_key
        };

        DataApi.readTitleAndPackageIdByUserId(params).then(res => {
            // titleList = res.result
            setPackageList(res.result.title_list);
        });
    },[]);

    if(initUpdateFlag) {
        let modifyUseWriteId = match.params.useWriteId;
        if (modifyUseWriteId !== undefined) {
            let params = {
                id: modifyUseWriteId
            };
            UserAPI.readUseGalleryDetail(params).then(res => {
                setServiceType(res.result.type);
                setServiceDate(res.result.dev_date);
                setServiceTitle(res.result.title);
                setServiceCreator(res.result.creator);
                setServiceLink(res.result.link);
                setServiceThumbnail(res.result.thumbnail);
                setContent(res.result.content);
                setServiceDataset(res.result.dataset_id);
            }).catch(res => {
                return (
                    <ErrorPage404/>
                )
            })
        }
    }
    const checkBlank = () => {
        let flag = true;
        if (serviceCreator === "") {
            swal("개발자(회사)를 입력해주세요.")
            flag = false;
        } else if (ServiceType === "") {
            swal("개발 유형을 선택해주세요.")
            flag = false;
        } else if (ServiceDate === "") {
            swal("개발 기간을 선택해주세요.")
            flag = false;
        } else if (content === "") {
            swal("서비스 소개를 입력해주세요.")
            flag = false;
        } else if (serviceTitle === "") {
            swal("서비스 명칭을 입력해주세요.")
            flag = false;
        }  else if (serviceLink === "") {
            swal("서비스 링크를 입력해주세요.")
            flag = false;
        } else if (pictures === "" || pictures === undefined) {
            swal("썸네일 이미지를 업로드해주세요.")
            flag = false;
        } else if (serviceDataset === "") {
            swal("사용한 데이터셋을 선택해주세요.")
            flag = false;
        }
        return flag;
    };

    const checkUrlForm = (strUrl) => {
        if (strUrl !== null) {
            var expUrl = /^http[s]?\:\/\//i;
            return expUrl.test(strUrl);
        } else {
            return true;
        }

    }


    const onWrite = () => {
        if (ServiceType === "idea") {
            setServiceDataset(null);
            setServiceDate(null);
            setServiceLink(null);
        }
        if (checkBlank()) {
            let img_params = {
                "upload_image": pictures
            };
            DataApi.createImageUpload(img_params)
                .then( res => {
                    let params = {
                        'user_id': userInfo.id,
                        'service_type': ServiceType,
                        'service_date': ServiceDate,
                        'service_title': serviceTitle,
                        'service_creator': serviceCreator,
                        'service_link': serviceLink,
                        'service_thumbnail': res.result,
                        'service_content': content,
                        'service_dataset': serviceDataset
                    };
                    if (!checkUrlForm(serviceLink)) {
                        swal("URL 앞에 http 또는 https를 붙여주세요.")
                        return;
                    }
                    let modifyUseWriteId = match.params.useWriteId;

                    if (modifyUseWriteId !== undefined) {
                        params.id = modifyUseWriteId;
                        UserAPI.updateUseGallery(params)
                            .then( res => {
                                swal('수정이 완료되었습니다.');
                                window.location.href = "/#/use";
                            })
                            .catch( err => {
                                console.log(err)
                            });
                        //    update
                    } else {
                        UserAPI.createUseGallery(params)
                            .then( res => {
                                swal('등록하신 게시물은 심사 후 2~3일 이내 등록 완료 됩니다.\n 등록해주셔서 감사합니다.');
                                window.location.href = "/#/use";
                            })
                            .catch( err => {
                                console.log(err)
                            });
                    }

                })
                .catch(err => {
                    console.log(err)
                });
        }
    };

    const onDrop = (pictureFiles, pictureBase64) => {
        setPictures(pictureFiles[0]);
    };

    const setCleanInput = (option) => {
        if (option === "idea") {
            setServiceDataset(null);
            setServiceDate(null);
            setServiceLink(null);
        }
        setServiceType(option);
    };

    return (
        <>
            <div className="renewal">
                <SubNavNew nav={subNav} history={props.history}/>
            </div>
            <div className={"wrap-dataset"}>
            {
                loading &&
                <div className={"wrap-use-write"}>
                    <div className={"use-write-title"}>활용 서비스 등록</div>
                    <div style={{"width": "51%", "display": "inline-block"}}>
                        <span> 서비스 명칭 </span>
                        <input type="text" value={serviceTitle} onChange={e => setServiceTitle(e.target.value)}
                               placeholder="명칭을 입력해주세요."/>
                    </div>
                    <div style={{"display": "inline-block", "margin-left": "25px"}}>
                        <span> 사용한 데이터셋 </span>
                        {
                            ServiceType === "idea" ?
                                <SelectSearch
                                    options={packageList}
                                    search
                                    onChange={option => setServiceDataset(option)}
                                    value={serviceDataset}
                                    placeholder={"데이터셋을 선택하세요."}
                                    className={"use-write-dataset-select-box"}
                                    disabled
                                />
                                :
                                <SelectSearch
                                    options={packageList}
                                    search
                                    onChange={option => setServiceDataset(option)}
                                    value={serviceDataset}
                                    placeholder={"데이터셋을 선택하세요."}
                                    className={"use-write-dataset-select-box"}
                                />
                        }

                    </div>
                    <div style={{"margin-top": "20px"}}>
                        <div style={{"display": "inline-block"}}>
                            <span> 개발유형 </span>
                            <SelectSearch
                                options={ServiceTypeOptions}
                                onChange={option => setCleanInput(option)}
                                value={ServiceType}
                                placeholder={"개발유형을 선택하세요."}
                            />
                        </div>

                        <div style={{"display": "inline-block", "margin-left": "25px"}}>
                            <span> 개발기간 </span>
                            {
                                ServiceType === "idea" ?
                                    <SelectSearch
                                        options={ServiceDateOptions}
                                        onChange={option => setServiceDate(option)}
                                        value={ServiceDate}
                                        placeholder={"개발기간을 선택하세요."}
                                        disabled
                                    />
                                    :
                                    <SelectSearch
                                        options={ServiceDateOptions}
                                        onChange={option => setServiceDate(option)}
                                        value={ServiceDate}
                                        placeholder={"개발기간을 선택하세요."}
                                    />
                            }

                        </div>

                        <div style={{"display": "inline-block", "margin-left": "25px"}}>
                            <span> 개발자(회사) </span>
                            <input type="text" value={serviceCreator} onChange={e => setServiceCreator(e.target.value)}
                                   placeholder="명칭을 입력해주세요."/>
                        </div>

                        <div style={{"display": "inline-block", "margin-left": "24px"}}>
                            <span> 서비스링크 </span>
                            {
                                ServiceType === "idea" ?
                                    <input type="text" value={serviceLink} onChange={e => setServiceLink(e.target.value)} disabled={true}
                                           placeholder="URL을 입력해주세요."/>
                                    :
                                    <input type="text" value={serviceLink} onChange={e => setServiceLink(e.target.value)}
                                           placeholder="URL을 입력해주세요."/>
                            }

                        </div>
                    </div>
                    <div style={{"margin-top": "20px"}}>
                        <span> 썸네일 </span>
                        <ImageUploader
                            {...props}
                            withIcon={false}
                            onChange={onDrop}
                            imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                            maxFileSize={5242880}
                            buttonText={"이미지를 선택하세요."}
                            label={"썸네일로 사용할 이미지를 업로드해주세요. (용량은 최대 5mb)"}
                            withPreview={true}
                            singleImage={true}
                        />
                    </div>
                    <div className="wtiter">
                        <span> 서비스 소개 </span>
                        <Froala onChange={content => setContent(content)}
                                content={content}
                        />
                    </div>
                    <div className={"btn-group"} style={{"margin-top": "30px"}}>
                        <div className={"ikan-button-wrap ikan-button-full"}>
                            <button onClick={() => onWrite()}>등록하기</button>
                        </div>
                    </div>
                </div>
            }
            </div>
        </>

    )
};

export default UseWrite;
