import React, { useEffect } from 'react';
import Swiper, { Autoplay } from 'swiper';
import {terms} from "../../util/Terms";

import logo1 from '../../assets/images/renewal/common/b_footer_1.png'
import logo2 from '../../assets/images/renewal/common/b_footer_2.png'
import logo3 from '../../assets/images/renewal/common/b_footer_3.png'
import logo4 from '../../assets/images/renewal/common/b_footer_4.png'
import logo5 from '../../assets/images/renewal/common/b_footer_5.png'
import logo6 from '../../assets/images/renewal/common/b_footer_6.png'
import logo7 from '../../assets/images/renewal/common/b_footer_7.png'
import logo8 from '../../assets/images/renewal/common/b_footer_8.png'
import logo9 from '../../assets/images/renewal/common/b_footer_9.png'
import logo10 from '../../assets/images/renewal/common/b_footer_10.png'

import logo from '../../assets/images/renewal/common/f-logo.png'
import msit from '../../assets/images/renewal/common/msit.jpg'
import nia from '../../assets/images/renewal/common/nia.jpg'


//const isMobile = navigator.userAgent.indexOf('Android') >= 0 || navigator.userAgent.indexOf('iOS') >= 0 || window.innerWidth == 500;

export default function() {

    useEffect(() => {
        new Swiper('.organizations-swiper', {
            modules: [Autoplay],
            loop:true,
            loopAdditionalSlides: 1,
            slidesPerView: 2,
            spaceBetween: 0,
            autoplay: {
                delay: 1700,
                disableOnInteraction: false,
            },
            breakpoints: {
                650: {
                    slidesPerView: 3
                },                              
                1050: {
                    slidesPerView: 7
                }                
            },
        });
    }, []);

    return (<>
        <div className="footerBanner">
            <div className="footerBannerWrap w1280">
                <h2>제공기관</h2>
                <div className="swiper organizations-swiper">
                    <div className="swiper-wrapper">
                        <div className="swiper-slide"><a href="http://www.kodata.co.kr/ci/CIINT01R0.do" className="item" target="_blank" rel="noopener noreferrer"><img src={logo1} alt="한국평가데이터"/></a></div>
                        <div className="swiper-slide"><a href="https://theimc.co.kr/web/main/main.php#" className="item" target="_blank" rel="noopener noreferrer"><img src={logo2} alt="(주)더아이엠씨"/></a></div>
                        <div className="swiper-slide"><a href="https://www.konai.com/" className="item" target="_blank" rel="noopener noreferrer"><img src={logo3} alt="코나아이"/></a></div>
                        <div className="swiper-slide"><a href="https://www.hanyang.ac.kr/" className="item" target="_blank" rel="noopener noreferrer"><img src={logo4} alt="한양대학교"/></a></div>
                        <div className="swiper-slide"><a href="https://www.gjf.or.kr/web/gjf/main" className="item" target="_blank" rel="noopener noreferrer"><img src={logo5} alt="경기도일자리재단"/></a></div>
                        <div className="swiper-slide"><a href="https://www.krihs.re.kr/" className="item" target="_blank" rel="noopener noreferrer"><img src={logo6} alt="국토연구원"/></a></div>
                        <div className="swiper-slide"><a href="https://kcd.co.kr/" className="item" target="_blank" rel="noopener noreferrer"><img src={logo7} alt="한국신용데이터"/></a></div>
                        <div className="swiper-slide"><a href="https://www.gcgf.or.kr/gcgf/index.do" className="item" target="_blank" rel="noopener noreferrer"><img src={logo8} alt="경기신용보증재단"/></a></div>
                        <div className="swiper-slide"><a href="https://www.epumpkin.co.kr/" className="item" target="_blank" rel="noopener noreferrer"><img src={logo9} alt="펌프킨"/></a></div>
                        <div className="swiper-slide"><a href="https://www.kitech.re.kr/main/" className="item" target="_blank" rel="noopener noreferrer"><img src={logo10} alt="한국생산기술연구원"/></a></div>
                    </div>
                </div>
            </div>
        </div>

        <footer>
            <div className="footer-wrap w1280">
                <div className="footer-info">
                    <address>
                        <img src={logo} alt="변화의 중심 기회의 경기"/>
                        <div>
                            <p>
                                <span>경기도 수원시 영통구 도청로 30</span>&nbsp; | &nbsp;고객센터:02-780-9841
                            </p>
                            <p className="copy">&copy; Gyeonggi Province. All Rights Reserved.</p>
                            <p>
                                <a href="#void" onClick={(e)=>{e.preventDefault(); terms("termsAndConditions")}}>이용약관</a>&nbsp; | &nbsp;
                                <a href="#void" onClick={(e)=>{e.preventDefault(); terms("privacyPolicy");}}><strong style={{color:"#379ac4",fontSize:"1.2em"}}>개인정보처리방침</strong></a>
                            </p>
                        </div>
                        <div className="footer-img">
                            <a href="https://www.msit.go.kr/index.do" target="_blank" title="새창열림" rel="noopener noreferrer">
                                <img src={msit} alt="과학기술정보통신부" style={{width:"230px"}}/>
                            </a>
                            <a href="https://www.nia.or.kr/site/nia_kor/main.do" target="_blank" title="새창열림" rel="noopener noreferrer">
                                <img src={nia} alt="한국지능정보사회진흥원" style={{width:"230px"}}/>
                            </a>
                        </div>
                    </address>
                     
                    <div className="footer-banner-site">
                        <a href="#void" onClick={(e)=>{e.preventDefault();}} title="패밀리사이트 바로가기">패밀리사이트</a>
                        <ul className="footer-site-list">
                            <li className="title">주무부처</li>
                            <li><a href="https://www.gg.go.kr/" target="_blank" title="새창열림" rel="noopener noreferrer">경기도</a></li>
                            <li><a href="https://www.msit.go.kr/index.do" target="_blank" title="새창열림" rel="noopener noreferrer">과학기술정보통신부</a></li>
                            
                            <li className="title">유관기관</li>
                            <li><a href="https://www.nia.or.kr/site/nia_kor/main.do" target="_blank" title="새창열림" rel="noopener noreferrer">한국지능정보사회진흥원</a></li>
                            
                            <li className="title">주관기관</li>
                            <li><a href="https://www.gbsa.or.kr/index.do" target="_blank" title="새창열림" rel="noopener noreferrer">경기도경제과학진흥원</a></li>
                            
                            <li className="title">데이터제공기관</li>
                            <li><a href="http://www.kodata.co.kr/ci/CIINT01R0.do" target="_blank" title="새창열림" rel="noopener noreferrer">한국평가데이터</a></li>
                            <li><a href="https://theimc.co.kr/web/main/main.php" target="_blank" title="새창열림" rel="noopener noreferrer">더아이엠씨</a></li>
                            <li><a href="https://konai.com/" target="_blank" title="새창열림" rel="noopener noreferrer">코나아이</a></li>
                            <li><a href="https://www.hanyang.ac.kr/" target="_blank" title="새창열림" rel="noopener noreferrer">한양대학교</a></li>
                            <li><a href="https://www.gjf.or.kr/web/gjf/main" target="_blank" title="새창열림" rel="noopener noreferrer">경기도일자리재단</a></li>
                            <li><a href="https://www.krihs.re.kr/" target="_blank" title="새창열림" rel="noopener noreferrer">국토연구원</a></li>
                            <li><a href="https://kcd.co.kr/" target="_blank" title="새창열림" rel="noopener noreferrer">한국신용데이터</a></li>
                            <li><a href="https://www.gcgf.or.kr/gcgf/index.do" target="_blank" title="새창열림" rel="noopener noreferrer">경기신용보증재단</a></li>
                            <li><a href="https://www.epumpkin.co.kr/" target="_blank" title="새창열림" rel="noopener noreferrer">펌프킨</a></li>
                            <li><a href="https://www.kitech.re.kr/main/" target="_blank" title="새창열림" rel="noopener noreferrer">한국생산기술연구원</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    </>);
}
