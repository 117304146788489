import React from 'react';
import { withRouter } from 'react-router-dom';

class GoogleAnalytics extends React.Component {
    UNSAFE_componentWillUpdate({ location, history }) {
        if (location.pathname === this.props.location.pathname) {
            // don't log identical link clicks (nav links likely)
            return;
        }
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        // s.innerHTML = "window.dataLayer = window.dataLayer || [];\n" +
        //     "function gtag(){dataLayer.push(arguments);}\n" +
        //     "gtag('js', new Date());\n" +
        //     "gtag('config', 'G-5PZ7J8DDD3', {'page_location': location.href});";
        s.innerHTML = "window.dataLayer = window.dataLayer || [];\n" +
            "function gtag(){dataLayer.push(arguments);}\n" +
            "gtag('js', new Date());\n" +
            "gtag('config', 'G-BBQ473D3GR', {'page_location': location.href});";        
        document.body.appendChild(s);
        document.body.removeChild(s);
        

    }

    render () {
        return null;
    }
}

export default withRouter(GoogleAnalytics);
