import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import SelectBox from "../SelectBox";

const SubNav = ({children, nav, ...props}) => {

    let [option] = useState(nav);

    useEffect(() => {

    }, []);

    return (
        <>
            <ul className={"sub-nav-bar"}>
                <li className={"icon-home"}><Link to="/new-main">홈</Link></li>
                {option.map((i, idx) =>
                    // <li className={(i.name === props.history.location.pathname && "active")}>
                    //     <Link to={i.name} style={{"text-align": "center"}}>
                    //         {i.display}
                    //     </Link>
                    // </li>)}
                    <li className={(i.name === props.history.location.pathname && "active" || "")} key={idx}>
                    {i.display === "데이터셋" ? 
                        <Link to={"/new-dataset"} style={{"textAlign": "center"}}>
                            {i.display}
                        </Link>
                        :
                        <Link to={i.name} style={{"textAlign": "center"}}>
                            {i.display}
                        </Link>                                
                    }
                </li>)}                                            
            </ul>
            <div className={"wrap-dataset"}>
                {children}
            </div>

        </>
    )
};

export default SubNav;
