import React, { Component, Fragment } from 'react';
import {getParamsFromQueryString} from "../util/Url";
import {timestampJavaScriptToPython} from "../util/Date";
import SelectSearch from 'react-select-search';
import OptData from "../pages/common/OptionData.json";
import downArrow from "../assets/images/ic_down_fillarrow.svg"
import upArrow from "../assets/images/ic_up_fillarrow.svg"
import downSelectedArrow from "../assets/images/ic_down_selected_fillarrow.svg"
import upSelectedArrow from "../assets/images/ic_up_selected_fillarrow.svg"
import {addComma} from "../util/Number"
// import SortUI from "./DatasetFilterSort"


class DatasetFilterNew extends Component {

    constructor(props){
        super(props);

        let qs = getParamsFromQueryString(props.history.location.search);
        if(Array.isArray(qs.word) && qs.word.length > 0)
            qs.word = qs.word[0];

        this.uri = props.history.location.search;
        this.filter = {};

        this.page = props.page;

        this.state = {
            selectedTags: qs.hasOwnProperty("tag") ? JSON.parse(JSON.stringify(qs.tag)) : [],
            selectedFormats: qs.hasOwnProperty("format") ? JSON.parse(JSON.stringify(qs.format)) : [],
            selectedCategory: qs.hasOwnProperty("category") ? JSON.parse(JSON.stringify(qs.category)) : [],
            searchKeyword: qs.hasOwnProperty("word") ? JSON.parse(JSON.stringify(qs.word)) : "",
            selectedPayTags: qs.hasOwnProperty("paytag") ? JSON.parse(JSON.stringify(qs.paytag)) : [],
            searchKeywordDummy: qs.hasOwnProperty("word") ? JSON.parse(JSON.stringify(qs.word)) : "",
            selectedStartDate: null,
            selectedEndDate: null,
            selectedCenterCode: "all",
            selectedDateRange: "all",
            selectedDateField: "created",
            selectedSort: "",
            detailFlag: false,
            detailIco: downArrow
        }
    }

    componentDidMount(){
        this.updateStateFromQueryString()
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext){
        if(this.uri !== this.props.history.location.search){
            this.updateStateFromQueryString()
        }
    }

    componentWillUpdate(nextProps, nextState, nextContext){
        if(this.uri !== this.props.history.location.search){
            this.uri = this.props.history.location.search;
            return true;
        } else return false;
    }

    updateStateFromQueryString = () => {
        const {tags, formats, onChange, category, history, paytag, sort} = this.props;
        const {selectedTags, selectedFormats, selectedCategory, searchKeyword, selectedPayTags, selectedSort} = this.state;

        let qs = getParamsFromQueryString(history.location.search);
        if(Array.isArray(qs.word) && qs.word.length > 0)
            qs.word = qs.word[0];

        let dst = qs.hasOwnProperty("tag") ? qs.tag : [];
        let dsf = qs.hasOwnProperty("format") ? qs.format : [];
        let dsc = qs.hasOwnProperty("category") ? qs.category : [];
        let dsk = qs.hasOwnProperty("word") ? qs.word : "";
        let dsp = qs.hasOwnProperty("paytag") ? qs.paytag : [];

        let udt = {};

        if(dst !== selectedTags)
            udt.selectedTags = qs.hasOwnProperty("tag") ? qs.tag : [];
        if(dsf !== selectedFormats)
            udt.selectedFormats = qs.hasOwnProperty("format") ? qs.format : [];
        if(dsc !== selectedCategory)
            udt.selectedCategory = qs.hasOwnProperty("category") ? qs.category : [];
        if(dsk !== searchKeyword)
            udt.searchKeyword = qs.hasOwnProperty("word") ? qs.word : "";
        if(dsp !== selectedPayTags)
            udt.selectedPayTags = qs.hasOwnProperty("paytag") ? qs.paytag : [];

        this.setState({
            ...udt
        }, () => {
            this.changeEventSubmit()
        })
    };

    changeEventSubmit = () => {
        const {tags, formats, onChange, category, history, paytag, sort} = this.props;
        const {selectedTags, selectedFormats, selectedCategory, searchKeyword, selectedPayTags, selectedSort,
            selectedCenterCode, selectedDateRange, selectedDateField, selectedStartDate, selectedEndDate} = this.state;

        let qs = getParamsFromQueryString(history.location.search);
        let page = qs.hasOwnProperty("page") ? qs.page[0] : 1;

        let params = {
            tag: selectedTags,
            format: selectedFormats,
            category: selectedCategory,
            word: searchKeyword,
            paytag: selectedPayTags,
        };

        if(selectedCenterCode !== undefined && selectedCenterCode !== 'all'){
            params["center_code"] = selectedCenterCode;
        }
        if(selectedDateRange !== undefined && selectedDateRange !== 'all'){
            params["start_date"] = String(selectedStartDate);
            params["end_date"] = String(selectedEndDate);
        }

        if(selectedDateField !== undefined && selectedDateField !== null){
            params["datecolumn"] = selectedDateField;
        }

        if(selectedSort !== undefined && selectedSort !== null){
            params["sort"] = selectedSort;
        }

        if(JSON.stringify(params) !== JSON.stringify(this.filter)){
            page = 1;
        }

        if(JSON.stringify(params) !== JSON.stringify(this.filter) || this.page != page){
            this.setState({
                searchKeywordDummy: searchKeyword
            }, () => {
                this.filter = JSON.parse(JSON.stringify(params));
                this.page = page;
                let param = JSON.parse(JSON.stringify(params));
                onChange(param);
            })
        }
    };





    setSearchKeyword(after){
        this.setState({
            searchKeyword: after
        }, () => {
            this.changeEventSubmit()
        })
    }

    handleSearchBtn = () => {
        const {tags, formats, onChange, category, history, paytag} = this.props;
        const {selectedTags, selectedFormats, selectedCategory, searchKeyword, searchKeywordDummy, selectedPayTags} = this.state;

        this.setSearchKeyword(searchKeywordDummy)
    };

    render() {

        const {tags, formats, onChange, category, history, paytag} = this.props;
        const {selectedTags, selectedFormats, selectedCategory, searchKeyword, selectedPayTags, selectedCenterCode, selectedDateRange} = this.state;
        let tagFilter = ["경기도일자리재단", "경기신용보증재단", "경기콘텐츠진흥원", "더아이엠씨", "코나아이", "한국기업데이터"];

        return (
            <Fragment>
                <div className={"wrap-dataset"} data-tut={"dataset_filter"}>
                    {/*<div className={"wrap-formats"}>*/}
                    {/*    <h3>포맷</h3>*/}
                    {/*    {formats.length > 0 ? <ul className={"group-tags"}>*/}
                    {/*        <li>*/}
                    {/*            {formats.map(format => <div className={"tag-block"}*/}
                    {/*                                        key={"formats" + format.display_name}>*/}
                    {/*                <input type="checkbox" id={"formats" + format.display_name}*/}
                    {/*                       checked={selectedFormats.includes(format.display_name)}*/}
                    {/*                       onChange={e => this.handleChange("formats", format.display_name, e.target.checked)}/>*/}
                    {/*                <label htmlFor={"formats" + format.display_name}>*/}
                    {/*                    <span>{format.display_name}</span>*/}
                    {/*                    <span>({format.count})</span>*/}
                    {/*                </label>*/}
                    {/*            </div>)}*/}
                    {/*        </li>*/}
                    {/*    </ul> : <div className={"ds-2-no-result"}>검색 결과가 없습니다.</div>}*/}
                    {/*</div>*/}
                    {/*<div className={"wrap-formats"}>*/}
                    {/*    <h3>카테고리</h3>*/}
                    {/*    {category.length > 0 ? <ul className={"group-tags"}>*/}
                    {/*        <li>*/}
                    {/*            {category.map(category => <div className={"tag-block"}*/}
                    {/*                                           key={"category" + category.display_name}>*/}
                    {/*                <input type="checkbox" id={"category" + category.display_name}*/}
                    {/*                       checked={selectedCategory.includes(category.display_name)}*/}
                    {/*                       onChange={e => this.handleChange("category", category.display_name, e.target.checked)}/>*/}
                    {/*                <label htmlFor={"category" + category.display_name}>*/}
                    {/*                    <span>{category.display_name}</span>*/}
                    {/*                    <span>({category.count})</span>*/}
                    {/*                </label>*/}
                    {/*            </div>)}*/}
                    {/*        </li>*/}
                    {/*    </ul> : <div className={"ds-2-no-result"}>검색 결과가 없습니다.</div>}*/}
                    {/*</div>*/}
                    {/*<div className={"wrap-formats"}>*/}
                    {/*    <h3>가격</h3>*/}
                    {/*    {paytag.length > 0 ? <ul className={"group-tags"}>*/}
                    {/*        <li>*/}
                    {/*            {paytag.map(paytag => <div className={"tag-block"}*/}
                    {/*                                       key={"paytag" + paytag.display_name}>*/}
                    {/*                <input type="checkbox" id={"paytag" + paytag.display_name}*/}
                    {/*                       checked={selectedPayTags.includes(paytag.display_name)}*/}
                    {/*                       onChange={e => this.handleChange("paytag", paytag.display_name, e.target.checked)}/>*/}
                    {/*                <label htmlFor={"paytag" + paytag.display_name}>*/}
                    {/*                    <span>{this.paytagToDisplay(paytag.display_name)}</span>*/}
                    {/*                    <span>({paytag.count})</span>*/}
                    {/*                </label>*/}
                    {/*            </div>)}*/}
                    {/*        </li>*/}
                    {/*    </ul> : <div className={"ds-2-no-result"}>검색 결과가 없습니다.</div>}*/}
                    {/*</div>*/}
                    <div className={"warp-tags"}>
                        <h1>빅데이터 플랫폼 데이터 통합검색</h1>
                        {/*<div className={"wrap-tags-mini-wrap"}>*/}
                        {/*    검색바 셀렉트 박스로 플랫폼을 선택하고 데이터를 검색하세요.*/}
                        {/*</div>*/}
                    </div>
                    <div className={"search-wrap"}>
                        <input type="text" placeholder="데이터 검색" value={this.state.searchKeywordDummy}
                               onKeyDown={e => e.keyCode === 13 && this.handleSearchBtn()}
                               onChange={e => this.setState({searchKeywordDummy: e.target.value})} autoComplete={"off"}/>
                        <button onClick={() => this.handleSearchBtn()}/>
                    </div>
                </div>
            </Fragment>
        );
    }
}

DatasetFilterNew.defaultProps = {
    onChange: () => {
    },
    tags: [],
    formats: [],
    paytag: []
};

export default DatasetFilterNew;
