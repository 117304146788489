export const index = `<strong style='font-size:1.4rem;'>경기지역경제포털 개인정보처리방침</strong>

<strong style='font-size:1.2rem;'>제1장 [총칙]</strong>
경기지역경제포털(이하 “플랫폼”)에서 취급하는 모든 개인정보는 관련 법령에 의거하여 처리되며, 플랫폼 업무의 적절한 수행과 이용자의 권익 보호를 위하여 노력할 것입니다.
플랫폼은 개인정보보호 법령에 따라 이용자의 권익을 보호하고 개인정보와 관련한 이용자의 고충처리를 위하여 다음과 같은 개인정보 처리방침을 두고 있습니다.

<strong style='font-size:1.2rem;'>제2장 [개인정보처리방침]</strong>
<span style='font-size:1.1rem;'>제1조 [목적]</span>
경기지역경제플랫폼은 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 목적 이외의 용도로는 이용되지 않으며, 목적이 변경되는 경우에는 이용자로부터 별도의 동의를 받는 등 필요한 조치를 이행할 것입니다.

① 회원가입 및 관리 :
회원 가입의사 확인, 회원제 서비스 제공에 따른 본인식별ㆍ인증, 회원자격 유지ㆍ관리, 제한적  본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 만14세 미만 아동의 개인정보 처리 시 법정대리인의 동의여부 확인, 각종 고지ㆍ통지, 고충처리 등을 목적으로 개인정보를 처리합니다.

② 서비스 제공 및 업무 수행 :
데이터 상품 중개 및 거래지원, 콘텐츠 제공, 이벤트, 마케팅, 설문조사, 이용자 의견수렴, 통계정보, 본인 식별, 고지사항 전달, 원활한 의사소통 경로의 확보, 증명서 발급 등이 플랫폼 홈페이지의 서비스 제공 및 업무 수행과 관련한 목적으로 개인정보를 처리합니다.

③ 민원처리 :
민원인의 신원 확인, 민원사항 확인, 사실 조사를 위한 연락ㆍ통지, 처리결과 통보 등의 목적으로 개인정보를 처리합니다.

<span style='font-size:1.1rem;'>제2조 [개인정보처리의 위탁]</span>
① 경기도(이하 경기지역경제포털)는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.

`+
    '<table style="width:100%;">'+
    '<colgroup>'+
        '<col width="25%"/>'+
        '<col width="50%"/>'+
        '<col width="25%"/>'+
    '</colgroup>'+
    '<tr style="text-align:center;background-color:#e7e8e9;">'+
        '<th>위탁업체</th>'+
        '<th>위탁업무</th>'+
        '<th>위탁기간</th>'+
    '</tr>'+
    '<tr style="text-align:center;">'+
        '<td >경기경제과학진흥원<br/>선도소프트<br/>한국평가데이터<br/>씨씨미디어서비스</td>'+
        '<td style="vertical-align:middle;">경기지역경제포털 플랫폼 구축 및 데이터 협업체계 강화</td>'+
        '<td style="vertical-align:middle;">2023.05.30.~계약 종료 시</td>'+
    '</tr>'+
    '</table>'
+`

② 경기도(이하 경기지역경제포털)는 위탁계약 체결 시 개인정보 보호법 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적˙관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리˙감독, 손해배상 등 책임에 관한 사항을 게약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.

③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체 없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.

<span style='font-size:1.1rem;'>제3조 [개인정보 이용 및 보유기간]</span>
플랫폼은 개인정보의 처리 목적이 달성될 때까지 개인정보를 보유합니다. 단, 이용자로부터 사전에 동의를 받은 경우 또는 상법 등 관련 법령에 의하여 보존할 필요성이 있는 다음의 경우에는 개인정보를 일정기간 동안 보유합니다.

1.계약 또는 청약철회 등에 관한 기록: 5년

2.대금 결제 및 재화 등의 공급에 관한 기록: 5년

3.소비자의 불만 또는 분쟁처리에 관한 기록: 3년

4.기타 고객의 동의를 받은 경우 동의를 받은 기간

<span style='font-size:1.1rem;'>제4조 [개인정보 수집항목 및 수집 방법] </span>

① 플랫폼이 수집 및 처리하는 개인정보 항목은 다음과 같습니다.

② “플랫폼”은 아래의 방법을 통해 개인정보를 수집합니다.
1. 회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를 입력하는 경우, 해당 개인정보를 수집합니다.
2. 민원처리 과정에서 웹 페이지, 메일, 팩스, 전화 등을 통해 이용자의 개인 정보가 수집될 수 있습니다.
3. 오프라인에서 진행되는 이벤트, 세미나 등에서 서면을 통해 개인정보가 수집될 수 있습니다.
4. 플랫폼과 제휴한 외부 기업이나 단체로부터 개인정보를 제공받을 수 있으며, 이러한 경우에는 관련법령에 따라 제휴사에서 이용자에게 개인정보 제공 동의 등을 받은 후에 플랫폼에 제공합니다.
5. 기기정보와 같은 생성정보는 PC웹, 모바일 웹/앱 이용 과정에서 자동으로 생성되어 수집될 수 있습니다.

<span style='font-size:1.1rem;'>제5조 [개인정보 제공 동의 등]</span>

① “플랫폼”이 제3자에게 구매자 개인정보를 제공할 필요가 있는 경우
1) 개인정보를 제공받는 자, 2) 개인정보를 제공받는 자의 개인정보 이용목적,
3) 제공하는 개인정보의 항목, 4) 개인정보를 제공받는 자의 개인정보 보유 및 이용기간을 구매자에게 알리고 동의를 받아야 합니다.
(동의를 받은 사항이 변경되는 경우에도 같습니다.)

② “플랫폼”이 제3자에게 구매자의 개인정보를 취급할 수 있도록 업무를 위탁하는 경우에는 
1) 개인정보 취급위탁을 받는 자,2) 개인정보 취급위탁을 하는 업무의 내용을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.) 
다만, 서비스제공에 관한 계약이행을 위해 필요하고 구매자의 편의증진과 관련된 경우에는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에서 정하고 있는 방법으로 개인정보 취급방침을 통해 알림으로써 고지절차와 동의절차를 거치지 않아도 됩니다.

③ 개인정보를 제공받는 자는 다음과 같습니다.

 


<span style='font-size:1.1rem;'>제6조 [개인정보의 파기]</span>
플랫폼은 개인정보의 처리목적이 달성되면 아래의 절차와 방법에 따라 개인정보를 파기합니다.

① 개인정보 보유기간의 경과, 개인정보의 처리 목적이 달성, 해당 서비스의 폐지, 사업 종료 등으로 보유 개인정보가 불필요하게 되었을 경우, 해당 개인정보는 별도의 DB로 옮겨져(서류의 경우 별도의 서류함) 개인정보의 처리 및 보유 기간에 따라 일정 기간 보관된 후 파기됩니다. 불필요한 것으로 인정되는 날로부터 5영업일 이내에 그 개인정보를 파기하며, 보존 이외의 다른 목적으로 이용되거나 제공되지 않습니다.

② 제1항에 불구하고 다음 각 조의 사항이나 관련 법령에 따라 5영업일 이상 보존될 수 있습니다.
1.상법 제33조 등 법령에 따라 보존하여야 하는 경우
2.기타 이와 유사한 정당한 사유가 있는 경우

③ 종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다. 
전자적 파일 형태인 경우 복원이 불가능한 방법으로 영구 삭제하며, 기록물, 인쇄물, 서면, 기타 기록매체인 경우에는 파쇄, 소각, 용해 등의 방법으로 파기합니다.

④ 정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보(또는 개인정보파일)을 별도의 데이터베이스(DB)로 옮기거나 보관 장소를 달리하여 보존합니다.

<span style='font-size:1.1rem;'>제7조 [개인정보의 안전성 확보 조치]</span>
플랫폼은 개인정보의 처리목적이 달성되면 아래의 절차와 방법에 따라 개인정보를 파기합니다.
플랫폼은 개인정보 보호 관련 법령에 따라 방화벽을 운영하고, 개인정보를 암호화하여 보관하는 등 개인정보 보호를 위해 필요한 관리적·기술적·물리적 조치를 취하고 있습니다.

① 관리적 조치
1. 개인정보의 안전한 처리를 위한 내부관리계획을 수립·시행하고 있습니다.
2. 플랫폼은 개인정보를 처리하는 직원을 최소한의 범위로 지정하고, 직원 교육 실시 등을 통하여 개인정보를 관리하는 대책을 시행하고 있습니다. 교육 시에는 새로운 보안 기술 습득 및 개인정보 보호 의무 등에 관해 정기적인 사내 교육 및 외부 위탁교육도 함께 실시하고 있습니다.
3. 플랫폼은 입사시 직원의 보안서약서를 통하여 사람에 의한 정보유출을 사전에 방지하고 개인정보보호 정책에 대한 이행사항 및 직원의 준수여부를 감사하기 위한 내부절차를 마련하고 있습니다.

② 기술적 조치
1. 플랫폼은 이용자의 개인정보에 대한 접근권한을 최소한의 인원으로 제한하고, 개인정보처리시스템에 대한 접근권한을 업무 수행에 필요한 최소한의 범위로 하여 업무 담당자별로 사용자 계정을 차등 부여하고 있고, 개인정보 취급자가 변경되었을 경우 지체없이 개인정보처리시스템의 접근권한을 변경 또는 말소하고 그 기록을 최소 3년간 보관하는 등 접근통제를 위하여 접근통제시스템 설치, 보안프로그램 설치 등 필요한 조치를 하고 있으며, 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
가. 플랫폼은 개인정보처리시스템 접속 기록을 최소 6개월 이상 보관하고 있고, 위·변조, 도난 및 분실되지 않도록 안전하게 관리하고 있습니다.
2. 고객의 비밀번호는 암호화되어 저장 및 관리되고 있어 본인만이 알 수 있으며, 중요한 데이터는 파일 및 전송데이터를 암호화하거나 파일잠금 기능을 사용하여 중요한 데이터는 별도의 보안기능을 통해 보호되고 있습니다. 
가. 플랫폼은 암호알고리즘을 이용하여 네트워크 상의 개인정보를 안전하게 전송할 수 있는 보안장치를 채택하고 있으며, 해킹 등 외부침입에 대비하여 방화벽을 설치하고, 각 서버마다 침입차단시스템 및 취약점 분석시스템 등을 이용하여 개인정보를 보호합니다.
나. 인터넷 구간 및 인터넷 구간과 내부망의 중간 지점(DMZ : Demilitarized Zone)에 고유식별정보를 저장하는 경우에는 이를 암호화하여 보관합니다.
3. 플랫폼은 해킹 등에 대비하여 해킹 또는 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위한 보안 프로그램의 설치 및 갱신을 주기적으로 진행하고 있습니다.
 
③ 물리적 조치
1. 플랫폼은 전산실 및 자료 보관실 등 개인정보를 보관하고 있는 물리적 보관 장소를 특별  보호구역으로 설정하여 접근 및 출입 통제 절차를 수립·운영하고 있고, 개인정보가 포함된 서류 또는 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관 및 관리하고 있습니다.
2. 플랫폼은 이용자 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다. 이용자 개개인은 본인의 개인정보를 보호하기 위해서 자신의 아이디와 비밀번호를 적절하게 관리하고 여기에 대한 책임을 져야 합니다.

<span style='font-size:1.1rem;'>제8조 [개인정보 자동수집 장치의 설치ㆍ운영 및 거부에 관한 사항]</span>
플랫폼은 귀하의 정보를 수시로 저장하고 찾아내는 '쿠키(cookie)' 등을 운용합니다. 쿠키란 플랫폼의 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에 보내는 아주 작은 텍스트 파일로서 컴퓨터 하드디스크에 저장됩니다.

① 쿠키 등 사용 목적 : 이용자의 페이지 접속 빈도나 방문 시간 등을 분석 귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.

② 설정방법 예(인터넷 익스플로러의 경우) : 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보
단, 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.

③ 경기지역경제포털은 경기지역경제포털 회원가입 가입 및 회원관리의 개인정보 항목을 처리하고 있습니다. 경기지역경제포털 서비스 이용과정에서 IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문기록, 불량 이용기록 등 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.

<span style='font-size:1.1rem;'>제9조 [정보주체의 권리와 의무 및 그 행사 방법]</span>
    이용자는 정보주체로서 경기지역경제포털에 대해 언제든지 다음과 같은 개인정보 보호 관련 권리를 행사할 수 있습니다.

① 개인정보 열람 요구: 이용자는 회사에서 보유하고 있는 개인정보에 대하여 열람을 요구할 수 있습니다. 단, 법률에 따라 열람이 금지되거나 제한되는 경우, 다른 사람의 생명ㆍ신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우에 해당하는 경우에는 제한될 수 있습니다.

② 개인정보 정정ㆍ삭제 요구: 이용자는 회사에서 보유하고 있는 개인정보에 대하여 개인정보의 정정ㆍ삭제를 요구할 수 있습니다. 단, 다른 법령에서 그 개인정보가 수집 대상으로 명시된 경우에는 그 삭제를 요구할 수 없습니다.

③ 개인정보 처리 동의 철회 또는 처리정지 요구: 이용자는 회사에서 보유하고 있는 개인정보에 대하여 회사에 개인정보의 처리정지를 요구하거나, 개인정보 처리에 대한 동의를 철회할 수 있습니다.
단, 법률에 따라 열람이 금지되거나 제한되는 경우, 다른 사람의 생명ㆍ신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우, 개인정보를 처리하지 아니하면 정보주체와 약정한 서비스를 제공하지 못하는 등 계약의 이행이 곤란한 경우로서 정보주체가 그 계약의 해지 의사를 명확하게 밝히지 아니한 경우에는 개인정보 처리에 대한 동의 철회 또는 처리정지 요구가 거절될 수 있습니다. 

④ 이용자는 나의 정보 화면에서 개인정보를 정정하거나 삭제할 수 있으며, 회사가 정한 방식(이메일 등)의 방법으로 위 권리들을 행사할 수 있습니다.

⑤ 이용자는 대리인(법정대리인이나 위임을 받은 자)을 통하여 권리를 행사할 수 있습니다. 
이 경우 위임장을 회사가 정한 방식(이메일, FAX 등)으로 제출하여야 합니다.

⑥ 정보주체는 개인정보 보호법 등 관계법령을 위반하여 경기지역경제포털이 처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 안됩니다. 

⑦ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우 경기지역경제포털은 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.

⑧ 권리 행사는 경기지역경제포털에 대해 서면, 전화, 전자우편 등을 통하여 하실 수 있으며 경기지역경제포털은 이에 대해 지체없이 조치하겠습니다.

<span style='font-size:1.1rem;'>제10조 [개인정보 보호책임자에 관한 사항]</span>
이용자의 개인정보를 보호하고 개인정보와 관련한 불만 및 열람 청구 등을 처리하기 위하여 아래와 같이 개인정보보호책임자(CPO)를 두고 있습니다. 개인정보와 관련한 문의사항이 있으시면 아래의 개인정보보호담당자에게 연락하여 주시기 바랍니다.

가. 개인정보보호책임자(CPO)
• 성 명 : 김양희 • 직 책 : 주무관 • 소속부서 : 경기도청 데이터 정책과
나. 개인정보보호담당자
• 성 명 : 김양희 • 직 책 : 주무관 • 소속부서 : 경기도청 데이터 정책과 • 이메일주소 : yanghee@gg.go.kr

<span style='font-size:1.1rem;'>제11조 [개인정보의 열람청구를 접수·처리하는 부서]</span>
① 정보주체의 권리와 그 행사방법
이용자는 정보주체로서 다음과 같은 권리를 행사할 수 있습니다.

1. 개인정보 열람 요구 : 경기지역경제포털에서 보유하고 있는 개인정보파일은 「개인정보보호법」 제35조(개인정보의 열람)에 따라 열람을 요구할 수 있습니다. 다만 개인정보 열람 요구는 「개인정보보호법」 제35조에 의하여 다음과 같이 제한될 수 있습니다.
가. 법률에 따라 열람이 금지되거나 제한되는 경우 나. 다른 사람의 생명ㆍ신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우
 2. 개인정보 정정ㆍ삭제요구 : 경기지역경제포털에서 보유하고 있는 개인정보파일에 대해서는 「개인정보보호법」 제36조(개인정보의 정정ㆍ삭제)에 따라 경기지역경제포털에 개인정보의 정정ㆍ삭제를 요구할 수 있습니다. 다만, 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다. 
3. 개인정보 처리정지 요구 : 경기지역경제포털에서 보유하고 있는 개인정보파일에 대해서는 「개인정보보호법」 제37조(개인정보의 처리정지 등)에 따라 경기지역경제포털에 개인정보의 처리정지를 요구할 수 있습니다. 또한 만 14세 미만 아동의 법정대리인은 경기지역경제포털에 그 아동의 개인정보의 열람, 정정ㆍ삭제, 처리정지 요구를 할 수 있습니다. 다만, 개인정보 처리정지 요구 시 「개인정보보호법」 제37조제2항에 의하여 처리정지 요구가 거절될 수 있습니다.
가. 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우 
나. 다른 사람의 생명ㆍ신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우 
다. 공공기관이 개인정보를 처리하지 아니하면 다른 법률에서 정하는 소관 업무를 수행할 수 없는 경우 
라. 개인정보를 처리하지 아니하면 정보주체와 약정한 서비스를 제공하지 못하는 등 계약의 이행이 곤란한 경우로서 정보주체가 그 계약의 해지 의사를 명확하게 밝히지 아니한 경우

 4. 개인정보의 열람, 정정ㆍ삭제, 처리정지 요구에 대해서는 10일 이내에 해당 사항에 대한 경기지역경제포털의 조치를 통지합니다. 개인정보의 열람, 정정ㆍ삭제, 처리정지 요구는 해당 부서를 통해서 가능합니다. 5.위 사항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.
가. 고객상담센터
경기지역경제포털은 고객과의 원활한 의사소통을 위해 고객상담센터를 운영하고 있습니다.
• 전화번호 : 031-8008-3953 • 상담가능시간 : 평일 09:00 ~ 18:00

<span style='font-size:1.1rem;'>제12조 [권익침해 구제방법]</span>

① 이용자는 개인정보침해로 인한 구제를 받기 위하여 아래의 기관에 분쟁해결이나 상담 등을 신청할 수 있습니다.
아래의 기관은 회사와는 별개의 정부기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요한 경우에 문의하여 주시기 바랍니다.

1. 개인정보침해신고센터 (한국인터넷진흥원 운영)
가. 소관업무: 개인정보 침해사실 신고, 상담 신청
나. 홈페이지: privacy.kisa.or.kr
다. 전화번호: (국번 없이) 118
2. 대검찰청 사이버범죄수사과
가. 홈페이지: www.spo.go.kr
나. 전화번호: (국번 없이) 1301
3. 경찰청 사이버안전지킴이
가. 홈페이지: http://www.police.go.kr/www/security/cyber.jsp
나. 전화번호: (국번없이) 110

<span style='font-size:1.1rem;'>제13조 [개인정보 처리방침 변경]</span>
① 본 개인정보 처리방침을 개정하는 경우에는 홈페이지를 통하여 공지할 것입니다. 개정된 개인정보 처리방침은 공지한 날로부터 7일 후부터 효력이 발생합니다.


부칙
<a href='/#/Policy/211117'>(시행일) 본 방침은 2021년 11월 17일부터 시행합니다.</a>
(시행일) 본 방침은 2020년 1월 21일부터 시행합니다.
`;
