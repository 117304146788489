import React, {useState, useEffect, useRef} from 'react';
import StarRatings from "react-star-ratings";
import SelectSearch from 'react-select-search';
import {useSelector} from "react-redux";
import swal from "sweetalert";
import {delCookie} from "../util/Cookie";
import * as DataApi from "../api/Data";
import * as OrderApi from "../api/Order";
import {post} from "../util/HttpRequest";

const CommonRatingScore = ({post_title, post_id, post_theme}) => {

    let [dataRating, setDataRating] = useState(5);
    let [avgDataRating, setAvgDataRating] = useState(0);
    let [alreadyDataRatingFlag, setAlreadyDataRatingFlag] = useState(false);
    let [ratingButtonName, setRatingButtonName] = useState("평가");

    const userInfo = useSelector(state => state.user.info);
    const isLogin = useSelector(state => state.user.isLogin);

    const dataRatingScore = [
        {
            name: "5",
            value: "5"
        },
        {
            name: "4",
            value: "4"
        },
        {
            name: "3",
            value: "3"
        },
        {
            name: "2",
            value: "2"
        },
        {
            name: "1",
            value: "1"
        }
    ];

    useEffect(() => {
        getDataRatingScore(userInfo.id_key, post_id)
    }, []);

    const setDataRatingScore = (user_id, user_name, post_id, post_title, rating_score, post_theme) => {
        if(!isLogin) {
            swal({
                text: "로그인 후 이용 가능한 서비스입니다.",
                buttons: [
                    "확인",
                    "로그인"
                ]
            }).then(function (isConfirm) {
                if(isConfirm) {
                    delCookie("token");
                    window.location.href = "/#/login";
                } else {
                    swal.close();
                }
            })
        } else {
            let params = {
                'user_id': user_id,
                'user_name': user_name,
                'rating_post_id': post_id,
                'rating_post_title': post_title,
                'rating_score': rating_score,
                'rating_post_theme': post_theme
            };
            DataApi.addDataRatingScore(params)
                .then(res => {
                    swal('평점이 반영되었습니다.');
                    getDataRatingScore(user_id, post_id)
                })
                .catch(res => {

                });
        }
    };

    const getDataRatingScore = (user_id, post_id) => {
        let params = {
            'rating_post_id': post_id,
            'user_id': user_id
        };

        DataApi.readDataRatingScore(params).then(res => {
            setAvgDataRating(res.result.score_avg);
            if (res.result.individual_score !== "None") {
                setDataRating(res.result.individual_score);
                setAlreadyDataRatingFlag(true);
                setRatingButtonName("갱신");
            }
        });
    };

    const selectRef = useRef(null);
    useEffect( () => {
        if (selectRef !== null) {
            document.getElementsByClassName("data-rating-select-box__input")[0].setAttribute("title", "평점")
        }
    }, [])

    return (
        <>
        <div style={{"border-top": "1px solid #e0e0e0", "margin-top": "5px", "margin-bottom": "20px"}}></div>
        <div style={{"padding-left": "20px"}}>
            <div style={{"display": "inline-block"}}>
                <p style={{"color": "rgb(109, 122, 130)", "font-size": "20px", "font-weight": "500"}}>{avgDataRating}</p>
            </div>
            <div style={{"display": "inline-block", "margin-left": "10px"}}>
                <StarRatings
                    rating={avgDataRating}
                    numberOfStars={5}
                    name='rating'
                    starDimension="20px"
                    starSpacing="1px"
                />
            </div>

            <div style={{"display": "inline-block", "margin-left": "10px"}}>
                {
                    alreadyDataRatingFlag === false ?
                        <SelectSearch
                            options={dataRatingScore}
                            onChange={option => {setDataRating(option)}}
                            placeholder={"미평가"}
                            className={"data-rating-select-box"}
                            ref={selectRef}
                        />
                        :
                        <SelectSearch
                            options={dataRatingScore}
                            onChange={option => {setDataRating(option)}}
                            value={dataRating}
                            className={"data-rating-select-box"}
                            ref={selectRef}
                        />
                }
            </div>
            <div style={{"display": "inline-block", "margin-left": "10px"}}>
                <button className={"detail-refresh-button"} onClick={() =>
                    setDataRatingScore(userInfo.id_key, userInfo.id, post_id, post_title, dataRating, post_theme)}> {ratingButtonName} </button>
            </div>
        </div>
        </>
    )
};

export default CommonRatingScore;
