import {get, post, getNew, postNew} from '../util/HttpRequest';

const ICAN_API_URL = process.env.REACT_APP_ICAN_API_URL;

/**
 * 데이터 생성
 * /create/data
*/
export const createData = (params = {}) => {
    const target = ICAN_API_URL + "/create/package";
    return post(target, params);
};

/**
 * 데이터 조회
 * /read/data
 */
export const readData = (params = {}) => {
    const target = ICAN_API_URL + "/read/package";
    return getNew(target, params);
};

/**
 * 통합 데이터 조회
 * /read/interated_data_detail
 */
export const readDataIntegrated = (params = {}) => {
    const target = ICAN_API_URL + "/read/interated_data_detail";
    return getNew(target, params);
};

/**
 * 데이터 수정
 * /update/data
 */
export const updateData = (params = {}) => {
    const target = ICAN_API_URL + "/update/package";
    return post(target, params);
};

/**
 * 데이터 삭제
 * /delete/data
 */
export const deleteData = (params = {}) => {
    const target = ICAN_API_URL + "/delete/package";
    return post(target, params);
};

/**
 * 데이터 목록 조회
 * /read/list_data
 */
export const readListData = (params = {}) => {
    const target = ICAN_API_URL + "/read/list_data";
    return get(target, params);
};

/**
 * 데이터 검색
 * /read/search_data
 */
export const readSearchData = (params = {}) => {
    const target = ICAN_API_URL + "/read/search_data";
    params = Object.assign({"from_portal": true}, params);
    return getNew(target, params);
};

/**
 * 통합 데이터 검색
 * /read/integrated_data_list
 */
export const readSearchIntegratedData = (params = {}) => {
    const target = ICAN_API_URL + "/read/integrated_data_list";
    return getNew(target, params);
};

/**
 * 검색어 자동완성
 * /read/search_keyword
 */
export const readSearchKeyword = (params = {}) => {
    const target = ICAN_API_URL + "/read/search_keyword";
    return getNew(target, params);
};

/**
 * 인기 검색어
 * /read/search_popular_keyword
 */
export const readSearchPopularKeyword = (params = {}) => {
    const target = ICAN_API_URL + "/read/search_popular_keyword";
    return getNew(target, params);
};

/**
 * 인기 데이터셋
 * /read/search_popular_data
 */
export const readSearchPopularData = (params = {}) => {
    const target = ICAN_API_URL + "/read/search_popular_data";
    return getNew(target, params);
};

/**
 * 최신 데이터셋
 * /read/search_popular_data
 */
export const readLatestData = (params = {}) => {
    const target = ICAN_API_URL + "/read/list_latest_data";
    return getNew(target, params);
};

/**
 * 데이터 시각화
 * /read/graph
 */
export const readGraph = (params = {}) => {
    const target = ICAN_API_URL + "/read/graph";
    return get(target, params);
};

/**
 * 시각화 다운로드
 * /read/download_graph
*/
export const readDownloadGraph = (params = {}) => {
    const target = ICAN_API_URL + "/read/download_graph";
    return get(target, params);
};

/**
 * 메타데이터 관리 > 메타데이터 조회
 * /read/meta
 */
export const readMeta = (params = {}) => {
    const target = ICAN_API_URL + "/read/meta";
    return getNew(target, params);
};

/**
 * 리소스 다운로드
 * /read/download_data
 */
export const downloadData = (params = {}) => {
    const target = ICAN_API_URL + "/read/download_data";
    return postNew(target, params);
};

/**
 * 리소스 미리보기 다운로드
 * /read/download_sample_data
 */
export const downloadPreviewData = (params = {}) => {
    const target = ICAN_API_URL + "/read/download_sample_data";
    return postNew(target, params);
};

/**
 * 리소스 미리보기
 * /read/resource_view
 */
export const previewData = (params = {}) => {
    const target = ICAN_API_URL + "/read/resource_view";
    return getNew(target, params);
};

/**
 * 메타데이터 관리 > 연관데이터 불러오기
 * /read/relationship
 */
export const readRelationship = (params = {}) => {
    const target = ICAN_API_URL + "/read/relationship";
    return getNew(target, params);
};

/**
 * 메인 > 데이터 갯수
 * /read/data_count
 */
export const readDataCount = (params = {}) => {
    const target = ICAN_API_URL + "/read/data_count";
    return getNew(target, params);
};

/**
 * 활용 > 활용 갤러리
 * /read/data_count
 */
export const readUseList = (params = {}) => {
    const target = ICAN_API_URL + "/read/use_case_list";
    return getNew(target, params);
};

/**
 * 활용 > 활용 갤러리 상세
 * /read/data_count
 */
export const readUseDetail = (params = {}) => {
    const target = ICAN_API_URL + "/read/use_case_content";
    return getNew(target, params);
};

/**
 * 활용 > 오프라인 데이터 분석 신청
 * /create/proposal_analysis
 */
export const proposalAnalysis = (params = {}) => {
    const target = ICAN_API_URL + "/create/proposal_analysis";
    return postNew(target, params);
};

export const readDatasetAllTitle = (params = {}) => {
  const target = ICAN_API_URL + "/read/all_dataset_title";
  return getNew(target, params);
};

/**
 * 데이터 상세 > 데이터 오류 신고
 * /create/data_report
 */
export const reportData = (params = {}) => {
    const target = ICAN_API_URL + "/create/data_report";
    return postNew(target, params);
};

/**
 * 스키마 정보 다운로드
 * /read/download_schema_ifno
 */
export const downloadSchemaInfoData = (params = {}) => {
    const target = ICAN_API_URL + "/read/download_schema_ifno";
    return postNew(target, params);
};

/**
 * 데이터 평점 추가
 * /create/data_rating_score
 */
export const addDataRatingScore = (params = {}) => {
    const target = ICAN_API_URL + "/create/data_rating_score";
    return postNew(target, params);
};


/**
 * 데이터 평점 읽기
 * /read/data_rating_score
 */
export const readDataRatingScore = (params = {}) => {
    const target = ICAN_API_URL + "/read/data_rating_score";
    return getNew(target, params);
};

/**
 * 데이터 댓글 추가
 * /create/data_rating_score
 */
export const addDataComment = (params = {}) => {
    const target = ICAN_API_URL + "/create/data_comment";
    return postNew(target, params);
};



/**
 * 데이터 평점 읽기
 * /read/data_rating_score
 */
export const readDataComment = (params = {}) => {
    const target = ICAN_API_URL + "/read/data_comment";
    return getNew(target, params);
};

/**
 * 데이터 댓글 삭제
 * /delete/data_rating_score
 */
export const deleteDataComment = (params = {}) => {
    const target = ICAN_API_URL + "/delete/data_comment";
    return postNew(target, params);
};

/**
 * 파일 업로드
 * /create/image_upload
 */
export const createImageUpload = (params = {}) => {
    const target = ICAN_API_URL + "/create/ikan_image_upload"
    return postNew(target, params)
};

/**
 * 패키지아이디와 이름 가져오기
 * /read/all_dataset_title_and_package_name
 */
export const readTitleAndPackageId = (params = {}) => {
    const target = ICAN_API_URL + "/read/all_dataset_title_and_package_id";
    return getNew(target, params);
}
/**
 * 유저 아이디를 통해 주문한 패키지아이디와 이름 가져오기
 * /read/all_dataset_title_and_package_name
 */
export const readTitleAndPackageIdByUserId = (params = {}) => {
    const target = ICAN_API_URL + "/read/all_dataset_title_and_package_id_by_user_id";
    return getNew(target, params);
};

/**
 * 시각화 갤러리 리스트
 * /read/use_visualization_list
 */
export const readVisualizationGalleryList = (params = {}) => {
    const target = ICAN_API_URL + "/read/use_visualization_list";
    return getNew(target, params)
}

/**
 * 시각화 갤러리 삭제
 * /delete/use_visualization
 */
export const deleteUseVisualization = (params = {}) => {
    const target = ICAN_API_URL + "/delete/use_visualization";
    return postNew(target, params);
};


/**
 * 데이터 검색 공모전 전용
 * /read/search_data_by_competition
 */
export const readSearchDataByCompetition = (params = {}) => {
    const target = ICAN_API_URL + "/read/search_data_by_competition";
    params = Object.assign({"from_portal": true}, params);
    return getNew(target, params);
};

export const readDataByCompetition = (params = {}) => {
    const target = ICAN_API_URL + "/read/package_by_competition";
    return getNew(target, params);
};

/**
 * 리소스 다운로드
 * /read/download_data
 */
export const downloadDataByCompetition = (params = {}) => {
    const target = ICAN_API_URL + "/read/download_data_by_competition";
    return postNew(target, params);
};

/**
 * IKAN 설정
 * /read/ikan_config
 */
export const readIkanConfig = (params = {}) => {
    const target = ICAN_API_URL + "/read/ikan_config";
    return getNew(target, params)
}


/**
 * 데이터셋 다운로드 카운트
 * /read/download_count
 */
export const readDownloadCount = (params = {}) => {
    const target = ICAN_API_URL + "/read/download_count";
    return getNew(target, params)
}

/**
 * 추천데이터가 들어간 최신데이터
 * /read/new_list_latest_data
 */
export const readNewLatestData = (params = {}) => {
    const target = ICAN_API_URL + "/read/new_list_latest_data";
    return getNew(target, params);
};