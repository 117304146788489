import React, { useEffect, useState } from 'react';
import { HashRouter, Route, Switch } from "react-router-dom";
import ErrorPage404 from './pages/common/ErrorPage404';
import PageCommon from "./pages/common";
import PageAuth from "./pages/user";
import PageDataset from "./pages/dataset";
import NewDataset from "./pages/new-dataset";
import PageIntegrated from "./pages/IntegratedDataSet";
import PageBoard from "./pages/board";
import PageOrder from "./pages/order"
import { useSelector } from "react-redux";
import * as IboardApi from "./api/Board"
import PageCompetition from "./pages/competition";
import Layout from "./pages/Layout";
import WhatWeDo from "./pages/WhatWeDo";
// import Allocation from "./pages/Allocation";
import Centers from "./pages/Centers";
import Event from "./pages/event/Event";
import Infographic from "./pages/Infographic";
import Policy from "./pages/Policy";
import ScrollToTop from "./util/scrollToTop";
import FloatingButton from "./components/FloatingButton";
import * as DataApi from "./api/Data";
import GoogleAnalytics from "./util/GoogleAnalytics";
import PageService from "./pages/service";

// import {setChangeMain} from "./store/actions/DataAction";
// import LocationLanding from "./pages/dataset/LocationLanding";
// import CreditAnalysis from "./pages/dataset/CreditAnalysis";
import AccessLog from './util/AccessLog';

function AppRouter(props) {

    const [boardList, setBoardList] = useState([]);

    useSelector(state => {
        if (state.board.boardMenuList !== boardList)
            setBoardList(state.board.boardMenuList);
    });

    const [competitionFlag, setCompetitionFlag] = useState(false);
    const [openapiFlag, setOpenApiFlag] = useState(false);
    useEffect(() => {
        DataApi.readIkanConfig({ 'type': 'competition' })
            .then(res => {
                setCompetitionFlag(res.result.config_list.filter(config => { return config.function_type === "competition" })[0].function_active)
                setOpenApiFlag(res.result.config_list.filter(config => { return config.function_type === "openapi" })[0].function_active)
            })


        IboardApi.readBoardList().then(res => {
            let boardList = res.result.boardlist;
            let boardInfo = {};
            let boardLink = [];
            boardList.map(board => {
                boardInfo[board.id] = {
                    name: board.subject
                };
                boardLink.push({
                    name: board.subject,
                    link: "/board/" + board.id
                })
            });

            // dispatch(setBoardInfo(boardInfo));
            // dispatch(setBoardMenuList(boardLink));
        })
    }, []);
    const mainFlag = useSelector(state => state.data.changeMain);

    return (
        <HashRouter>
            {mainFlag === false && <FloatingButton />}

            <ScrollToTop />
             <Switch>
                <Route path="/login"
                    component={PageAuth.Login} exact />
                <Route path="/join"
                    component={PageAuth.Join} exact />
                <Route path="/forgot_id"
                    component={PageAuth.ForgotId} exact />
                <Route path="/forgot_password"
                    component={PageAuth.ForgotPassword} exact />
                <Route path="/reset_password/:user_id_key/:reset_key"
                    component={PageAuth.ResetPassword} exact />
                <Route path="/email_confirm/:user_id_key/:temporary_key"
                    component={PageAuth.EmailConfirm} exact />
                <Route path="/delete_user/:user_id_key/:delete_key"
                    component={PageAuth.DeleteUser} exact />

                <Layout>
                    <Switch>                        
                        <Route path="/new-main" 
                            component={PageCommon.NewMain} exact />
                        <Route path="/new-dataset" 
                            component={NewDataset.ListDataset} exact />
                        <Route path="/new-datasetMix" 
                            component={NewDataset.MixListDataset} exact />
                        <Route path="/"
                            component={PageCommon.NewMain} exact />
                        <Route path="/mypage"
                            component={PageAuth.Mypage} />                      
                        <Route path="/analysisService"
                            component={PageDataset.AnalysisService} exact />
                        <Route path="/storeService"
                            component={PageService.StoreService} exact />
                        <Route path="/dataset"
                            component={PageDataset.ListDataset} exact />
                        <Route path="/datasetMix"
                            component={PageDataset.MixListDataset} exact />
                        <Route path="/dataset/:id"
                            component={PageDataset.Item} exact />
                        <Route path="/integratedDataset"
                            component={PageIntegrated.ListDataset} exact />
                        <Route path="/integratedDataset/:id"
                            component={PageIntegrated.Item} exact />
                        <Route path="/board/:board"
                            component={PageBoard.List} exact />
                        <Route path="/board/:board/write"
                            component={PageBoard.Write} exact />
                        <Route path="/board/:board/write/:articleId"
                            component={PageBoard.Write} exact />
                        <Route path="/board/:board/article/:articleId"
                            component={PageBoard.Article} exact />
                        <Route path="/order/:dataId"
                            component={PageOrder.Order} exact />
                        <Route path="/whatwedo"
                            component={WhatWeDo} exact />
                        <Route path="/Centers"
                            component={Centers} exact />
                        <Route path="/event"
                            component={Event} exact />
                        <Route path="/event/:eventSort"
                            component={Event} exact />
                        <Route path="/use"
                            component={PageDataset.Use} exact />
                        <Route path="/use/write"
                            component={PageDataset.UseWrite} exact />
                        <Route path="/use/update/:useWriteId"
                            component={PageDataset.UseUpdate} exact />
                        <Route path="/use/:id"
                            component={PageDataset.UseDetail} exact />
                        <Route path="/landing"
                            component={PageDataset.LocationLanding} exact />
                        <Route path="/creditanalysis"
                            component={PageDataset.CreditAnalysis} exact />
                        {
                            competitionFlag === false ?
                                null
                                :
                                <>
                                    <Route path="/competition"
                                        component={PageCompetition.ListDataset} exact />
                                    <Route path="/competition/:id"
                                        component={PageCompetition.Item} exact />
                                </>
                        }
                        {
                            openapiFlag === false ?
                                null
                                :
                                <>
                                    <Route path="/openapiService"
                                        component={PageDataset.OpenApiService} exact />
                                </>
                        }
                        <Route path="/Infographic"
                            component={Infographic} exact />

                        <Route path="/Policy/:version"
                            component={Policy} exact />

                        <Route component={ErrorPage404} />


                    </Switch>
                </Layout>
                
                <Route component={ErrorPage404} />
            </Switch>
            <GoogleAnalytics />
            <AccessLog />
        </HashRouter>
    );
}

export default AppRouter;
