const GNB_HEIGHT = '390px';

export const initGnb = () => {
    const $$ = document.querySelector.bind(document);

    // EBI의 ebi.gnb.js 리팩터링

    // 접속 디바이스 체크
	var deviceCk = "win16|win32|win64|mac|macintel";
	var device;
	// if(navigator.platform){
	// 	if( deviceCk.indexOf(navigator.platform.toLowerCase())<0 ){
	// 		device = "mobile";
	// 	}else{
	// 		device = "pc";			
	// 	}
	// }
    device = "mobile";
	
	var speed = 300;//gnb 열림,닫힘 시간 / 1초=1000

    // $$("nav.mobile").appendChild($$("nav.pc .gnb").cloneNode(true));
	$$("nav.mobile a").classList.add("trans0");	
    if ($$(".gnbHead.pc .menu"))
    	$$("nav.mobile .gnb").appendChild($$(".gnbHead.pc .menu").cloneNode(true));
	$$("nav.mobile").style.visibility = "hidden";
	"data-orgH data-gap data-leftPos".split(' ').forEach(key => $$("nav.mobile .gnb").removeAttribute(key));
	document.querySelectorAll("nav.mobile form").forEach(f => f.parentElement.removeChild(f));		//모바일에서 pc 에서 사용된느 form 제거

    /* ********************* PC ******************** */

    /* ON 메뉴 찾기 */
    var onmenu = 'null';
    if ($$("nav.pc .gnb>ul>li.on"))
        $$("nav.pc .gnb>ul>li.on").parentElement.children.forEach((li, i) => {
            if (li.classList.contains("on")) onmenu = i;
        });

    var orgH = +$$("nav.pc .gnb").getAttribute("data-orgH");
    var leftPos = $$("nav.pc .gnb").getAttribute("data-leftPos");
    var gap = $$("nav.pc .gnb").getAttribute("data-gap");

    $$("nav.pc").style.height = orgH + 'px';
    $$("nav.pc .gnb").style.height = '';
    $$("nav.pc .gnb").style.left = leftPos;
    document.querySelectorAll("nav.pc .gnb>ul>li").forEach((el) => {
        var device = el.getAttribute("data-device");
        if(device === "mobile"){
            el.style.display = "none";
        }else{
            el.style.paddingRight = gap;
            if (el.querySelector('ul'))
                el.querySelector('ul').style.width = el.offsetWidth-20 + 'px';
            $$("nav.pc").style.visibility = "visible";
        }
        
        /* 서브메뉴 제어 */
        // $$(".lnb .wrap>div").on("mouseover",function(){
        //     $(".lnb .wrap ul").style.{display:"none"});
        //     el.children("ul").style.{display:"block"});
        // });
        // $(".lnb .wrap>div").on("mouseleave",function(){
        //     el.children("ul").style.{display:"none"});
        // });
    });

    "mouseover touch focusin".split(' ').forEach((event) => {
        $$("nav.pc").addEventListener(event, () => {//열림
            $$('nav.pc').style.height = GNB_HEIGHT;
            $$("nav.pc").style.boxShadow = "0 5px 10px rgba(0,0,0,.1)";
            return false;
        });
    });
    "mouseout focusout".split(' ').forEach((event) => {
        $$("nav.pc").addEventListener(event, () => {//닫힘
            $$('nav.pc').style.height = orgH + 'px';
            $$("nav.pc").style.boxShadow = '';
            return false;
        });
    });
    
    //패드, 탭 등 가로사이즈가 Pc모드를 충족하지만 1차 메뉴 선택 시 링크를 타면 하위 메뉴를 볼 수 없기 때문에 처리 함.
    if(device === "mobile"){
        $$("nav.pc .gnb>ul>li>a").href = "#void";
        document.body.addEventListener("touchmove",function(){
            $$("nav.pc").style.height = orgH + 'px';
        });
        $$("nav.pc .gnb>ul>li>a").addEventListener("click",function(){
            $$("nav.pc").style.height = GNB_HEIGHT;
        });
    }


    /* ********************* 모바일 ******************** */
    // pc용 dom을 복제해서 사용함.
    $$("nav.mobile").removeAttribute("style");
    $$("nav.mobile .gnb").removeAttribute("style");
    $$("nav.mobile .gnb>ul>li>a").href = "#void";

    var winH;
    var slideArrow = $$("nav.mobile").getAttribute("data-arrow");//왼쪽열림 or 오른쪽 열림
    var gnbState=false;//gnb 열림상태
    var gnbPos;//gnb 열림,닫힘 위치
    var closePos;//gnb 닫힐 때 이동위치
    var $dep1 = Array.from(document.querySelectorAll("nav.mobile .gnb>ul>li"));

    const onResize = function(){
        winH = window.innerHeight;
        if(slideArrow === "left"){//왼쪽 열림 방식
            closePos = $$("nav.mobile").offsetWidth;//닫힘위치
            $$("nav.mobile").style.left = -closePos + 'px';
        }
        if(slideArrow === "right"){//오른쪽 열림 방식
            closePos = document.body.offsetWidth;//닫힘위치
            $$("nav.mobile").style.left = closePos + 'px';
        }
        $$("nav.mobile .gnb").style.height = winH-$$("header .closeWrap").offsetHeight + 'px';

        // 열린상태이면...(가로,세로 모드로 돌렸을 때 대응)
        if(gnbState){
            $$(".gnbCover").style.display = 'none'; // TODO 애니메이션 처리
            closeFn();
        }else{
            if(slideArrow === "left") {
                $$("nav.mobile").style.visibility = "hidden";
                $$("nav.mobile").style.left = -closePos + 'px';
            }
            if(slideArrow === "right") {
                $$("nav.mobile").style.visibility = "hidden";
                $$("nav.mobile").style.left = closePos + 'px';
            }
        }
    };

    window.addEventListener("resize", onResize);
    onResize();

    function gnbActionFn(gnbPos,speed){
        $$("nav.mobile").style.transform = "translateX("+gnbPos+"px)";
        $$("nav.mobile").style.transitionDuration = speed/1000+"s";
        return false;
    };

    // gnb 열기
    function openFn(){
        if(!gnbState){
            gnbState = null;//버튼을 연타할 경우를 대비...
            document.documentElement.style.position = document.body.style.position = "fixed";
            $dep1.forEach(el => {
                el.classList.remove("on");
                if (el.querySelector("ul"))
                    el.querySelector("ul").style.display = "none";
            });
            if (onmenu >= 0) {
                $dep1[onmenu].classList.add("on")
                if ($dep1[onmenu].querySelector("ul"))
                    $dep1[onmenu].querySelector("ul").style.display = "block";
            }

            if(slideArrow === "left") gnbPos=closePos;
            if(slideArrow === "right"){
                $$("nav.mobile").style.left = document.body.offsetWidth;//여기서 위치를 바디 넓이로 잡는 것은, 크롬계열 브라우저에서는 스크롤바 넓이의 간섭이 생기기 때문.
                gnbPos=-($$("nav.mobile").offsetWidth);
            }
            $$("nav.mobile").style.visibility = "visible";
            gnbActionFn(gnbPos,speed);
          
            $$(".gnbCover").style.display = ''; // TODO 애니메이션 처리
            gnbState = true;
        };
        return false;
    }//end openFn

    // gnb 닫기
    function closeFn(){
        if(gnbState){
            gnbState = null;//버튼을 연타할 경우를 대비...
            document.documentElement.style.position = document.body.style.position = "static";

            gnbPos = 0;
            gnbActionFn(gnbPos,speed);
            $$(".gnbCover").style.display = 'none';

            $dep1.forEach(el => {
                el.querySelectorAll("a").forEach(a => {
                    a.style.backgroundColor = '';
                    a.style.color = "";
                });
            });
            gnbState = false;
        };
    };// end closeFn

    "click touchstart".split(" ").forEach(function (e) {
        $$(".gnbCover").addEventListener(e, closeFn);
        $$(".gnbClose").addEventListener(e, closeFn);
    });

    const elements = document.querySelectorAll(".menuLi");
    elements.forEach(element => {
        element.addEventListener("click", closeFn);
    })

    "click touchstart focusin".split(" ").forEach(function (e) {
        $$(".gnbView").addEventListener(e, openFn);
    });    

    // gnb 버튼제어
    $dep1.forEach((el, index) => {
        if (el.matches(".on")) {
            onmenu = index;
        }

        // 1단계 메뉴 항목(그룹 열고 닫기 항목)
        el.children[0].addEventListener('click', (e) => {
            e.preventDefault();

            // 열린 그룹 모두 닫기
            $dep1.forEach(el => {
                el.classList.remove('on');
                el.children[1].style.display = 'none';
            });

            // 클릭한 a의 상위 li 강조 표시하고 하위 메뉴 열기
            const a = e.target;
            a.parentElement.classList.add('on');
            a.nextElementSibling.style.display = 'block';
        });

        // el.querySelectorAll('a').forEach(a => {
        //     a.addEventListener("click", (e) => {
        //         if (e.target.parentElement.children.length > 1) {
        //             if(!e.target.parentElement.matches('.on')) {
        //                 // $dep1.removeClass("on").children("ul").slideUp(300);
        //                 $$(this).parent().addClass("on");
        //                 $$(this).next("ul").stop().slideDown(300);
        //             };
        //         }else{
        //             window.location.href = e.target.href;
        //         };
        //     });
        // });
    });
    
    // lnb 온 메뉴 글자 넣기
    // if($$(".lnb").length){
    //     var dep1 = $$(".lnb>.wrap>.dep1>ul>li.on>a")?.textContent;
    //     var dep2 = $$(".lnb>.wrap>.dep2>ul>li.on>a")?.textContent;
    //     var dep3 = $$(".lnb>.wrap>.dep2>ul>li.on .on a")?.textContent;
        
    //     $$(".lnb>.wrap>.dep1 .onmenu").textContent = dep1;
    //     $$(".lnb>.wrap>.dep2 .onmenu").textContent = dep2;
    //     $$(".lnb>.wrap>.dep3 .onmenu").textContent = dep3;
        
    //     if(document.querySelectorAll(".lnb .dep2 .on .dep3").length){
    //             $$(".lnb .dep2 .on .dep3").clone().appendTo($$(".lnb>.wrap>.dep3"));
    //             $$(".lnb .dep3 .onmenu").addClass("point");
    //     }else{
    //             $$(".lnb .dep3").style.display = "none";
    //             $$(".lnb .dep2 .onmenu").addClass("point");
    //     }
    // }

}
