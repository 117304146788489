import React, {useEffect, useState} from 'react';
import * as DataApi from "../../api/Data";
import * as UserAPI from "../../api/User";
import DatasetList from '../../components/DatasetListCompetition';
import SubNavNew from "../../components/SubNavNew";
import {getParamsFromQueryString, serialize} from '../../util/Url';
import Pagination from "../../components/Pagination";
import DatasetFilterNew from "../../components/DatasetFilterNewCompetition";
import {useSelector} from "react-redux";
import swal from "sweetalert";
import Modal from "react-modal";
import CheckBox from "../../components/CheckBox";
import {index as privacyPolicy} from "../../util/Terms/competition/CompetitionPrivacyPolicy";
import {index as securityPledge} from "../../util/Terms/competition/CompetitionSecurityPledge";
import {delCookie} from "../../util/Cookie";

let oldParam = {};

const ListDataset = (props) => {
    const userInfo = useSelector(state => state.user.info);
    const [modalIsOpen,setIsOpen] = useState(true);
    const [agreeA, setAgreeA] = useState(false);
    const [agreeB, setAgreeB] = useState(false);
    const [agreeALL, setAgreeALL] = useState(false);

    let [checkCompetition, setCheckCompetition] = useState(true);

    const isLogin = useSelector(state => state.user.isLogin);

    let competition_params = {
        'user_id': userInfo.id,
        'token': userInfo.id_key
    };

    let subNav = [
        {
            display: "활용 갤러리",
            name: "/use"
        },
        {
            display: "공모전",
            name: "/competition"
        }
    ];

    useEffect(() => {

        if(!isLogin) {
            swal({
                text: "로그인 후 이용 가능한 서비스입니다.",
                buttons: [
                    "확인",
                    "로그인"
                ]
            }).then(function (isConfirm) {
                if(isConfirm) {
                    delCookie("token");
                    window.location.href = "/#/login";
                } else {
                    swal.close();
                }
            })
        } else {
            UserAPI.checkCompetition(competition_params)
                .then(res => {
                    setCheckCompetition(res.result)
                });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    const getPage = () => {
        let qs = getParamsFromQueryString(props.history.location.search);
        let page = qs.hasOwnProperty("page") ? JSON.parse(JSON.stringify(Number(qs.page[0]))) : 1;
        return page;
    };

    let [dataList, setDataList] = useState([]);
    let [isLoaded, setIsLoaded] = useState(false);
    let [page] = useState(getPage());
    let [maxPage, setMaxPage] = useState(20);
    let [tags, setTags] = useState([]);
    let [formats, setFormats] = useState([]);
    let [category, setCategory] = useState([]);
    let [paytag, setPayTags] = useState([]);
    let [totalCount, setTotalCount] = useState(0);
    let [limit] = useState(10);

    const searchData = (params = false, page = 1) => {
        let p = {};
        if(params){
            p = params;
            oldParam = p;
        }else{
            p = oldParam;
        }
        p.page = getPage() -1;
        p.limit = limit;
        if(!isLogin) {
            swal("로그인 후 이용 가능한 서비스입니다.")
            window.location.href = "/#/login"
        } else {
            DataApi.readSearchDataByCompetition(p).then(res => {
                setTotalCount(res.result.count);
                setFormats(res.result.search_facets.res_format.items);
                setTags(res.result.search_facets.tags.items);
                setCategory(res.result.search_facets.DataSet_DataSet_theme.items);
                setPayTags(res.result.search_facets.DataService_DataService_priceInfo.items);
                setDataList(res.result.results);
                // setPage(p.page + 1);
            });
            console.log(p);
        }
    };

    useEffect(() => {
        setMaxPage(Math.ceil(totalCount / limit));
    }, [limit, totalCount]);

    useEffect(() => {
        // searchData(false, page);
    }, [page]);

    const onPageMove = (page) => {

        let qs = getParamsFromQueryString(props.history.location.search);
        qs.page = [String(page)];

        props.history.push({
            pathname: "/competition",
            search: serialize(qs)
        });

        // setPage(page);
    };

    const handleFilterChange = (params) => {
        if((oldParam.hasOwnProperty("tag") ? oldParam.tag.length : 0) +
            (oldParam.hasOwnProperty("category") ? oldParam.category.length : 0) +
            (oldParam.hasOwnProperty("word") ? oldParam.word.length : 0) +
            (oldParam.hasOwnProperty("format") ? oldParam.format.length : 0) +
            (oldParam.hasOwnProperty("paytag") ? oldParam.paytag.length : 0)
            !==
            (params.hasOwnProperty("tag") ? params.tag.length : 0) +
            (params.hasOwnProperty("category") ? params.category.length : 0) +
            (params.hasOwnProperty("word") ? params.word.length : 0) +
            (params.hasOwnProperty("format") ? params.format.length : 0) +
            (params.hasOwnProperty("paytag") ? params.paytag.length : 0)){
            params.page = String(1);
        }else params.page = String(getPage());


        if(isLoaded){
            props.history.push({
                pathname: "/competition",
                search: serialize(params)
            });
        }
        else {
            props.history.replace({
                pathname: "/competition",
                search: serialize(params)
            });
            setIsLoaded(true);
        }

        oldParam = JSON.parse(JSON.stringify(params));


        searchData(params);
    };

    const closeModal = () => {
        setIsOpen(false);
        window.location.href = "/#/"
    };

    const handleAgreeChanged = (bool, type) => {
        if (type === "ALL") {
            setAgreeA(bool);
            setAgreeB(bool);
            setAgreeALL(bool);
        }
        else if (type === "A") {
            setAgreeA(bool);
            if (agreeALL) {
                setAgreeALL(false)
            }
        }
        else if (type === "B") {
            setAgreeB(bool);
            if (agreeALL) {
                setAgreeALL(false)
            }
        }
    };

    const isValidatePolicyCheck = () => {
        let validateArr = [agreeA, agreeB];

        return validateArr.indexOf(false) < 0;
    };

    const createCompetitionUser = () => {
        let params = {
            'user_uuid': userInfo.id_key,
            'user_id': userInfo.id,
            'email': userInfo.email,
            'phone': userInfo.phone,
            'privacy_policy': agreeA,
            'security_pledge': agreeB
        }
        UserAPI.createCompetitionUser(params)
            .then( res => {
                setIsOpen(false);
                setCheckCompetition(true);
            })
    }

    const popUpAgreeCompetition = () => {
        const customStyles = {
            overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                zIndex: 1060
            },
            content : {
                top                   : '50%',
                left                  : '50%',
                right                 : 'auto',
                bottom                : 'auto',
                marginRight           : '-50%',
                transform             : 'translate(-50%, -50%)',
                width:  '50%'
            }
        };

        return (
            <Modal
                isOpen={modalIsOpen}
                // onAfterOpen={afterOpenModal}
                aria={{
                    labelledby: "heading",
                    describedby: "full_description"
                }}
                style={customStyles}
                onRequestClose={closeModal}
                contentLabel="Inline Styles Modal Example"
            >

                <strong> [경기지역경제포털 빅데이터 활용 시각화 아이디어 공모전] 개인정보 수집･이용 동의서 </strong>
                <div className={"privacyPolicyWrap"} style={{"height": "300px"}} dangerouslySetInnerHTML={{__html: privacyPolicy.replace(/\n/g, '<br/>')}} />
                <CheckBox onChange={bool => handleAgreeChanged(bool, 'A')} checked={agreeA} id={"agreeA"}
                          label={
                              <>
                                  <div style={{"display": "inline-block", "color": "red"}}>
                                      (필수)
                                  </div>
                                  <div style={{"display": "inline-block", "margin-left": "3px"}}>
                                      동의합니다.
                                  </div>
                                  {/*<i onClick={() => terms("joinPolicy")} >이용약관</i>&nbsp;및&nbsp;*/}
                                  {/*<i onClick={() => terms("joinPolicy")} style={{padding: 0}}>개인정보처리방침</i> 동의*/}
                              </>
                          }/>

                <div style={{"margin-top": "30px"}}/>

                <strong> [2022 지역경제 빅데이터 활용 시각화 아이디어 공모전] 참가 서약서 </strong>
                <div className={"privacyPolicyWrap"} style={{"height": "250px"}} dangerouslySetInnerHTML={{__html: securityPledge.replace(/\n/g, '<br/>')}} />
                <CheckBox onChange={bool => handleAgreeChanged(bool, 'B')} checked={agreeB} id={"agreeB"}
                          label={
                              <>
                                  <div style={{"display": "inline-block", "color": "red"}}>
                                      (필수)
                                  </div>
                                  <div style={{"display": "inline-block", "margin-left": "3px"}}>
                                      동의합니다.
                                  </div>
                                  {/*<i onClick={() => terms("joinPolicy")} >이용약관</i>&nbsp;및&nbsp;*/}
                                  {/*<i onClick={() => terms("joinPolicy")} style={{padding: 0}}>개인정보처리방침</i> 동의*/}
                              </>
                          }/>
                <div style={{"margin-top": "30px"}}/>

                <CheckBox onChange={bool => handleAgreeChanged(bool, 'ALL')} checked={agreeALL} id={"agreeALL"}
                          label={
                              <>
                                  <div style={{"display": "inline-block", "margin-left": "3px"}}>
                                      모두 동의합니다.
                                  </div>
                                  {/*<i onClick={() => terms("joinPolicy")} >이용약관</i>&nbsp;및&nbsp;*/}
                                  {/*<i onClick={() => terms("joinPolicy")} style={{padding: 0}}>개인정보처리방침</i> 동의*/}
                              </>
                          }/>

                <div style={{"float": "right"}}>
                  <button className={"agree-button"} style={{"width": "20%"}} disabled={!isValidatePolicyCheck()} onClick={() => createCompetitionUser()}> 신청 </button>
                  <button className={"cancel-button"} style={{"width": "20%", "margin-left": "10px"}} onClick={() => closeModal()}> 취소 </button>
                </div>

            </Modal>
        )
    }
    return (
        <>
            {
                !checkCompetition ?
                    popUpAgreeCompetition()
                    :
                    <>
                        <div className="renewal">
                            <SubNavNew nav={subNav} history={props.history} />
                        </div>                    
                        <div style={{"width": "1180px", "margin": "0 auto", "margin-top": "30px", "text-align": "center"}}>
                            <span style={{"color": "red"}}>데이터가 최신화 되었습니다. 이용에 참고해주시길 바랍니다.</span>
                        </div>
                        <DatasetFilterNew page={page} tags={tags} formats={formats} totalCount={totalCount} category={category} paytag={paytag} onChange={(params) => handleFilterChange(params)} history={props.history}/>
                        <DatasetList list={dataList} totalCount={totalCount}/>
                            <Pagination page={getPage()}
                            maxPage={maxPage}
                            padding={2}
                            onPageMove={num => onPageMove(num)}
                            />
                    </>

            }

        </>
    )

};

export default ListDataset;
