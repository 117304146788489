import React, {useEffect, useState} from 'react';
import * as DataApi from "../../api/Data";
import DatasetList from './DatasetList';
import {getParameterByName, getParamsFromQueryString, serialize} from '../../util/Url';
import Pagination from "./Pagination";
import DatasetFilterNew from "./DatasetFilterNew";
import {useDispatch, useSelector} from "react-redux";
import TourMessage from "../common/tourMessage";
import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";
import Tour from "reactour";
import {setTourBtn} from "../../store/actions/UserAction";
import SubNavNew from "../../components/SubNavNew";
import NewHeader from '../common/NewHeader';
import NewFooter from '../common/NewFooter';
import OptData from "../../pages/common/OptionData.json";

let oldParam = {};
const MixListDataset = (props) => {
    let subNav = [
        {
            display: '데이터셋',
            name: '/new-dataset'
        },
        {
            display: '융합 데이터',
            name: '/new-datasetMix'
        },
        {
            display: '통합 데이터 검색',
            name: '/integratedDataset'
        }
    ];

    const getPage = () => {
        let qs = getParamsFromQueryString(props.history.location.search);
        let page = qs.hasOwnProperty("page") ? JSON.parse(JSON.stringify(Number(qs.page[0]))) : 1;
        return page;
    };
    //let center = OptData.search_detail.center_code;
    let dispatch = useDispatch()

    let [dataList, setDataList] = useState([]);
    let [isLoaded, setIsLoaded] = useState(false);
    let [page, setPage] = useState(getPage());
    let [maxPage, setMaxPage] = useState(20);
    let [tags, setTags] = useState([]);
    let [formats, setFormats] = useState([]);
    let [category, setCategory] = useState([]);
    let [paytag, setPayTags] = useState([]);
    let [filters, setFilters] = useState([]);
    let [totalCount, setTotalCount] = useState(0);
    let [limit] = useState(10);
    let [centers, setCenters] = useState([]);

    // 투어
    // const isTourOpen = useSelector(state => state.user.isTourOpen)
    // const datasetTourMsg = new TourMessage.DataSetListPageTourMessage()
    // let disableBody = target => disableBodyScroll(target)
    // let enableBody = target => enableBodyScroll(target)

    const CATEGORY_LIST = ['지역현황', '지역트랜드', '지역화폐', '기업', '기업신용보증', '소상공인', '취업', '영상', '친환경충전인프라'];

    const searchData = (params = false, page = 1) => {
        let p = {};
        if(params){
            p = params;
            oldParam = p;
        }else{
            p = oldParam;
        }
        p.page = getPage() -1;
        p.limit = limit;
        p.mixData = true;

        DataApi.readSearchData(p).then(res => {
            const CENTERS = OptData.search_detail.center_code;
            res.result.search_facets.Ikan_Extra_centerCode.items.sort((a, b) => CENTERS.findIndex(c => c.value === a.name) - CENTERS.findIndex(c => c.value === b.name));
            res.result.search_facets.DataSet_DataSet_theme.items.sort((a, b) => CATEGORY_LIST.indexOf(a.name) - CATEGORY_LIST.indexOf(b.name));

            setTotalCount(res.result.count);
            setFormats(res.result.search_facets.res_format.items);
            setTags(res.result.search_facets.tags.items);
            setCenters(res.result.search_facets.Ikan_Extra_centerCode.items);
            setCategory(res.result.search_facets.DataSet_DataSet_theme.items);
            setPayTags(res.result.search_facets.DataService_DataService_priceInfo.items);
            setDataList(res.result.results);
            // setPage(p.page + 1);
        });
    };

    useEffect(() => {
        setMaxPage(Math.ceil(totalCount / limit));
    }, [limit, totalCount]);

    useEffect(() => {
        // searchData(false, page);
    }, [page]);

    const onPageMove = (page, e) => {
        e.preventDefault();        

        let qs = getParamsFromQueryString(props.history.location.search);
        qs.page = [String(page)];

        props.history.push({
            pathname: "/new-datasetMix",
            search: serialize(qs)
        });

        // setPage(page);
    };

    const handleFilterChange = (params) => {

        if((oldParam.hasOwnProperty("tag") ? oldParam.tag.length : 0) +
            (oldParam.hasOwnProperty("category") ? oldParam.category.length : 0) +
            (oldParam.hasOwnProperty("word") ? oldParam.word.length : 0) +
            (oldParam.hasOwnProperty("format") ? oldParam.format.length : 0) +
            (oldParam.hasOwnProperty("paytag") ? oldParam.paytag.length : 0)
            !=
            (params.hasOwnProperty("tag") ? params.tag.length : 0) +
            (params.hasOwnProperty("category") ? params.category.length : 0) +
            (params.hasOwnProperty("word") ? params.word.length : 0) +
            (params.hasOwnProperty("format") ? params.format.length : 0) +
            (params.hasOwnProperty("paytag") ? params.paytag.length : 0)){
            params.page = String(1);
        }else params.page = String(getPage());


        if(isLoaded){
            props.history.push({
                pathname: "/new-datasetMix",
                search: serialize(params)
            });
        }
        else {
            props.history.replace({
                pathname: "/new-datasetMix",
                search: serialize(params)
            });
            setIsLoaded(true);
        }

        oldParam = JSON.parse(JSON.stringify(params));        

        
        searchData(params);
    };

    const closeTour = () => {
        dispatch(setTourBtn(false))
    }

    return (
        <>
            <div className="renewal">

                <SubNavNew nav={subNav} history={props.history}/>

                <section className="contents 데이터셋">
                    <div className="contents-wrap w1280 datasetWrap">

                        <DatasetFilterNew page={page} tags={tags} formats={formats} totalCount={totalCount} category={category} paytag={paytag} centers={centers} onChange={(params) => handleFilterChange(params)} history={props.history}/>

                        <DatasetList list={dataList} totalCount={totalCount}/>

                        <Pagination page={getPage()}
                                    maxPage={maxPage}
                                    padding={2}
                                    onPageMove={onPageMove}
                        />

                    </div>
                </section>


            </div>
        </>        
    )

};

export default MixListDataset;
