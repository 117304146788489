import React, {useState} from 'react';
import * as UserApi from "../../api/User";
import LoginImg from "../../assets/images/img_bg_signin.png";
import swal from "sweetalert";
import {Link} from "react-router-dom";
import logo_ict_grey from "../../assets/images/logo_ict_default@2x.png";
import logo_nia_grey from "../../assets/images/logo_nia_default@2x.png";
import logo_gg_grey from "../../assets/images/logo_gg_default@2x.png";

const ForgotId = (props) => {
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");

    const getMail = () => {
        const auth_params = {
            'email': email,
            'phone': phone
        }
        UserApi.readAuthPhoneAndEmail(auth_params).then(res => {
            swal(email + '로 메일을 발송하였습니다. \n 이메일을 확인해주세요.')
                .catch(res => {
                    if(res.status === 500){
                        swal("서버 에러. 잠시 후 다시 시도해주세요." );
                    }else{
                        swal('입력하신 정보가 존재하지 않습니다.')
                    }
                })

        }).catch(res => {
            swal('입력하신 정보가 존재하지 않습니다.')
        })

    };

    const pStyle = {
        textAlign:"right",
        width:"330px",
        height:"20px",
        color:"red",
        fontSize:"10px",
    }

    return (
        <>
            <div className={"login-wrap real-login"}>
                <div className={"img-wrap"}>
                    <img src={LoginImg} alt={"background-image"}/>
                    <div className={"WhatWeDo-banner"}>
                        <img src={logo_ict_grey} alt={"과학기술정보통신부"}/>
                        <img src={logo_nia_grey} alt={"한국지능정보사회진흥원"}/>
                        <img src={logo_gg_grey} alt={"세계속의 경기도"}/>
                    </div>
                </div>
                <div className={"login-form"}>
                    <div className={"form-wrap"}>
                        <h1>
                            <Link to="/">
                                <strong>경기지역</strong>
                                <strong>경제포털</strong>
                            </Link>
                        </h1>
                        <p style={pStyle}>※전화번호 입력시 하이픈('-')을 같이 입력해 주세요.</p>
                        <input 
                            type={"text"} 
                            placeholder={"Phone"} 
                            title={"Phone"}
                            value={phone} 
                            onChange={e => setPhone(e.target.value)}
                        />
                        <input 
                            type={"text"} 
                            placeholder={"EMAIL"} 
                            title={"EMAIL"}
                            value={email} 
                            onChange={e => setEmail(e.target.value)}
                        />
                        <button className={"button"} onClick={() => getMail()}> 메일받기</button>
                        <div className={"login-text"}>
                            <a onClick={() => props.history.push('login')}>뒤로가기</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
export default ForgotId;
