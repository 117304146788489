import React, {useState} from "react";
import Froala from "../../components/Froala";
import {useDispatch, useSelector} from "react-redux";
import ErrorPage404 from "../common/ErrorPage404";
import * as IboardApi from "../../api/Board";
import {getCookie} from "../../util/Cookie";
import swal from "sweetalert";
import SelectSearch from 'react-select-search';
import * as UserApi from "../../api/User";
import SubNav from "../../components/SubNav";
import * as DOMPurify from 'dompurify';

const Write = ({match, ...props}) => {

    const dispatch = useDispatch();

    let [boardInfo, setBoardInfo] = useState({});
    let [isInit, setIsInit] = useState(true);
    let [content, setContent] = useState('');
    let [title, setTitle] = useState("");

    let [isLoading, setIsLoading] = useState(false);

    let [writeType, setWriteType] = useState(null);

    // 접수완료(receipt), 처리중(process), 답변완료(completed)
    let [writeProcess, setWriteProcess] = useState("receipt");

    const userInfo = useSelector(state => state.user.info);

    const typeOptions = [
        {
            name: "이용문의",
            value: "use"
        },
        {
            name: "오류내용",
            value: "error"
        },
        {
            name: "개선요청",
            value: "improvement"
        }
    ];

    let subNav = [
        {
            display: '통합 검색',
            name: '/board/all'
        },
        {
            display: '공지사항',
            name: '/board/board_14ab0a7f-d410-48d7-91ca-e264d5121794'
        },
        {
            display: '묻고 답하기',
            name: '/board/board_942f2cfa-3294-42c5-ac68-b4f3abbed510'
        },
        {
            display: '자주하는 질문',
            name: '/board/board_a2eb004d-73ca-4014-a9d2-c402675cf83f'
        },
        {
            display: '데이터 문의',
            name: '/board/board_87b9692c-8959-4b3a-83c5-28e209ceca1f'
        }
    ];

    useSelector(state => {
        if(JSON.stringify(state.board.boardInfo) !== JSON.stringify(boardInfo)){
            setBoardInfo(state.board.boardInfo);
        }
    });

    let board = match.params.board;
    let info = boardInfo[board];


    if (info === undefined) return <ErrorPage404/>;
    // 0122 경기도 보안 임시조치 - 공지사항, 자주하는 질문 : 주소 입력(~/write)을 통한 진입 금지
    // 공지사항
    if (board === 'board_14ab0a7f-d410-48d7-91ca-e264d5121794') return <ErrorPage404/>;
    // 자주하는 질문
    if (board === 'board_a2eb004d-73ca-4014-a9d2-c402675cf83f') return <ErrorPage404/>; 

    if(isInit){
        setIsInit(false);

        // 수정일 경우
        let articleId = match.params.articleId;
        if(articleId !== undefined){
            setIsLoading(true);
            let params = {
                text_id: articleId,
            };
            IboardApi.readText(params).then(res => {
                let rst = res.result;

                if(rst.user_id === userInfo.id_key){
                    setContent(stringClean(rst.text));
                    setTitle(stringClean(rst.subject));
                    setWriteType(rst.text_type);
                    setWriteProcess(rst.text_process);
                    setIsLoading(false);
                }else{
                    swal("권한이 없습니다.")
                    return props.history.push("/board/" + board);
                }
            })
        }
    }

    const onWrite = () => {
        if(title === ""){
            swal("제목을 입력해 주세요.");
            return;
        }

        if(content === ""){
            swal("내용을 입력해 주세요.");
            return;
        }

        if (board === "board_942f2cfa-3294-42c5-ac68-b4f3abbed510" && writeType === null) {
            swal("글종류를 선택해주세요.");
            return ;
        }

        const cleanTitle = stringClean(title);
        const cleanContent = stringClean(content);

        let articleId = match.params.articleId;
        let params = {
            board_id: board,
            subject: cleanTitle,
            text: cleanContent,
            user_id: userInfo.id_key,
            text_type: writeType,
            text_process: writeProcess
        };

        if(articleId !== undefined) {
            params.article_id = articleId;
            IboardApi.updateText(params).then(res => {
                props.history.push("/board/" + board);
            })
        }else{
            IboardApi.createText(params).then(res => {
                let point_params = {
                    portal_user_id_key: userInfo.id_key,
                    portal_user_id: userInfo.id,
                    action_type: "add_article",
                    point: "10",
                }
                UserApi.savePoint(point_params).then(res => {

                })
                props.history.push("/board/" + board);
            })
        }

    };

    const stringClean = (content) => {
        const str = DOMPurify.sanitize(content);
        return str;
    } 

    return (
        <>
            <SubNav nav={subNav} history={props.history} >

            <div className={"wrap-write"}>
                <div className={"write-title"}>{info.name}</div>
                {
                    board === "board_942f2cfa-3294-42c5-ac68-b4f3abbed510" ?
                        <SelectSearch
                            options={typeOptions}
                            onChange={option => {setWriteType(option)}}
                            value={writeType}
                            placeholder={"글종류를 선택하세요."}
                        />
                        :
                        null
                }
                <input type="text" value={title} onChange={e => setTitle(e.target.value)} placeholder="제목을 입력하세요"
                style={{"marginTop": "30px"}}/>
                <div className="wtiter">
                    <Froala onChange={content => setContent(content)}
                            content={content}
                    />
                </div>
                <div className={"btn-group"}>
                    <div className={"ikan-button-wrap ikan-button-full"}>
                        <button onClick={() => onWrite()}>글쓰기</button>
                    </div>
                </div>
            </div>
            </SubNav>    
        </>
    )
};

export default Write;
