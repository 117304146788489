import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ history }) {
    useEffect(() => {
        const unlisten = history.listen(() => {
            let root = document.getElementById("root")
            root.scrollTop = 0;
        });
        return () => {
            unlisten();
        }
    }, [history]);

    return (null);
}

export default withRouter(ScrollToTop);