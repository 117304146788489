import React, {useState} from 'react';
import Option from "./components/Option";

const SelectBox = ({onChange, options, value, placeholder}) => {

    let [isOpen, setIsOpen] = useState(false);

    let selected = options.find(option => {

        return option.name === value;
    });

    if (selected === undefined)
        selected = {
            display: placeholder
        };

    return (
        <ul>
            <li className={"icon-home"}></li>
            <li className={"select-txt"} onClick={e => setIsOpen(!isOpen)}>
                <div>
                    <span>
                        {selected.display}
                    </span>
                </div>
                <div>
                    <button>
                        DOWN
                    </button>
                </div>
            </li>

            {isOpen && <div className={"select-list"}>
                <ul>
                    {options.map(option => value !== option.name &&
                        <Option key={option.name} option={option} onChange={option => {
                            onChange(option);
                            setIsOpen(!isOpen);
                        }}/>)}
                </ul>
            </div>}

        </ul>
    )
};

SelectBox.defaultProps = {
    onChange: () => {
    },
    options: [],
    value: "",
    placeholder: "선택하세요"
};

export default SelectBox;
