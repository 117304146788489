import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import arrowIcon from '../../assets/images/icon_up.png';
import hamIcon from '../../assets/images/white-menu-icon.jpg'
import { setTourBtn } from "../../store/actions/UserAction";
import { withRouter } from 'react-router-dom';

const FloatingButton = ({history}) => {
    let dispatch = useDispatch()
    let [isShowTourBtn, setIsShowTourBtn] = useState(false)
    let [isShowFab, setIsShowFab] = useState(false)
    const renderPageList = ['/', '/dataset', '/datasetMix']
    // const renderPageList = ['/', '/dataset', '/datasetMix', '/use',
    //     '/board/board_942f2cfa-3294-42c5-ac68-b4f3abbed510', '/board/board_87b9692c-8959-4b3a-83c5-28e209ceca1f', '/board/all',
    //     "/board/board_14ab0a7f-d410-48d7-91ca-e264d5121794", "/board/board_a2eb004d-73ca-4014-a9d2-c402675cf83f"]
    const ignoreRenderPageList = ['/join', '/login', '/forgot_password']

    // fab render
    useEffect(() => {
        if (ignoreRenderPageList.find(element => element === history.location.pathname)) {
            setIsShowFab(false)
        }
        else {
            setIsShowFab(true)
        }

        const unlisten = history.listen(() => {
            if (ignoreRenderPageList.find(element => element === history.location.pathname)) {
                setIsShowFab(false)
            }
            else {
                setIsShowFab(true)
            }
        });
        return () => {
            unlisten();
        }
    }, []);

    // tour btn render
    useEffect(() => {
        if (renderPageList.find(element => element === history.location.pathname)) {
            setIsShowTourBtn(true)
        } else if (history.location.pathname.split("/")[1] === "dataset") {
            setIsShowTourBtn(true)
        } else if(history.location.pathname.split("/")[1] === "datasetMix") {
            setIsShowTourBtn(true)
        }
        else {
            setIsShowTourBtn(false)
        }

        const unlisten = history.listen(() => {
            if (renderPageList.find(element => element === history.location.pathname)) {
                setIsShowTourBtn(true)
            } else if (history.location.pathname.split("/")[1] === "dataset") {
                setIsShowTourBtn(true)
            } else if(history.location.pathname.split("/")[1] === "datasetMix") {
                setIsShowTourBtn(true)
            }
            else {
                setIsShowTourBtn(false)
            }
        });
        return () => {
            unlisten();
        }
    }, []);


    const toTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    const handleClick = () => {
        dispatch(setTourBtn(true))
    }
    return (
        <>
        {isShowFab === true &&
            <Fab
                event={'hover'}
                mainButtonStyles={{width:"46px",height:"46px",backgroundColor: "#004c9d"}}
                icon={<img width={"26px"} height={"26px"} src={hamIcon} alt={"ham_ico"}/>}
            >
                {isShowTourBtn === true &&
                <Action
                    text="이용 안내"
                    style={{width:"36px",height:"36px",backgroundColor: "#004c9d"}}
                    onClick={ () => handleClick()}
                >
                    ?
                </Action>
                }
                <Action
                    text="맨 위로"
                    style={{width:"36px",height:"36px",backgroundColor: "#004c9d"}}
                    onClick={ () => toTop()}
                ><img width={"16px"} height={"16px"} src={arrowIcon} alt={"top"}/></Action>
            </Fab>
        }

        </>
    );
}

export default withRouter(FloatingButton);
