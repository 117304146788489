import {index as privacyPolicy} from "./privacyPolicy";
import {index as termsAndConditions} from "./termsAndConditions";
import {index as OrderPolicy} from "./orderPolicy"
import {index as JoinPolicy} from "./JoinPolicy"

export const terms = (term) => {
    let body = document.body;
    let element = document.createElement('div');
    element.classList.add("terms");
    let wrap = document.createElement('div');
    wrap.classList.add("ds-terms-wrap");
    let inner = document.createElement('div');
    inner.classList.add("ds-terms-inner");
    let txtWrap = document.createElement('div');
    txtWrap.classList.add("ds-terms-txt-wrap");
    let btn = document.createElement('button');
    btn.classList.add("ds-terms-button-out");
    btn.onclick = () => {
        body.removeChild(element)
    };
    let txtInner = document.createElement('div');
    txtInner.classList.add("ds-terms-txt-inner");
    let infographic=document.createElement('a');
    infographic.textContent="[인포그래픽 바로가기]";
    infographic.href="/#/Infographic";
    infographic.classList.add("ds-terms-infographic")
    let txt = document.createElement('pre');
    txt.classList.add("ds-terms-txt");

    const policy=()=>{
        let privacyPolicyString= privacyPolicy.replace(/\n/g, '<br/>');
        // privacyPolicyString= privacyPolicyString.replace(`PRIVACY_POLICY_TABLE`,
        //     '<table border="2" > <th>구분</th><th>수집항목</th><th>필수여부</th>' +
        //     '<tr><td rowspan="2">회원가입 및 관리</td><td>성명, 아이디, 비밀번호, 휴대전화번호, 이메일주소</td><td>필수</td></tr>' +
        //     '<tr><td>소속기관/부서명, 직장 전화번호</td><td>선택</td></tr>' +
        //     '<tr><td>서비스 계약 및 거래</td><td>성명, 아이디, 휴대전화번호, 이메일주소, 신용카드번호, 은행계좌정보 등 결제 정보</td><td>필수</td></tr>' +
        //     '<tr><td>영업 및 마케팅</td><td>성명, 아이디, 휴대전화번호, 이메일주소</td><td>선택</td></tr>' +
        //     '<tr><td rowspan="2">민원 처리</td><td>성명, 아이디, 이메일주소</td><td>필수</td></tr>' +
        //     '<tr><td>휴대전화번호</td><td>선택</td></tr>' +
        //     '</table>')
        privacyPolicyString = privacyPolicyString.replace(`TABLE_BABYLAB`,
            '<table > ' +
            '<th>제공받는 자</th><th>제공 목적</th><th>제공하는 항목</th><th>보유 및 이용 기간</th>' +
            '<tr><td>㈜베이비랩</td><td>마케팅</td><td>이름, 이메일, 전화번호</td><td>서비스 해지 후 파기</td></tr>' +
            '<tr><td>한국신용데이터</td><td>마케팅</td><td>이름, 이메일, 전화번호</td><td>서비스 해지 후 파기</td></tr>' +
            '</table>')        

        // privacyPolicyString = privacyPolicyString.replace('(시행일) 본 방침은 2021년 11월 17일부터 시행합니다.',
        //     `<Link to={"/Policy"}>(시행일) 본 방침은 2021년 11월 17일부터 시행합니다.</Link>`
        // )
        return privacyPolicyString.replace(/\n/g, '<br/>').replace(`INFORMPRIVACY_TABLE`,
        `<table style="width:100%;">
            <colgroup>
                <col width="25%"/>
                <col width="50%"/>
                <col width="25%"/>
            </colgroup>            
            <tr style="text-align:center;">
                <th>위탁업체</th>
                <th>위탁업무</th>
                <th>위탁기간</th>
            </tr>
            <tr>
                <td>경기경제과학진흥원 한국평가데이터 선도소프트</td>
                <td style="vertical-align:middle;">경기지역경제포털 플랫폼 구축 및 데이터 협업체계 강화</td>
                <td style="vertical-align:middle;">2022.05.18.~계약 종료 시</td>
            </tr>
        </table>`)
        
    }

    if(term === "privacyPolicy"){
        txt.innerHTML = policy();   
    }else if(term === "termsAndConditions"){
        txt.innerText = termsAndConditions;
    }
    else if (term === "orderPolicy") {
        txt.innerText = OrderPolicy;
    }

    else if (term === "joinPolicy") {
        txt.innerText = JoinPolicy;
    }

    body.appendChild(element);
    element.appendChild(wrap);
    wrap.appendChild(inner);
    inner.appendChild(txtWrap);
    inner.appendChild(btn);
    txtWrap.appendChild(txtInner);
    txtInner.appendChild(infographic);
    txtInner.appendChild(txt);
};
