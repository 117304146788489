import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import ErrorPage404 from "../common/ErrorPage404";
import * as IboardApi from "../../api/Board";
import Comment from "./Comment";
import Button from "../../components/Button";
import SubNav from "../../components/SubNav";
import * as DOMPurify from 'dompurify';
import 'froala-editor/css/froala_style.min.css';
import Skeleton from "react-loading-skeleton";
import {yyyymmdd} from "../../util/Date";
import swal from "sweetalert";

const Article = ({match, ...props}) => {
    // const sanitizeHtml = require('sanitize-html');
    const dispatch = useDispatch();

    const [boardInfo, setBoardInfo] = useState({});
    const [isInit, setIsInit] = useState(true);
    const [content, setContent] = useState("");
    const [subject, setSubject] = useState("");
    const [userId, setUserId] = useState("");
    const [date, setDate] = useState(new Date());
    const [username, setUsername] = useState("");
    const [hits, setHits] = useState(0);
    const [originalFileName, setOriginalFileName] = useState("");
    const [downloadUrl, setDownloadUrl] = useState("");
    const [showLoading, setShowLoading] = useState(true);

    const userInfo = useSelector(state => state.user.info);

    useSelector(state => {
        if(JSON.stringify(state.board.boardInfo) !== JSON.stringify(boardInfo)){
            setBoardInfo(state.board.boardInfo);
        }
    });

    let board = match.params.board;
    let info = boardInfo[board];
    let articleId = match.params.articleId;

    const subNav = [
        {
            display: '통합 검색',
            name: '/board/all'
        },
        {
            display: '공지사항',
            name: '/board/board_14ab0a7f-d410-48d7-91ca-e264d5121794'
        },
        {
            display: '묻고 답하기',
            name: '/board/board_942f2cfa-3294-42c5-ac68-b4f3abbed510'
        },
        {
            display: '자주하는 질문',
            name: '/board/board_a2eb004d-73ca-4014-a9d2-c402675cf83f'
        },
        {
            display: '데이터 문의',
            name: '/board/board_87b9692c-8959-4b3a-83c5-28e209ceca1f'
        },
        {
            display: '개인정보 현황관리',
            name: '/board/board_bd79845f-53f1-4cb9-a394-ea01d3fd0c9f'
        }    
    ];

    useEffect(() => {
        setShowLoading(true);
        setContent("");
        const params = {
            text_id: articleId
        };
        IboardApi.readText(params).then(res => {
            const rst = res.result;
            setUserId(res.result.user_id);
            setContent(stringClean(rst.text));
            setSubject(stringClean(rst.subject));
            setUsername(rst.user_name);
            setHits(rst.read_count);
            setDate(new Date(rst.cdate.replace(" ", "T")));
            setOriginalFileName(rst.original_file_name);
            setDownloadUrl(rst.download_url);
            setShowLoading(false);
        })
    }, [articleId]);

    if(info === undefined) return <ErrorPage404/>;

    if(isInit){
        setIsInit(false);
    }

    const removeArticle = () => {            
        const params = {
            text_id: articleId,
            user_id: userInfo.id_key
        };

        IboardApi.deleteText(params).then(res => {
            props.history.push("/board/" + board);
        }).catch(res => {
            swal("삭제할 수 없습니다.")
        });
    };

    const fileDownload = () => {
        const download_url = downloadUrl + "?fn=" + originalFileName;
        window.open(download_url);
    };

    const stringClean = (content) => {
        const str = DOMPurify.sanitize(content);
        return str;
    }

    return (
        <>
            <SubNav nav={subNav} history={props.history}>    
            <div className={"list-view"}>
                {showLoading ? 
                    <>
                        <h1><Skeleton/></h1>
                        <Skeleton count={10}/>
                    </>
                    :
                    <>
                        <div className={"list-view-title"}>
                            {stringClean(subject)}
                        </div>
                        <div className={"article-date"}>
                            <span>작성자:{
                                userInfo.role === "superadmin" || username==="관리자"?
                                    username
                                    :username.substring(0,3)+"*****"
                            }
                            </span>
                            <span className={"dash"}>|</span>
                            <span>조회수: {hits}</span>
                            <span className={"dash"}>|</span>
                            <span title={date.toLocaleString()}>작성일: {yyyymmdd(date)}</span>
                        </div>
                        {originalFileName ?
                            <div className={"file-download"}>
                                <div className={"fr-view"}>
                                    <span>첨부파일</span>
                                    <p style={{backgroundColor:"white"}}></p>
                                    <p style={{backgroundColor:"white"}}></p>
                                    <p style={{cursor: 'pointer'}} onClick={() => fileDownload()}>{originalFileName}</p>{/*div->p div는 많이 쓰일 수 있어서 css적용을 위해 바꿈*/}
                                </div>
                            </div> 
                        : 
                            null
                        }
                        <div className={"fr-view"} dangerouslySetInnerHTML={{__html: stringClean(content)}}/>
                    </>
                }
            </div>
            <Comment textId={articleId} info={info} isA={(board === "board_942f2cfa-3294-42c5-ac68-b4f3abbed510")}/>

            <div className={"list-view fr-view"}>

            </div>

            <div className={"view-btn-wrap"}>

                <Button type={"line"} onClick={() => props.history.go(-1)}>목록</Button>

                {info.write && userId === userInfo.id_key &&
                    <Button type={"line"}
                        onClick={() => props.history.push("/board/" + board + "/write/" + articleId)}>수정</Button>
                }

                {info.remove && userId === userInfo.id_key &&
                    <Button type={"line"} onClick={e => removeArticle()}>삭제</Button>
                }
            </div>
        </SubNav>    
        </>
    )
};

export default Article;
