import React from 'react';
import * as UserApi from "../../api/User";
import LoginImg from "../../assets/images/img_bg_signin.png";
import swal from "sweetalert";
import {Link} from "react-router-dom";

const EmailConfirm = ({match, ...props}) => {

    let user_id_key = match.params.user_id_key;
    let temporary_code = match.params.temporary_key;

    let params = {
        'user_id': user_id_key,
        'temporary_code': temporary_code
    };

    UserApi.readCheckTemporaryCode(params).then(res => {
        if (res.result.check === "auth_timeout") {
            swal('이메일 인증시간이 지났습니다. \n인증을 다시 해주세요.').then(() => {
                props.history.push("/join");
            });
        }
        else if (res.result.check === "auth_success") {
            swal("계정 활성화가 완료되었습니다.").then(() => {
                props.history.push("/login");
            });
        }
        else if (res.result.check === "auth_fail"){
            swal ("계정 활성화가 실패하였습니다. \n메일을 다시 확인해주세요.").then(() => {
            props.history.push("/join");
            });
        }
    }).catch(res => {
        console.log(res)
    });

    return (
        <div className={"login-wrap"}>
            <div className={"img-wrap"}>
                <Link to="/">찾기
                    <img src={LoginImg}/>
                </Link>
            </div>
            <div className={"login-form"}>
                <div className={"form-wrap"}>
                    <h1>
                        <Link to="/">
                            <strong>경기지역</strong>
                            <strong>경제포털</strong>
                        </Link>
                    </h1>
                </div>
            </div>
        </div>
    );
};
export default EmailConfirm;
