import React from 'react';
import {Link} from "react-router-dom";
import {yyyymmdd} from "../util/Date";
import {addComma, byte} from "../util/Number";

function GetShowPrice(priceInfo, priceInquiry) {
    return "-";
}

const DatasetList = ({list, totalCount}) => {
    return (
        <>
            {list.length > 0 ? <ul className={"dataset-list"}>
                {list.map(item => <DatasetItem key={item.id} info={item}/>)}
            </ul> : <div className={"ds-2-no-result"} style={{textAlign: "center", padding: 0}}>검색 결과가 없습니다.</div>}
        </>
    );
};

const searchInfoToWhat=(info,what)=>{
    let date="";
    info.map(i=> {
            if (i.key === what) {
                date=i.value.replaceAll("-",".")
                date=date.replace("년",".")
                date=date.replace("월",".")
                date=date.replace("일","")
            }
        }
    )
    return date
}

const DatasetItem = ({info}) => {
    let displayDate = searchInfoToWhat(info.extras,"DataSet_DataSet_modified")
    let displayCreatedDate = yyyymmdd(new Date(info.metadata_created), ".");

    let extraDict = info.extras.reduce(function(map, obj) {
        map[obj.key] = obj.value;
        return map;
    }, {});
    return (
        <li key={info.id} data-tut={"dataset_list"}>
            <dl>
                <Link to={"/competition/"+info.id}>
                <dt>
                    {info.title}
                    <div className={"date"}>
                        갱신일 {displayDate}
                    </div>
                    <div className={"createdDate"}>
                        등록일 {displayCreatedDate}
                    </div>
                    {/*<div className={"date"}>*/}
                    {/*</div>*/}
                </dt>
                <dd>
                    <div className={"explanation"} dangerouslySetInnerHTML={{__html:info.notes.replace(/\r\n|\r|\n/g, "<br />")}} />
                    <div className={"info"}>

                        <div className={"dataset-list-add-info-wrap"}>
                            <span className={"dataset-list-add-info-color"}>제공기관: {extraDict['DataSet_DataSet_creator_name']} </span>
                            <span className={"dataset-list-add-info-price"}>가격:{
                                " " + GetShowPrice(extraDict['DataService_DataService_priceInfo'], extraDict['Ikan_Extra_priceInquiry'])
                            }
                            </span>
                            {info.resources.map((resource) =>
                                <span className={"dataset-list-add-info-size"}>사이즈: {byte(resource.size, 0)}</span>
                            )}
                        </div>

                        <div className={"list-inner-btn-group"}>
                            {info.resources.map((resource, idx) => {
                                return (
                                   <>
                                       {
                                           idx > 0 ? null :
                                           resource.format === "CSV" || resource.format === "ZIP" ?
                                               <Link to={"/dataset/" + info.id + "?preview=true"}>
                                                   <span data-tut={"dataset_list_component1"} key={info.package_id} className={"Preview m_right10 m_left10"}>SHEET</span>
                                               </Link> :
                                               null
                                       }
                                       <span data-tut={"dataset_list_component2"} key={resource.format + info.id + idx} className={resource.format}>{resource.format}</span>
                                   </>
                                )
                            }
                        )}</div>
                    </div>
                </dd>
                </Link>
            </dl>
        </li>
    );
};

export default DatasetList;
