import {get, post, postNew, getNew} from '../util/HttpRequest';
import { withRouter } from "react-router-dom";

const ICAN_API_URL = process.env.REACT_APP_ICAN_API_URL;

/**
 * 유저 생성
 * /create/user
 */
export const createUser = (params = {}) => {
    let target = ICAN_API_URL + "/create/user";
    return postNew(target, params);
};

/**
 * 유저 정보 수정
 * /update/user
 */
export const updateUser = (params = {}) => {
    let target = ICAN_API_URL + "/update/user";
    return postNew(target, params);
};

/**
 * 유저 조회
 * /read/user
 */
export const readUser = (params = {}) => {
    let target = ICAN_API_URL + "/read/user";
    return getNew(target, params);
};

/**
 * 유저 삭제
 * /read/user
 */
export const sendDeleteEmail = (params = {}) => {
    let target = ICAN_API_URL + "/create/delete_key";
    return postNew(target, params);
};
export const deleteUser = (params = {}) => {
    let target = ICAN_API_URL + "/delete/user";
    return postNew(target, params);
};

/**
 * 로그인
 * /read/login
 */
export const readLogin = (params = {}) => {
    let target = ICAN_API_URL + "/read/login";
    return postNew(target, params);
};

/**
 * 유저 목록 조회
 * /read/list_user
 */
export const readListUser = (params = {}) => {
    let target = ICAN_API_URL + "/read/list_user";
    return getNew(target, params);
};

/**
 * 유저 데이터셋
 * /read/user_data
 */
export const readUserData = (params = {}) => {
    let target = ICAN_API_URL + "/read/user_data";
    return getNew(target, params);
};

/**
 * 토큰을 통한 유저 정보 조회
 * /read/user_info_by_token
 */

export const readUserInfoByToken = (params = {}) => {
    let target = ICAN_API_URL + "/read/user_info_by_token";
    return getNew(target, params);
};

/**
 *  ID, 이메일을 통한 user_id 조회
 * /read/auth_id_email
 */
export const readAuthPhoneAndEmail = (params = {}) => {
    let target = ICAN_API_URL + "/read/auth_phone_email";
    return getNew(target, params)
};

/*
* 휴대폰 번호, 이메일을 통한 user_id 조회*/
export const readAuthIdEmail = (params = {}) => {
    let target = ICAN_API_URL + "/read/auth_id_email";
    return getNew(target, params)
};
/**
 *  유저정보가 맞으면 리셋키 만들고 메일 던져줌
 *  /create/reset_key
 */
export const createResetKey = (params = {}) => {
    let target = ICAN_API_URL + "/create/reset_key";
    return postNew(target, params)
};

export const readForgotPassword = (params = {}) => {
    let target = ICAN_API_URL + "/create/forgot_password";
    return postNew(target, params)
};

/**
 * 로그아웃
 * /read/logout
 */
export const userLogout = (params = {}) => {
    let target = ICAN_API_URL + "/read/logout";
    return postNew(target, params)
};
/**
 * 다운로드 내역
 * /read/list_download_history
 */
export const readDownloadHistory = (params = {}) => {
    let target = ICAN_API_URL + "/read/list_download_history";
    return getNew(target, params)
};
/**
 * 다운로드 내역 삭제
 * /delete/download_history
 */
export const deleteDownloadHistory = (params = {}) => {
    let target = ICAN_API_URL + "/delete/download_history";
    return postNew(target, params)
};

/**
 * 이메일 인증
 *
 */
export const readCheckTemporaryCode = (params = {}) => {
    let target = ICAN_API_URL + "/read/check_temporary_code";
    return getNew(target, params)
};

/**
 * 이메일 재인증
 *
 */
export const createRetryEmailAuth = (params = {}) => {
    let target = ICAN_API_URL + "/create/retry_email_auth";
    return postNew(target, params)
};

/**
 * 유저 정보 수정
 * /update/user
 */
export const updatePassword = (params = {}) => {
    let target = ICAN_API_URL + "/update/password";
    return postNew(target, params);
};

/**
 * 유저 코드
 * /read/category_list_by_dict
 */
export const categoryListByDict = (params = {}) => {
    let target = ICAN_API_URL + "/read/category_list_by_dict";
    return getNew(target, params);
};

/**
 * 오프라인 데이터 분석신청 히스토리
 * /read/list_proposal_analysis
 */
export const readProposalAnalysis = (params = {}) => {
    let target = ICAN_API_URL + "/read/list_proposal_analysis";
    return getNew(target, params);
};

/**
 * 오프라인 데이터 분석신청 상태 업데이트
 * /read/list_proposal_analysis
 */
export const updateProposalAnalysis = (params = {}) => {
    let target = ICAN_API_URL + "/update/proposal_analysis";
    return postNew(target, params);
};

/**
 * 설문조사 링크
 * /read/survey
 */
export const readSurvey = (params = {}) => {
    let target = ICAN_API_URL + "/read/survey";
    return getNew(target, params);
};

export const checkDuplicateId = (params = {}) => {
    let target = ICAN_API_URL + "/read/check_duplicate_id";
    return getNew(target, params)
}
export const checkDuplicateEmail= (params = {}) => {
    let target = ICAN_API_URL + "/read/check_duplicate_email";
    return getNew(target, params)
}
/**
 * 데이터 오류 신고 불러오기
 * /read/data_report
 */
export const readReportData = (params = {}) => {
    let target = ICAN_API_URL + "/read/list_data_report";
    return getNew(target, params);
};

/**
 * 데이터 오류 신고 수정
 * /update/data_report
 */
export const updateReportData = (params = {}) => {
    let target = ICAN_API_URL + "/update/data_report";
    return postNew(target, params);
};

/**
 * 활용갤러리 등록
 * /create/use_gallery
 */
export const createUseGallery = (params = {}) => {
    let target = ICAN_API_URL + "/create/use_gallery";
    return postNew(target, params)
};

/**
 * 활용갤러리 수정
 * /update/use_gallery
 */
export const updateUseGallery = (params = {}) => {
    let target = ICAN_API_URL + "/update/use_gallery";
    return postNew(target, params)
};

/**
 * 활용 갤러리 리스트
 * /read/use_gallery_list
 */
export const readUseGalleryList = (params = {}) => {
    let target = ICAN_API_URL + "/read/use_gallery_list";
    return getNew(target, params)
};

/**
 * 활용 갤러리 상세
 * /read/use_gallery_detail
 */
export const readUseGalleryDetail = (params = {}) => {
    let target = ICAN_API_URL + "/read/use_gallery_detail";
    return getNew(target, params)
};

/**
 * 활용 갤러리 삭제
 * /delete/use_gallery
 */
export const deleteUseGallery = (params = {}) => {
    let target = ICAN_API_URL + "/delete/use_gallery";
    return postNew(target, params);
};


/**
 * 공모전 참여자 확인
 * /read/competition
 * */
export const checkCompetition = (params = {}) => {
    let target = ICAN_API_URL + "/read/check_competition";
    return getNew(target, params);
};

/**
 * 공모전 참가 신청
 * /read/competition
 * */
export const createCompetitionUser = (params = {}) => {
    let target = ICAN_API_URL + "/create/competition_user";
    return postNew(target, params);
};

/**
 * 포인트 사용
 * /update/use_point
 */
export const usePoint = (params = {}) => {
    let target = ICAN_API_URL + "/update/use_point";
    return postNew(target, params);
};

/**
 * 포인트 적립 (포털 전용)
 * /update/use_point
 */
export const savePoint = (params = {}) => {
    let target = ICAN_API_URL + "/create/internal_save_point";
    return postNew(target, params);
};

/**
 * 포인트 적립 내역
 * /read/list_point_save_history
 */
export const getPointSaveHistory = (params = {}) => {
    let target = ICAN_API_URL + "/read/list_point_save_history";
    return getNew(target, params);
};

/**
 * 포인트 사용 내역
 * /read/list_point_use_history
 */
export const getPointUseHistory = (params = {}) => {
    let target = ICAN_API_URL + "/read/list_point_use_history";
    return getNew(target, params);
};

/**
 * 서비스 연동
 * /create/account_link
 */
export const linkAppService = (params = {}) => {
    let target = ICAN_API_URL + "/create/account_link";
    return postNew(target, params);
};

/**
 * 방문 기록
 * /create/access_log
 */
 export const accessLog = (params = {}) => {
    let target = ICAN_API_URL + "/create/access_log";
    return postNew(target, params);
};

