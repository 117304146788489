import React from 'react';

const Cell = ({no, item, name, value, cellType, render}) => {

    let output;

    if(cellType === "image"){
        output = <img src={value} alt="" />;
    }else if(cellType === "checkbox"){
        output = <input type={"checkbox"} />
    }
    else output = value;

    if(render !== null)
        output = render(value, item, no);

    return (
        <td>
            {output}
        </td>
    )
};

Cell.defaultProps = {
    name: "",
    value: "",
    cellType: null,
    render: null
};

export default Cell;
