import React, {useState, useMemo, useEffect} from 'react';
import Pagination from "../../components/Pagination";

import thumbnail001 from "./event_001/images/event_roulette_thumbnail.png"
import thumbnail002 from "./event_002/images/lottery_thumbnail.jpg"
import thumbnail003 from "./event_003/images/lottery_thumbnail.jpg"
import thumbnail004 from "./event_004/images/event_roulette_thumbnail.png"
import thumbnail005 from "./event_005/images/thumbnail.png"
import thumbnail006 from "./event_006/images/thumbnail.png"
import thumbnail007 from "./event_007/images/thumbnail.png"
import thumbnail008 from "./event_008/images/thumbnail.jpg"
import thumbnail009 from "./event_009/images/thumbnail.png"
import thumbnail010 from "./event_010/images/thumbnail.png"
import thumbnail011 from "./event_011/images/thumbnail.png"
import thumbnail012 from "./event_012/images/thumbnail.png"
import thumbnail013 from "./event_013/images/thumbnail.png"
import thumbnail014 from "./event_014/images/thumbnail.png"
import thumbnail015 from "./event_015/images/thumbnail.png"
import SubNav from "../../components/SubNav";
import {Route, Switch} from "react-router-dom";
// import Event_003 from "./event_003/Event_003";
// import Event_004 from "./event_004/Event_004";
// import Event_005 from "./event_005/Event_005";
// import Event_006 from "./event_006/Event_006";
// import Event_007 from "./event_007/Event_007";
// import Event_008 from "./event_008/Event_008";
// import Event_009 from "./event_009/Event_009";
// import Event_010 from "./event_010/Event_010";
// import Event_011 from "./event_011/Event_011";
import Event_012 from "./event_012/Event_012";
import Event_013 from "./event_013/Event_013";
// import Event_014 from "./event_014/Event_014";
import Event_015 from "./event_015/Event_015";
import swal from "sweetalert";

const EventCard = ( {event, onClick, isClosed}) => {
    const pStyle = {
        width : "100%",
        height : "21px",
    }

    return (
        <li onClick={onClick} key={event.name + event.no}>
            <dl>
                <dt>
                    <img src={event.thumbnail} alt={event.eventName}/>
                </dt>
                <dd>
                    <strong>
                        {event.eventName} 
                        {isClosed && <span> (마감)</span>}
                    </strong>
                    <p style={pStyle}>서비스 유형 : {event.type}</p>
                    <p style={pStyle}>주최자(회사): {event.host}</p>
                </dd>
            </dl>
        </li>   
    )                  
}

const Event=(props)=> {
    const [maxPage, setMaxPage] = useState(1);
    const [page, setPage] = useState(1);
    const limit = 6;
    const offset = (page - 1) * limit;
    const today = new Date();

    const subNav = [
        {
            display: "진행 중인 이벤트",
            name: "/event"
        }, {
            display: "마감된 이벤트",
            name: "/event/done"
        }
    ];

    const eventList = [
        {
            no : "015",
            thumbnail : thumbnail015,
            eventName : "한국신용데이터 다운로드 이벤트",
            type : "선착순",
            host : "한국신용데이터", 
            start : "2023-11-22 10:00:00",
            end : "2023-11-27 23:59:59",
        },             
        {
            no : "014",
            thumbnail : thumbnail014,
            eventName : "펌프킨 데이터 다운로드 이벤트",
            type : "선착순 및 추첨",
            host : "펌프킨", 
            start : "2023-11-01 10:00:00",
            end : "2023-11-08 23:59:59",
        },        
        {
            no : "013",
            thumbnail : thumbnail013,
            eventName : "한국신용데이터 다운로드 이벤트",
            type : "선착순 및 추첨",
            host : "한국신용데이터", 
            start : "2023-10-30 10:00:00",
            end : "2023-11-17 23:59:59",
        },
        {
            no : "012",
            thumbnail : thumbnail012,
            eventName : "한국신용데이터 다운로드 이벤트",
            type : "선착순 및 추첨",
            host : "한국신용데이터", 
            start : "2023-09-18 10:00:00",
            end : "2023-10-10 23:59:59",
        },
        {
            no : "011",
            thumbnail : thumbnail011,
            eventName : "한국신용데이터 다운로드 이벤트",
            type : "추첨",
            host : "한국신용데이터", 
            start : "2022-11-23",
            end : "2022-11-29",
        },
        {
            no : "010",
            thumbnail : thumbnail010,
            eventName : "펌프킨 데이터 다운로드 이벤트",
            type : "추첨",
            host : "펌프킨", 
            start : "2022-11-16",
            end : "2022-11-22",
        },
        {
            no : "009",
            thumbnail : thumbnail009,
            eventName : "한국신용데이터 다운로드 이벤트",
            type : "선착순",
            host : "한국신용데이터", 
            start : "2022-11-14",
            end : "2022-11-20",
        },
        {
            no : "008",
            thumbnail : thumbnail008,
            eventName : "빅데이터 플랫폼 Meet-Up Day 및 세미나 개최",
            type : "세미나",
            host : "한국지능정보사회진흥원", 
            start : "2022-11-08",
            end : "2022-11-08",
        },
        {
            no : "007",
            thumbnail : thumbnail007,
            eventName : "한국신용데이터 다운로드 이벤트",
            type : "선착순 및 추첨",
            host : "한국신용데이터", 
            start : "2022-10-26",
            end : "2022-11-01",
        },
        {
            no : "006",
            thumbnail : thumbnail006,
            eventName : "한국신용데이터 다운로드 이벤트",
            type : "추첨",
            host : "한국신용데이터", 
            start : "2022-10-19",
            end : "2022-10-25",
        },
        {
            no : "005",
            thumbnail : thumbnail005,
            eventName : "한국신용데이터 다운로드 이벤트",
            type : "선착순",
            host : "한국신용데이터", 
            start : "2022-10-12",
            end : "2022-10-18",
        },
        {
            no : "004",
            thumbnail : thumbnail004,
            eventName : "한국신용데이터 다운로드 이벤트",
            type : "퀴즈",
            host : "한국신용데이터", 
            start : "2022-10-05",
            end : "2022-10-11",
        },
        {
            no : "003",
            thumbnail : thumbnail003,
            eventName : "한국신용데이터 다운로드 이벤트",
            type : "복권",
            host : "한국신용데이터", 
            start : "2021-11-17",
            end : "2022-12-05",
        },
        {
            no : "002",
            thumbnail : thumbnail002,
            eventName : "데이터 다운로드 이벤트",
            type : "복권",
            host : "경기도", 
            start : "2021-10-11",
            end : "2021-11-30",
        },
        {
            no : "001",
            thumbnail : thumbnail001,
            eventName : "이용자 가입 이벤트",
            type : "룰렛",
            host : "경기도", 
            start : "2021-09-27",
            end : "2021-10-31",
        },
    ];

    const onGoingEvents = useMemo(() => {
        const currentDate = new Date();
        return eventList.filter(event => new Date(event.start) <= currentDate && new Date(event.end) >= currentDate);
    }, [eventList]);

    const doneEvents = useMemo(() => {
        const currentDate = new Date();
        return eventList.filter(event => new Date(event.end) < currentDate);
    }, [eventList]);

    useEffect(() => {
        setMaxPage(Math.ceil(doneEvents.length/limit));
    }, [doneEvents]);

    return(
        <>
        <SubNav nav={subNav} history={props.history}>
            <Switch>
                <Route path="/event"
                       render={() =>
                            <div className={"gallery-wrap"}>
                                <ul className="gallery-block" data-tut={"use_gallery_body"}>
                                    {onGoingEvents.length > 0 ?
                                        onGoingEvents.map(event => 
                                            <EventCard 
                                                key={event.host+event.no}
                                                event={event}
                                                onClick={() => props.history.push("/event/" + event.no)}
                                                isClosed={false}
                                            />
                                        )
                                    :
                                        "진행 중인 이벤트가 없습니다"
                                    }
                                </ul>
                            </div>    
                       }
                exact/>

                <Route path="/event/done"
                       render={() => 
                            doneEvents.length > 0 ?
                                <>
                                    <div className={"gallery-wrap"}>
                                        <ul className="gallery-block" data-tut={"use_gallery_body"}>         
                                            {doneEvents.slice(offset,offset+limit).map(event => 
                                                    <EventCard 
                                                        key={event.host+event.no}
                                                        event={event}
                                                        onClick={() => swal("마감되었습니다")}
                                                        isClosed={true}
                                                    />
                                                )
                                            }
                                        </ul>
                                    </div>
                                    <Pagination maxPage={maxPage}
                                                page={page}
                                                padding={2}
                                                onPageMove={page => setPage(page)}
                                                customStyle={{"margin-top": "20px !important"}}
                                    />                                        
                                </>
                                :
                                <div className={"gallery-wrap"}>
                                    <ul className="gallery-block" data-tut={"use_gallery_body"}>         
                                        마감된 이벤트가 없습니다
                                    </ul>
                                </div>                                
                            }
                exact/>

                {/*이벤트 추가시 여기에 라우터로 추가해주기. 이벤트 마감되면 없애주기*/}
                {/* <Route path="/event/012" render={() => <Event_012/>} exact/> */}
                {/* {new Date('2023-11-21 10:00') < today && new Date('2023-11-27 23:59') > today && 
                    <Route path="/event/015" render={() => <Event_015/>} exact/>
                } */}
                {/* <Route path="/event/012" render={() => <Event_012/>} exact/>
                <Route path="/event/013" render={() => <Event_013/>} exact/> */}
            </Switch>
        </SubNav>
        </>
    )
};

export default Event;