import React, { useState, useEffect } from "react";
import * as PopupApi from "../api/Popup";
import {getCookie, setCookie} from "../util/Cookie";

const Home = () => {
    const [popupList, setPopupList] = useState([]);
    useEffect(() => {
        if (getCookie("testtest") === '2') {
            return null;
        } else {
            const d = new Date();
            const ymd2 = new Date(d.getTime() - (d.getTimezoneOffset() * 60000)).toISOString().slice(0, 16).replace("T", " ");
            
            PopupApi.readPopupList().then(res => {
                setPopupList(res.result.filter(popup => (!popup.start_date || popup.start_date <= ymd2) && (!popup.end_date || popup.end_date >= ymd2)))
            })
        }}, []);

    const closeOnce = (popup) => {
        setPopupList(popupList.filter(popupCheck=>popupCheck.cookie!==popup.cookie))

    };

    const closeOneDay = (e, cookie) => {
        setPopupList(popupList.filter(popupCheck=>popupCheck.cookie!==popup.cookie))
        setCookie(cookie, "N");
    };

    const popup=popupList
        .filter(popup => getCookie(popup.cookie)!=="N")
        .map((popup,index) => (
            <div key={index} className="onePopup">

                <a href={popup.link_address} target="_blank" rel="noopener noreferrer">
                    {/* <img src={popup.image_path} style={{ width: popup.width + "px", height: popup.height + "px"}} alt={popup.title_kor} /> */}
                    <img src={popup.image_path} style={{ width: popup.width + "px", height: popup.height + "px"}} alt={popup.title_kor} />
                </a>
                <br/>
                <div className="popup_button" type="checkbox" style={{textAlign:'center'}} onClick={(e) => closeOnce(popup)}>
                    닫기
                </div>

                <div className="popup_onday" type="checkbox" onClick={(e) => closeOneDay(index, popup.cookie)}>
                    하루동안 보지 않기
                </div>
            </div>
        ));


    return (
        <div className="App">
            {popup.length > 0 && (
                <div className="renewal">
                    <div className="newPopupModal" style={{flexFlow:"wrap",paddingTop:"100px"}}>
                        { popup }
                    </div>
                </div>    
            )}
        </div>
    );
};

export default Home;
