import React, { Component, Fragment } from 'react';
import {getParamsFromQueryString} from "../util/Url";
import {timestampJavaScriptToPython} from "../util/Date";
import SelectSearch from 'react-select-search';
import OptData from "../pages/common/OptionData.json";
import downArrow from "../assets/images/ic_down_fillarrow.svg"
import upArrow from "../assets/images/ic_up_fillarrow.svg"
import downSelectedArrow from "../assets/images/ic_down_selected_fillarrow.svg"
import upSelectedArrow from "../assets/images/ic_up_selected_fillarrow.svg"
import {addComma} from "../util/Number"
// import SortUI from "./DatasetFilterSort"


class DatasetFilterNew extends Component {

    constructor(props){
        super(props);

        let qs = getParamsFromQueryString(props.history.location.search);
        if(Array.isArray(qs.word) && qs.word.length > 0)
            qs.word = qs.word[0];

        this.uri = props.history.location.search;
        this.filter = {};

        this.page = props.page;

        this.state = {
            selectedTags: qs.hasOwnProperty("tag") ? JSON.parse(JSON.stringify(qs.tag)) : [],
            selectedFormats: qs.hasOwnProperty("format") ? JSON.parse(JSON.stringify(qs.format)) : [],
            selectedCategory: qs.hasOwnProperty("category") ? JSON.parse(JSON.stringify(qs.category)) : [],
            searchKeyword: qs.hasOwnProperty("word") ? JSON.parse(JSON.stringify(qs.word)) : "",
            selectedPayTags: qs.hasOwnProperty("paytag") ? JSON.parse(JSON.stringify(qs.paytag)) : [],
            searchKeywordDummy: qs.hasOwnProperty("word") ? JSON.parse(JSON.stringify(qs.word)) : "",
            selectedStartDate: null,
            selectedEndDate: null,
            selectedCenterCode: "all",
            selectedDateRange: "all",
            selectedDateField: "created",
            selectedSort: "",
            detailFlag: false,
            detailIco: downArrow
        }
    }

    componentDidMount(){
        this.updateStateFromQueryString()
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext){
        if(this.uri !== this.props.history.location.search){
            this.updateStateFromQueryString()
        }
    }

    componentWillUpdate(nextProps, nextState, nextContext){
        if(this.uri !== this.props.history.location.search){
            this.uri = this.props.history.location.search;
            return true;
        } else return false;
    }

    updateStateFromQueryString = () => {
        const { history } = this.props;
        const { selectedTags, selectedFormats, selectedCategory, searchKeyword, selectedPayTags } = this.state;

        let qs = getParamsFromQueryString(history.location.search);
        if(Array.isArray(qs.word) && qs.word.length > 0)
            qs.word = qs.word[0];

        let dst = qs.hasOwnProperty("tag") ? qs.tag : [];
        let dsf = qs.hasOwnProperty("format") ? qs.format : [];
        let dsc = qs.hasOwnProperty("category") ? qs.category : [];
        let dsk = qs.hasOwnProperty("word") ? qs.word : "";
        let dsp = qs.hasOwnProperty("paytag") ? qs.paytag : [];

        let udt = {};

        if(dst !== selectedTags)
            udt.selectedTags = qs.hasOwnProperty("tag") ? qs.tag : [];
        if(dsf !== selectedFormats)
            udt.selectedFormats = qs.hasOwnProperty("format") ? qs.format : [];
        if(dsc !== selectedCategory)
            udt.selectedCategory = qs.hasOwnProperty("category") ? qs.category : [];
        if(dsk !== searchKeyword)
            udt.searchKeyword = qs.hasOwnProperty("word") ? qs.word : "";
        if(dsp !== selectedPayTags)
            udt.selectedPayTags = qs.hasOwnProperty("paytag") ? qs.paytag : [];

        this.setState({
            ...udt
        }, () => {
            this.changeEventSubmit()
        })
    };

    changeEventSubmit = () => {
        const { onChange, history, } = this.props;
        const {selectedTags, selectedFormats, selectedCategory, searchKeyword, selectedPayTags, selectedSort,
            selectedCenterCode, selectedDateRange, selectedDateField, selectedStartDate, selectedEndDate} = this.state;

        let qs = getParamsFromQueryString(history.location.search);
        let page = qs.hasOwnProperty("page") ? qs.page[0] : 1;

        let params = {
            tag: selectedTags,
            format: selectedFormats,
            category: selectedCategory,
            word: searchKeyword,
            paytag: selectedPayTags,
        };

        if(selectedCenterCode !== undefined && selectedCenterCode !== 'all'){
            params["center_code"] = selectedCenterCode;
        }
        if(selectedDateRange !== undefined && selectedDateRange !== 'all'){
            params["start_date"] = String(selectedStartDate);
            params["end_date"] = String(selectedEndDate);
        }

        if(selectedDateField !== undefined && selectedDateField !== null){
            params["datecolumn"] = selectedDateField;
        }

        if(selectedSort !== undefined && selectedSort !== null){
            params["sort"] = selectedSort;
        }

        if(JSON.stringify(params) !== JSON.stringify(this.filter)){
            page = 1;
        }

        if(JSON.stringify(params) !== JSON.stringify(this.filter) || this.page !== page){
            this.setState({
                searchKeywordDummy: searchKeyword
            }, () => {
                this.filter = JSON.parse(JSON.stringify(params));
                this.page = page;
                let param = JSON.parse(JSON.stringify(params));
                onChange(param);
            })
        }
    };

    // flag = in selectBox of Date.
    getFromTo(flag){
        let from = null;
        let to = null;

        if(flag !== 'all'){
            from = new Date();
            to = new Date();
            if(flag === '1month'){
                from.setMonth(from.getMonth() - 1);
            }else if(flag === '3month'){
                from.setMonth(from.getMonth() - 3);
            }else if(flag === '6month'){
                from.setMonth(from.getMonth() - 6);
            }
            // divide 1000 => for python (backend).
            from = timestampJavaScriptToPython(from.getTime());
            to = timestampJavaScriptToPython(to.getTime());
        }
        return {"from": from, "to": to};
    };

    setSelectedTags(after){
        this.setState({
            selectedTags: after
        }, () => {
            this.changeEventSubmit()
        })
    }

    setSelectedFormats(after){
        this.setState({
            selectedFormats: after
        }, () => {
            this.changeEventSubmit()
        })
    }

    setSelectedCategory(after){
        this.setState({
            selectedCategory: after
        }, () => {
            this.changeEventSubmit()
        })
    }

    setSelectedPayTags(after){
        this.setState({
            selectedPayTags: after
        }, () => {
            this.changeEventSubmit()
        })
    }

    setSearchKeyword(after){
        this.setState({
            searchKeyword: after
        }, () => {
            this.changeEventSubmit()
        })
    }

    setSelectedCenterCode(after){
        this.setState({
            selectedCenterCode: after
        }, () => {
            this.changeEventSubmit()
        })
    }

    setSelectedDateRange(after){
        let {from, to} = this.getFromTo(after);
        this.setState({
            selectedDateRange: after,
            selectedStartDate: from,
            selectedEndDate: to
        }, () => {
            this.changeEventSubmit()
        })
    }

    setSelectedDateField(after){
        this.setState({
            selectedDateField: after
        }, () => {
            this.changeEventSubmit()
        })
    }

    setSortText(field, direction){
        let sorttext = null;
        if(typeof field === 'string' && typeof direction === 'string'){
            sorttext = [field, direction].join(" ");

            // 동일 버튼 클릭 시 정렬 해제
            // if(!this.state.selectedSort){
            //     sorttext = [field, direction].join(" ");
            // }else{
            //     let temp = this.state.selectedSort.split(" ")
            //     if(temp.length === 2 && field === temp[0] && direction === temp[1]){
            //         sorttext = null;
            //     }else{
            //         sorttext = [field, direction].join(" ");
            //     }
            // }
        }
        this.setState({
            selectedSort: sorttext
        }, () => {
            this.changeEventSubmit();
        })
    }

    getCenterList = () => {
        // 센터 코드 추가는 백엔드 서버와 DB를 검토해야 한다.
        return OptData.search_detail.center_code;
    }

    getDataDateList = () => {
        // 만약 플래그가 추가된다면, getFromTo 에도 start와 end를 정의해야 한다.
        return OptData.search_detail.date_range;
    }

    getDateFieldList = () => {
        // 날짜 필드의 추가/삭제/변경 은 CKAN의 스키마를 검토해야 한다.
        return OptData.search_detail.date_field;
    }

    getCurrentSortState = (btnfield, btndirection) => {
        if(!this.state.selectedSort){
            return false;
        }
        let temp = this.state.selectedSort.split(" ")
        if(temp.length === 2){
            if(btnfield === temp[0] && btndirection === temp[1]){
                return true;
            }
        }
        return false;
    }

    paytagToDisplay = (txt) => {
        if(txt === 'pay') return '유료'
        else if(txt === 'free') return '무료'
        return txt
    }

    handleSearchBtn = () => {
        const { searchKeywordDummy } = this.state;

        this.setSearchKeyword(searchKeywordDummy)
    };

    handleChange = (target, name, bool) => {
        const {selectedTags, selectedFormats, selectedCategory, selectedPayTags} = this.state;

        let selectedState = [];
        if(target === "tags") selectedState = JSON.parse(JSON.stringify(selectedTags));
        else if(target === "formats") selectedState = JSON.parse(JSON.stringify(selectedFormats));
        else if(target === "category") selectedState = JSON.parse(JSON.stringify(selectedCategory));
        else if(target === "paytag") selectedState = JSON.parse(JSON.stringify(selectedPayTags));

        if(bool)
            selectedState.push(name);
        else{
            let index = selectedState.indexOf(name);
            if(index > -1){
                selectedState.splice(index, 1);
            }
        }

        if(target === "tags") this.setSelectedTags(selectedState);
        else if(target === "formats") this.setSelectedFormats(selectedState);
        else if(target === "category") this.setSelectedCategory(selectedState);
        else if(target === "paytag") this.setSelectedPayTags(selectedState);
    };

    detailSearchShowAndHide = () => {
        if (this.state.detailFlag) {
            this.setState({
                detailFlag: false,
                detailIco: downArrow
            })
        } else {
            this.setState({
                detailFlag: true,
                detailIco: upArrow
            })
        }

    };

    detailSearchRefresh = () => {
        this.setState({
            selectedCenterCode: "all",
            selectedDateRange: "all",
            selectedStartDate: null,
            selectedEndDate: null
        }, () => {
            this.changeEventSubmit()
        })

    };

    render() {

        const {tags} = this.props;
        const {selectedTags} = this.state;
        let tagFilter = ["경기도일자리재단", "경기신용보증재단", "경기콘텐츠진흥원", "더아이엠씨", "코나아이", "한국기업데이터"];

        return (
            <Fragment>
                <div className={"wrap-dataset"} data-tut={"dataset_filter"}>
                    {/*<div className={"wrap-formats"}>*/}
                    {/*    <h3>포맷</h3>*/}
                    {/*    {formats.length > 0 ? <ul className={"group-tags"}>*/}
                    {/*        <li>*/}
                    {/*            {formats.map(format => <div className={"tag-block"}*/}
                    {/*                                        key={"formats" + format.display_name}>*/}
                    {/*                <input type="checkbox" id={"formats" + format.display_name}*/}
                    {/*                       checked={selectedFormats.includes(format.display_name)}*/}
                    {/*                       onChange={e => this.handleChange("formats", format.display_name, e.target.checked)}/>*/}
                    {/*                <label htmlFor={"formats" + format.display_name}>*/}
                    {/*                    <span>{format.display_name}</span>*/}
                    {/*                    <span>({format.count})</span>*/}
                    {/*                </label>*/}
                    {/*            </div>)}*/}
                    {/*        </li>*/}
                    {/*    </ul> : <div className={"ds-2-no-result"}>검색 결과가 없습니다.</div>}*/}
                    {/*</div>*/}
                    {/*<div className={"wrap-formats"}>*/}
                    {/*    <h3>카테고리</h3>*/}
                    {/*    {category.length > 0 ? <ul className={"group-tags"}>*/}
                    {/*        <li>*/}
                    {/*            {category.map(category => <div className={"tag-block"}*/}
                    {/*                                           key={"category" + category.display_name}>*/}
                    {/*                <input type="checkbox" id={"category" + category.display_name}*/}
                    {/*                       checked={selectedCategory.includes(category.display_name)}*/}
                    {/*                       onChange={e => this.handleChange("category", category.display_name, e.target.checked)}/>*/}
                    {/*                <label htmlFor={"category" + category.display_name}>*/}
                    {/*                    <span>{category.display_name}</span>*/}
                    {/*                    <span>({category.count})</span>*/}
                    {/*                </label>*/}
                    {/*            </div>)}*/}
                    {/*        </li>*/}
                    {/*    </ul> : <div className={"ds-2-no-result"}>검색 결과가 없습니다.</div>}*/}
                    {/*</div>*/}
                    {/*<div className={"wrap-formats"}>*/}
                    {/*    <h3>가격</h3>*/}
                    {/*    {paytag.length > 0 ? <ul className={"group-tags"}>*/}
                    {/*        <li>*/}
                    {/*            {paytag.map(paytag => <div className={"tag-block"}*/}
                    {/*                                       key={"paytag" + paytag.display_name}>*/}
                    {/*                <input type="checkbox" id={"paytag" + paytag.display_name}*/}
                    {/*                       checked={selectedPayTags.includes(paytag.display_name)}*/}
                    {/*                       onChange={e => this.handleChange("paytag", paytag.display_name, e.target.checked)}/>*/}
                    {/*                <label htmlFor={"paytag" + paytag.display_name}>*/}
                    {/*                    <span>{this.paytagToDisplay(paytag.display_name)}</span>*/}
                    {/*                    <span>({paytag.count})</span>*/}
                    {/*                </label>*/}
                    {/*            </div>)}*/}
                    {/*        </li>*/}
                    {/*    </ul> : <div className={"ds-2-no-result"}>검색 결과가 없습니다.</div>}*/}
                    {/*</div>*/}
                    <div className={"warp-tags"}>
                        <h3>태그
                            <div className={"wrap-tags-mini-wrap"}>{selectedTags.map((tag, idx) => {
                                // if(idx > 25) return null;
                                if(tagFilter.indexOf(tag) > -1) return null;
                                return (
                                    <div className={"tag-mini-block"} key={"tags" + tag}>
                                        <input type="checkbox" id={"tags" + tag}
                                               checked={selectedTags.includes(tag)}
                                               onChange={e => this.handleChange("tags", tag, e.target.checked)}/>
                                        <label htmlFor={"tags" + tag}>
                                            <span>{tag}</span>
                                        </label>
                                    </div>
                                )
                            })}</div>
                        </h3>
                        {tags.length > 0 ? <ul className={"group-tags"}>
                            <li>
                                {tags.map((tag, idx) => {
                                    // if(idx > 25) return null;
                                    if(tagFilter.indexOf(tag.display_name) > -1) return null;
                                    return (
                                        <div className={"tag-block"} key={"tags" + tag.display_name}>
                                            <input type="checkbox" id={"tags" + tag.display_name}
                                                   checked={selectedTags.includes(tag.display_name)}
                                                   onChange={e => this.handleChange("tags", tag.display_name, e.target.checked)}/>
                                            <label htmlFor={"tags" + tag.display_name}>
                                                <span>{tag.display_name}</span>
                                                <span>({tag.count})</span>
                                            </label>
                                        </div>
                                    )
                                })}
                            </li>
                        </ul> : <div className={"ds-2-no-result"}>검색 결과가 없습니다.</div>}
                    </div>
                    <div className={"search-wrap"}>
                        <input type="text" placeholder="데이터 검색" value={this.state.searchKeywordDummy}
                               onKeyDown={e => e.keyCode === 13 && this.handleSearchBtn()}
                               onChange={e => this.setState({searchKeywordDummy: e.target.value})} autoComplete={"off"}/>
                        <img src={this.state.detailIco} width={"15px"} height={"15px"} alt={"detail"} onClick={() => this.detailSearchShowAndHide()} style={{"fill": "#A9B7B7", "cursor": "pointer", "position": "absolute", "right": "72px", "top": "18px"}}/>
                        <button onClick={() => this.handleSearchBtn()}/>
                    </div>
                    {
                        this.state.detailFlag ?
                            <>
                            <div className={"detail-search"}>
                                {/*<div className={"detail-search-form"}>*/}
                                {/*    <span className={"detail-search-title"}> 데이터 제공처 </span>*/}
                                {/*    <SelectSearch*/}
                                {/*        options={this.getCenterList()}*/}
                                {/*        onChange={option => {this.setSelectedCenterCode(option)}}*/}
                                {/*        value={this.state.selectedCenterCode}*/}
                                {/*    />*/}
                                {/*</div>*/}
                                <div className={"detail-search-form"}>
                                    <span className={"detail-search-title"}> 데이터 기간 </span>
                                    <div>
                                        <div className={"detail-search-form"}>
                                            <SelectSearch
                                                options={this.getDateFieldList()}
                                                onChange={option => {this.setSelectedDateField(option)}}
                                                value={this.state.selectedDateField}
                                                className={"data-rating-select-box"}
                                            />
                                        </div>
                                        <div className={"detail-search-form detail-search-pc-left-margin-small"}>
                                            <SelectSearch
                                                options={this.getDataDateList()}
                                                onChange={option => {this.setSelectedDateRange(option)}}
                                                value={this.state.selectedDateRange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={"detail-refresh-form detail-search-pc-left-margin-normal"}>
                                    <button className={"detail-refresh-button"} onClick={() => this.detailSearchRefresh()}> 초기화 </button>
                                </div>
                            </div>
                            </> :
                        null

                    }
                    <div className={"search-result"}>
                        전체({addComma(this.props.totalCount)})
                        {/* <SortUI display="가격유형" field="price" handler={this.setSortText}/>
                        <SortUI display="제공기관" field="center" handler={this.setSortText}/>
                        <SortUI display="갱신일" field="modified" handler={this.setSortText}/>
                        <SortUI display="등록일" field="created" handler={this.setSortText}/>
                        <SortUI display="제목" field="title" handler={this.setSortText}/> */}
                        {/* <SortUI display="제목" field="title" handler={this.setSortText}/> */}
                        {/* <div style={{"float": "right", "margin-left": "10px"}}>
                            <span className={"search-order"}>가격유형순</span>
                            {
                                this.getCurrentSortState("price", "asc") ?
                                <>
                                    <button className={"search-order-up-btn"} onClick={() => this.setSortText("price", "asc")} style={{"background-color": "#79818c"}}>
                                        <img src={upSelectedArrow} width={"12px"} height={"12px"} className={"search-order-up-img"}/>
                                    </button>
                                </>
                                :
                                <>
                                    <button className={"search-order-up-btn"} onClick={() => this.setSortText("price", "asc")}>
                                        <img src={upArrow} width={"12px"} height={"12px"} className={"search-order-up-img"}/>
                                    </button>
                                </>
                            }
                            {
                                this.getCurrentSortState("price", "desc") ?
                                <>
                                    <button className={"search-order-down-btn"} onClick={() => this.setSortText("price", "desc")} style={{"background-color": "#79818c"}}>
                                        <img src={downSelectedArrow} width={"12px"} height={"12px"} className={"search-order-down-img"}/>
                                    </button>
                                </>
                                :
                                <>
                                    <button className={"search-order-down-btn"} onClick={() => this.setSortText("price", "desc")}>
                                        <img src={downArrow} width={"12px"} height={"12px"} className={"search-order-down-img"}/>
                                    </button>
                                </>
                            }
                        </div> */}

                        {/*<div style={{"float": "right", "margin-left": "10px"}}>*/}
                        {/*    <p className={"search-order"}>제공기관순</p>*/}
                        {/*    {*/}
                        {/*        this.getCurrentSortState("center", "asc") ?*/}
                        {/*        <>*/}
                        {/*            <button className={"search-order-up-btn"} onClick={() => this.setSortText("center", "asc")} style={{"background-color": "#79818c"}}>*/}
                        {/*                <img src={upSelectedArrow} width={"10px"} height={"10px"} className={"search-order-up-img"}/>*/}
                        {/*            </button>*/}
                        {/*        </>*/}
                        {/*        :*/}
                        {/*        <>*/}
                        {/*            <button className={"search-order-up-btn"} onClick={() => this.setSortText("center", "asc")}>*/}
                        {/*                <img src={upArrow} width={"10px"} height={"10px"} className={"search-order-up-img"}/>*/}
                        {/*            </button>*/}
                        {/*        </>*/}
                        {/*    }*/}
                        {/*    {*/}
                        {/*        this.getCurrentSortState("center", "desc") ?*/}
                        {/*        <>*/}
                        {/*            <button className={"search-order-down-btn"} onClick={() => this.setSortText("center", "desc")} style={{"background-color": "#79818c"}}>*/}
                        {/*                <img src={downSelectedArrow} width={"10px"} height={"10px"} className={"search-order-down-img"}/>*/}
                        {/*            </button>*/}
                        {/*        </>*/}
                        {/*        :*/}
                        {/*        <>*/}
                        {/*            <button className={"search-order-down-btn"} onClick={() => this.setSortText("center", "desc")}>*/}
                        {/*                <img src={downArrow} width={"10px"} height={"10px"} className={"search-order-down-img"}/>*/}
                        {/*            </button>*/}
                        {/*        </>*/}
                        {/*    }*/}
                        {/*</div>*/}

                        <div style={{"float": "right", "margin-left": "10px"}}>
                            <p className={"search-order"}>갱신일순</p>
                            {
                                this.getCurrentSortState("modified", "asc") ?
                                <>
                                    <button className={"search-order-up-btn"} onClick={() => this.setSortText("modified", "asc")} style={{"background-color": "#79818c"}}>
                                        <img src={upSelectedArrow} width={"10px"} height={"10px"} className={"search-order-up-img"} alt="" />
                                    </button>
                                </>
                                :
                                <>
                                    <button className={"search-order-up-btn"} onClick={() => this.setSortText("modified", "asc")}>
                                        <img src={upArrow} width={"10px"} height={"10px"} className={"search-order-up-img"} alt="" />
                                    </button>
                                </>
                            }
                            {
                                this.getCurrentSortState("modified", "desc") ?
                                <>
                                    <button className={"search-order-down-btn"} onClick={() => this.setSortText("modified", "desc")} style={{"background-color": "#79818c"}}>
                                        <img src={downSelectedArrow} width={"10px"} height={"10px"} className={"search-order-down-img"} alt="" />
                                    </button>
                                </>
                                :
                                <>
                                    <button className={"search-order-down-btn"} onClick={() => this.setSortText("modified", "desc")}>
                                        <img src={downArrow} width={"10px"} height={"10px"} className={"search-order-down-img"} alt="" />
                                    </button>
                                </>
                            }
                        </div>

                        <div style={{"float": "right", "margin-left": "10px"}}>
                            <p className={"search-order"}>등록일순</p>
                            {
                                this.getCurrentSortState("created", "asc") ?
                                <>
                                    <button className={"search-order-up-btn"} onClick={() => this.setSortText("created", "asc")} style={{"background-color": "#79818c"}}>
                                        <img src={upSelectedArrow} width={"10px"} height={"10px"} className={"search-order-up-img"} alt="" />
                                    </button>
                                </>
                                :
                                <>
                                    <button className={"search-order-up-btn"} onClick={() => this.setSortText("created", "asc")}>
                                        <img src={upArrow} width={"10px"} height={"10px"} className={"search-order-up-img"} alt="" />
                                    </button>
                                </>
                            }
                            {
                                this.getCurrentSortState("created", "desc") ?
                                <>
                                    <button className={"search-order-down-btn"} onClick={() => this.setSortText("created", "desc")} style={{"background-color": "#79818c"}}>
                                        <img src={downSelectedArrow} width={"10px"} height={"10px"} className={"search-order-down-img"} alt="" />
                                    </button>
                                </>
                                :
                                <>
                                    <button className={"search-order-down-btn"} onClick={() => this.setSortText("created", "desc")}>
                                        <img src={downArrow} width={"10px"} height={"10px"} className={"search-order-down-img"} alt="" />
                                    </button>
                                </>
                            }
                        </div>

                        <div style={{"float": "right", "margin-left": "10px"}}>
                            <p className={"search-order"}>제목순</p>
                            {
                                this.getCurrentSortState("title", "asc") ?
                                <>
                                    <button className={"search-order-up-btn"} onClick={() => this.setSortText("title", "asc")} style={{"background-color": "#79818c"}}>
                                        <img src={upSelectedArrow} width={"10px"} height={"10px"} className={"search-order-up-img"} alt="" />
                                    </button>
                                </>
                                :
                                <>
                                    <button className={"search-order-up-btn"} onClick={() => this.setSortText("title", "asc")}>
                                        <img src={upArrow} width={"10px"} height={"10px"} className={"search-order-up-img"} alt="" />
                                    </button>
                                </>
                            }
                            {
                                this.getCurrentSortState("title", "desc") ?
                                <>
                                    <button className={"search-order-down-btn"} onClick={() => this.setSortText("title", "desc")} style={{"background-color": "#79818c"}}>
                                        <img src={downSelectedArrow} width={"10px"} height={"10px"} className={"search-order-down-img"} alt="" />
                                    </button>
                                </>
                                :
                                <>
                                    <button className={"search-order-down-btn"} onClick={() => this.setSortText("title", "desc")}>
                                        <img src={downArrow} width={"10px"} height={"10px"} className={"search-order-down-img"} alt="" />
                                    </button>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

DatasetFilterNew.defaultProps = {
    onChange: () => {
    },
    tags: [],
    formats: [],
    paytag: []
};

export default DatasetFilterNew;
