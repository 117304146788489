import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import swal from "sweetalert";
import {delCookie, getCookie} from "../util/Cookie";
import * as DataAPI from "../api/Data";
import * as UserApi from "../api/User"
import Pagination from "./Pagination";
import {yyyymmddhhiiss} from "../util/Date";

const DatasetComment = ({postId, postTitle, postTheme, postPlaceholder}) => {
    const isLogin = useSelector(state => state.user.isLogin);
    const userInfo = useSelector(state => state.user.info);

    let [commentContent, setCommentContent] = useState(null);
    let [commentList, setCommentList] = useState(null);
    let [commentListFlag, setCommentListFlag] = useState(false);

    let [page, setPage] = useState(1);
    let [maxPage, setMaxPage] = useState(1);
    let [limit, setLimit] = useState(3);
    let [totCnt, setTotCnt] = useState(0);

    useEffect( () => {
        getComment();
    }, [page]);

    const getComment = () => {
        let params = {
            'comment_post_id': postId,
            'user_id': userInfo.id_key,
            "page_limit": limit,
            "page_num": page - 1,
        };

        DataAPI.readDataComment(params).then(res => {
            setCommentList(res.result.comment_list);
            setTotCnt(res.result.count);
            setMaxPage(Math.ceil(res.result.count / limit));
            setCommentListFlag(true);
        });
    };

    const getCommentDelete = () => {
        let params = {
            'comment_post_id': postId,
            'user_id': userInfo.id_key,
            "page_limit": limit,
            "page_num": page - 1,
        };

        DataAPI.readDataComment(params).then(res => {
            setCommentList(res.result.comment_list);
            setTotCnt(res.result.count);
            setMaxPage(Math.ceil(res.result.count / limit));
            setCommentListFlag(true);
            if (res.result.count >= 3) {
                if(res.result.count % limit === 0) {
                    setPage(maxPage - 1)
                }
            } else {
                getComment();
            }

        });
    };

    const addComment = () => {
        if(!isLogin) {
            swal({
                text: "로그인 후 이용 가능한 서비스입니다.",
                buttons: [
                    "확인",
                    "로그인"
                ]
            }).then(function (isConfirm) {
                if(isConfirm) {
                    delCookie("token");
                    window.location.href = "/#/login";
                } else {
                    swal.close();
                }
            })
        } else {
            if (commentContent === "" || commentContent === null) {
                return ;
            }

            let params = {
                "user_id": userInfo.id_key,
                "user_name": userInfo.id,
                "comment_post_id": postId,
                "comment_post_title": postTitle,
                "comment_post_theme": postTheme,
                "comment_content": commentContent
            };
            DataAPI.addDataComment(params).then(res => {
                let point_params = {
                    portal_user_id_key: userInfo.id_key,
                    portal_user_id: userInfo.id,
                    action_type: "add_comment",
                    point: "10",
                }
                UserApi.savePoint(point_params).then(res => {

                })
                swal("댓글 작성을 완료하였습니다.")
                getComment();
            })
        }
        setCommentContent("");
    };

    const deleteComment = (comment_id, user_name) => {
        swal({
            text: "정말 삭제하시겠습니까?",
            buttons: [
                "취소",
                "확인",
            ],
        }).then(function (isConfirm) {
            if(isConfirm) {
                let params = {
                    "comment_id": comment_id
                };
                DataAPI.deleteDataComment(params).then( res => {
                    swal("삭제가 완료되었습니다.");
                    getCommentDelete();
                });
            }
            else {
                swal.close();
            }
        })
    };

    return (
        <>
            {
                commentListFlag === false ? null :
                    <ul className={"comment-ul"}>
                        {
                            commentList.map(comment =>
                                <li className={"comment-li"}>
                                    <div className={"comment-main"}>
                                        <span className={"comment-id"}>
                                            {comment.user_name}
                                        </span>
                                        <span className={"comment-text"}>
                                            {comment.comment_content}
                                        </span>
                                        <span className={"comment-date"}>
                                            {yyyymmddhhiiss(new Date((comment.date).replace(" ", "T")))}
                                            {
                                                userInfo.role === "superadmin"
                                                    ?
                                                        <a onClick={ e => deleteComment(comment.id, comment.user_name)} className={"comment-del-button"}> 삭제 </a>
                                                    :
                                                    comment.user_name === userInfo.id
                                                        ?
                                                        <a onClick={ e => deleteComment(comment.id, comment.user_name)} className={"comment-del-button"}> 삭제 </a>
                                                        :
                                                        null
                                            }
                                        </span>
                                    </div>
                                </li>
                            )}
                    </ul>
            }
            {
                totCnt !== 0
                    ?
                    <Pagination maxPage={maxPage}
                                page={page}
                                padding={2}
                                onPageMove={page => setPage(page)}
                                customStyle={{"marginTop": "20px !important"}}
                    />
                    :
                    null
            }
            <textarea title="댓글" placeholder={postPlaceholder} className={"comment-textarea"} value={commentContent||""} onChange={e => setCommentContent(e.target.value)}/>
            {
                commentContent === null || commentContent.replace(/(\s*)/g, "") === ""
                    ?
                <button className={"comment-button-disabled"} onClick={() => addComment()}>입력</button>
                    :
                <button className={"comment-button"} onClick={() => addComment()}>입력</button>
            }
        </>
    )
};

export default DatasetComment;
