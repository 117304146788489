import React from 'react';
import Num from './Num';

const Pagination = ({maxPage, page, padding, onPageMove}) => {

    let start = page - padding;
    let r = 0;

    if(start < 1){
        r = Math.abs(page - padding) + 1;
        start = 1;
    }

    let end = page + padding + r;

    if(end > maxPage){
        let el = end - maxPage;
        start = start - el;
        if(start < 1) start = 1;
        end = maxPage;
    }

    let Nums = [];
    for (let i = start; i <= end; i++) {
        Nums.push(<Num key={i} num={i} page={page} onPageMove={onPageMove}/>);
    }

    let beforePage = (page - 1 > 0) ? page - 1 : 1;
    let afterPage = (page + 1 > maxPage) ? maxPage : page + 1;

    return (
        <div className={"paging-wrap"}>
            <div className={"paging-button"}>
                <button className={"btn-rew"} onClick={() => onPageMove(1)}
                        disabled={(page === 1)}
                >{""}</button>
                <button className={"btn-back"} onClick={() => onPageMove(beforePage)}
                        disabled={(page === beforePage)}
                >{""}</button>
                {Nums}
                <button className={"btn-next"} onClick={() => onPageMove(afterPage)}
                        disabled={(page === afterPage)}
                >{""}</button>
                <button className={"btn-ff"} onClick={() => onPageMove(maxPage)}
                        disabled={(page === maxPage)}
                >{""}</button>
            </div>
        </div>
    )
};

export default Pagination;
