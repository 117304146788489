import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {yyyymmdd} from "../../util/Date";
import swal from "sweetalert";
import {NaverMap, Marker} from 'react-naver-maps';
import * as DataApi from "../../api/Data";
import SelectSearch from 'react-select-search';
import SubNavNew from "../../components/SubNavNew";
import {delCookie} from "../../util/Cookie";

// import ImgCity from "../../assets/images/img_data_city@2x.png";
// import ImgAnalytics from "../../assets/images/img_data_analytics@2x.png";
// import SlideShow from "../../components/SlideShow"
// import CenterIco from "../../assets/images/ic_center.svg"
// import AnalysisIco from "../../assets/images/ic_analysis.svg"
// import NoticeIco from "../../assets/images/ic_notice.svg"
// import LocationIco from "../../assets/images/ic_location.svg"
// import CalendarIco from "../../assets/images/ic_calendar.svg"
// import * as OpenAPI from "../../api/OpenApi";

import PublicDataIcon from "../../assets/images/ico_data_folder_1@2x.png"
import PrivateDataIcon from "../../assets/images/ico_data_folder_2@2x.png"
import swal2 from "sweetalert2";
import * as PointAPI from "../../api/User";

function NaverMapAPI() {
    let agent = navigator.userAgent.toLowerCase();
    if (agent.indexOf("msie") !== -1) {
        if (document.getElementById("maps") !== null) {
            let maps_obj = window.naver.maps;
            var mapOptions = {
                center: new maps_obj.LatLng(37.300080, 127.046666),
                zoom: 18
            };
            let naver_maps = new maps_obj.Map('maps', mapOptions);
            var marker = new maps_obj.Marker({
                position: new maps_obj.LatLng(37.300080, 127.046666),
                map: naver_maps
            });
        }
        return (
            <div id="maps" style={{"width": "100%", "height": "100%"}}/>
        )
    }
    const navermaps = window.naver.maps;
    return (
        
        <NaverMap
            mapDivId={'maps-getting-started-uncontrolled'} // default: react-naver-map
            style={{
                width: '100%', // 네이버지도 가로 길이
                height: '100%', // 네이버지도 세로 길이
                border: "1px solid #00000033",
                borderRadius: "10px"
            }}
            defaultCenter={{lat: 37.300080, lng: 127.046666}} // 지도 초기 위치
            defaultZoom={18} // 지도 초기 확대 배율
        >
            <Marker
                key={1}
                position={new navermaps.LatLng(37.300080, 127.046666)}
                animation={0}
            />
        </NaverMap>
    );
}

const AnalysisService = (props) => {

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDateYear, setSelectedDateYear] = useState(selectedDate.getFullYear());
    const [selectedDateMonth, setSelectedDateMonth] = useState(selectedDate.getMonth() + 1);
    const [selectedDateDay, setSelectedDateDay] = useState(selectedDate.getDate());
    const [pointFlag, setPointFlag] = useState(false);
    const categoryPurpose = useSelector(state => {
        let rst = [];

        for (let [name, display] of Object.entries(state.user.categoryPurpose)) {
            rst.push({
                name: display,
                value: name
            })
        }

        return rst;
    });

    const [selectCategoryPurpose, setSelectCategoryPurpose] = useState(categoryPurpose[0].value);
    const [memo, setMemo] = useState("");

    const proposalType = [
        {
            name: "온라인",
            value: "online"
        },
        {
            name: "오프라인",
            value: "offline"
        }];

    const subNav = [
        {
            display: "분석 서비스 신청",
            name: "/analysisService"
        }
    ];


    const [selectProposalType, setSelectProposalType] = useState(proposalType[0].value);

    useEffect(() => {
        var list, index;
        list = document.getElementsByClassName("select-search__input");
        for (index = 0; index < list.length; ++index) {
            list[index].setAttribute("title", "이용 신청");
        }
    }, [])
    useEffect(() => {
        const t = new Date(selectedDate);
        t.setFullYear(selectedDateYear);

        setSelectedDate(t);
        setSelectedDateMonth(false);
        setSelectedDateDay(false);
    }, [selectedDateYear]);

    useEffect(() => {
        const t = new Date(selectedDate);
        t.setMonth(selectedDateMonth);

        setSelectedDateDay(false);
        setSelectedDate(t);
    }, [selectedDateMonth]);

    useEffect(() => {
        const t = new Date(selectedDate);
        t.setDate(selectedDateDay);

        setSelectedDate(t);
    }, [selectedDateDay]);

    useEffect(() => {
        console.log(selectedDate);
    }, [selectedDate]);

    // 포인트 활성화 체크
    useEffect(() => {
        DataApi.readIkanConfig().then(res => {
            setPointFlag(res.result.config_list.filter(config => {
                return config.function_type === "point"
            })[0].function_active)
        })
    })

    const getOptions = (target) => {
        let rst = [];
        let now = new Date();

        if (target === 'date') {
            let startDay = 1;
            if (now.getFullYear() === parseInt(selectedDateYear) && (now.getMonth() + 1) === parseInt(selectedDateMonth))
                startDay = now.getDate() + 1;
            let lastDay = (new Date(selectedDateYear, selectedDateMonth, 0)).getDate();
            for (let a = startDay; a <= lastDay; a++) {
                rst.push({
                    name: a + "일",
                    value: a.toString()
                })
            }
        } else if (target === 'month') {
            let startMonth = 1;
            if (now.getFullYear() === parseInt(selectedDateYear))
                startMonth = now.getMonth() + 1;
            for (let a = startMonth; a <= 12; a++) {
                rst.push({
                    name: a + "월",
                    value: a.toString()
                })
            }
        } else if (target === 'year') {
            let nowYear = now.getFullYear();
            rst.push({
                name: nowYear + "년",
                value: nowYear.toString()
            });
            rst.push({
                name: (nowYear + 1) + "년",
                value: (nowYear + 1).toString()
            });
        }

        return rst;
    };

    const userInfo = useSelector(state => state.user.info);
    const isLogin = useSelector(state => state.user.isLogin);

    useEffect(() => {
        if(!isLogin) {
            swal({
                text: "로그인 후 이용 가능한 서비스입니다.",
                buttons: [
                    "확인",
                    "로그인"
                ]
            }).then(function (isConfirm) {
                if(isConfirm) {
                    delCookie("token");
                    window.location.href = "/#/login";
                } else {
                    swal.close();
                }
            })
        }        
    }, [])

    const apply = () => {

        if (isLogin === false) {
            swal("로그인 이후 신청 가능합니다.");
            return
        }

        if (selectedDateMonth === false) {
            swal("이용하실 날짜의 월을 선택해주세요.");
            return
        }

        if (selectedDateDay === false) {
            swal("이용하실 날짜의 을 선택해주세요.");
            return
        }

        if (selectedDateYear === false) {
            swal("이용하실 날짜의 년을 선택해주세요.");
            return
        }

        const params = {
            user_id: userInfo.id,
            user_name: userInfo.fullname,
            email: userInfo.email,
            phone: userInfo.phone,
            start_date: yyyymmdd(new Date(selectedDateYear, selectedDateMonth - 1, selectedDateDay)) + " 00:00:00",
            end_date: yyyymmdd(new Date(selectedDateYear, selectedDateMonth - 1, selectedDateDay)) + " 23:59:59",
            analysis_purpose: selectCategoryPurpose,
            user_memo: memo,
            analysis_type: selectProposalType
        };

        if (pointFlag) {
            let use_point = 30;
            let confirm_msg = use_point + " 포인트가 차감 됩니다. \n 분석서비스를 신청 하시겠어요?";
            swal2.fire({
                html: '<pre>' + confirm_msg + '</pre>',
                confirmButtonText: "확인",
                cancelButtonText: "취소",
                showCancelButton: true
            }).then((result) => {
                if (result.value) {
                    const point_params = {
                        portal_user_id_key: userInfo.id_key,
                        action_type: "analysis",
                        point: use_point
                    }
                    PointAPI.usePoint(point_params).then(res => {
                        DataApi.proposalAnalysis(params).then(res => {
                            swal("신청이 완료되었습니다.\n신청내역은 마이페이지에서 확인 가능합니다.");
                        }).catch((e) => {
                            let error_response = JSON.parse(e.response)
                            console.log(error_response.result)
                            if (error_response.result === "full proposal") {
                                swal("해당 날짜는 이용신청이 마감되었습니다. \n다른 날짜를 선택해주세요.");
                            } else if (error_response.result === "already proposal") {
                                swal("현재 분석을 신청한 상태입니다. \n분석 신청일 이후에 다시 신청해주세요.");
                            } else {
                                swal("오류가 발생했습니다. \n잠시 후 다시 시도해주세요.");
                            }
                        })
                    }).catch(res => {
                        let msg = JSON.parse(res.response);
                        if (msg.result === "not enough points" || msg.result === "not exist point") {
                            swal("포인트가 부족 합니다. \n 잔여 포인트를 확인해주세요.")
                        } else {
                            swal("오류가 발생했습니다. \n잠시 후 다시 시도해주세요.");
                        }
                    })
                }
            });
        } else {
            DataApi.proposalAnalysis(params).then(res => {
                swal("신청이 완료되었습니다.\n신청내역은 마이페이지에서 확인 가능합니다.");
            }).catch((e) => {
                const error_response = JSON.parse(e.response)
                if (error_response.result === "full proposal") {
                    swal("해당 날짜는 이용신청이 마감되었습니다. \n다른 날짜를 선택해주세요.");
                } else if (error_response.result === "already proposal") {
                    swal("현재 분석을 신청한 상태입니다. \n분석 신청일 이후에 다시 신청해주세요.");
                } else {
                    swal("오류가 발생했습니다. \n잠시 후 다시 시도해주세요.");
                }
            })
        }

    };

    return (
        <>
            {/*<div className={"AnalysisService-wrap"}>*/}
            <div className="renewal">
                <SubNavNew nav={subNav} history={props.history} />
            </div>
            <div className={"wrap-dataset"}>
                <div className={"AnalysisService-inner"}>
                    <h1>데이터 분석 센터</h1>
                    <p>데이터 분석 센터는, 방대하고 다양한 데이터를 통해 빅데이터의 성공적인 발전을 도모하기 위해 누구나 손쉽게 데이터를 분석할 수 있는 무료 공간입니다.</p>
                    {/*<img src={ImgCity} alt={"ImgCity"} className={"ImgCity"}/>*/}
                </div>
            {/*</div>*/}
                <div className={"AnalysisService-wrap3"}>
                    <div className={"AnalysisService-notice-inner"}>
                        {/*<h2><img src={CenterIco} width={"28px"} height={"28px"}/> 센터 내부</h2>*/}
                        {/*<h2>센터 내부</h2>*/}
                        {/*<img src={dataImg1} width={"50%"} alt={"센터 내부"}/>*/}
                        {/*<img src={dataImg2} width={"50%"} alt={"센터 내부"}/>*/}
                        <div>
                            {/*<SlideShow/>*/}
                        </div>
                    </div>
                </div>
                <div className={"AnalysisService-wrap2"}>
                    <div className={"AnalysisService-Reservation"}>
                        {/*<h2><img src={CalendarIco} width={"28px"} height={"28px"}/> 서비스 이용 신청</h2>*/}
                        <h2>서비스 이용 신청</h2>
                        <div className={"wrap2-gap-wrap"}>
                            <label>이용 날짜</label>
                            <div style={{"display": "inline-block"}}>
                                <SelectSearch
                                    options={getOptions('year')}
                                    onChange={option => {
                                        setSelectedDateYear(option)
                                    }}
                                    value={selectedDateYear}
                                />
                            </div>
                            <div style={{"display": "inline-block", "marginLeft": "20px"}}>
                                <SelectSearch
                                    options={getOptions('month')}
                                    onChange={option => {
                                        setSelectedDateMonth(option)
                                    }}
                                    value={selectedDateMonth}
                                    placeholder={"월"}
                                />
                            </div>
                            <div style={{"display": "inline-block", "marginLeft": "20px"}}>
                                <SelectSearch
                                    options={getOptions('date')}
                                    onChange={option => {
                                        setSelectedDateDay(option)
                                    }}
                                    value={selectedDateDay}
                                    placeholder={"일"}
                                />
                            </div>
                        </div>

                        <div className={"wrap2-gap-wrap"}>
                            <label>이용 구분</label>
                            <div style={{"display": "inline-block"}}>
                                <SelectSearch
                                    options={proposalType}
                                    onChange={option => {
                                        setSelectProposalType(option)
                                    }}
                                    value={selectProposalType}
                                    placeholder={"이용 구분 선택"}
                                />
                            </div>
                        </div>

                        <div className={"wrap2-gap-wrap"}>
                            <label>활용 목적</label>
                            <div style={{"display": "inline-block"}}>
                                <SelectSearch
                                    options={categoryPurpose}
                                    onChange={option => {
                                        setSelectCategoryPurpose(option)
                                    }}
                                    value={selectCategoryPurpose}
                                    placeholder={"활용 목적 선택"}
                                />
                            </div>
                        </div>

                        <div className={"wrap2-gap-wrap"}>
                            <label>메모</label>
                            <div className={"AnalysisService-note-wrap"}>
                                <textarea
                                    placeholder="메모"
                                    title="메모"
                                    onChange={e => {
                                        if (e.target.value.length <= 500) {
                                            setMemo(e.target.value);
                                        }
                                    }}/>
                                <p>{memo.length} / 500</p>
                            </div>
                        </div>

                    </div>

                    <div className="btn-group ac wrap2-btn-wrap">
                        <button onClick={e => apply()}>신청</button>
                    </div>
                </div>
                {/*<div className={"AnalysisService-wrap3"}>*/}
                {/*    <div className={"AnalysisService-notice-inner"}>*/}
                {/*        <h2>센터 내부</h2>*/}
                {/*        /!*<img src={dataImg1} width={"50%"} alt={"센터 내부"}/>*!/*/}
                {/*        /!*<img src={dataImg2} width={"50%"} alt={"센터 내부"}/>*!/*/}
                {/*        <div>*/}
                {/*            <SlideShow/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className={"AnalysisService-wrap3"}>
                    <div className={"AnalysisService-notice-inner"}>
                        <div >
                            <h2>보유 데이터</h2>
                            <p style={{marginBottom:"10px"}}>민관에서 수집한 1,000여개의 원시데이터를 8개의 카테고리로 분류하고 나열하여 경기도 데이터 분석센터(오프라인)를 이용하는 이용자들 및 도민들에게 한눈에 보기 좋은 데이터 리스트를 제공합니다.</p>
                        </div>
                        <div className={"AnalysisService-notice-inner-detail"} style={{display:"flex", justifyContent:"center", marginTop:"50px", marginBottom:"50px"}}>
                            <img src={PublicDataIcon} style={{width:"142px",height:"142px", margin:"10px"}}/>
                            <p style={{margin:"10px"}}>공공데이터<br/><br/>분석센터 내 폐쇄 정보통신망에서 데이터 열람이 가능하도록 원천기관의 동의를 취득한 데이터입니다.<br/>
                                경기지역경제포털 등을 통해 공개 데이터를 제공합니다.</p>
                            <img src={PrivateDataIcon} style={{width:"142px",height:"142px",margin:"10px"}}/>
                            <p style={{margin:"10px"}}>민간데이터<br/><br/>분석센터 내 폐쇄 정보통신망에서 데이터 열람이 가능하도록 원천기관의 동의를 취득한 데이터입니다.<br/>
                            경기지역경제포털 등을 통해 공개 데이터를 제공합니다.</p>
                        </div>
                        <h2>분석 환경 안내</h2>
                        <div>
                            <p style={{'fontSize': '18px'}}> 온라인 분석 신청 </p>
                            <ul>
                                <li>- 온라인 분석 환경은 신청 후 나의정보 &gt; 분석신청 내역 &gt; 분석 URL [바로가기]로 들어갈 수 있습니다.</li>
                                <li>- 온라인 분석 환경은 신청날 기준 00:00 ~ 23:59 까지 사용 가능합니다.</li>
                                <li>- 온라인 분석 환경은 사용기간 이후에 초기화가 이루어집니다.</li>
                                <li>- 온라인 분석 신청 시 신청 마감이 되었다면 다른 날짜를 선택하여 신청해주시기 바랍니다.</li>
                                <li>- 온라인 분석 환경은 Jupyter Lab 제공되며 사양은 아래와 같습니다.</li>
                                <li>
                                    <table style={{
                                        "width": "65%",
                                        "border": "0.5px solid rgb(204,204,204)",
                                        "marginTop": "15px"
                                    }}>
                                        <thead>
                                        <tr>
                                            <th style={{"width": "33%", "fontWeight": "500", "textAlign": "center"}}>
                                                CPU
                                            </th>
                                            <th style={{
                                                "width": "33%", "fontWeight": "500", "textAlign": "center",
                                                "borderLeft": "0.5px solid rgb(204,204,204)",
                                                "borderRight": "0.5px solid rgb(204,204,204)"
                                            }}>
                                                Memory
                                            </th>
                                            <th style={{"width": "33%", "fontWeight": "500", "textAlign": "center"}}>
                                                HDD
                                            </th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        <tr>
                                            <th style={{"textAlign": "center"}}>
                                                3.40GHz 1Core
                                            </th>
                                            <th style={{
                                                "textAlign": "center",
                                                "borderLeft": "0.5px solid rgb(204,204,204)",
                                                "borderRight": "0.5px solid rgb(204,204,204)"
                                            }}>
                                                4GB
                                            </th>
                                            <th style={{"textAlign": "center"}}>
                                                50GB
                                            </th>
                                        </tr>

                                        </tbody>

                                    </table>
                                </li>

                            </ul>
                            <br/>
                            <p style={{'fontSize': '18px', "marginTop": "15px"}}> 오프라인 분석 신청 </p>
                            <ul>
                                <li>- 별도 이용료 없이 무료로 이용하실 수 있습니다.</li>
                                <li>- 신청서가 제출되면 평균 2~3일 정도의 검토 과정을 거친 후 승인되며, 사정에 따라 지연될 수 있습니다.</li>
                                <li>- 신청한 이용 시간을 초과하여 이용하실 수 없으므로 이용 시간을 준수하여 주시기 바랍니다.</li>
                                <li>- 사정에 의해 부득이하게 연장이 필요한 경우 '참여소통 &gt; 묻고 답하기'에서 문의하여 주시기 바랍니다.</li>
                                <li>- 센터에서 물과 음료를 제외한 음식물을 반입하거나 섭취하실 수 없습니다.</li>
                                <li>- 데이터 분석과 관련된 이용 목적 외 다른 목적으로 사용하실 수 없으며, 적발 시 법적 제재가 발생할 수 있습니다.</li>
                                <li>- 신청서 작성 시 주민등록번호, 성명, 연락처 및 기타 개인정보가 포함되지 않도록 주의하시기 바랍니다.</li>
                                <li>- 부득이한 사정으로 예고 없이 센터 이용이 불가해질 수 있습니다.</li>
                                <li>- 오프라인 분석 환경은 데스크탑을 사용할 수 있으며 사양은 아래와 같습니다.</li>
                                <li>
                                    <table style={{
                                        "width": "65%",
                                        "border": "0.5px solid rgb(204,204,204)",
                                        "marginTop": "15px"
                                    }}>
                                        <thead>
                                        <tr>
                                            <th style={{"width": "33%", "fontWeight": "500", "textAlign": "center"}}>
                                                CPU
                                            </th>
                                            <th style={{
                                                "width": "33%", "fontWeight": "500", "textAlign": "center",
                                                "borderLeft": "0.5px solid rgb(204,204,204)",
                                                "borderRight": "0.5px solid rgb(204,204,204)"
                                            }}>
                                                Memory
                                            </th>
                                            <th style={{"width": "33%", "fontWeight": "500", "textAlign": "center"}}>
                                                HDD
                                            </th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        <tr>
                                            <th style={{"textAlign": "center"}}>
                                                3.2GHz 6Core
                                            </th>
                                            <th style={{
                                                "textAlign": "center",
                                                "borderLeft": "0.5px solid rgb(204,204,204)",
                                                "borderRight": "0.5px solid rgb(204,204,204)"
                                            }}>
                                                32GB
                                            </th>
                                            <th style={{"textAlign": "center"}}>
                                                10TB
                                            </th>
                                        </tr>

                                        </tbody>

                                    </table>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={"AnalysisService-wrap4"}>
                    <div className={"AnalysisService-notice-inner"}>
                        {/*<h2><img src={LocationIco} width={"28px"} height={"28px"}/> 위치</h2>*/}
                        <h2>위치</h2>
                        <ul>수원시 영통구 대학4로 17 에이스광교타워 1차 614-3호(6층)</ul>
                        <ul>&nbsp;</ul>
                        <div className={"AnalysisService-map"}>
                            <NaverMapAPI />
                        </div>
                        <div>
                            <ul>
                                <li>&nbsp;</li>
                                <h2>대중 교통</h2>
                                <p style={{'fontSize': '18px'}}> 지하철 </p>
                                <li>- 광교역 신분당선 1번 출구 도보 5분 거리</li>
                                <li>&nbsp;</li>
                                <p style={{'fontSize': '18px'}}> 버스 </p>
                                <li>- 400-4번, 6번 광교사회복지관에서 하차 시 도보 1분 거리</li>
                                <li>- 5-3번, 400번, 400-4번, 1007-1번, 2번, 6번 광교역, 광교대학로에서 하차 시 도보 5분 거리</li>
                                <li>&nbsp;</li>
                                <h2>주차요금 안내</h2>

                                <table className="tableStyle">
                                    <tbody>
                                        <tr>
                                            <th scope="row" rowSpan="2" className="tableStyle">에이스광교타워 주차장</th>
                                            <th scope="row" className="tableStyle">주차시간</th>
                                            <td className="tableStyle">30분(추가30분당)</td>
                                            <td className="tableStyle">1일</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="tableStyle">주차요금</th>
                                            <td className="tableStyle">200원(1,000원)</td>
                                            <td className="tableStyle">15,000원</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" rowSpan="2" className="tableStyle">광교대학로 공영주차장(294m)</th>
                                            <th scope="row" className="tableStyle">주차시간</th>
                                            <td className="tableStyle">30분(추가10분)</td>
                                            <td className="tableStyle">1일</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="tableStyle">주차요금</th>
                                            <td className="tableStyle">600원(200원)</td>
                                            <td className="tableStyle">7,000원</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default AnalysisService;

