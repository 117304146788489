import React, { Component, Fragment } from 'react';
import { getParamsFromQueryString } from "../../util/Url";
import { timestampJavaScriptToPython } from "../../util/Date";
import { Link } from 'react-router-dom';
import { addComma } from "../../util/Number"
import OptData from "../../pages/common/OptionData.json";
import downArrow from "../../assets/images/ic_down_fillarrow.svg"
import upArrow from "../../assets/images/ic_up_fillarrow.svg"
import downSelectedArrow from "../../assets/images/ic_down_selected_fillarrow.svg"
import upSelectedArrow from "../../assets/images/ic_up_selected_fillarrow.svg"
import AutocompleteSearchDataset from '../../components/search/AutocompleteSearchDataset';

class DatasetFilterNew extends Component {
    
    constructor(props){
        super(props);
        let qs = getParamsFromQueryString(props.history.location.search);
        if(Array.isArray(qs.word) && qs.word.length > 0)
            qs.word = qs.word[0];

        this.uri = props.history.location.search;
        this.filter = {};

        this.page = props.page;

        this.state = {
            selectedTags: qs.hasOwnProperty("tag") ? JSON.parse(JSON.stringify(qs.tag)) : [],
            selectedFormats: qs.hasOwnProperty("format") ? JSON.parse(JSON.stringify(qs.format)) : [],
            selectedCategory: qs.hasOwnProperty("category") ? JSON.parse(JSON.stringify(qs.category)) : [],
            selectedCenter_code: qs.hasOwnProperty("center_code") ? JSON.parse(JSON.stringify(qs.center_code)) : [],
            searchKeyword: qs.hasOwnProperty("word") ? JSON.parse(JSON.stringify(qs.word)) : "",
            selectedPayTags: qs.hasOwnProperty("paytag") ? JSON.parse(JSON.stringify(qs.paytag)) : [],
            searchKeywordDummy: qs.hasOwnProperty("word") ? JSON.parse(JSON.stringify(qs.word)) : "",
            selectedStartDate: null,
            selectedEndDate: null,
            selectedDateRange: "all",
            selectedDateField: "created",
            selectedSort: "",
            detailFlag: false,
            detailIco: downArrow,
            yScrollValue: null,
            allSelectedItems: [],
            filterBox: true,
        }
        if(qs.hasOwnProperty("category")){
            this.state.allSelectedItems.push(JSON.parse(JSON.stringify(qs.category)).pop())
            this.state.selectedTags.push(JSON.parse(JSON.stringify(qs.category)).pop())
        }
        if(qs.hasOwnProperty("paytag")){
            let txt = JSON.parse(JSON.stringify(qs.paytag));
            if(txt == 'pay') {
                this.state.allSelectedItems.push('유료')
                this.state.selectedTags.push('유료')
            }
            else if(txt == 'free') {
                this.state.allSelectedItems.push('무료')
                this.state.selectedTags.push('무료')
            }    
        }
        if(qs.hasOwnProperty("format")){
            this.state.allSelectedItems.push(JSON.parse(JSON.stringify(qs.format)).pop())
            this.state.selectedTags.push(JSON.parse(JSON.stringify(qs.format)).pop())
        }
        if(qs.hasOwnProperty("word")){
            this.state.allSelectedItems.push(JSON.parse(JSON.stringify(qs.word)))
            this.state.selectedTags.push(JSON.parse(JSON.stringify(qs.word)))
        }
        if(qs.hasOwnProperty("center_code")){
            this.state.allSelectedItems.push(JSON.parse(JSON.stringify(qs.center_code)).pop())
            this.state.selectedTags.push(JSON.parse(JSON.stringify(qs.center_code)).pop())
        }        
    }

    componentDidMount(){
        this.updateStateFromQueryString()
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext){
        if(this.uri !== this.props.history.location.search){
            this.updateStateFromQueryString()
        }
    }

    componentWillUpdate(nextProps, nextState, nextContext){
        if(this.uri !== this.props.history.location.search){
            this.uri = this.props.history.location.search;
            return true;
        } else return false;
    }

    updateStateFromQueryString = () => {
        const {tags, formats, onChange, category, history, paytag, sort} = this.props;
        const {selectedTags, selectedFormats, selectedCategory, selectedCenter_code, searchKeyword, selectedPayTags, selectedSort} = this.state;

        let qs = getParamsFromQueryString(history.location.search);
        if(Array.isArray(qs.word) && qs.word.length > 0)
            qs.word = qs.word[0];

        let dst = qs.hasOwnProperty("tag") ? qs.tag : [];
        let dsf = qs.hasOwnProperty("format") ? qs.format : [];
        let dsc = qs.hasOwnProperty("category") ? qs.category : [];
        let dscc = qs.hasOwnProperty("center_code") ? qs.center_code : [];
        let dsk = qs.hasOwnProperty("word") ? qs.word : "";
        let dsp = qs.hasOwnProperty("paytag") ? qs.paytag : [];

        let udt = {};

        if(dst !== selectedTags)
            udt.selectedTags = qs.hasOwnProperty("tag") ? qs.tag : [];
        if(dsf !== selectedFormats)
            udt.selectedFormats = qs.hasOwnProperty("format") ? qs.format : [];
        if(dsc !== selectedCategory)
            udt.selectedCategory = qs.hasOwnProperty("category") ? qs.category : [];
        if(dscc !== selectedCenter_code)
            udt.selectedCenter_code = qs.hasOwnProperty("center_code") ? qs.center_code : [];            
        if(dsk !== searchKeyword)
            udt.searchKeyword = qs.hasOwnProperty("word") ? qs.word : "";
        if(dsp !== selectedPayTags)
            udt.selectedPayTags = qs.hasOwnProperty("paytag") ? qs.paytag : [];

        this.setState({
            ...udt
        }, () => {
            this.changeEventSubmit()
        })
    };

    changeEventSubmit = () => {
        const {tags, formats, onChange, category, history, paytag, sort} = this.props;
        const {selectedTags, selectedFormats, selectedCategory, selectedCenter_code, searchKeyword, selectedPayTags, selectedSort,
            selectedDateRange, selectedDateField, selectedStartDate, selectedEndDate} = this.state;

        let qs = getParamsFromQueryString(history.location.search);
        let page = qs.hasOwnProperty("page") ? qs.page[0] : 1;

        let params = {
            tag: selectedTags,
            format: selectedFormats,
            category: selectedCategory,
            word: searchKeyword,
            paytag: selectedPayTags,
            center_code : selectedCenter_code
        };

        if(selectedDateRange !== undefined && selectedDateRange !== 'all'){
            params["start_date"] = String(selectedStartDate);
            params["end_date"] = String(selectedEndDate);
        }

        if(selectedDateField !== undefined && selectedDateField !== null){
            params["datecolumn"] = selectedDateField;
        }

        if(selectedSort !== undefined && selectedSort !== null){
            params["sort"] = selectedSort;
        }

        if(JSON.stringify(params) !== JSON.stringify(this.filter)){
            page = 1;
        }

        if(JSON.stringify(params) !== JSON.stringify(this.filter) || this.page != page){
             this.setState({
                searchKeywordDummy: searchKeyword
            }, () => {
                this.filter = JSON.parse(JSON.stringify(params));
                this.page = page;
                let param = JSON.parse(JSON.stringify(params));
                onChange(param);
            })
        }
        if(this.state.yScrollValue != null){
            document.querySelector("#root").scrollTo(0,this.state.yScrollValue);
        }
    };
    
    // flag = in selectBox of Date.
    getFromTo(flag){
        let from = null;
        let to = null;

        if(flag !== 'all'){
            from = new Date();
            to = new Date();
            if(flag === '1month'){
                from.setMonth(from.getMonth() - 1);
            }else if(flag === '3month'){
                from.setMonth(from.getMonth() - 3);
            }else if(flag === '6month'){
                from.setMonth(from.getMonth() - 6);
            }
            // divide 1000 => for python (backend).
            from = timestampJavaScriptToPython(from.getTime());
            to = timestampJavaScriptToPython(to.getTime());
        }
        return {"from": from, "to": to};
    };

    setSelectedTags(after){
        const yScrollValue = document.querySelector('#root').scrollTop;   
        this.setState({
            selectedTags: after,
            yScrollValue: yScrollValue
        }, () => {
            this.changeEventSubmit()
        })
    }

    setSelectedFormats(after){
        const yScrollValue = document.querySelector('#root').scrollTop;   
        this.setState({
            selectedFormats: after,
            yScrollValue: yScrollValue
        }, () => {
            this.changeEventSubmit()
        })
    }

    setSelectedCategory(after){
        const yScrollValue = document.querySelector('#root').scrollTop;   
        this.setState({
            selectedCategory: after,
            yScrollValue: yScrollValue
        }, () => {
            this.changeEventSubmit()
        })
    }

    setSelectedOrgan(after){
        const yScrollValue = document.querySelector('#root').scrollTop;   
        this.setState({
            selectedOrgan: after,
            yScrollValue: yScrollValue
        }, () => {
            this.changeEventSubmit()
        })
    }    

    setSelectedPayTags(after){
        const yScrollValue = document.querySelector('#root').scrollTop;   
        this.setState({
            selectedPayTags: after,
            yScrollValue: yScrollValue
        }, () => {
            this.changeEventSubmit()
        })
    }

    setSearchKeyword(after){
        const yScrollValue = document.querySelector('#root').scrollTop;   
        this.setState({
            searchKeyword: after,
            yScrollValue: yScrollValue
        }, () => {
            this.changeEventSubmit()
        })
    }

    setSelectedCenter_codes(after){
        const yScrollValue = document.querySelector('#root').scrollTop;   
        this.setState({
            selectedCenter_code: after,
            yScrollValue: yScrollValue
        }, () => {
            this.changeEventSubmit()
        })
    }

    setSelectedDateRange(after){
        const yScrollValue = document.querySelector('#root').scrollTop;           
        let {from, to} = this.getFromTo(after);
        this.setState({
            selectedDateRange: after,
            selectedStartDate: from,
            selectedEndDate: to,
            yScrollValue: yScrollValue            
        }, () => {
            this.changeEventSubmit()
        })
    }

    setSelectedDateField(after){
        const yScrollValue = document.querySelector('#root').scrollTop;        
        this.setState({
            selectedDateField: after,
            yScrollValue: yScrollValue
        }, () => {
            this.changeEventSubmit()
        })
    }

    setSortText(field, direction){
        let sorttext = null;
        const yScrollValue = document.querySelector('#root').scrollTop;

        if(typeof field === 'string' && typeof direction === 'string'){
            sorttext = [field, direction].join(" ");

            // 동일 버튼 클릭 시 정렬 해제
            // if(!this.state.selectedSort){
            //     sorttext = [field, direction].join(" ");
            // }else{
            //     let temp = this.state.selectedSort.split(" ")
            //     if(temp.length === 2 && field === temp[0] && direction === temp[1]){
            //         sorttext = null;
            //     }else{
            //         sorttext = [field, direction].join(" ");
            //     }
            // }
        }
        this.setState({
            selectedSort: sorttext,
            yScrollValue: yScrollValue
        }, () => {
            this.changeEventSubmit();
        })
    }

    setAllSelectedItems(value) {
        const yScrollValue = document.querySelector('#root').scrollTop;        
        this.setState({
            allSelectedItems: value,
            yScrollValue: yScrollValue
        })
    }

    setFilterBox() {
        this.setState({
            filterBox: !this.state.filterBox,
        })
        console.log(this.state.filterBox);
    }


    getCenterList = () => {
        // 센터 코드 추가는 백엔드 서버와 DB를 검토해야 한다.
        return OptData.search_detail.center_code;
    }

    getDataDateList = () => {
        // 만약 플래그가 추가된다면, getFromTo 에도 start와 end를 정의해야 한다.
        return OptData.search_detail.date_range;
    }

    getDateFieldList = () => {
        // 날짜 필드의 추가/삭제/변경 은 CKAN의 스키마를 검토해야 한다.
        return OptData.search_detail.date_field;
    }

    getCurrentSortState = (btnfield, btndirection) => {
        if(!this.state.selectedSort){
            return false;
        }
        let temp = this.state.selectedSort.split(" ")
        if(temp.length === 2){
            if(btnfield === temp[0] && btndirection === temp[1]){
                return true;
            }
        }
        return false;
    }

    paytagToDisplay = (txt) => {
        if(txt === 'pay') return '유료'
        else if(txt === 'free') return '무료'
        return txt
    }

    handleSearchBtn = (word) => {
        // const {tags, formats, onChange, category, history, paytag} = this.props;
        this.setState({searchKeywordDummy: word})
        const {searchKeywordDummy} = this.state;
        this.handleChange("word", word, true);
        this.setSearchKeyword(word);
    };

    handleChange = (target, name, checked) => {

        const {selectedTags, selectedFormats, selectedCategory, searchKeyword, selectedPayTags, allSelectedItems, selectedCenter_code} = this.state;

        let selectedState = [];
        let selectedTag = [];
        selectedTag = JSON.parse(JSON.stringify(selectedTags));

        const selectTypeObj = {
            "tags" : selectedTags,
            "formats" : selectedFormats,
            "category" : selectedCategory,
            "paytag" : selectedPayTags,
            "center_code" : selectedCenter_code,
            "word" : searchKeyword,
        };

        selectedState = JSON.parse(JSON.stringify(selectTypeObj[target]));

        if(checked && name.length > 0){
            selectedTag.push(name);
        }else{
            const index = selectedState.indexOf(name);
            if(index > -1){
                selectedTag.splice(index, 1);
            }
        }

        // searchkeyword는 배열이 아니여서 따로 구분
        if(checked && target === "word"){
            selectedState = name;

            if(searchKeyword != ""){
                allSelectedItems.splice(allSelectedItems.indexOf(searchKeyword), 1);
            }
            const index = allSelectedItems.indexOf(name);
            if(index > -1){
                allSelectedItems.splice(index, 1);            
            }
        }else if(checked){
            selectedState.push(name);
        }else{
            const index = selectedState.indexOf(name);
            if(index > -1){
                selectedState.splice(index, 1);
            }
        }

        switch(target){
            case "tags" : this.setSelectedTags(selectedState); break;
            case "formats" : this.setSelectedFormats(selectedState); break;
            case "category" : this.setSelectedCategory(selectedState); break;
            case "paytag" : this.setSelectedPayTags(selectedState); break;
            case "center_code" : this.setSelectedCenter_codes(selectedState); break;
            case "word" : this.setSearchKeyword(selectedState); break;
            default: break;
        }

        if(target === "paytag") {name = this.paytagToDisplay(name)};        
        if (checked && name.length > 0)
            allSelectedItems.push(name);
        else {
            let index = allSelectedItems.indexOf(name);
            if(index > -1){
                allSelectedItems.splice(allSelectedItems.indexOf(name), 1);
            }            
        }

        this.setAllSelectedItems([...allSelectedItems]);
    };   

    detailSearchShowAndHide = () => {
        if (this.state.detailFlag) {
            this.setState({
                detailFlag: false,
                detailIco: downArrow
            })
        } else {
            this.setState({
                detailFlag: true,
                detailIco: upArrow
            })
        }
    };

    resetTag = () => {
        this.setState({
            selectedTags: [],
            selectedFormats: [],
            selectedCategory: [],
            selectedOrgan: [],
            searchKeyword: "",
            selectedPayTags: [],
            allSelectedItems: [],
            selectedSort: "",
            selectedCenter_code: [],
        }, () => {
            this.changeEventSubmit()
        })
    }

    activeCheckBox = (targets, display_name, check_val) => {
        this.handleChange(targets, display_name, check_val)
    }

    filterBox = (e) => {
        e.preventDefault();
        this.setFilterBox();
    }


    render() {
        const CENTERS = OptData.search_detail.center_code;
        const {tags, formats, onChange, category, history, paytag, centers} = this.props;
        const {
            selectedFormats, selectedCategory,
            selectedPayTags, selectedCenter_code,
            allSelectedItems, filterBox
        } = this.state;

        return (
            <Fragment>
                    <div className="inner dataset">
                        {/*  */}
                        <div className={filterBox ? "boxWrap on" : "boxWrap"}>
                            <div className="inner-tit">
                                <h6>데이터 상품
                                    <span>상세검색</span>
                                    <p>검색결과({(+this.props.totalCount)?.toLocaleString()})</p>
                                </h6>
                                <a href="#more" title="더보기" onClick={this.filterBox}>&nbsp;</a>
                            </div>
                            <div className="inner-con">
                                <div className="category">

                                    {/* 전체 카테고리 */}
                                    <div className="inputWrap all" style={{flex: 1.5}}>
                                        <div className="title">
                                            <input
                                                type="checkbox"
                                                id="cbx_chkAll"
                                                value="category" 
                                                checked={category.length === selectedCategory.length}
                                                onChange={e => this.handleChangeAll("category", e.target.value, e.target.checked)}
                                                disabled={true}
                                                hidden={true}
                                            />
                                            <label htmlFor="cbx_chkAll">전체 카테고리</label>
                                        </div>
                                        {category.length > 0
                                            ? <ul>
                                                <li>
                                                    <ul>
                                                        {category.filter((_c, idx) => idx < category.length / 2).map(category =>
                                                        <li key={category.display_name}>
                                                                <input tabIndex={"-1"} type="checkbox" id={"category" + category.display_name}
                                                                    checked={selectedCategory.includes(category.display_name)}
                                                                    onChange={e => this.handleChange("category", category.display_name, e.target.checked)}/>
                                                                <label tabIndex={"0"} htmlFor={"category" + category.display_name}
                                                                    onKeyDown={e => e.keyCode === 13 && this.activeCheckBox("category", category.display_name, !document.getElementById("category" + category.display_name).checked)}>
                                                                    <span>{category.display_name}</span>
                                                                </label>
                                                        </li>
                                                        )}
                                                    </ul>
                                                </li>
                                                <li>
                                                    <ul>
                                                        {category.filter((_c, idx) => idx >= category.length / 2).map(category =>
                                                        <li key={category.display_name}>
                                                                <input tabIndex={"-1"} type="checkbox" id={"category" + category.display_name}
                                                                    checked={selectedCategory.includes(category.display_name)}
                                                                    onChange={e => this.handleChange("category", category.display_name, e.target.checked)}/>
                                                                <label tabIndex={"0"} htmlFor={"category" + category.display_name}
                                                                    onKeyDown={e => e.keyCode === 13 && this.activeCheckBox("category", category.display_name, !document.getElementById("category" + category.display_name).checked)}>
                                                                    <span>{category.display_name}</span>
                                                                </label>
                                                        </li>
                                                        )}
                                                    </ul>
                                                </li>
                                            </ul>
                                            : 
                                            <div className={"ds-2-no-result"}>검색 결과가 없습니다.</div>
                                        }
                                    </div>

                                    {/* 판매기관,업체 */}
                                    <div className="inputWrap organ" style={{flex: 2}}>
                                        <div className="title">
                                            <input type="checkbox" 
                                                   id="cbx_chkORGAN" 
                                                   disabled={true}
                                                   hidden={true}/>
                                            <label htmlFor="cbx_chkORGAN">판매기관 · 업체</label>
                                        </div>
                                        
                                        {centers.length > 0
                                            ? <ul>
                                                <li>
                                                    <ul>
                                                        {centers.filter((_c, idx) => idx < centers.length / 2).map(center =>
                                                            <li key={center.name}>
                                                                <input tabIndex={"-1"} type="checkbox" id={"center_code" + center.name}
                                                                    checked={selectedCenter_code.includes(center.name)}
                                                                    onChange={e => this.handleChange("center_code", center.name, e.target.checked)}/>
                                                                <label tabIndex={"0"} htmlFor={"center_code" + center.name}
                                                                    onKeyDown={e => e.keyCode === 13 && this.activeCheckBox("center_code", center.name, !document.getElementById("center" + center.name).checked)}>
                                                                    <span>{CENTERS.find(c => c.value === center.name)?.name}</span>
                                                                </label>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </li>
                                                <li>
                                                    <ul>
                                                        {centers.filter((_c, idx) => idx >= centers.length / 2).map(center =>
                                                            <li key={center.name}>
                                                                <input tabIndex={"-1"} type="checkbox" id={"center_code" + center.name}
                                                                    checked={selectedCenter_code.includes(center.name)}
                                                                    onChange={e => this.handleChange("center_code", center.name, e.target.checked)}/>
                                                                <label tabIndex={"0"} htmlFor={"center_code" + center.name}
                                                                    onKeyDown={e => e.keyCode === 13 && this.activeCheckBox("center_code", center.name, !document.getElementById("center" + center.name).checked)}>
                                                                    <span>{CENTERS.find(c => c.value === center.name)?.name}</span>
                                                                </label>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </li>
                                            </ul>
                                            : <div className={"ds-2-no-result"}>검색 결과가 없습니다.</div>
                                        }
                                        
                                    </div>

                                    <div className="inputWrap" style={{flex: 1}}>
                                        {/* 이용조건 */}
                                        <div className="use">
                                            <div className="title">
                                                <input type="checkbox" id="cbx_chkUSE" />
                                                <label htmlFor="cbx_chkUSE">이용조건</label>
                                            </div>
                                            {paytag.length > 0 ? <ul>
                                                    {paytag.map(paytag =>
                                                <li key={paytag.display_name}>
                                                    <input tabIndex={"-1"} type="checkbox" id={"paytag" + paytag.display_name}
                                                        checked={selectedPayTags.includes(paytag.display_name)}
                                                        onChange={e => this.handleChange("paytag", paytag.display_name, e.target.checked)}/>
                                                    <label tabIndex={"0"} htmlFor={"paytag" + paytag.display_name}
                                                        onKeyDown={e => e.keyCode === 13 && this.activeCheckBox("paytag", paytag.display_name, !document.getElementById("paytag" + paytag.display_name).checked)}>
                                                        <span>{this.paytagToDisplay(paytag.display_name)}</span>
                                                    </label>
                                                </li>
                                                    )}
                                            </ul> : <div className={"ds-2-no-result"}>검색 결과가 없습니다.</div>}                                        
                                        </div>

                                        {/* 파일포맷 */}
                                        <div className="format" style={{ marginTop: '25px', paddingTop: '25px', borderTop: 'solid 1px #eee' }}>
                                            <div className="title">
                                                <input type="checkbox" id="cbx_chkFORMAT" />
                                                <label htmlFor="cbx_chkFORMAT">파일포맷</label>
                                            </div>
                                            {formats.length > 0 ? <ul>
                                                    {formats.map(format =>
                                                <li key={format.display_name}>
                                                    <input tabIndex={"-1"} type="checkbox" id={"formats" + format.display_name}
                                                        checked={selectedFormats.includes(format.display_name)}
                                                        onChange={e => this.handleChange("formats", format.display_name, e.target.checked)}/>
                                                    <label tabIndex={"0"} htmlFor={"formats" + format.display_name}
                                                        onKeyDown={e => e.keyCode === 13 && this.activeCheckBox("formats", format.display_name, !document.getElementById("formats" + format.display_name).checked)}>
                                                        <span>{format.display_name}</span>
                                                    </label>
                                                </li>
                                                    )}
                                            </ul> : <div className={"ds-2-no-result"}>검색 결과가 없습니다.</div>}
                                        </div>
                                    </div>
                                    
                                </div>{/* //카테고리 */}
                                <div style={{display:"flex"}}>
                                    <div className="search" style={{flexGrow:"1"}}>
                                        <div className="keyword">
                                            <Link to="#void" className="reset" onClick={this.resetTag}>초기화</Link>
                                            <div>
                                                {allSelectedItems.map(item => (
                                                    <span key={"selectedTag"+item}>#{item}</span>
                                                ))}
                                            </div>
                                    </div>
                                    </div>{/* //검색바*/}
                                    <div className="search-bar" style={{flexGrow:"1"}}>
                                        <AutocompleteSearchDataset search={this.handleSearchBtn}/>
                                    </div>
                                </div>
                                    {/* <input type="text" placeholder="데이터를 검색해보세요." 
                                        onKeyDown={e => e.keyCode === 13 && this.handleSearchBtn()}
                                        value={this.state.searchKeywordDummy} onChange={e => this.setState({searchKeywordDummy: e.target.value})}
                                        title={"데이터셋 검색"} autoComplete={"off"}
                                    />
                                    <button onClick={() => this.handleSearchBtn()}>검색</button>                                     */}
                            </div>
                        </div>
                    </div>
                    <div className={"search-result"}>
                        <div>
                            데이터 상품({addComma(this.props.totalCount)})
                        </div>
                        <div>
                            <div style={{"float": "right", "marginLeft": "10px"}}>
                                <p className={"search-order"}>갱신일순</p>
                                {
                                    this.getCurrentSortState("modified", "asc") ?
                                    <>
                                        <button className={"search-order-up-btn"} onClick={() => this.setSortText("modified", "asc")} style={{"background-color": "#79818c"}}>
                                            <img alt={"upSelectArrow"} src={upSelectedArrow} width={"10px"} height={"10px"} className={"search-order-up-img"}/>
                                        </button>
                                    </>
                                    :
                                    <>
                                        <button className={"search-order-up-btn"} onClick={() => this.setSortText("modified", "asc")}>
                                            <img alt={"upArrow"} src={upArrow} width={"10px"} height={"10px"} className={"search-order-up-img"}/>
                                        </button>
                                    </>
                                }
                                {
                                    this.getCurrentSortState("modified", "desc") ?
                                        <button className={"search-order-down-btn"} onClick={() => this.setSortText("modified", "desc")} style={{"background-color": "#79818c"}}>
                                            <img alt={"downSelectArrow"} src={downSelectedArrow} width={"10px"} height={"10px"} className={"search-order-down-img"}/>
                                        </button>
                                    :
                                        <button className={"search-order-down-btn"} onClick={() => this.setSortText("modified", "desc")}>
                                            <img alt={"downArrow"} src={downArrow} width={"10px"} height={"10px"} className={"search-order-down-img"}/>
                                        </button>
                                }
                            </div>

                            <div style={{"float": "right", "marginLeft": "10px"}}>
                                <p className={"search-order"}>등록일순</p>
                                {
                                    this.getCurrentSortState("created", "asc") ?
                                    <>
                                        <button className={"search-order-up-btn"} onClick={() => this.setSortText("created", "asc")} style={{"background-color": "#79818c"}}>
                                            <img alt={"upSelectArrow"} src={upSelectedArrow} width={"10px"} height={"10px"} className={"search-order-up-img"}/>
                                        </button>
                                    </>
                                    :
                                    <>
                                        <button className={"search-order-up-btn"} onClick={() => this.setSortText("created", "asc")}>
                                            <img alt={"upArrow"} src={upArrow} width={"10px"} height={"10px"} className={"search-order-up-img"}/>
                                        </button>
                                    </>
                                }
                                {
                                    this.getCurrentSortState("created", "desc") ?
                                    <>
                                        <button className={"search-order-down-btn"} onClick={() => this.setSortText("created", "desc")} style={{"background-color": "#79818c"}}>
                                            <img alt={"downSelectArrow"} src={downSelectedArrow} width={"10px"} height={"10px"} className={"search-order-down-img"}/>
                                        </button>
                                    </>
                                    :
                                    <>
                                        <button className={"search-order-down-btn"} onClick={() => this.setSortText("created", "desc")}>
                                            <img alt={"downArrow"} src={downArrow} width={"10px"} height={"10px"} className={"search-order-down-img"}/>
                                        </button>
                                    </>
                                }
                            </div>

                            <div style={{"float": "right", "marginLeft": "10px"}}>
                                <p className={"search-order"}>제목순</p>
                                {
                                    this.getCurrentSortState("title", "asc") ?
                                    <>
                                        <button className={"search-order-up-btn"} onClick={() => this.setSortText("title", "asc")} style={{"background-color": "#79818c"}}>
                                            <img alt={"upSelectArrow"} src={upSelectedArrow} width={"10px"} height={"10px"} className={"search-order-up-img"}/>
                                        </button>
                                    </>
                                    :
                                    <>
                                        <button className={"search-order-up-btn"} onClick={() => this.setSortText("title", "asc")}>
                                            <img alt={"upArrow"} src={upArrow} width={"10px"} height={"10px"} className={"search-order-up-img"}/>
                                        </button>
                                    </>
                                }
                                {
                                    this.getCurrentSortState("title", "desc") ?
                                    <>
                                        <button className={"search-order-down-btn"} onClick={() => this.setSortText("title", "desc")} style={{"background-color": "#79818c"}}>
                                            <img alt={"downSelectArrow"} src={downSelectedArrow} width={"10px"} height={"10px"} className={"search-order-down-img"}/>
                                        </button>
                                    </>
                                    :
                                    <>
                                        <button className={"search-order-down-btn"} onClick={() => this.setSortText("title", "desc")}>
                                            <img alt={"downArrow"} src={downArrow} width={"10px"} height={"10px"} className={"search-order-down-img"}/>
                                        </button>
                                    </>
                                }
                            </div>
                        </div>
                    </div>                    
            </Fragment>
        );
    }
}

DatasetFilterNew.defaultProps = {
    onChange: () => {
    },
    tags: [],
    formats: [],
    paytag: []
};

export default DatasetFilterNew;
