import React from 'react';

const DatasetCard = ({title, description, image}) => {

    return (
        <>
            {/* <dl>
                <dt>
                    <img src={image} alt={title}/>
                </dt>
                <dd>
                    <strong>{title}</strong>
                    폰트 1px 올림
                    <p style={{fontSize: "15px"}}>{description}</p>
                </dd>
            </dl> */}
            <a>
                <img src={image} alt="이달의 추천 데이터 이미지" />
                <h4>{title}</h4>
                <p>{description}</p>
            </a>            
        </>
    )
};

export default DatasetCard;
