import {get, post, getNew, postNew} from '../util/HttpRequest';

const ICAN_API_URL = process.env.REACT_APP_ICAN_API_URL;

/**
 * 가맹점 리스트 조회
 * /read/local_stores
 */
export const readStore = (params = {}) => {
    let target = ICAN_API_URL + "/read/local_stores";
    return getNew(target, params);
};

/**
 * 가맹점 검색
 * /read/search_stores
 */
export const searchStore = (params = {}) => {
    let target = ICAN_API_URL + "/read/search_stores";
    return getNew(target, params);
};

/**
 * 시군구 선택
 * /read/search_stores
 */
export const selectCity = (params = {}) => {
    let target = ICAN_API_URL + "/read/local_store_areas";
    return getNew(target, params);
};

// /**
//  * 통합 데이터 조회
//  * /read/interated_data_detail
//  */
// export const readDataIntegrated = (params = {}) => {
//     let target = ICAN_API_URL + "/read/interated_data_detail";
//     return getNew(target, params);
// };

// /**
//  * 데이터 목록 조회
//  * /read/list_data
//  */
// export const readListData = (params = {}) => {
//     let target = ICAN_API_URL + "/read/list_data";
//     return get(target, params);
// };

/**
 * 지역화폐 가맹점 검색
 * /read/search_data
 */
// export const readSearchData = (params = {}) => {
//     let target = ICAN_API_URL + "/read/search_data";
//     params = Object.assign({"from_portal": true}, params);
//     return getNew(target, params);
// };

