export const index = `경기지역경제포털 이용약관

제1장 [총칙]
제1조 [목적]
본 약관은 경기도청과 경기도청이 운영하는 지역경제 빅데이터 플랫폼 (이하“플랫폼”)에서 제공하는 서비스 이용을 하려는 회원간의 권리와 의무, 책임 사항, 그 밖에 필요한 기본적인 사항을 규정함을 목적으로 합니다.

제2조 [이용약관의 효력 및 변경]
① 본 이용약관은 서비스의 이용을 위하여 회원 또는 비회원이 동의를 함으로써 효력이 발생합니다.
② 경기지역경제포털에서는 합리적인 사유가 발생할 경우 본 약관을 변경할 수 있으며, 이 경우 일정한 기간을 정하여 적용일자 및 변경사유를 명시한 사항을 회원 또는 비회원에게 공지 또는 통지합니다.
③ 제2항에 따른 약관의 변경은 회원 또는 비회원이 동의함으로써 그 효력이 발생됩니다. 다만, 제2항에 따른 통지를 하면서 회원 또는 비회원에게 일정한 기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확히 전달하였음에도 회원 또는 비회원이 명시적으로 거부의 의사표시를 하지 아니한 경우에는 회원 또는 비회원이 개정약관에 동의한 것으로 봅니다.
④ 회원 또는 비회원은 경기지역경제포털 서비스를 이용할 시 주기적으로 공지사항을 확인하여야 할 의무가 있습니다.
⑤ 약관의 변경 사실 및 내역을 확인하지 못하여 발생한 모든 손해에 대한 책임은 회원 또는 비회원에게 귀속됩니다.

제3조 [용어 정의]
이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
① ‘데이터 상품’이란 플랫폼에서 거래되는 데이터, API 등 일체의 정형 및 비정형 데이터를 의미합니다.
② ‘서비스’란 ‘회원’이 이용 가능한 “플랫폼”이 제공하는 제반의 혜택을 의미합니다.
③ ‘플랫폼’이란 데이터, UI, API 등을 (이하 “데이터 등”이라 함)을 이용자에게 제공하기 위하여, 데이터를 수집, 처리, 저장, 분석 및 시각화 등을 통하여 회원들에게 제반을 혜택을 제공하는 시스템을 말하며, 아울러 플랫폼을 운영하는 사업자의 의미로도 사용합니다.
④ ‘이용자‘란 ‘플랫폼’에 접속하여 이 약관에 따라 ‘플랫폼’이 제공 하는 서비스를 받는 회원 및 비회원을 말합니다.
⑤ ’회원’이라 함은 ‘플랫폼’에 회원등록을 한 자로서, 계속적으로 ‘플랫폼’이 제공하는 서비스를 이용할 수 있는 자를 말합니다.
⑥ ‘비회원’이라 함은 회원에 가입하지 않고 ‘플랫폼’이 제공하는 서비스를 이용하는 자를 말합니다.
⑦ ‘아이디(ID)’라 함은 ‘회원’의 식별과 서비스 이용을 위하여 ‘회원’이 정하고 ‘플랫폼’이 승인하는 문자 또는 숫자의 조합을 말합니다. ‘아이디(ID)’라 함은 회원의 식별과 서비스 이용을 위하여 ‘플랫폼’이 정한 기준에 따라 이용자가 정한 회원 고유값을 의미 합니다.
⑧ ‘비밀번호(PASSWORD)‘라 함은 ‘회원’이 부여받은 ‘아이디’와 일치되는 ‘회원’임을 확인하고 비밀보호를 위해 ‘회원‘ 자신이 정한 문자 또는 숫자의 조합을 말합니다.
⑨ ‘이용’이란 무료 또는 유료로 데이터 상품의 개별내용을 확인, 수정, 변환, 추출 등 가공할 수 있는 상태로 데이터 상품을 전송하는 것을 의미합니다.
⑩ ‘전송’이란 데이터 상품의 특성에 따라 온라인 파일전송(플랫폼에서 다운로드, API방식, 그 외 플랫폼이 지원하는 프로토콜 활용)의 방법으로 제공되는 것을 의미합니다.
⑪ ‘게시물’이란 플랫폼이 운영하는 게시판 및 커뮤니티에 ‘회원’과 플랫폼 운영자가 올린 글, 이미지, 질문과 대답 및 데이터 분석결과 등을 포함합니다.
⑫ ‘소유권’이란 데이터 상품의 판매를 통해 수익을 발생시킬 수 있는 권리를 의미하며 ‘소유권자’란 해당 권리를 가진 자를 의미합니다.
⑬‘가공’이란 플랫폼에 등록된 데이터 상품을 활용하여 새로운 가치를 가지는 데이터 상품을 만드는 행위를 의미합니다.

제4조 [적용 범위] 
① 본 약관은 경기지역경제포털 서비스 회원에 가입한 후, 본 약관에 동의한 자(이하 ‘회원’이라 합니다)에 대하여 적용합니다. 회원으로 가입하시면 경기지역경제포털 서비스를 제한 없이 자유롭게 활용하실 수 있습니다.
② 본 약관은 회원 또는 비회원에 대한 경기지역경제포털 서비스 제공행위 및 회원 또는 비회원의 경기지역경제포털 서비스 이용행위에 대하여 우선적으로 적용됩니다.

제5조 [약관 등의 명시와 설명 및 개정] 
① ‘플랫폼’은 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소 (소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호, 모사전송번호, 전자우편주소, 사업자등록번호 등을 이용자가 쉽게 알 수 있도록 ‘플랫폼’의 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
② ‘플랫폼’은 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회, 환불조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다.
③ ‘플랫폼’은 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
④ ‘플랫폼’이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 플랫폼 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 ‘플랫폼’은 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.
⑤ ‘플랫폼’이 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지 기간 내에 ‘플랫폼’에 송신하여 ‘플랫폼’의 동의를 받은 경우에는 개정 약관 조항이 적용됩니다.
⑥ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침 및 관계법령 또는 상관례에 따릅니다.

제6조 [서비스의 제공 및 변경]
① ‘플랫폼’은 다음과 같은 업무를 수행합니다.
1. 데이터 또는 분석환경에 대한 정보 제공 및 무료, 유료 구매 계약의 체결
2. 데이터 거래 및 활용을 위한 플랫폼 운영
3. 데이터 상품 분석 환경 제공 서비스 : 회원에게는 구매한 데이터를 분석하기 위한 분석 환경을 제공하며, 사용시에 별도의 추가 비용 발생
4. 빅데이터, AI 관련 교육 및 컨퍼런스 기회 제공
5. 기타 ‘플랫폼’이 정하는 업무
② ‘플랫폼’의 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 데이터 및 분석 환경 상품 정보와 관련된 내용을 변경할 수 있습니다. 이 경우에는 변경된 내용 및 제공일자를 명시하여 현재의 데이터 및 분석 환경 상품 내용을 게시한 곳에 즉시 공지합니다.

제7조 [서비스의 중단]
① ‘플랫폼’은 컴퓨터 등 정보통신설비의 보수점검․교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.
② ‘플랫폼’은 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상하지 않습니다.

제2장 [서비스 이용계약]
제8조 [서비스 이용계약 및 가입신청]
① 플랫폼 서비스를 이용하고자 하는 자는 ‘플랫폼’이 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로써 회원가입을 신청합니다. 가입신청 시 필요한 정보는 사실대로 기재하여야 하며 정해진 인증절차를 준수해야 합니다.
② 회원가입신청서에는 다음 사항을 기재해야 합니다. 1호 내지 3호의 사항은 필수사항이며, 그 외의 사항은 선택사항입니다.
1. ‘회원‘의 성명
2. ’아이디‘와 ’비밀번호‘
3. 전자우편주소
4. 기타 ’플랫폼’이 필요하다고 인정하는 사항

제9조 [서비스 가입 신청의 승낙]
① ‘플랫폼’은 ‘이용자’가 약관의 내용에 대해서 동의를 하고, 서비스 가입신청에 대하여 승낙함을 원칙으로 합니다. 회원은 이메일 인증완료 시 서비스 가입신청에 대한 승낙이 완료 됩니다.
② ‘플랫폼’은 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않을 수 있습니다.
1. 실명이 아니거나 타인의 명의를 이용한 경우
2. 허위의 정보를 기재하거나, 플랫폼에서 제시하는 내용을 기재하지 않은 경우
3. 가입신청자의 귀책사유로 인하여 승인이 불가능한 경우
4. ‘플랫폼’으로부터 자격정지 조치 등을 받은 회원이 그 조치 기간 중에 이용계약을 임의 해지하고 재이용신청을 하는 경우
5. 기타 본 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우 및 플랫폼의 합리적 판단에 의하여 필요하다고 인정되는 경우
③ 제1항에 따른 신청에 있어서 ‘플랫폼’은 전문기관을 통한 실명확인 및 본인인증, 계좌검증 및 사업자등록 확인 등을 요청할 수 있습니다.
④ 서비스 이용계약의 성립시기는 제1항에 따른 ‘플랫폼’의 승낙이 완료되고 해당 내용을 회원에게 발송한 시점으로 합니다.

제10조 [서비스 이용 계약의 종료]
① 회원은 ‘플랫폼’에 언제든지 탈퇴를 요청할 수 있으며 ‘플랫폼’은 즉시 회원탈퇴를 처리합니다.
② 회원이 다음 각 호의 사유에 해당하는 경우, ‘플랫폼’은 회원자격을 제한 및 정지시킬 수 있습니다.
1. 가입 신청 시에 허위 내용을 등록한 경우
2. ‘플랫폼’을 이용하여 구입한 데이터 상품 등의 대금, 기타 ‘플랫폼’ 이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우
3. 다른 사람의 ‘플랫폼’ 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우
4. ‘플랫폼’을 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우
③ ‘플랫폼’이 회원자격을 제한․정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 ‘플랫폼’은 회원자격을 상실시킬 수 있습니다.
④ ‘플랫폼’이 회원자격을 상실시키는 경우에는 회원등록을 말소합니다.
이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.
⑤ 휴면 계정
1. 회원 이용기간 만료 후 1년 이상 플랫폼에 로그인 하지 않을 경우에는 회원의 계정은 휴면 계정으로 전환 되며, 휴면 계정의 개인정보는 다른 계정과 별도로 관리됩니다.
2. 판매자로 활동하던 회원의 계정이 휴면계정으로 전환 될 경우, 구매자가 없는 데이터 상품은 즉시 삭제됩니다.


제3장 [회원정보의 보호]
제11조 [회원정보의 수집과 제공]
① ‘플랫폼’은 이용계약을 위하여 회원이 제공한 정보 외에도 수집목적 또는 이용목적 등을 밝혀 회원으로부터 필요한 정보를 수집하거나 이용할 수 있습니다. 이 경우, 플랫폼은 회원으로부터 정보수집 이용에 대한 동의를 받아야 합니다.
② ‘플랫폼’은 이용계약을 위하여 회원이 제공한 정보를 회원이 동의한 서비스 운영을 위한 목적 이외의 용도로 사용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용·제공단계에서 당해 회원에게 그 목적을 고지하고 동의를 받아야 합니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.

제12조 [회원정보의 변경]
① 회원은 이용신청 시 기재한 사항이 잘못되었거나 변경되었을 경우, 즉시 해당 사항을 수정해야 합니다. 회원정보의 변경은 로그인을 한 후 나의 정보에서 변경할 수 있으며 단, 아이디(ID)는 수정할 수 없음을 원칙으로 합니다.
② ‘플랫폼’은 회원에 대한 통지는 회원이 제공한 이메일 주소에 도달함으로써 통지된 것으로 보며, 수정하지 않은 정보로 인하여 발생하는 손해는 당해 회원이 전적으로 부담하고, ‘플랫폼’은 이에 대하여 어떠한 책임도 지지 않습니다.

제13조 [아이디(ID) 및 비밀번호 관리]
① 아이디(ID), 비밀번호에 대한 관리책임은 회원에게 있으며, 회원은 어떠한 경우에도 본인의 아이디(ID) 또는 비밀번호를 타인에게 양도하거나 대여할 수 없습니다.
② ‘플랫폼’의 귀책사유에 의하지 아니한 아이디(ID) 또는 비밀번호의 유출, 양도, 대여로 인한 손실이나 손해에 대하여 ‘플랫폼’은 어떠한 책임도 지지 않습니다.
③ 회원이 아이디(ID) 또는 비밀번호를 도난당하거나 제3자가 무단으로 이를 사용하고 있음을 인지한 경우, 이를 즉시 ‘플랫폼’에 통보하여야 하고, ‘플랫폼’의 안내가 있는 경우에는 그에 따라야 합니다.

제14조 [개인정보 제공 동의 등]
① ‘플랫폼’이 제3자에게 구매자 개인정보를 제공할 필요가 있는 경우
1) 개인정보를 제공받는 자, 2) 개인정보를 제공받는 자의 개인정보 이용목적,
3) 제공하는 개인정보의 항목, 4) 개인정보를 제공받는 자의 개인정보 보유 및 이용기간을 구매자에게 알리고 동의를 받아야 합니다.
(동의를 받은 사항이 변경되는 경우에도 같습니다.)
② ‘플랫폼’이 제3자에게 구매자의 개인정보를 취급할 수 있도록 업무를 위탁하는 경우에는 1) 개인정보 취급위탁을 받는 자, 2) 개인정보 취급위탁을 하는 업무의 내용을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.) 
다만, 서비스제공에 관한 계약이행을 위해 필요하고 구매자의 편의증진과 관련된 경우에는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에서 정하고 있는 방법으로 개인정보 취급방침을 통해 알림으로써 고지절차와 동의절차를 거치지 않아도 됩니다.
제4장 [서비스의 이용]
제15조 [유료 데이터상품의 구매 등]
① 회원이 유료데이터 상품을 이용하기 위해서는 해당 유료 데이터 상품의 대금을 결재해야 하며, 이와 관련하여 회원이 입력한 정보 및 그 정보와 관련하여 발생하는 책임과 불이익은 전적으로 회원이 부담합니다.
② 회원이 유료 데이터상품을 주문한 후 일정 기간 내에 대금을 결재하지 않을 경우 ‘플랫폼’은 당해 주문을 회원의 동의 없이 취소할 수 있습니다.
③ 회원은 상품 주문 정보를 마이페이지를 통해 확인 할 수 있으며, ‘플랫폼’은 데이터 상품의 주문의 취소 방법 및 절차를 안내합니다.
④ ‘플랫폼’은 회원이 대금 결재 시 사용한 결재수단에 대해 정당한 사용 권한을 가지고 있는지의 여부를 확인 할 수 있으며, 이에 대한 확인이 완료될 때까지 거래 진행을 중지하거나, 확인이 불가한 거래를 취소할 수 있습니다.
⑤ 회원이 실제로 결재하는 금액은 회원이 정한 공급원가(실구매액)에 플랫폼 수수료 및 전자결재서비스 수수료가 포함된 금액이며 구매회원에게 발행되는 구매증빙서(세금계산서, 신용카드매출전표, 현금영수증 등)은 실구매액으로 발행됩니다.
⑥ ‘플랫폼’에서 구매한 데이터상품에 대한 대금지급방법은 다음 각 호의 방법 중 가용한 방법으로 할 수 있습니다.
1. 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체
2. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제
3. 온라인 무통장입금
4. 수령 시 대금지급
5. ‘플랫폼’과 계약을 맺었거나 ‘플랫폼’이 인정한 상품권에 의한 결제
6. 기타 전자적 지급 방법에 의한 대금 지급 등
⑦ ‘플랫폼’은 구매한 데이터 상품을 이용할 수 있는 분석환경을 지원합니다. 분석환경을 이용하기 위해서는 별도의 이용신청을 하여야 하며, 분석환경 이용 시 추가적인 이용수수료가 발생할 수 있습니다.

제16조 [데이터상품 및 분석환경의 이용 등]
① ‘플랫폼’은 회원이 승인 및 취소에 대한 내역을 구매회원에게 통보합니다.
② ‘플랫폼’에서 구매한 데이터는 판매회원이 명시한 이용범위 내에서 활용이 가능합니다. 이를 위반 시 발생하는 손해에 대해서는 이용회원이 책임집니다. ‘플랫폼’에서 거래되는 데이터 상품의 경우에 아래 항목을 공통 이용범위로 합니다.
1. 구매한 데이터 상품을 재판매하는 행위 금지
2. 구매한 데이터 상품에 대한 개인정보 식별 시도 금지
3. 구매한 데이터 상품을 활용∙가공 및 타 데이터와 결합하여 새로운 데이터 상품을 만드는 행위 금지. 단, 구매한 데이터 상품의 회원과 협의된 경우는 제외함
③ ‘플랫폼’에서 제공하는 분석환경은 이용신청 범위 안에서 활용 가능합니다. 단, 분석환경에서 사용되는 데이터의 개인정보보호 등 조치에 대한 책임은 이용자에게 있습니다.
④ 구매 데이터 상품의 전송과 관련하여 판매회원과 이용회원 사이에 발생한 분쟁은 당사자들 간의 해결을 원칙으로 합니다.

제17조 [데이터상품 구매의 취소 및 환불]
① ‘플랫폼’을 통해 거래되는 상품은 디지털화된 상품의 특성상 원칙적으로 청약 철회, 반품, 취소, 환불이 제약됩니다. 다만 아래의 경우에는 예외로 합니다.
1. 구매 승인이 필요한 데이터 상품에 대하여 7일 내 승인을 하지 아니하여 결제가 자동 취소되는 경우
2. 구매회원이 상품 구매 후 상품 다운로드를 하지 않거나 분석환경에서 이용하지 않은 상태에서 구매 완료일로부터 7일 내 환불을 신청한 경우


제5장 [손해배상 및 책임의 제한 등]
제18조 [손해배상]
① 경기지역경제포털은 회원 또는 비회원이 본 이용약관을 준수하지 않는 경우 서비스 사용 중지 및 이용자격을 박탈할 수 있습니다.
② 경기지역경제포털 서비스 이용상 회원 또는 비회원의 귀책사유로 인하여 경기지역경제포털에 손해가 발생한 경우 경기지역경제포털은 본 약관에 따른 계약의 해지와는 별도로 손해배상을 청구할 수 있습니다.
③ 경기지역경제포털 서비스의 이용으로 경기지역경제포털과 회원 또는 비회원간에 발생한 분쟁에 관하여 소송이 제기되는 경우 각 당사자는 자신의 주소지를 관할하는 법원에 소송을 제기할 수 있습니다.
④ 경기지역경제포털은 유료 및 무료서비스의 이용과 관련하여 고의가 없는 한 회원 및 비회원에게 발생한 손해를 배상하지 않습니다.
⑤ 당사자 일방 또는 당사자 일방의 피고용인, 대리인, 기타 도급 및 위임 등으로 당사자 일방을 대신하여 이용계약을 이행하는 자의 책임 있는 사유로 이용계약의 이행과 관련하여 상대방에 손해가 발생한 경우, 그 당사자 일방은 상대방에게 발생한 손해를 배상할 책임이 있습니다.
⑥ 회원이 이용계약을 위반함으로써 ‘플랫폼’의 대외 이미지 실추 등 ‘플랫폼’에 유, 무형적 손해가 발생한 경우, 회원은 손해를 배상하여야 합니다.

제19조 [저작권의 귀속 및 이용제한]
① ‘플랫폼’이 작성한 저작물에 대한 저작권 기타 지적재산권은 ‘플랫폼’에 귀속합니다.
② 이용자는 “플랫폼”을 이용함으로써 얻은 정보 중 ‘플랫폼’에게 지적재산권이 귀속된 정보를 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.

제20조 [서비스 저작권]
① 회원 또는 비회원은 경기지역경제포털 서비스 이용 시 경기지역경제포털 및 제3자의 지식재산권을 침해해서는 안됩니다.
② 회원 또는 비회원은 경기지역경제포털 서비스를 이용하여 검색결과를 노출할 경우, 해당 페이지에 ‘경기지역경제포털 데이터’를 사용한 결과임을 명시해야 합니다. 다만, 경기지역경제포털에서 별도의 표시방식을 정한 경우에는 그에 따라야 합니다.

제21조 [분쟁해결]
① ‘플랫폼’은 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치․운영합니다.
② ‘플랫폼’은 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.
③ 단, ‘플랫폼’은 판매회원이 등록한 상품 및 용역 등에 관한 정보 또는 구매회원과의 거래에 관하여 분쟁이 발생한 경우 ‘플랫폼’은 그 분쟁에 개입하지 않으며 그 분쟁의 결과로 인한 모든 책임은 판매회원 또는 구매회원이 부담하여야 합니다. 또한, 이와 관련하여 플랫폼이 제3자에게 손해 를 배상하거나 기타 비용을 지출한 경우 ‘플랫폼’은 귀책사유 있는 자에게 구상권을 행사할 수 있습니다.
④ ‘플랫폼’과 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.

제22조 [재판권 및 준거법]
① ‘플랫폼’과 이용자 간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방 법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.
② ‘플랫폼’과 이용자 간 제기된 전자상거래 소송에는 대한민국 법률을 적용합니다. 본 약관은 대한민국 법률에 따라 해석되고 규율 되며, 본 약관과 ‘플랫폼’과 가입회원 간의 이용계약 및 회원 상호간의 분쟁에 대해 ‘플랫폼’을 당사자로 하는 소송이 제기될 경우에는 ‘플랫폼’의 본점 소재지를 관할하는 법원을 합의관할법원으로 정하기로 합니다. 

제23조 [경기지역경제포털 서비스 이용 시 주의사항]
① 경기지역경제포털 플랫폼은 관계법령의 제·개정 및 기타 정책적 사유 등에 따라 경기지역경제포털 서비스를 변경하거나 중단할 수 있습니다.
② 경기지역경제포털 플랫폼은 경기지역경제포털 서비스를 운영함에 있어 데이터의 특정 범위를 분할하거나 또는 전체에 대하여 별도의 이용가능 시간 또는 이용가능 횟수를 지정할 수 있으며 이를 사전에 고지하여야 합니다.
③ 회원 또는 비회원은 경기지역경제포털 서비스를 이용한 검색결과를 노출함에 있어 선정적, 폭력적, 혐오적인 내용을 포함하여 반사회적, 비도덕적, 불법적인 내용과 결합 또는 연계하거나 인접하도록 구성할 수 없으며, 검색결과의 공공성을 준수하여야 합니다.
④ 경기지역경제포털은 개인정보 보호정책을 공시하고 준수합니다.

제24조 [회원 또는 비회원의 의무]
① 회원 또는 비회원은 경기지역경제포털 서비스를 이용함에 있어서 본 약관에서 규정하는 사항과 기타 경기지역경제포털이 정한 제반 규정, 공지사항 및 관계법령을 준수하여야 합니다.
② 경기지역경제포털 서비스를 이용함에 있어서 회원 또는 비회원의 행위에 대한 모든 책임은 당사자가 부담하며, 회원은 경기지역경제포털을 대리하는 것으로 오해가 될 수 있는 행위를 해서는 안됩니다.

제25조 [책임의 제한]
① 경기지역경제포털에서 제공하는 서비스 및 데이터에 대한 책임은 데이터를 보유한 제공기관에게 귀속됩니다. 경기지역경제포털 플랫폼에서는 경기지역경제포털 서비스에 관하여 약관, 서비스별 안내, 기타 플랫폼이 정한 이용기준 및 관계법령을 준수하지 않은 이용으로 인한 결과에 대하여 책임을 지지 않습니다.
② 경기지역경제포털 플랫폼은 경기지역경제포털 서비스의 사용불능으로 인하여 회원 또는 비회원에게 발생한 손해에 대하여 책임을 지지 않습니다.
③ 경기지역경제포털 플랫폼은 회원 또는 비회원이 경기지역경제포털 서비스를 이용하여 기대하는 수익을 얻지 못하거나 상실한 것에 대하여 책임을 지지 않습니다.
④ 경기지역경제포털 플랫폼은 회원·비회원·제3자 상호 간에 경기지역경제포털 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며, 이로 인한 손해를 배상할 책임도 없습니다.
⑤ 천재지변, 사변, 기타 이에 준하는 사태가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다. 이 경우 서비스의 제공이 일시적으로 중단됨으로 인하여 회원·비회원 또는 제3자가 입은 손해에 대하여 경기지역경제포털은 책임을 지지 않습니다.
⑥ 경기지역경제포털 플랫폼은 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주, 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우, 기타 불가항력적 사유가 있는 경우 등으로 정상적인 서비스 이용에 지장이 있는 때에는 서비스의 전부 또는 일부를 제한하거나 정지할 수 있습니다. 이 경우 서비스 제공에 관한 책임이 면제되며, 서비스의 전부 또는 일부의 제공이 일시적으로 제한되거나 중단됨으로 인하여 회원·비회원 또는 제3자가 입은 손해에 대하여 배상할 책임이 없습니다.

제26조 [면책조항]
① ‘플랫폼’은 판매회원이 등록한 상품 등에 관한 정보 또는 구매회원과의 거래에 관하여 분쟁이 발생한 경우 플랫폼은 그 분쟁에 개입하지 않으며 그 분쟁의 결과로 인한 모든 책임은 판매회원 또는 구매회원이 부담하여야 합니다. 또한, 이와 관련하여 플랫폼이 제3자에게 손해를 배상하거나 기타 비용을 지출한 경우 플랫폼은 귀책사유 있는 자(판매회원 또는 구매회원)에게 구상권을 행사할 수 있습니다.
② ‘플랫폼’은 적법한 권리자의 요구가 있는 경우에는 당해 상품 등에 관한 정보를 삭제하거나 수정할 수 있으며, 판매회원은 이로 인한 손해배상을 플랫폼에 청구할 수 없습니다.
③ ‘플랫폼’은 「전자상거래 등에서의 소비자보호에 관한 법률」 제20조 제2항에 의거하여 판매회원의 정보를 열람할 수 있는 방법을 구매회원에게 제공할 수 있으며, 판매회원은 당해 정보를 기재하지 아니하거나, 허위로 기재함으로써 발생하는 모든 책임을 부담하여야 합니다. 이와 관련하여 ‘플랫폼’은 제3자에게 손해를 배상하거나 기타 비용을 지출한 경우 ‘플랫폼’은 판매회원에게 구상권을 행사할 수 있습니다.
④ 경기지역경제포털 플랫폼은 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신의 두절, 디도스 공격 또는 이에 준하는 불가항력 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다. 이 경우 서비스 제공에 관한 책임이 면제되며, 서비스의 제공이 일시적으로 중단됨으로 인하여 회원·비회원 또는 제3자가 입은 손해에 대하여 배상할 책임이 없습니다.
⑤ 회원이 자신의 개인정보를 타인에게 유출 또는 제공함으로써 발생하는 피해에 대해서 ‘플랫폼’은 일체의 책임을 지지 않습니다.
⑥ 기타 관련 법령 및 ‘플랫폼’에서 제공한 이용약관 및 개별약관의 변경, 회원 공지사항 등의 주의의무를 게을리하여 발생한 회원의 피해에 대해서 ‘플랫폼’은 일체의 책임을 지지 않기로 합니다.
⑦ 경기지역경제포털 플랫폼은 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
⑧ 경기지역경제포털 플랫폼은 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여는 책임을 지지 않습니다.
⑨ 경기지역경제포털 플랫폼은 회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.
⑩ 경기지역경제포털 플랫폼은 회원 간 또는 회원과 제3자 상호간에 서비스를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다.
⑪ 경기지역경제포털 플랫폼은 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.


부칙
(시행일) 본 약관은 2021년 11월 17일부터 시행합니다.

(시행일) 본 약관은 2020년 1월 21일부터 시행합니다.
`;
