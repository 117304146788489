import {get, post, getNew, postNew, getOpenApi} from '../util/HttpRequest';

const ICAN_API_URL = process.env.REACT_APP_ICAN_API_URL;
const OPEN_API_URL = process.env.REACT_APP_OPENAPI_URL;
/**
 * 인증 키 발급
 * /create/api_key
*/
export const createApiKey = (params = {}) => {
    let target = ICAN_API_URL + "/create/api_key";
    return postNew(target, params);
};

/**
 * 인증 키 불러오기
 * /read/api_key
 */
export const readApiKey = (params = {}) => {
    let target = ICAN_API_URL + "/read/api_key";
    return getNew(target, params);
};

/**
 * 데이터 제공기관 목록 조회
 * /read/center_list
 */
export const readCenterList = (params = {}) => {
    let target = OPEN_API_URL + "/read/center_list";
    return getOpenApi(target, params);
};

/**
 * 데이터 목록 조회
 * /read/data_list
 */
export const readDataList = (params = {}) => {
    let target = OPEN_API_URL + "/read/data_list";
    return getOpenApi(target, params);
};

/**
 * 데이터 조회
 * /read/data
 */
export const readData = (params = {}) => {
    let target = OPEN_API_URL + "/read/data";
    return getOpenApi(target, params);
};

/**
 * 잔여 요청 건수 조회
 * /read/remain_request
 */
export const readRemainKey = (params = {}) => {
    let target = OPEN_API_URL + "/read/remain_request";
    return getOpenApi(target, params);
}
