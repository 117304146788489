export const index = `2021 한국기업데이터(KED) 빅데이터 시각화 아이디어 공모전 개인정보 수집･이용 동의서

경기도경제과학진흥원은 ‘2022 지역경제 빅데이터 활용 시각화 아이디어 공모전’ 추진을 위해 아래와 같이 정보를 수집 및 이용하고자 합니다.

[개인정보 및 고유식별정보 수집·이용·제공 동의서]

■ 개인정보 및 고유식별정보 수집·이용안내

* 수집·이용에 관한 사항
○ 개인정보의 수집·이용 목적
- 「2021 한국기업데이터(KED) 빅데이터 시각화 아이디어 공모전」신청자 및 수상자 관리
- 「2021 한국기업데이터(KED) 빅데이터 시각화 아이디어 공모전」안내 및 지원
수집·이용할 개인정보의 내용
- 작품 접수, 접수 확인, 작품 심사, 수상 발표 시 본인 확인 및 필요한 사항 등 안내를 위해 원활한 의사소통 경로를 확보하고자 최소한의 개인정보를 수집·이용하고자 합니다.

○ 수집하려는 개인정보의 항목
- 수집항목(필수) : 성명, 생년월일, 주소, 연락처(휴대폰 또는 전화, 이메일)
- 수집항목(선택) : 없음

○ 개인정보의 보유 및 이용기간
- 개인정보는 공모전이 종료한 날로 6개월 이내 또는 동의 철회 시까지 보유·이용 할 수 있습니다.
- 보유항목 : 성명, 생년월일, 주소, 연락처(휴대폰 또는 전화, 이메일)
- 보유기간 : 공모전 종료일로부터 6개월

○ 동의를 거부할 권리 및 동의를 거부할 경우의 불이익
위 사항에 동의하지 않으실 수 있으나, 「2021 한국기업데이터(KED) 빅데이터 시각화 아이디어 공모전」 참가를 위해서는 위 개인정보의 수집·이용이 필수적이므로 
동의하지 않으실 경우 참가가 불가능합니다.

▩ 본인은 ｢2021 한국기업데이터(KED) 빅데이터 시각화 아이디어 공모전]에서 본인의 개인정보를 수집･이용하는 것에 동의합니다.
▩ 개인정보 제공자가 동의한 내용 이외의 다른 목적으로 활용하지 않으며, 제공된 개인정보의 이용을 거부하고자 하는 경우 개인정보 관리책임자를 통해 열람, 정정, 삭제를 요구할 수 있습니다.
`
// export const index = `2021 한국기업데이터(KED) 빅데이터 시각화 아이디어 공모전 개인정보 수집･이용 동의서

// 한국기업데이터는 다음의 목적으로 기재된 개인정보를 수집합니다.

// 한국기업데이터가 「2021 한국기업데이터(KED) 빅데이터 시각화 아이디어 공모전」 운영·관리를 위하여 본인의 개인정보를 수집·이용하고자 하는 경우에는 「개인정보보호법」 

// 제15조에 제1항 제1호, 제22조 제3항에 따라 본인의 동의가 필요합니다.

// * 수집·이용에 관한 사항
// ○ 개인정보의 수집·이용 목적
// - 「2021 한국기업데이터(KED) 빅데이터 시각화 아이디어 공모전」신청자 및 수상자 관리
// - 「2021 한국기업데이터(KED) 빅데이터 시각화 아이디어 공모전」안내 및 지원
// 수집·이용할 개인정보의 내용
// - 작품 접수, 접수 확인, 작품 심사, 수상 발표 시 본인 확인 및 필요한 사항 등 안내를 위해 원활한 의사소통 경로를 확보하고자 최소한의 개인정보를 수집·이용하고자 합니다.

// ○ 수집하려는 개인정보의 항목
// - 수집항목(필수) : 성명, 생년월일, 주소, 연락처(휴대폰 또는 전화, 이메일)
// - 수집항목(선택) : 없음

// ○ 개인정보의 보유 및 이용기간
// - 개인정보는 공모전이 종료한 날로 6개월 이내 또는 동의 철회 시까지 보유·이용 할 수 있습니다.
// - 보유항목 : 성명, 생년월일, 주소, 연락처(휴대폰 또는 전화, 이메일)
// - 보유기간 : 공모전 종료일로부터 6개월

// ○ 동의를 거부할 권리 및 동의를 거부할 경우의 불이익
// 위 사항에 동의하지 않으실 수 있으나, 「2021 한국기업데이터(KED) 빅데이터 시각화 아이디어 공모전」 참가를 위해서는 위 개인정보의 수집·이용이 필수적이므로 
// 동의하지 않으실 경우 참가가 불가능합니다.

// ▩ 본인은 ｢2021 한국기업데이터(KED) 빅데이터 시각화 아이디어 공모전]에서 본인의 개인정보를 수집･이용하는 것에 동의합니다.
// ▩ 개인정보 제공자가 동의한 내용 이외의 다른 목적으로 활용하지 않으며, 제공된 개인정보의 이용을 거부하고자 하는 경우 개인정보 관리책임자를 통해 열람, 정정, 삭제를 요구할 수 있습니다.
// `