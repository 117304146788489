import React, {useState, useEffect, useRef} from 'react';
import {useSelector} from "react-redux";
import {yyyymmdd} from "../../util/Date";
import swal from "sweetalert";
import SelectSearch from 'react-select-search';
import SubNavNew from "../../components/SubNavNew";
import {getParamsFromQueryString, serialize} from "../../util/Url";
import OptData from "../../pages/common/OptionData.json";
import swal2 from "sweetalert2";
import Datatable from "../../components/Datatable";
import * as ServiceApi from "../../api/Service";
import {getDistance} from "geolib";
import ServiceNaverMap from './ServiceNaverMap';
import ServiceKakaoMap from './ServiceKakaoMap';
import Skeleton from 'react-loading-skeleton';
import SelectDistrictBox from './selectDistrictBox';
import StorePagination from "./StorePagination";
import LoadingCheckPage from "../LoadingCheckPage/index";
import TableState from '../../components/Datatable/components/TableState';


const StoreService = (props) => {
    
    let qs = getParamsFromQueryString(props.history.location.search);
    let p_search_value = qs.hasOwnProperty("s_value") ? qs.s_value : "";
    let p_search_city = qs.hasOwnProperty("s_city") ? qs.s_city : "";
    let p_search_district = qs.hasOwnProperty("s_district") ? qs.s_district : "";

    const [searchWord, setSearchWord] = useState("");
    const [word, setWord] = useState("");
    const [loading, setLoading] = useState(true);
    const [isLoaded, setIsLoaded] = useState(true);
    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [limit, setLimit] = useState(7);
    const [totCnt, setTotCnt] = useState(0);
    const [storeList, setStoreList] = useState([]);
    const [userLocation, setUserLocation] = useState([37.2893545,127.0535128])
    const [mapCenter, setMapCenter] = useState([37.2893545,127.0535128]);
    const [selectCity, setSelectCity] = useState("수원시");
    const [selectDistrict, setSelectDistrict] = useState("영통구");
    const [selectBoxCity, setSelectBoxCity] = useState([]);
    const [selectBoxDistrict, setSelectBoxDistrict] = useState([]);
    const [location, setLocation] = useState(true);
    const [selectStore, setSelectStore] = useState(null);
    const [dragMoveCenter, setDragMoveCenter] = useState(false);
    const [isMount, setIsMount] = useState(true);
    const [searchParams, setSearchParams] = useState({
        lat: 37.2893545,
        lon: 127.0535128,
        dist: 500
    });

    const userInfo = useSelector(state => state.user.info);
    const isLogin = useSelector(state => state.user.isLogin);


    const subNav = [
        {
            display: "지역화폐 가맹점",
            name: "/storeService"
        }
    ];

    //css
    const searchWrapStyle = {
        display: "inline-block",
        width: "100%",
        border: "2px solid gray",
        borderRadius: "5px"
    }
    const searchInputStyle = {
        width: "100%",
        height: "46px",
        fontSize: "15px",
        borderRadius: "5px",
        border: "0px"
    }
    const searchButtonStyle = {
        height: "35px",
        width: "35px",
        marginLeft: "10px",
        position: "absolute",
        right: "10px",
        top: "5px",
        backgroundSize: "15px 15px",
        borderRadius: "40px"        
    }

    const isSearchStore = (searchWord) => {
        if(searchWord === "") return swal("상호 또는 업종명을 입력해주세요!");
        const set_params = {
            "s_value": searchWord,
            "s_city": selectCity,
            "s_district": selectDistrict
        }
        if(p_search_value[0] !== searchWord){
            setLoading(true);
            props.history.push({
                pathname: window.location.search,
                search: serialize(set_params)
            });
        }
        else {
            // // props.history.replace(props.history.go(0));
            // props.history.replace({
            //     pathname: window.location.search,
            //     search: serialize(set_params)
            // });
            // setIsLoaded(false);
            setPage(1);
        }
    }

    const SearchDistrictStore = () => {
        const set_params = {
            "s_city": selectCity,
            "s_district": selectDistrict
        }
        setLoading(true);
        props.history.push({
            pathname: window.location.search,
            search: serialize(set_params)
        });
    }

    const searchValue = () => {
        setSearchWord('');
        searchWord='';
        isSearchStore(searchWord);
    }

    const changeValue = (value) => {
        setWord(value);
    }    

    // let selectBoxCity = OptData.city;
    // let selectBoxDistrict = OptData.district;

    // let selectBoxCity;
    // let selectBoxDistrict;    

    // useEffect(() => {
    //     if(navigator.geolocation){
    //         navigator.geolocation.getCurrentPosition(success, error)
    //     }
    //     function success(position){
    //         setUserLocation([
    //             position.coords.latitude,
    //             position.coords.longitude
    //         ])
    //         setLocation(true);      
    //     }
    //     function error(){
    //         setUserLocation(
    //             [37.2893545,127.0535128]
    //         )
    //         setLocation(false);        
    //     }
    // })

    useEffect(() => {
        const params = {
            city : "수원시"
        }
        //지역
        ServiceApi.selectCity(params).then(res => {
            const city = res.result.cities;
            const district = res.result.districts;
            setSelectBoxCity(city);
            setSelectBoxDistrict(district);
            // setSelectCity("수원시");
            // setSelectDistrict("영통구");        
        })
    },[])

    useEffect( () => {
        setLoading(true);
        setSearchWord(p_search_value)
        
        if(location){
            const params = {        
                search_value: p_search_value,
                lat: userLocation[0],
                lon: userLocation[1],
                dist: 500
            };
            //가맹점 api
            // ServiceApi.readStore(params).then(res => {
            //     let list = res.result.filter((store) => store.status === "01") //휴업, 폐업 상태 제외
            //                          .filter((store) => store.latitude !== "") //위치정보값이 없는 가맹점 제외
            //                          .filter((store) => store.latitude !== "None") //위치정보값이 없는 가맹점 제외
            //                          .sort((a, b) => a.store_name.localeCompare(b.store_name))
            //     setStoreList(list, true);
            //     setTotCnt(list.length);
            //     setMaxPage(Math.ceil(list.length / limit));
            //     setLoading(false);
            //     setPage(1);
            //     setMapCenter([userLocation[0],userLocation[1]]);
            // })
        }
    },[userLocation]);

    useEffect(() => {
        const params = {
            city : selectCity
        }
        console.log("useEffect, selectCity");
        //지역   
        ServiceApi.selectCity(params).then(res => {
            //const city = res.result.cities;
            const district = res.result.districts;
            //setSelectBoxCity(city);
            setSelectBoxDistrict(district);
            return district;
        }).then((district) => {
            const set_params = {    
                "s_city": selectCity,
                "s_district": district[0]
            }
            setSelectDistrict(district[0]);
            setLoading(true);
            props.history.push({
                pathname: window.location.search,
                search: serialize(set_params)
            });        
        })        
    }, [selectCity])

    useEffect(() => {
        const set_params = {    
            "s_city": selectCity,
            "s_district": selectDistrict
        }
        setLoading(true);
        props.history.push({
            pathname: window.location.search,
            search: serialize(set_params)
        });        
    }, [selectDistrict])

    //검색
    useEffect( () => {
        // setLoading(true);
        setSearchWord(p_search_value);
        setSearchParams({
            search_value: p_search_value,
            search_city: p_search_city?p_search_city[0]:"수원시",
            search_district: p_search_district?p_search_district[0]:"영통구",
        });
    }, [props.history.location.search]);

    //api에서 가져온 리스트를 수정
    const getDistStore = (list, bool) => {
        let newList = list;

        if(list.length !== 0){
            setMapCenter([parseFloat(list[0].latitude), parseFloat(list[0].longitude)]);
            if(bool){
                newList = list.filter((store) => calDistance(store.latitude, store.longitude) < 500) //반경 500미터 초과 가맹점 제외
                              .filter((store) => store.status === "01") //휴업, 폐업 상태 제외
                              .filter((store) => store.latitude !== "") //위치정보값이 없는 가맹점 제외
            }else{
                newList = list.filter((store) => store.status === "01") //휴업, 폐업 상태 제외
                              .filter((store) => store.latitude !== "") //위치정보값이 없는 가맹점 제외
                              .sort((a, b) => calDistance(a.latitude, a.longitude) - calDistance(b.latitude, b.longitude))
                              .filter((store) => calMapCenterDistance(store.latitude, store.longitude) < 500) //반경 500미터 초과 가맹점 제외
            }
        }        
        // if(newList.length !== 0){
        //     setMapCenter([parseFloat(newList[0].latitude), parseFloat(newList[0].longitude)]);
        // }else{
        //     setMapCenter(userLocation);
        // }        

        return newList;
    }

    //사용자와 가맹점 거리 
    const calDistance = (sLat, sLon) => {
        const user = {latitude:userLocation[0], longitude:userLocation[1]};
        const store = {latitude:sLat, longitude:sLon};
        return getDistance(user,store);
    }

    //맵중심과 가맹점 거리 
    const calMapCenterDistance = (sLat, sLon) => {
        const user = {latitude:mapCenter[0], longitude:mapCenter[1]};
        const store = {latitude:sLat, longitude:sLon};
        return getDistance(user,store);
    }
    
    const changeCity = (value) => {
        const params = {
            city : value
        }
        //지역
        ServiceApi.selectCity(params).then(res => {
            const city = res.result.cities;
            const district = res.result.districts;
            setSelectBoxCity(city);
            setSelectBoxDistrict(district);

            const set_params = {
                "s_city": value,
                "s_district": district[0]
            }
            setSelectCity(value);
            // setLoading(true);
            props.history.push({
                pathname: window.location.search,
                search: serialize(set_params)
            });        
        })
    }

    const changeDistrict = (value) => {
        //지역
        const set_params = {
            "s_city": p_search_city,
            "s_district": value
        }
        setSelectDistrict(value);
        // setLoading(true);
        props.history.push({
            pathname: window.location.search,
            search: serialize(set_params)
        });        
     
    }
    //리스트 클릭시 해당 가맹점으로 세팅
    const handleClickList = (item) => {
        setMapCenter([item.latitude, item.longitude]);
        setSelectStore(item);
    }

    const handleMapCenter = (lat, lon) => {
            setMapCenter([lat, lon]);
            setSearchParams({
                search_value: p_search_value,
                lat: lat,
                lon: lon,
                dist: 500                
            });
    }

    useEffect(() => {
        //가맹점 api
        ServiceApi.readStore(searchParams).then(res => {
            const list = res.result.filter((store) => store.status === "1") //휴업, 폐업 상태 제외
                                   .filter((store) => (store.lot_address !== "" )) //위치정보값이 없는 가맹점 제외
                                   .filter((store) => store.latitude !== "None") //위치정보값이 없는 가맹점 제외
                                   .sort((a, b) => a.store_name.localeCompare(b.store_name));
            return list;                                
        }).then((list) => {
            if(list.length > 0){
                // list = getDistStore(list, false);
                let la;
                let lo;
                if(isMount){
                    la = userLocation[0];
                    lo = userLocation[1];
                }else{
                    la = searchParams.lat?searchParams.lat:list[0].latitude?list[0].latitude:userLocation[0];
                    lo = searchParams.lon?searchParams.lon:list[0].longitude?list[0].longitude:userLocation[1];
                }
                list = list.filter((store) => getDistance({latitude:la, longitude:lo},{latitude:store.latitude, longitude:store.longitude}) < 500); //반경 500미터 초과 가맹점 제외
                setMapCenter([la,lo]);       
                setStoreList(list);
                setTotCnt(list.length);
                setMaxPage(Math.ceil(list.length / limit));
                setLoading(false);
                setPage(1);
            }else{
                setLoading(false);
            }
            setIsMount(false);
        })
    }, [searchParams])

    return (
        <>
            <div className="renewal">
                <SubNavNew nav={subNav} history={props.history} />
            </div>
            <div className={"wrap-dataset"}>
                <div className={"store-inner"}>
                    <p>경기도 지역화폐 가맹점을 확인할 수 있습니다.</p>
                    <div className={"store-select"}>
                        <select>
                            <option>경기도</option>
                        </select>
                        <select value={selectCity} onChange={(e) => setSelectCity(e.target.value)}>
                            {selectBoxCity.map((el) => 
                                <option key={el} value={el}>{el}</option>
                            )}
                        </select>   
                        <select value={selectDistrict} onChange={(e) => changeDistrict(e.target.value)}>
                            {selectBoxDistrict.map((el) => 
                                <option key={el} value={el}>{el}</option>
                            )}
                        </select>
                    </div>   
                </div>
                <div className={"store-wrap"}>
                    <div className={"store-map"} style={{"width":"1180px", "height":"600px", "margin": "15px 0px"}}>
                        {!loading ? 
                            //<ServiceNaverMap pUserLocation={userLocation} pList={storeList} pMapCenter={mapCenter} pSelectStore={selectStore} />
                            <ServiceKakaoMap pUserLocation={userLocation} pList={storeList} pMapCenter={mapCenter} pSelectStore={selectStore} handleMapCenter={(lat, lon) => handleMapCenter(lat,lon)} />
                        :
                            <Skeleton height={600}/>
                        }
                    </div>
                    <div>
                        <div className={"search-wrap"} style={searchWrapStyle}>
                            <input title={"검색창"} type={"text"} id="board_search_input" placeholder={"상호, 업종명을 검색하세요!"}
                                value={word} onChange={ e => changeValue(e.target.value)}
                                onKeyDown={e => e.keyCode === 13 && isSearchStore(word)} style={searchInputStyle}>
                            </input>
                            <button type="button" onClick={() => isSearchStore(word)} style={searchButtonStyle}/>
                        </div>
                    </div>
                    <div>
                        {storeList.length === 0 ?
                            <div style={{
                                "textAlign": "center",
                                "padding": "0px",
                                "color": "#cccccc",
                                "opacity": "1",
                                "fontSize": "1em"
                            }}>
                                검색된 가맹점이 없습니다.
                            </div>
                        :
                            !loading ?
                            <>
                                <Datatable
                                    search={false}           //검색 사용여부
                                    head={[{
                                        name: "store_name",
                                        display: "가맹점",
                                        render: (value, item, no) => <div
                                            style={{textAlign: "center"}} onClick={e => handleClickList(item)}>{value}</div>,
                                            style: {
                                                width: '20%', backgroundColor: '#fff', cursor: 'pointer'
                                            }
                                    }, {
                                        name: "category",
                                        display: "업종명",
                                        render: (value, item, no) => <div
                                            style={{textAlign: "center"}}>{value}</div>,
                                            style: {
                                                width: '15%', backgroundColor: '#fff'
                                            }
                                    }, {
                                        name: "road_address",
                                        display: "소재지 도로명주소",
                                        render: (value, item) => <div 
                                            style={{textAlign: "left"}}>{value}</div>,
                                            style: {
                                                width: '50%', backgroundColor: '#fff'
                                            }
                                    }, 
                                    // {
                                    //     name: "phone_number",
                                    //     display: "전화번호",
                                    //     render: (value, item, no) => <div
                                    //         style={{textAlign: "center"}}>{value}</div>,
                                    //     style: {
                                    //         width: '15%', backgroundColor: '#fff'
                                    //     }
                                    // }
                                    ]}              //head 데이터 (필수)
                                    list={storeList.slice((page - 1) * limit, page * limit)}              //body 데이터 (필수)
                                    paging={false}            //페이지 사용여부
                                    page={page}              //현재 페이지 (필수)
                                    maxPage={Math.ceil(totCnt / limit)}        //전체 페이지 (필수)
                                    padding={2}              //페이지번호개수 (선택)
                                    onPageMove={page => setPage(page)}
                                    tableState={true}        //테이블상태 사용여부 (page필수)
                                    rowTotal={totCnt}        //전체데이터 값 (선택)
                                    limit={false}            //출력개수 사용여부
                                    showLoading={false}    //로딩UI 사용여부
                                    dataTour={"board_body_1"} // 이용안내
                                    usePagination={false} // pagination 사용여부
                                />
                                <div className="renewal">        
                                    <StorePagination 
                                        page={page}
                                        maxPage={Math.ceil(totCnt / limit)}
                                        padding={2}
                                        onPageMove={page => setPage(page)}
                                    />                       
                                </div>
                            </>
                            :
                            <>
                                <LoadingCheckPage/>
                                <Datatable
                                    search={false}           //검색 사용여부
                                    head={[{
                                        name: "store_name",
                                        display: "가맹점",
                                        render: (value, item, no) => <div
                                            style={{textAlign: "center"}}>{value}</div>,
                                        style: {
                                            width: '20%', backgroundColor: '#fff'
                                        }
                                    }, {
                                        name: "category",
                                        display: "업종명",
                                        render: (value, item, no) => <div
                                            style={{textAlign: "center"}}>{value}</div>,
                                        style: {
                                            width: '15%', backgroundColor: '#fff'
                                        }
                                    }, {
                                        name: "road_address",
                                        display: "소재지 도로명주소",
                                        render: (value, item) => <div 
                                            style={{textAlign: "left"}}>{value}</div>,
                                        style: {
                                            width: '50%', backgroundColor: '#fff'
                                        }
                                    }, {
                                        name: "phone_number",
                                        display: "전화번호",
                                        render: (value, item, no) => <div
                                            style={{textAlign: "center"}}>{value}</div>,
                                        style: {
                                            width: '15%', backgroundColor: '#fff'
                                        }
                                    }]}              //head 데이터 (필수)
                                    list={storeList.slice((page - 1) * limit, page * limit)}              //body 데이터 (필수)
                                    paging={false}            //페이지 사용여부
                                    page={page}              //현재 페이지 (필수)
                                    maxPage={Math.ceil(totCnt / limit)}        //전체 페이지 (필수)
                                    padding={2}              //페이지번호개수 (선택)
                                    onPageMove={page => setPage(page)}
                                    tableState={true}        //테이블상태 사용여부 (page필수)
                                    rowTotal={totCnt}        //전체데이터 값 (선택)
                                    limit={false}            //출력개수 사용여부
                                    showLoading={true}    //로딩UI 사용여부
                                    dataTour={"board_body_1"} // 이용안내
                            />
                                <div className="renewal">        
                                    <StorePagination page={page}
                                            maxPage={Math.ceil(totCnt / limit)}
                                            padding={2}
                                            onPageMove={page => setPage(page)}
                                    />                       
                                </div>
                        </>
                        }        
                    </div>
                </div>
            </div>
        </>
    )
};

export default StoreService;

