import React from 'react';

import leftImage from '../../assets/images/prev.png';
import left2Image from '../../assets/images/first.png';
import rightImage from '../../assets/images/next.png';
import right2Image from '../../assets/images/last.png';
import Num from '../../components/Datatable/components/Num';

const Pagination = ({maxPage, page, padding, onPageMove}) => {

    let start = page - padding;

    // 현재페이지 중심으로 페이지 표시
    // if(start < 1){
    //     r = Math.abs(page - padding) + 1;
    //     start = 1;
    // }
    // let end = page + padding + r;

    if(start < 1){
        start = 1;
    }else{
        start = (Math.floor((page - 1) / 5)) * 5 + 1;
    }
    
    let end = Math.ceil(page / 5) * 5;

    if(end > maxPage){
        let el = end - maxPage;
        start = start - el;
        if(start < 1) start = 1;
        end = maxPage;
    }

    let Nums = [];
    let options = [];
    for (let i = start; i <= end; i++) {
        // Nums.push(
        //     <li key={i}><a onClick={e => onPageMove(i, e)} href="#page" className={page === i ? 'active' : ''} style={{ background: page === i ? '#eee' : '' }}>
        //         {i}
        //     </a></li>
        // );
        // Nums.push(<Num key={i} num={i} page={page} onPageMove={onPageMove}/>);

        Nums.push(
            <li key={i}><span onClick={e => onPageMove(i, e)} className={page === i ? 'active' : ''} style={{ background: page === i ? '#eee' : '' }}>
                {i}
            </span></li>
        );        
    }

    // let beforePage = (page - 1 > 0) ? page + 1 : 1;
    // let afterPage = (page + 1 > maxPage) ? maxPage : page + 1;
    let beforePage = (page - 5 > 0) ? end - 5 : 1;
    let afterPage = (page + 1 > maxPage) ? maxPage : start + 5;    

    return (<>
        {/* 페이징 */}
        <div className="page_box">	
            <ul className="mobile">
                <li><a className="btn prev" href="#void" alt="Prev" title="Prev" onClick={(e) => onPageMove(beforePage, e)}><img src={leftImage} alt="Prev" /></a></li>
                <li><a className="btn next" href="#void" alt="Next" title="Next" onClick={(e) => onPageMove(afterPage, e)}><img src={rightImage} alt="Next" /></a></li>
                <li>
                    <label htmlFor="page" className="col-lg-2 control-label">페이징</label>
                    <select id="page" onChange={(e) => onPageMove(+e.target.value, e)}>
                        {options}
                    </select>
                </li>		
            </ul>


            {/* PC 페이징 */}
            {/* <ul className="pagination pc">
                <li><a className={`first ${page === 1 ? 'disabled' : ''}`} href="#void" onClick={(e) => onPageMove(1, e)}><img src={left2Image} alt="First" /></a></li>
                <li><a className={`prev ${page === beforePage ? 'disabled' : ''}`} href="#void" onClick={(e) => onPageMove(beforePage, e)}><img src={leftImage} alt="Prev" /></a></li>
                {Nums}
                <li><a className={`next ${page === afterPage ? 'disabled' : ''}`} href="#void" onClick={(e) => onPageMove(afterPage, e)}><img src={rightImage} alt="Next" /></a></li>
                <li><a className={`last ${page === maxPage ? 'disabled' : ''}`} href="#void" onClick={(e) => onPageMove(maxPage, e)}><img src={right2Image} alt="Last" /></a></li>
            </ul> */}

            <ul className="pagination pc">
                <li><span className={`first ${page === 1 ? 'disabled' : ''}`} href="#void" onClick={(e) => onPageMove(1, e)}><img src={left2Image} alt="First" /></span></li>
                <li><span className={`prev ${page === beforePage ? 'disabled' : ''}`} href="#void" onClick={(e) => onPageMove(beforePage, e)}><img src={leftImage} alt="Prev" /></span></li>
                {Nums}
                <li><span className={`next ${page === afterPage ? 'disabled' : ''}`} href="#void" onClick={(e) => onPageMove(afterPage, e)}><img src={rightImage} alt="Next" /></span></li>
                <li><span className={`last ${page === maxPage ? 'disabled' : ''}`} href="#void" onClick={(e) => onPageMove(maxPage, e)}><img src={right2Image} alt="Last" /></span></li>
            </ul>
        </div>
        {/* 페이징 END */}
    </>)
};

export default Pagination;
