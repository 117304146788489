import React from 'react';
import {byte} from "../util/Number";
import { decodeHtml } from '../util/DecodeHtml';

const DatasetResource = ({resource, dataInfo}) => {

    const buttonStyle = {
        backgroundColor: "#003a78",
        color: "#fff",
        marginRight: "10px",
        height: "36px",
        fontSize: "15px",
        borderRadius: "5px"
    };

    return (
        <>
            <ul className={"info-table"}>
                <li>
                    <strong>데이터 이름</strong>
                    <span>{resource.title}</span>
                </li>
                <li>
                    <strong>데이터 설명</strong>
                    <span>{decodeHtml(resource.description)}</span>
                </li>
                {/*<li>*/}
                {/*    <strong>등록일자</strong>*/}
                {/*    <span>{yyyymmddhhiiss(new Date(resource.created), "-")}</span>*/}
                {/*</li>*/}
                <li>
                    <strong>포 맷</strong>
                    <span>{resource.mediaType}</span>
                </li>
                <li>
                    <strong>사이즈</strong>
                    <span>{byte(resource.byteSize, 0)}</span>
                </li>

                <li>
                    {/*<strong>{resource.format.toUpperCase()}</strong>*/}
                    <strong style={{borderTop: "none"}}>데이터 링크</strong>
                    <span style={{borderTop: "none"}}>
                    <div className={"ikan-button-wrap ikan-button-line"}>
                        <button onClick={()=> window.open(dataInfo.landingPage)} style={buttonStyle}>바로가기</button>
                    </div>
                    </span>
                </li>
            </ul>
        </>
    )
};

export default DatasetResource;
