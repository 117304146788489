import React, {useState, useEffect, useRef} from "react";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import ErrorPage404 from "../common/ErrorPage404";
import * as IboardApi from "../../api/Board";
import SubNav from "../../components/SubNav";
import {yyyymmddhhiiss} from "../../util/Date";
import Datatable from "../../components/Datatable";
import SelectSearch from "react-select-search";
import {getParamsFromQueryString, serialize} from "../../util/Url";
import TourMessage from "../common/tourMessage";
import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";
import {setTourBtn} from "../../store/actions/UserAction";
import Tour from "reactour";

const List = ({match, ...props}) => {
    const dispatch = useDispatch();

    // 투어
    const BoardTour = new TourMessage.BoardTourMessage()
    const BoardTour2 = new TourMessage.BoardTourMessage2()
    const BoardTour3 = new TourMessage.BoardTourMessage3()
    const BoardTour4 = new TourMessage.BoardTourMessage4()
    const BoardTour5 = new TourMessage.BoardTourMessage5()
    //const BoardTour6 = new TourMessage.BoardTourMessage6()    

    const isTourOpen = useSelector(state => state.user.isTourOpen)
    const isTourOpen2 = useSelector(state => state.user.isTourOpen)
    const isTourOpen3 = useSelector(state => state.user.isTourOpen)
    const isTourOpen4 = useSelector(state => state.user.isTourOpen)
    const isTourOpen5 = useSelector(state => state.user.isTourOpen)
    const isTourOpen6 = useSelector(state => state.user.isTourOpen)    

    let disableBody = target => disableBodyScroll(target)
    let enableBody = target => enableBodyScroll(target)

    let disableBody2 = target => disableBodyScroll(target)
    let enableBody2 = target => enableBodyScroll(target)

    let disableBody3 = target => disableBodyScroll(target)
    let enableBody3 = target => enableBodyScroll(target)

    let disableBody4 = target => disableBodyScroll(target)
    let enableBody4 = target => enableBodyScroll(target)

    let disableBody5 = target => disableBodyScroll(target)
    let enableBody5 = target => enableBodyScroll(target)

    let disableBody6 = target => disableBodyScroll(target)
    let enableBody6 = target => enableBodyScroll(target)    

    const closeTour = () => {
        dispatch(setTourBtn(false))
    }
    //

    const [boardInfo, setBoardInfo] = useState({});
    const [isInit, setIsInit] = useState(true);

    const [loading, setLoading] = useState(true);
    const [textList, setTextList] = useState([]);
    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totCnt, setTotCnt] = useState(0);

    const [searchWord, setSearchWord] = useState("");
    const [searchType, setSearchType] = useState("subject")
    const [isLoaded, setIsLoaded] = useState(true);

    const selectRef = useRef(null);

    const isLogin = useSelector(state => state.user.isLogin);
    const userInfo = useSelector(state => state.user.info);

    const typeOptions = {
        "use": "이용문의",
        "error": "오류내용",
        "improvement": "개선요청"
    };
    
    const processOptions = {
        'receipt': "접수완료",
        'process': "처리중",
        'completed': "답변완료"
    };

    const boardType = {
        'board_942f2cfa-3294-42c5-ac68-b4f3abbed510': "묻고 답하기",
        'board_87b9692c-8959-4b3a-83c5-28e209ceca1f': "데이터 신청",
        'board_a2eb004d-73ca-4014-a9d2-c402675cf83f': "자주하는 질문",
        'board_14ab0a7f-d410-48d7-91ca-e264d5121794': "공지사항",
        'board_bd79845f-53f1-4cb9-a394-ea01d3fd0c9f': "개인정보 현황관리"
    }

    const boardSearchType = [
        {
            name: "제목",
            value: "subject"
        },
        {
            name: "제목+내용",
            value: "subject_and_text"
        }
    ];

    useSelector(state => {
        if(JSON.stringify(state.board.boardInfo) !== JSON.stringify(boardInfo)){
            setBoardInfo(state.board.boardInfo);
        }
    });

    let board = match.params.board;
    let info = boardInfo[board];

    let qs = getParamsFromQueryString(props.history.location.search);
    let p_search_value = qs.hasOwnProperty("s_value") ? qs.s_value : "";
    let p_search_type = qs.hasOwnProperty("s_type") ? qs.s_type : "subject";

    useEffect( () => {
        setLoading(true);
        setSearchWord(p_search_value)
        setSearchType(p_search_type)

        const params = {
            board_id: board,
            page_size: limit,
            page: 0,
            user_id: "",
            search_value: p_search_value,
            search_type: p_search_type
        };
        IboardApi.readTextList(params).then(res => {
            const textList = res.result.textlist;
            setTotCnt(res.result.count);
            setMaxPage(Math.ceil(res.result.count / limit));
            setTextList(textList);
            setLoading(false);
            setPage(1);
        })
    }, [props.history.location.search]);

    useEffect(() => {
        setLoading(true);
        if (selectRef !== null) {
            //document.getElementsByClassName("board-search-select-box__input")[0].setAttribute("title", "검색종류")
        }
        setSearchWord(p_search_value)
        setSearchType(p_search_type)

        const params = {
            board_id: board,
            page_size: limit,
            page: 0,
            user_id: "",
            search_value: p_search_value,
            search_type: p_search_type
        };
        IboardApi.readTextList(params).then(res => {
            let textList = res.result.textlist;
            setTotCnt(res.result.count);
            setMaxPage(Math.ceil(res.result.count / limit));
            setTextList(textList);
            setLoading(false);
            setPage(1);
        })
    }, [board]);


    useEffect(() => {
        setLoading(true);
        setSearchWord(p_search_value)
        setSearchType(p_search_type)

        const params = {
            board_id: board,
            page_size: limit,
            page: page - 1,
            user_id: "",
            search_value: p_search_value,
            search_type: p_search_type
        };
        IboardApi.readTextList(params).then(res => {
            const textList = res.result.textlist;
            setTotCnt(res.result.count);
            setMaxPage(Math.ceil(res.result.count / limit));
            setTextList(textList);
            setLoading(false);
        })
    }, [page]);

    const isSearchBoard = (searchWord) => {
        setLoading(true);

        const set_params = {
            "s_type": searchType,
            "s_value": searchWord
        }
        if(isLoaded){
            props.history.push({
                pathname: window.location.search,
                search: serialize(set_params)
            });
        }
        else {
            props.history.replace({
                pathname: window.location.search,
                search: serialize(set_params)
            });
            setIsLoaded(false);
        }

        // let params = {
        //     board_id: board,
        //     page_size: limit,
        //     page: page - 1,
        //     user_id: "",
        //     search_value: p_search_value,
        //     search_type: p_search_type
        // };
        //
        // IboardApi.readTextList(params).then(res => {
        //     console.log(res.result);
        //     let textList = res.result.textlist;
        //     setTotCnt(res.result.count);
        //     setMaxPage(Math.ceil(res.result.count / limit));
        //     setTextList(textList);
        //     setLoading(false);
        // })
    }

    const changeValue = (value) => {
        const resetBtn = document.getElementById('resetBtn');
         if(value != ''&&resetBtn.style.display!='inline-block'){
            resetBtn.style.display='inline-block';
         }else if(value == ''){
            resetBtn.style.display='none';
         }
        setSearchWord(value);
    }

    const searchValue = () => {
        setSearchWord('');
        searchWord='';
        isSearchBoard(searchWord);
        document.getElementById('resetBtn').style.display='none';
    }

    if(info === undefined) return <ErrorPage404/>;

    if(isInit){
        setIsInit(false);
    }

    const descriptionRender = data => {
        return null;
    };

    const handleClickList = data => {
        props.history.push("/board/" + board + "/article/" + data.id);
    };

    const onPageMove = (page ,e) => {
        e.preventDefault();
        setPage(page);
    }

    const subNav = [
        {
            display: '통합 검색',
            name: '/board/all'
        },
        {
            display: '공지사항',
            name: '/board/board_14ab0a7f-d410-48d7-91ca-e264d5121794'
        },
        {
            display: '묻고 답하기',
            name: '/board/board_942f2cfa-3294-42c5-ac68-b4f3abbed510'
        },
        {
            display: '자주하는 질문',
            name: '/board/board_a2eb004d-73ca-4014-a9d2-c402675cf83f'
        },
        {
            display: '데이터 문의',
            name: '/board/board_87b9692c-8959-4b3a-83c5-28e209ceca1f'
        },
        {
            display: '개인정보 현황관리',
            name: '/board/board_bd79845f-53f1-4cb9-a394-ea01d3fd0c9f'
        }        
    ];

    // 뭄고 답하기
    if (board === "board_942f2cfa-3294-42c5-ac68-b4f3abbed510") {
        return (
            <>
            <Tour
                steps={BoardTour.tourConfig}
                isOpen={isTourOpen}
                onRequestClose={closeTour}
                rounded={5}
                accentColor={"#004c9d"}
                onAfterOpen={disableBody}
                onBeforeClose={enableBody}
                closeWithMask={false}
            />
            <SubNav nav={subNav} history={props.history} key={board} >
            <div className={"wrap-dataset"}>
                <div data-tut={"board_search_1"}>
                    <SelectSearch
                        options={boardSearchType}
                        onChange={option => {setSearchType(option)}}
                        className={"board-search-select-box"}
                        value={searchType}
                        ref={selectRef}
                    />
                    <div className={"search-wrap"} style={{display: "inline-block", width: "35%", marginLeft: "10px", marginBottom: "30px"}}>
                        <input title={"검색창"} type={"text"} id="board_search_input" placeholder={"찾고 싶은 내용을 입력해주세요!"}
                               //value={searchWord} onChange={ e => setSearchWord(e.target.value)}
                               value={searchWord} onChange={ e => changeValue(e.target.value)}
                               onKeyDown={e => e.keyCode === 13 && isSearchBoard(searchWord)} style={{
                                width: "400px", 
                                height: "46px",
                                borderColor: "rgb(204,204,204)",
                                borderWidth: "1px",
                                borderStyle: "solid",
                                borderRadius: "4px",
                                fontSize: "15px"}}>
                        </input>
                        <button onClick={() => isSearchBoard(searchWord)} style={{"height": "46px", "marginLeft": "10px"}}/>
                        <span 
                            id="resetBtn" 
                            className="search-reset" 
                            style={{marginLeft:"-35px", marginTop:"10px", position:"absolute", display:"none", cursor:"pointer"}} 
                            onClick={e => searchValue()}
                        >
                            ✖
                        </span>
                    </div>
                </div>
                {
                textList.length === 0 ?
                <div style={{
                    textAlign: "center",
                    padding: "0px",
                    color: "#cccccc",
                    opacity: "1",
                    fontSize: "1em"
                }}>
                    등록된 게시물이 없습니다.
                </div>
                :
                <>
                    <div className={"list-title-mo"} >{boardInfo[board].name}</div>
                    
                    <Datatable
                        search={false}           //검색 사용여부
                        head={[{
                            name: "no",
                            display: "구분",
                            className: "datatable-no",
                            render: (value, item, no) => <div className={"datatable-no"}
                                style={{textAlign: "center"}}>{totCnt - ((page - 1) * limit + no)}</div>,
                            style: {
                                width: '80px'
                            },
                        }, {
                            name: "text_type",
                            display: "종류",
                            className: "datatable-type",
                            render: (value) => <div style={{textAlign: "center"}} className={"writeTypeBtn datatable-type" }> {typeOptions[value]} </div>,
                            style: {
                                width: '100px'
                            }
                        }, {
                            name: "subject",
                            display: "제목",
                            className: "datatable-subject",
                            render: (value, item) => <div style={{textAlign: "left"}} className="datatable-subject"> 
                                           <span onClick={e => handleClickList(item)}
                                                 style={{cursor: "pointer"}}
                                           >
                                           {value}
                                           </span>
                            </div>
                        }, {
                            name: "user_name",
                            display: "작성자",
                            className: "datatable-user",
                            style: {
                                width: '160px'
                            },
                            render: value => <div style={{textAlign: "center"}} className="datatable-user" >
                                {
                                    userInfo.role === "superadmin" || value==="관리자"?
                                        value
                                        :value.substring(0,3)+"*****"
                                }
                            </div>
                        }, {
                            name: "date",
                            display: "작성일",
                            className: "datatable-date",
                            style: {
                                width: '240px'
                            },
                            render: value => <div style={{textAlign: "center"}} className="datatable-date">{yyyymmddhhiiss(new Date(value.replace(" ", "T")))}</div>
                        }, {
                            name: "text_process",
                            display: "진행상황",
                            className: "datatable-process",
                            style: {
                                width: '60px'
                            },
                            render: value => <div style={{textAlign: "center"}} className={"writeProcessBtn datatable-process"}>{processOptions[value]}</div>
                        }, {
                            name: "read_count",
                            display: "조회수",
                            className: "datatable-view",
                            style: {
                                width: '60px'
                            },
                            render: value => <div style={{textAlign: "center"}} className="datatable-view">{value}</div>
                        }]}              //head 데이터 (필수)
                        list={textList}              //body 데이터 (필수)
                        paging={true}            //페이지 사용여부
                        page={page}              //현재 페이지 (필수)
                        maxPage={Math.ceil(totCnt / limit)}        //전체 페이지 (필수)
                        padding={2}              //페이지번호개수 (선택)
                        onPageMove={(page, e) => {e.preventDefault(); setPage(page)}}
                        tableState={true}        //테이블상태 사용여부 (page필수)
                        rowTotal={totCnt}        //전체데이터 값 (선택)
                        limit={false}            //출력개수 사용여부

                        dataTour={"board_body_1"} // 이용안내
                    />


                    {/*<DsList list={textList}*/}
                    {/*        totCnt={totCnt}*/}
                    {/*        descriptionRender={data => info.description && descriptionRender(data)}*/}
                    {/*        onClick={data => handleClickList(data)}*/}
                    {/*        page={page}*/}
                    {/*        limit={limit}*/}
                    {/*/>*/}
                    {/*<Pagination maxPage={maxPage}*/}
                    {/*            page={page}*/}
                    {/*            padding={2}*/}
                    {/*            onPageMove={page => setPage(page)}*/}
                    {/*/>*/}
                </>}

                {info.write && isLogin &&
                <div className={"btn-group"}>
                    <Link to={"/board/" + board + "/write"} className={"btn"}>{info.writeTxt}</Link>
                </div>
                }
            </div>
            </SubNav>
            </>
        )
    }
    // 데이터 문의
    else if(board === "board_87b9692c-8959-4b3a-83c5-28e209ceca1f") {
        return (
        <>
        <Tour
            steps={BoardTour2.tourConfig}
            isOpen={isTourOpen2}
            onRequestClose={closeTour}
            rounded={5}
            accentColor={"#004c9d"}
            onAfterOpen={disableBody2}
            onBeforeClose={enableBody2}
            closeWithMask={false}

        />

        <SubNav nav={subNav} history={props.history} key={board}>

        <div className={"wrap-dataset"}>
            <div data-tut={"board_search_2"}>
                <SelectSearch
                    options={boardSearchType}
                    onChange={option => {setSearchType(option)}}
                    className={"board-search-select-box"}
                    value={searchType}
                    ref={selectRef}
                />
                <div className={"search-wrap"} style={{display: "inline-block", width: "35%", marginLeft: "10px", marginBottom: "30px"}}>
                    <input title={"검색창"} type={"text"} id="board_search_input" placeholder={"찾고 싶은 내용을 입력해주세요!"}
                           //value={searchWord} onChange={ e => setSearchWord(e.target.value)}
                           value={searchWord} onChange={ e => changeValue(e.target.value)}
                           onKeyDown={e => e.keyCode === 13 && isSearchBoard(searchWord)} style={{
                                width: "400px", 
                                height: "46px",
                                borderColor: "rgb(204,204,204)",
                                rderWidth: "1px",
                                borderStyle: "solid",
                                borderRadius: "4px",
                                fontSize: "15px"
                        }}>
                    </input>
                    <button onClick={() => isSearchBoard(searchWord)} style={{height: "46px", marginLeft: "10px"}}/>
                    <span 
                        id="resetBtn" 
                        className="search-reset" 
                        style={{marginLeft:"-35px", marginTop:"10px", position:"absolute", display:"none", cursor:"pointer"}} 
                        onClick={e => searchValue()}
                    >
                        ✖
                    </span>
                </div>
            </div>
            {
                textList.length === 0 ?
                    <div style={{
                        textalign: "center",
                        padding: "0px",
                        color: "#cccccc",
                        opacity: "1",
                        fontsize: "1em"
                    }}>
                        등록된 게시물이 없습니다.
                    </div>
                    :
                <>
                <div className={"list-title-mo"}>{boardInfo[board].name}</div>

                <Datatable
                    search={false}           //검색 사용여부
                    head={[{
                        name: "no",
                        display: "구분",
                        className: "datatable-no",
                        render: (value, item, no) => <div className="datatable-no"
                            style={{textAlign: "center"}}>{totCnt - ((page - 1) * limit + no)}</div>,
                        style: {
                            width: '80px'
                        }
                    }, {
                        name: "subject",
                        className: "datatable-subject",
                        display: "제목",
                        render: (value, item) => <div style={{textAlign: "left"}} className="datatable-subject" >
                                           <span onClick={e => handleClickList(item)}
                                                 style={{cursor: "pointer"}}
                                           >
                                           {value}
                                           </span>
                        </div>
                    }, {
                        name: "user_name",
                        display: "작성자",
                        className: "datatable-user",
                        style: {
                            width: '160px'
                        },
                        render: value => <div style={{textAlign: "center"}} className="datatable-user">
                            {
                                userInfo.role === "superadmin" || value==="관리자"?
                                    value
                                    :value.substring(0,3)+"*****"
                            }
                        </div>
                    }, {
                        name: "date",
                        display: "작성일",
                        className: "datatable-date",
                        style: {
                            width: '240px'
                        },
                        render: value => <div style={{textAlign: "center"}} className="datatable-date"
                        >
                            {yyyymmddhhiiss(new Date(value.replace(" ", "T")))}
                        </div>
                    }, {
                        name: "text_process",
                        display: "진행상황",
                        className: "datatable-process",
                        style: {
                            width: '60px'
                        },
                        render: value => <div style={{textAlign: "center"}}
                         className={"writeProcessBtn datatable-process"}>
                            {processOptions[value]}
                        </div>
                    },{
                        name: "read_count",
                        display: "조회수",
                        className: "datatable-view",
                        style: {
                            width: '60px'
                        },
                        render: value => <div style={{textAlign: "center"}}
                        className="datatable-view"
                        >{value}</div>
                    }]}              //head 데이터 (필수)
                    list={textList}              //body 데이터 (필수)
                    paging={true}            //페이지 사용여부
                    page={page}              //현재 페이지 (필수)
                    maxPage={Math.ceil(totCnt / limit)}        //전체 페이지 (필수)
                    padding={2}              //페이지번호개수 (선택)
                    onPageMove={(page, e) => {e.preventDefault(); setPage(page)}}
                    tableState={true}        //테이블상태 사용여부 (page필수)
                    rowTotal={totCnt}        //전체데이터 값 (선택)
                    limit={false}            //출력개수 사용여부
                    dataTour={"board_body_2"} // 이용안내 관련
                />


                {/*<DsList list={textList}*/}
                {/*        totCnt={totCnt}*/}
                {/*        descriptionRender={data => info.description && descriptionRender(data)}*/}
                {/*        onClick={data => handleClickList(data)}*/}
                {/*        page={page}*/}
                {/*        limit={limit}*/}
                {/*/>*/}
                {/*<Pagination maxPage={maxPage}*/}
                {/*            page={page}*/}
                {/*            padding={2}*/}
                {/*            onPageMove={page => setPage(page)}*/}
                {/*/>*/}
            </>}

            {info.write && isLogin &&
            <div className={"btn-group"}>
                <Link to={"/board/" + board + "/write"} className={"btn"}>{info.writeTxt}</Link>
            </div>
            }
        </div>
        </SubNav>
        </>
        )
    }

    // 통합 게시판
    else if(board === "all") {
        return (
            <>
            <Tour
                steps={BoardTour3.tourConfig}
                isOpen={isTourOpen3}
                onRequestClose={closeTour}
                rounded={5}
                accentColor={"#004c9d"}
                onAfterOpen={disableBody3}
                onBeforeClose={enableBody3}
                closeWithMask={false}

            />

            <SubNav nav={subNav} history={props.history} key={board}>

            <div className={"wrap-dataset"}>    
                <div data-tut={"board_search_3"}>
                    <SelectSearch
                        options={boardSearchType}
                        onChange={option => {setSearchType(option)}}
                        className={"board-search-select-box"}
                        value={searchType}
                        ref={selectRef}
                    />
                    <div className={"search-wrap"} style={{display: "inline-block", width: "35%", marginLeft: "10px", marginBottom: "30px"}}>
                        <input title={"검색창"} type={"text"} id="board_search_input" placeholder={"찾고 싶은 내용을 입력해주세요!"}
                            //value={searchWord} onChange={ e => setSearchWord(e.target.value)}
                            value={searchWord} onChange={ e => changeValue(e.target.value)}
                            onKeyDown={e => e.keyCode === 13 && isSearchBoard(searchWord)} style={{
                                width: "400px", "height": "46px",
                                borderColor: "rgb(204,204,204)",
                                borderWidth: "1px",
                                borderStyle: "solid",
                                borderRadius: "4px",
                                fontSize: "15px"
                        }}>
                        </input>
                        <button onClick={() => isSearchBoard(searchWord)} style={{height: "46px", marginLeft: "10px"}}/>
                        <span 
                            id="resetBtn" 
                            className="search-reset" 
                            style={{marginLeft:"-35px", marginTop:"10px", position:"absolute", display:"none", cursor:"pointer"}} 
                            onClick={e => searchValue()}
                        >
                                ✖
                        </span>
                    </div>
                </div>
                {
                    textList.length === 0 ?
                        <div style={{
                            textAlign: "center",
                            padding: "0px",
                            color: "#cccccc",
                            opacity: "1",
                            fontSize: "1em"
                        }}>
                            등록된 게시물이 없습니다.
                        </div>
                        :
                        <>
                        <div className={"list-title-mo"}>{boardInfo[board].name}</div>

                            <Datatable
                                search={false}           //검색 사용여부
                                head={[{
                                    name: "no",
                                    display: "구분",
                                    className: "datatable-no",
                                    render: (value, item, no) => <div
                                        style={{textAlign: "center"}}
                                        className="datatable-no">
                                            {totCnt - ((page - 1) * limit + no)}
                                        </div>,
                                    style: {
                                        width: '80px'
                                    }
                                }, {
                                    name: "board_id",
                                    display: "종류",
                                    style: {
                                        width: '150px'
                                    },
                                    render: value => <div
                                        style={{textAlign: "center", "fontSize": "14px"}}>{boardType[value]}</div>
                                }, {
                                    name: "subject",
                                    display: "제목",
                                    render: (value, item) => <div style={{textAlign: "left"}}>
                                       <span onClick={e => handleClickList(item)}
                                             style={{cursor: "pointer"}}
                                       >
                                       {value}
                                       </span>
                                    </div>
                                }, {
                                    name: "user_name",
                                    display: "작성자",
                                    style: {
                                        width: '150px'
                                    },
                                    render: value => <div style={{textAlign: "center"}}>
                                        {
                                            userInfo.role === "superadmin" || value==="관리자"?
                                                value
                                                :value.substring(0,3)+"*****"
                                        }
                                    </div>
                                }, {
                                    name: "date",
                                    display: "작성일",
                                    style: {
                                        width: '230px'
                                    },
                                    render: value => <div
                                        style={{textAlign: "center"}}>{yyyymmddhhiiss(new Date(value.replace(" ", "T")))}</div>
                                }, {
                                    name: "read_count",
                                    display: "조회수",
                                    style: {
                                        width: '60px'
                                    },
                                    render: value => <div style={{textAlign: "center"}}>{value}</div>
                                }]}              //head 데이터 (필수)
                                list={textList}              //body 데이터 (필수)
                                paging={true}            //페이지 사용여부
                                page={page}              //현재 페이지 (필수)
                                maxPage={Math.ceil(totCnt / limit)}        //전체 페이지 (필수)
                                padding={2}              //페이지번호개수 (선택)
                                onPageMove={(page, e) => {e.preventDefault(); setPage(page)}}
                                tableState={true}        //테이블상태 사용여부 (page필수)
                                rowTotal={totCnt}        //전체데이터 값 (선택)
                                limit={false}            //출력개수 사용여부
                                dataTour={"board_body_3"}
                            />


                            {/*<DsList list={textList}*/}
                            {/*        totCnt={totCnt}*/}
                            {/*        descriptionRender={data => info.description && descriptionRender(data)}*/}
                            {/*        onClick={data => handleClickList(data)}*/}
                            {/*        page={page}*/}
                            {/*        limit={limit}*/}
                            {/*/>*/}
                            {/*<Pagination maxPage={maxPage}*/}
                            {/*            page={page}*/}
                            {/*            padding={2}*/}
                            {/*            onPageMove={page => setPage(page)}*/}
                            {/*/>*/}
                        </>
                    }
            </div>
            </SubNav>
            </>
        )
    }

    else if(board === "board_14ab0a7f-d410-48d7-91ca-e264d5121794") {
        return (
            <>
            <Tour
                steps={BoardTour4.tourConfig}
                isOpen={isTourOpen4}
                onRequestClose={closeTour}
                rounded={5}
                accentColor={"#004c9d"}
                onAfterOpen={disableBody4}
                onBeforeClose={enableBody4}
                closeWithMask={false}
            />

            <SubNav nav={subNav} history={props.history} key={board}>

            <div className={"wrap-dataset"}>
                <div data-tut={"board_search_4"}>
                    <SelectSearch
                        options={boardSearchType}
                        onChange={option => {
                            setSearchType(option)
                        }}
                        className={"board-search-select-box"}
                        value={searchType}
                        ref={selectRef}
                    />
                    <div className={"search-wrap"} style={{
                        "display": "inline-block",
                        "width": "35%",
                        "marginLeft": "10px",
                        "marginBottom": "30px"
                    }}>
                        <input title={"검색창"} type={"text"} id="board_search_input" placeholder={"찾고 싶은 내용을 입력해주세요!"}
                               //value={searchWord} onChange={e => setSearchWord(e.target.value)}
                               value={searchWord} onChange={ e => changeValue(e.target.value)}
                               onKeyDown={e => e.keyCode === 13 && isSearchBoard(searchWord)} style={{
                            "width": "400px", 
                            "height": "46px",
                            "borderColor": "rgb(204,204,204)",
                            "borderWidth": "1px",
                            "borderStyle": "solid",
                            "borderRadius": "4px",
                            "fontSize": "15px"
                        }}>
                        </input>
                        <button 
                            onClick={() => isSearchBoard(searchWord)}
                            style={{"height": "46px", "marginLeft": "10px"}}
                        />
                        <span id="resetBtn" className="search-reset" style={{"marginLeft":"-35px", "marginTop":"10px", "position":"absolute", "display":"none", "cursor":"pointer"}} onClick={e => searchValue()}>✖</span>        
                    </div>
                </div>
                {
                    textList.length === 0 ?
                        <div style={{
                            "textAlign": "center",
                            "padding": "0px",
                            "color": "#cccccc",
                            "opacity": "1",
                            "fontSize": "1em"
                        }}>
                            등록된 게시물이 없습니다.
                        </div>
                        :
                        <>
                            <div className={"list-title-mo"}>{boardInfo[board].name}</div>

                            <Datatable
                                search={false}           //검색 사용여부
                                head={[{
                                    name: "no",
                                    display: "구분",
                                    className: "datatable-no",
                                    render: (value, item, no) => <div
                                        style={{textAlign: "center"}}
                                        className="datatable-no">
                                            {totCnt - ((page - 1) * limit + no)}
                                        </div>,
                                    style: {
                                        width: '80px'
                                    }
                                }, {
                                    name: "subject",
                                    display: "제목",
                                    className:"datatable-subject",
                                    render: (value, item) => <div style={{textAlign: "left"}}
                                    className="datatable-subject"
                                    >
                                           <span onClick={e => handleClickList(item)}
                                                 style={{cursor: "pointer"}}
                                           >
                                           {value}
                                           </span>
                                    </div>
                                }, {
                                    name: "user_name",
                                    display: "작성자",
                                    className: "datatable-user",
                                    style: {
                                        width: '160px'
                                    },
                                    render: value => <div style={{textAlign: "center"}}
                                    className="datatable-user"
                                    >
                                        {
                                            userInfo.role === "superadmin" || value==="관리자"?
                                                value
                                                :value.substring(0,3)+"*****"
                                        }
                                    </div>
                                }, {
                                    name: "date",
                                    display: "작성일",
                                    className: "datatable-date",
                                    style: {
                                        width: '240px'
                                    },
                                    render: value => <div className="datatable-date"
                                        style={{textAlign: "center"}}>{yyyymmddhhiiss(new Date(value.replace(" ", "T")))}</div>
                                }, {
                                    name: "read_count",
                                    display: "조회수",
                                    className: "datatable-view",
                                    style: {
                                        width: '60px'
                                    },
                                    render: value => <div style={{textAlign: "center"}}
                                    className="datatable-view"
                                    >{value}</div>
                                }]}              //head 데이터 (필수)
                                list={textList}              //body 데이터 (필수)
                                paging={true}            //페이지 사용여부
                                page={page}              //현재 페이지 (필수)
                                maxPage={Math.ceil(totCnt / limit)}        //전체 페이지 (필수)
                                padding={2}              //페이지번호개수 (선택)
                                onPageMove={(page, e) => {e.preventDefault(); setPage(page)}}
                                tableState={true}        //테이블상태 사용여부 (page필수)
                                rowTotal={totCnt}        //전체데이터 값 (선택)
                                limit={false}            //출력개수 사용여부
                                dataTour={"board_body_4"}
                            />

                            {/*<DsList list={textList}*/}
                            {/*        totCnt={totCnt}*/}
                            {/*        descriptionRender={data => info.description && descriptionRender(data)}*/}
                            {/*        onClick={data => handleClickList(data)}*/}
                            {/*        page={page}*/}
                            {/*        limit={limit}*/}
                            {/*/>*/}
                            {/* <Pagination maxPage={maxPage}
                                        page={page}
                                        padding={2}
                                        onPageMove={page => setPage(page)}
                            /> */}
                        </>}

                {info.write && isLogin &&
                <div className={"btn-group"}>
                    <Link to={"/board/" + board + "/write"} className={"btn"}>{info.writeTxt}</Link>
                </div>
                }
            </div>
            </SubNav>
            </>
        )
    }
    else if(board === "board_a2eb004d-73ca-4014-a9d2-c402675cf83f") {
        return (
            <>
            <Tour
                steps={BoardTour5.tourConfig}
                isOpen={isTourOpen5}
                onRequestClose={closeTour}
                rounded={5}
                accentColor={"#004c9d"}
                onAfterOpen={disableBody5}
                onBeforeClose={enableBody5}
                closeWithMask={false}

            />
            
            <SubNav nav={subNav} history={props.history} key={board}>

            <div className={"wrap-dataset"}>    
                <div data-tut={"board_search_5"}>
                    <SelectSearch
                        options={boardSearchType}
                        onChange={option => {
                            setSearchType(option)
                        }}
                        className={"board-search-select-box"}
                        value={searchType}
                        ref={selectRef}
                    />
                    <div className={"search-wrap"} style={{
                        display: "inline-block",
                        width: "35%",
                        marginLeft: "10px",
                        marginBottom: "30px"
                    }}>
                        <input title={"검색창"} type={"text"} id="board_search_input" placeholder={"찾고 싶은 내용을 입력해주세요!"}
                               //value={searchWord} onChange={e => setSearchWord(e.target.value)}
                               value={searchWord} onChange={ e => changeValue(e.target.value)}
                               onKeyDown={e => e.keyCode === 13 && isSearchBoard(searchWord)} style={{
                            width: "400px", 
                            height: "46px",
                            borderColor: "rgb(204,204,204)",
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderRadius: "4px",
                            fontSize: "15px"
                        }}>
                        </input>
                        <button onClick={() => isSearchBoard(searchWord)}
                                style={{height: "46px", marginLeft: "10px"}}/>
                        <span
                            id="resetBtn" 
                            className="search-reset" 
                            style={{marginLeft:"-35px", marginTop:"10px", position:"absolute", display:"none", cursor:"pointer"}} 
                            onClick={e => searchValue()}
                        >
                            ✖
                        </span>        
                    </div>
                </div>
                {
                    textList.length === 0 ?
                        <div style={{
                            "textAlign": "center",
                            "padding": "0px",
                            "color": "#cccccc",
                            "opacity": "1",
                            "fontSize": "1em"
                        }}>
                            등록된 게시물이 없습니다.
                        </div>
                        :
                        <>
                            <div className={"list-title-mo"}>{boardInfo[board].name}</div>

                            <Datatable
                                search={false}           //검색 사용여부
                                head={[{
                                    name: "no",
                                    display: "구분",
                                    render: (value, item, no) => <div
                                        style={{textAlign: "center"}}>
                                            {totCnt - ((page - 1) * limit + no)}
                                        </div>,
                                    style: {
                                        width: '80px'
                                    }
                                }, {
                                    name: "subject",
                                    display: "제목",
                                    render: (value, item) => <div style={{textAlign: "left"}}>
                                           <span onClick={e => handleClickList(item)}
                                                 style={{cursor: "pointer"}}
                                           >
                                           {value}
                                           </span>
                                    </div>
                                }, {
                                    name: "user_name",
                                    display: "작성자",
                                    style: {
                                        width: '160px'
                                    },
                                    render: value => <div style={{textAlign: "center"}}>
                                        {
                                            userInfo.role === "superadmin" || value==="관리자"?
                                                value
                                                :value.substring(0,3)+"*****"
                                        }
                                    </div>
                                }, {
                                    name: "date",
                                    display: "작성일",
                                    style: {
                                        width: '240px'
                                    },
                                    render: value => <div
                                        style={{textAlign: "center"}}>{yyyymmddhhiiss(new Date(value.replace(" ", "T")))}</div>
                                }, {
                                    name: "read_count",
                                    display: "조회수",
                                    style: {
                                        width: '60px'
                                    },
                                    render: value => <div style={{textAlign: "center"}}>{value}</div>
                                }]}              //head 데이터 (필수)
                                list={textList}              //body 데이터 (필수)
                                paging={true}            //페이지 사용여부
                                page={page}              //현재 페이지 (필수)
                                maxPage={Math.ceil(totCnt / limit)}        //전체 페이지 (필수)
                                padding={2}              //페이지번호개수 (선택)
                                onPageMove={(page, e) => {e.preventDefault(); setPage(page)}}
                                tableState={true}        //테이블상태 사용여부 (page필수)
                                rowTotal={totCnt}        //전체데이터 값 (선택)
                                limit={false}            //출력개수 사용여부
                                dataTour={"board_body_5"}
                            />


                            {/*<DsList list={textList}*/}
                            {/*        totCnt={totCnt}*/}
                            {/*        descriptionRender={data => info.description && descriptionRender(data)}*/}
                            {/*        onClick={data => handleClickList(data)}*/}
                            {/*        page={page}*/}
                            {/*        limit={limit}*/}
                            {/*/>*/}
                            {/*<Pagination maxPage={maxPage}*/}
                            {/*            page={page}*/}
                            {/*            padding={2}*/}
                            {/*            onPageMove={page => setPage(page)}*/}
                            {/*/>*/}
                        </>}

                {info.write && isLogin &&
                <div className={"btn-group"}>
                    <Link to={"/board/" + board + "/write"} className={"btn"}>{info.writeTxt}</Link>
                </div>
                }
            </div>
            </SubNav>
            </>
        )
    }
    else if(board === "board_bd79845f-53f1-4cb9-a394-ea01d3fd0c9f") {
        return (
            <>
            <Tour
                steps={BoardTour4.tourConfig}
                isOpen={isTourOpen6}
                onRequestClose={closeTour}
                rounded={5}
                accentColor={"#004c9d"}
                onAfterOpen={disableBody6}
                onBeforeClose={enableBody6}
                closeWithMask={false}

            />
            <SubNav nav={subNav} history={props.history} key={board}>
                <div data-tut={"board_search_6"}>
                    <SelectSearch
                        options={boardSearchType}
                        onChange={option => {
                            setSearchType(option)
                        }}
                        className={"board-search-select-box"}
                        value={searchType}
                        ref={selectRef}
                    />
                    <div className={"search-wrap"} style={{
                        "display": "inline-block",
                        "width": "35%",
                        "marginLeft": "10px",
                        "marginBottom": "30px"
                    }}>
                        <input title={"검색창"} type={"text"} id="board_search_input" placeholder={"찾고 싶은 내용을 입력해주세요!"}
                               //value={searchWord} onChange={e => setSearchWord(e.target.value)}
                               value={searchWord} onChange={ e => changeValue(e.target.value)}
                               onKeyDown={e => e.keyCode === 13 && isSearchBoard(searchWord)} style={{
                            "width": "400px", "height": "46px",
                            "borderColor": "rgb(204,204,204)",
                            "borderWidth": "1px",
                            "borderStyle": "solid",
                            "borderRadius": "4px",
                            "fontSize": "15px"
                        }}>
                        </input>
                        <button onClick={() => isSearchBoard(searchWord)}
                                style={{"height": "46px", "marginLeft": "10px"}}/>
                        <span id="resetBtn" className="search-reset" style={{"marginLeft":"-35px", "marginTop":"10px", "position":"absolute", "display":"none", "cursor":"pointer"}} onClick={e => searchValue()}>✖</span>        
                    </div>
                </div>
                {
                    textList.length === 0 ?
                        <div style={{
                            "textAlign": "center",
                            "padding": "0px",
                            "color": "#cccccc",
                            "opacity": "1",
                            "fontSize": "1em"
                        }}>
                            등록된 게시물이 없습니다.
                        </div>
                        :
                        <>
                            <div className={"list-title-mo"}>{boardInfo[board].name}</div>

                            <Datatable
                                search={false}           //검색 사용여부
                                head={[{
                                    name: "no",
                                    display: "구분",
                                    render: (value, item, no) => <div
                                        style={{textAlign: "center"}}>
                                            {totCnt - ((page - 1) * limit + no)}
                                        </div>,
                                    style: {
                                        width: '80px'
                                    }
                                }, {
                                    name: "subject",
                                    display: "제목",
                                    render: (value, item) => <div style={{textAlign: "left"}}>
                                           <span onClick={e => handleClickList(item)}
                                                 style={{cursor: "pointer"}}
                                           >
                                           {value}
                                           </span>
                                    </div>
                                }, {
                                    name: "user_name",
                                    display: "작성자",
                                    style: {
                                        width: '160px'
                                    },
                                    render: value => <div style={{textAlign: "center"}}>
                                        {
                                            userInfo.role === "superadmin" || value==="관리자"?
                                                value
                                                :value.substring(0,3)+"*****"
                                        }
                                    </div>
                                }, {
                                    name: "date",
                                    display: "작성일",
                                    style: {
                                        width: '240px'
                                    },
                                    render: value => <div
                                        style={{textAlign: "center"}}>{yyyymmddhhiiss(new Date(value.replace(" ", "T")))}</div>
                                }, {
                                    name: "read_count",
                                    display: "조회수",
                                    style: {
                                        width: '60px'
                                    },
                                    render: value => <div style={{textAlign: "center"}}>{value}</div>
                                }]}              //head 데이터 (필수)
                                list={textList}              //body 데이터 (필수)
                                paging={true}            //페이지 사용여부
                                page={page}              //현재 페이지 (필수)
                                maxPage={Math.ceil(totCnt / limit)}        //전체 페이지 (필수)
                                padding={2}              //페이지번호개수 (선택)
                                onPageMove={(page, e) => {e.preventDefault(); setPage(page)}}
                                tableState={true}        //테이블상태 사용여부 (page필수)
                                rowTotal={totCnt}        //전체데이터 값 (선택)
                                limit={false}            //출력개수 사용여부
                                dataTour={"board_body_6"}
                            />


                            {/*<DsList list={textList}*/}
                            {/*        totCnt={totCnt}*/}
                            {/*        descriptionRender={data => info.description && descriptionRender(data)}*/}
                            {/*        onClick={data => handleClickList(data)}*/}
                            {/*        page={page}*/}
                            {/*        limit={limit}*/}
                            {/*/>*/}
                            {/*<Pagination maxPage={maxPage}*/}
                            {/*            page={page}*/}
                            {/*            padding={2}*/}
                            {/*            onPageMove={page => setPage(page)}*/}
                            {/*/>*/}
                        </>}

                {info.write && isLogin &&
                <div className={"btn-group"}>
                    <Link to={"/board/" + board + "/write"} className={"btn"}>{info.writeTxt}</Link>
                </div>
                }
            </SubNav>
            </>
        )
    }

};

export default List;
