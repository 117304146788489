import React, {useState, useEffect, useRef} from 'react';
import StarRatings from "react-star-ratings";
import SelectSearch from 'react-select-search';
import {useSelector} from "react-redux";
import swal from "sweetalert";
import {delCookie} from "../util/Cookie";
import * as DataApi from "../api/Data";
import * as OrderApi from "../api/Order";

const DatasetRatingScore = ({resource, dataInfo, style}) => {
    let [dataRating, setDataRating] = useState(5);
    let [avgDataRating, setAvgDataRating] = useState(0);
    let [alreadyDataRatingFlag, setAlreadyDataRatingFlag] = useState(false);
    let [ratingButtonName, setRatingButtonName] = useState("평가");

    const userInfo = useSelector(state => state.user.info);
    const isLogin = useSelector(state => state.user.isLogin);

    if (style === undefined) {
        style = {
            "marginTop": "30px",
            "marginBottom": "30px"
        }
    }
    const dataRatingScore = [
        {
            name: "5",
            value: "5"
        },
        {
            name: "4",
            value: "4"
        },
        {
            name: "3",
            value: "3"
        },
        {
            name: "2",
            value: "2"
        },
        {
            name: "1",
            value: "1"
        }
    ];

    useEffect(() => {
        getDataRatingScore(userInfo.id_key, resource.package_id)
    }, []);

    const setDataRatingScore = (user_id, user_name, data_id, data_title, rating_score) => {
        if(!isLogin) {
            swal({
                text: "로그인 후 이용 가능한 서비스입니다.",
                buttons: [
                    "확인",
                    "로그인"
                ]
            }).then(function (isConfirm) {
                if(isConfirm) {
                    delCookie("token");
                    window.location.href = "/#/login";
                } else {
                    swal.close();
                }
            })
        } else {
            let params = {
                'user_id': user_id,
                'user_name': user_name,
                'rating_post_id': data_id,
                'rating_post_title': data_title,
                'rating_score': rating_score,
                'rating_post_theme': "dataset"
            };
            DataApi.addDataRatingScore(params)
                .then(res => {
                    swal('평점이 반영되었습니다.');
                    getDataRatingScore(user_id, data_id)
                })
                .catch(res => {

                });
        }
    };

    const getDataRatingScore = (user_id, data_id) => {
        let params = {
            'rating_post_id': data_id,
            'user_id': user_id
        };
        
        DataApi.readDataRatingScore(params).then(res => {
            setAvgDataRating(res.result.score_avg);
            if (res.result.individual_score !== "None") {
                setDataRating(res.result.individual_score);
                setAlreadyDataRatingFlag(true);
                setRatingButtonName("갱신");
            }
        });
    };
    const selectRef = useRef(null);
    useEffect( () => {
        if (selectRef !== null) {
            document.getElementsByClassName("data-rating-select-box__input")[0].setAttribute("title", "평점")
        }
    }, [])

    return (
        <>
            <ul className={"info-table"} style={style}>
                <li>
                    <strong>평 점</strong>
                    <span>
                        <div style={{"display": "inline-block"}}>
                            <p style={{"color": "rgb(109, 122, 130)", "fontSize": "20px", "fontWeight": "500"}}>{avgDataRating}</p>
                        </div>

                        <div style={{"display": "inline-block", "position": "relative", "left": "5px"}}>
                            <StarRatings
                                rating={avgDataRating}
                                numberOfStars={5}
                                name='rating'
                                starDimension="20px"
                                starSpacing="1px"
                            />
                        </div>

                        <div style={{"display": "inline-block", "position": "relative", "left": "10px"}}>
                            {
                                alreadyDataRatingFlag === false ?
                                    <SelectSearch
                                        options={dataRatingScore}
                                        onChange={option => {setDataRating(option)}}
                                        placeholder={"미평가"}
                                        className={"data-rating-select-box"}
                                        ref={selectRef}
                                    />
                                    :
                                    <SelectSearch
                                        options={dataRatingScore}
                                        onChange={option => {setDataRating(option)}}
                                        value={dataRating}
                                        className={"data-rating-select-box"}
                                        ref={selectRef}
                                    />
                            }

                        </div>
                        <div style={{"display": "inline-block", "position": "relative", "left": "15px"}}>
                            <button className={"detail-refresh-button"} style={{"fontSize": "13px", "border": "0.5px solid #003a78"}} onClick={() =>
                                setDataRatingScore(userInfo.id_key, userInfo.id, resource.package_id, dataInfo.title, dataRating)}> {ratingButtonName} </button>
                        </div>

                    </span>
                </li>
            </ul>
        </>
    )
};

export default DatasetRatingScore;
