import React from 'react';

const Option = ({option, onChange}) => {

    return (
        <li onClick={e => onChange(option)}
            key={option.name}>
            {option.display}
        </li>
    )
};

export default Option;
