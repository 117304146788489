export const index=`경기도 지역경제 빅데이터 플랫폼 데이터 거래 계약서 

제1조(목적) 본 계약은 데이터제공자가 데이터이용자에게 [ +dataName+ ]  데이터를 제공하는 행위와 관련하여 발생하는 권리와 의무, 그 밖에 필요한 기본적인 사항을 규정하는 것을 목적으로 한다.

제2조(정의)
본 계약에서 사용하는 용어의 뜻은 다음과 같다.
① ‘제공데이터’는 본 계약에 따라 데이터제공자가 데이터이용자에게 제공하는 데이터로서, [별지]에 기재된 것을 말한다.
② ‘파생데이터’는 데이터이용자가 제공데이터를 가공, 분석, 편집, 통합 등을 통하여 2차적으로 생성한 데이터를 말한다.
③ ‘이용권한’이란 제공데이터 또는 파생데이터를 이용할 수 있는 권한을 말한다.
④ ‘비밀정보’는 계약당사자가 상대방에 공개하고 또 공개한 때에 비밀이라는 취지를 명시
한 기술상 또는 경영상 정보, 본 계약의 존재 · 내용 기타 일체의 정보를 말한다.
⑤ ‘가명처리’는 특정 개인을 알아볼 수 없도록 개인정보보호법령이 정하는 방법으로 데이
터를 처리하는 것을 말한다.
⑥ ‘가명정보’는 전항의 가명처리에 의해 원상태로 복원하기 위한 추가 정보의 사용·결합 없이는 특정 개인을 알아볼 수 없는 정보를 말한다.
⑦ ‘민감정보’는 사상·신념, 노동조합·정당의 가입·탈퇴, 정치적 견해, 건강, 성생활 등에 관한 정보, 그 밖에 개인정보보호법령으로 정한 정보를 말한다.

제3조(제공데이터의 제공방법)
데이터제공자는 본 계약기간 중 데이터이용자에 대하여 제공데이터를 [별지]에 정한 제공 방식으로 제공한다.
그러나 데이터제공자는 데이터 제공  3 일 전에 데이터이용자에게 통지하는 것으로 데이터 제공 방식을 변경할 수 있다.

제4조(제공·파생 데이터의 이용허락)
① 데이터제공자는 데이터이용자에 대하여, 제공·파생 데이터를 본 계약의 유효기간 내에서 본 계약 목적의 범위 내에서만 이용하는 것을 허락한다.
② 데이터이용자는 본 계약에서 명시적으로 규정된 것을 제외하고는 제공·파생 데이터에 대하여 내용의 정정, 추가 또는 삭제를 행할 수 있는 권한이 없다.
③ 데이터이용자는 데이터제공자의 서면에 의하여 사전 승낙이 없는 한 본 계약 목적 이외의 목적으로 제공·파생 데이터를 가공, 분석, 편집, 통합 기타의 이용을 할 수 없으며, 제공·파생 데이터를 제3자(데이터이용자가 법인인 경우 그 계열사, 관련회사도 제3자에 포함)에게 공개, 제공, 누설하여서는 아니된다.
④ 제공·파생 데이터에 관한 지적재산권(데이터베이스에 관한 권리를 포함하고 이에 제한되지 않
음)은 데이터제공자에게 귀속된다. 그러나 제공데이터 중 제3자에게 지적재산권이 귀속되는 것은 그러하지 아니한다.
⑤ 본 조의 이용허락은 데이터제공자의 제공데이터의 이용을 방해하지 아니한다.
⑥ 데이터제공자는 데이터이용자 이외의 제3자에게도 본 조의 이용허락을 할 수 있다.
 
제5조(대금지급)
① 데이터이용자는 데이터제공자에게 제공데이터의 이용허락에 대한 대가로서 데이터 수령 후 3일이내 금.이천오백만원정(25,000,000)원(부가세 별도)을 지급한다.

제6조(제공데이터 등에 대한 보증 등)
① 데이터제공자는 제공데이터가 적법하고 적절한 방법에 의해 취득되었음을 표명하고 보증한다.
② 데이터제공자는 개인정보를 포함한 데이터를 데이터이용자에게 제공하는 경우, 해당 데이터의 생성, 수집, 제공 등에 대해 개인정보보호 관계 법률에서 정한 요건과 절차를 준수하였음을 보증한다.
③ 데이터제공자는 제공데이터의 정확성, 완전성(데이터에 하자 내지 결함이 없음), 안전성(데이터에 바이러스 등 악성코드가 없음), 유효성(본 계약 목적에의 적합성)을 보증하지 않는다.
④ 데이터제공자는 제공데이터가 타인의 지적재산권 및 기타 권리를 침해하지 않는다는 것을 보증하지 아니한다.

제7조(가명정보, 민감정보의 처리)
① 가명정보는 개인정보보호 관계 법령에서 정한 목적 범위와 법적 요건에 따라 처리되어야 한다.
② 데이터이용자는 특정 개인을 재식별하려는 목적으로 가명정보를 처리하여서는 아니되며 특정 개인을 알아 볼 수 있는 정보가 생성된 경우에는 가명정보의 처리를 중단하고 해당 정보를 파기하여야 한다
③ 데이터제공자는 민감정보 내지 특정 개인에게 신체적, 재산적으로 중대한 영향을 미칠 수 있는 데이터를 데이터이용자에게 제공하는 경우, 데이터이용자의 제공데이터 접근을 장소적, 물리적으로 제한하여 데이터를 제공할 수 있으며 데이터이용자에게 가명처리 등 필요한 안전성 확보 조치를 요구할 수 있다.

제8조(제공데이터 관리의무)
① 데이터이용자는 제공데이터를 자신이 보관하고 있는 다른 정보와 명확히 구별하고 선량한 관리자의 주의를 가지고 관리·보관하여야 한다.
② 데이터이용자는 제공데이터에 대하여 자신의 영업비밀과 동등 이상의 합리적인 관리 조치를 취하여야 한다.
③ 데이터이용자는 상대방의 사전서면에 의한 승낙을 받지 아니하고 제공데이터를 제3자에게 공개, 제공, 누설해서는 아니된다.
④ 데이터제공자는 제공데이터의 관리상황에 대하여, 데이터이용자에게 언제든지 서면에 의한 보고를 요청할 수 있다. 이 경우 제공데이터의 유출 또는 상실의 우려가 있다고 데이터제공자가 판단한 경우, 데이터제공자는 데이터이용자에게 제공데이터의 관리방법·보관 방법의 시정을 요구할 수 있다.
⑤ 전 항의 보고 또는 시정 요구가 있었는 경우, 데이터이용자는 즉시 이에 응하여야 한다.

제9조(이용현황 보고 등)
① 데이터제공자는 데이터이용자에 대하여 데이터이용자에 의한 제공데이터의 이용이 본 계약의 조건에 적합한지를 확인하는데 필요한 이용현황의 보고를 요구할 수 있다.
② 데이터제공자는 합리적인 기준에 따라 전항에 따른 보고가 제공데이터 이용현황을 검증하기에 충분하지 않다고 판단하는 경우, 제공데이터의 이용 상황의 감사를 실시할 수 있다.
이 경우 데이터제공자는 데이터이용자의 정보보안에 관한 규정 그 이외의 데이터이용자가 별도로 정하는 사내규정을 준수 하여야 한다.
③ 전항에 의한 감사의 결과, 데이터이용자가 본 계약을 중대하게 위반하여 제공데이터를 이용하였다는 것이 확인되었을 경우, 데이터이용자는 데이터제공자에 대하여 감사에 소요된 비용 및 제공데이터의 이용에 관한 추가의 대가를 지급해야 한다. 데이터이용자가 데이터제공자에게 지급해야 하는 감사비용은 협의에 의해 정한다.

제10조(제공데이터의 유출 시 조치)
① 데이터이용자는 제공데이터 유출, 제3자 제공, 목적 외 이용 등 본 계약에 위반하는 제공데이터의 이용(이하 [제공데이터 유출 등]이라 한다)을 발견한 경우, 즉시 데이터제공자에게 그 사실을 통지하여야 한다.
② 데이터이용자의 고의 또는 과실에 의해 제공데이터 유출 등이 발생한 경우, 데이터이용자는 자기의 비용과 책임으로 제공데이터 유출 등의 사실 여부를 확인하고, 제공데이터 유출 등의 사실이 확인된 경우 그 원인 및 재발방지책을 검토하고 이를 데이터제공자에게 보고하여야 한다.
③ 제공데이터에 개인정보가 포함되어 있는 경우, 데이터이용자는 개인정보보호 법령에 따라 개인정보 유출 등의 사실을 감독당국에 신고하여야 한다.

제11조(손해배상)
양 당사자는 자기의 본 계약의 위반에 기인하여 상대방 당사자가 입은 일체의 손해, 손실, 비용 및 지출(합리적인 변호사비용을 포함함. 이하 “손해 등”이라 함)을 상대방 당사자에 대하여 배상할 책임이 있다.

제12조(책임의 제한 등)
① 데이터제공자는 데이터이용자가 제공데이타를 이용하는 과정에서 발생한 타인의 발명, 고안, 창작 및 영업비밀 등 지적재산권 및 기타 권리 침해에 관한 모든 청구, 손실, 손해 또는 비용에 대하여 책임을 지지 않는다.
② 데이터이용자는 제공데이터 이용에 관련되는 제3자와의 사이에 분쟁, 클레임 또는 청구(이하 [분쟁 등]이라 한다)가 발생한 경우에는 즉시 데이터제공자에게 서면으로 통지하여야 하고 또한 자기의 책임과 비용 부담으로 해당 분쟁 등을 해결한다.
③ 데이터이용자는 전항에서 정하는 분쟁 등과 관련하여 데이터제공자가 손해, 손실 또는 비용(합리적인 변호사 비용을 포함, 이하 [손해 등]이라 한다)을 피해 받은 경우(단, 분쟁 등이 데이터제공자의 귀책 사유에 따른 경우를 제외) 데이터제공자에 대하여 해당 손해 등을 전보한다.

제13조(비밀유지의무)
① 데이터제공자 및 데이터이용자는 상대방이 서면·구두 또는 기타 방법을 가리지 않고 비밀정보임을 표명한 후 공개한 비밀정보를 엄격하게 비밀로 유지하고, 상대방의 서면에 의한 사전 동의 없이 제3자에게 공개, 제공, 누설하거나 비밀정보를 본 계약에 따른 권리의 행사 또는 의무의 이행 이외의 목적으로 이용해서는 아니 된다. 다만, 법원의 판결, 수사기관의 강제수사, 행정청의
처분, 명령 또는 관련 법령의 공개의무에 따라서 공개하는 행위는 비밀유지의무를 위반하지
않는 것으로 본다.
② 전항의 규정에도 불구하고 다음 각 호의 어느 하나에 해당하는 정보는 비밀정보에 해당
하지 않는 것으로 한다.
1. 공개 시점에서 이미 공개자가 보유하고 있던 정보
2. 비밀정보에 의하지 않고 공개자가 독자적으로 개발 한 정보
3. 공개 시점에 알려진 정보
4. 공개 후에 공개자의 귀책사유에 의하지 아니하고 공지된 정보
5. 정당한 권리를 가진 제3자로부터 비밀유지의무 없이 공개된 정보
③ 공개자는 본 계약의 이행을 위해 필요한 범위 내에서만 본조 제1항에 근거하여 비밀유지 의무를 준수하는 것을 전제로, 자신의 임직원 또는 법률상 준수의무가 있는 자신의 변호사, 회계사, 세무사 등에 비밀정보를 공개할 수 있다.
④ 본조에 따른 의무는 본 계약이 종료 된 후에도  1 년간 존속한다

제14조(계약기간)
① 본 계약의 유효기간은 계약 체결일로부터 [1년]이다.
② 양 당사자는 전항의 기간만료 [1개월] 전까지 상대방 당사자에게 계약의 종료 의사를 서면으로 통지해야 하며, 별도의 통지가 없는 한 본 계약은 동일한 내용으로 [1년]씩 자동연장 되는 것으로 본다.
③ 당사자들이 본 계약의 내용 및 조건을 변경하고자 할 경우 변경된 내용을 반영하여 계약서를 새로이 작성하여야 한다.

제15조(계약의 해제 또는 해지)
① 일방 당사자는 타방 당사자가 본 계약을 위반한 경우  15 일간의 기간을 정해 위반의 시정을 요구할 수 있고, 그 기간내에 타방 당사자가 위반을 시정하지 않은 때에는 서면에 의한 통지로써 본 계약을 해제/해지할 수 있다.
② 다음 각 호에 해당하는 경우, 양 당사자는 최고 없이도 즉시 본 계약을 해제/해지할 수 있다.
1. 데이터이용자가 대금 지급의무를  7 일 이상 지체하는 경우
2. 수표, 어음의 부도처리 등 지급정지상태에 빠진 경우
3. 압류, 가압류, 가처분, 강제집행이 발생한 경우
4. 파산, 화의, 정리, 회생절차 개시신청을 받거나 또는 스스로 그러한 신청을 한 경우
5. 데이터제공자의 사전승낙 없이 본 계약상 권리의 전부 또는 일부 양도하는 경우
6. 데이터이용자의 회사가 타사와 합병, 인수되는 경우
③ 본 조의 해제/해지는 손해배상청구권에 영향을 미치지 않는다.
④ 양 당사자는 본 계약에서 별도로 정한 경우 이외에 서면으로 합의함으로써 본 계약을
해제/해지할 수 있다.

제16조(본 계약 종료 후의 효력)
① 본 계약이 유효기간의 만료 또는 해제에 따라 종료한 경우이더라도, 제7조, 제9조, 제12조의 규정은 유효하게 존속하는 것으로 한다.
② 데이터이용자는 본 계약이 종료한 때 제공데이터 중에서 [별지]에서 계약종료시에 폐기 또는 제거, 삭제가 명기된 것에 관하여, 데이터제공자 및 데이터이용자가 별도로 협의하여 정한 절차에 따라서 신속히 폐기 또는 제거, 삭제하는 것으로 한다.
③ 데이터이용자는 제2항에 따라 폐기 또는 제거, 삭제할 의무를 부담하는 데이터 이외의 제공데이터 등에 대하여 데이터제공자의 이용허락을 받아 계속 이용할 수 있다. 데이터이용자가 계속 제공데이터 등을 이용하는 범위 및 행위에 대해서는 본 계약의 효력이 유지된다.
④ 데이터이용자는 계약 종료 후 데이터제공자로부터 제공받은 자료를 즉시 데이터제공자에게 반환한다. 물리적으로 반환할 수 없는 경우에는 데이터제공자의 지시에 따라 폐기하고 폐기한 사실을 증명하는 자료를 서면으로 제출 하여야 한다.

제17조(비용)
본 계약에 별단의 정함이 있는 경우와 아울러 데이터제공자 및 데이터이용자가 별도 합의한 경우를 제외하고, 본 계약의 체결 및 이행에 관한 비용에 관해서는 각자의 부담으로 한다.

제18조(불가항력)
① 어떠한 당사자도 지진, 태풍, 수해, 화재, 노동쟁의, 전쟁 기타 예측이 불가능하고 합리적인 통제를 벗어나는 원인으로 인해 계약의 이행에 실패하거나 이행을 지체하여 손해 또는 손실을 발생시켰더라도 이에 대해 책임을 지지 않는다. 단, 이행을 하지 못하게 된 당사자는 그 원인을 제거하기 위해 상업적이고 합리적인 노력을 다하여야 하고, 그 원인이 제거된 후에는 합리적인 조치로 계약의 이행을 지속해야 한다.
② 어느 일방 당사자가 불가항력의 사유로 인한 이행의무의 전부 또는 일부에 대한 불능의
상태에 이르는 경우, 즉시 상대 당사자에게 그러한 불가항력에 대한 합리적으로 충분한 내용을 통지하여야 한다.
③ 전항의 경우, 양 당사자들은 계약의 원활한 이행을 위해 성실하게 선후책을 협의해야 한다.
④ 불가항력에 의한 본 계약의 이행불능 또는 이행지체가  10 일 이상 지속되는 경우, 일방
당사자는 상대방에 대한 서면 통지로서 손해배상 없이도 본 계약을 해제/해지할 수 있다.
⑤ 불가항력을 이유로 본 계약이 해제/해지될 경우 그때까지 데이터제공자가 수령한 이용대가는 반환되지 않는다.

제19조(완전합의)
본 계약은 양 당사자의 모든 합의를 망라하며, 본건 주제에 관한 양 당사자 간의 서면 또는 구두에 의한 종전의 일체의 합의에 우선한다. 본 계약의 변경은 양 당사자가 서명한 서면에 의하지 않는 한 구속력을 가지지 않는 것으로 한다.

제20조(일부 무효)
본 계약의 일부 조항이 무효 또는 이행불능으로 인정되더라도 특별한 사정이 없는 한 이러한 사실은 본 계약의 다른 조항의 유효성에 영향을 미치지 아니한다.

제21조(양도금지)
데이터제공자 및 데이터이용자는 상대방 당사자의 사전 서면에 의한 동의를 받지 아니하고, 본 계약, 본 계약상의 지위 또는 이들에 기한 권리 · 의무를 양도, 이전 기타 처분을 해서는 아니된다.

제22조(준거법)
본 계약은 한국법에 준거하고, 한국법에 따라서 해석한다.

제23조(관할법원)
본 계약에 기인하거나 관련하여 발생한 분쟁(본 계약의 각 조항의 해석에 의문이 발생한 경우를 포함함)에 대해서는 우선 데이터제공자 및 데이터이용자가 성실하게 협의하여 해결하기로 한다. 다만 이러한 협의가 이루어지지 않은 경우에는 경기도 관할 지방법원의 재판에 의하여 해결한다.

(데이터 이용자)
회 사 명 : +dataUser_company+
주    소 : +dataUser_address+
대 표 자 : +dataUser_representor+
연 락 처 : +dataUser_phone+

(데이터 제공자)
회 사 명 : 한국평가데이터(주)
주    소 : 서울특별시 영등포구 의사당대로 21 3층
담 당 자 : 이  강  원
연 락 처 : 02-3215-1213
`;
