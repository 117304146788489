export const getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const serialize = (obj, prefix) => {
    let str = [], p;
    for (p in obj) {
        if (obj.hasOwnProperty(p) && obj[p].length > 0) {
            let k = prefix ? prefix + "" : p,
                v = obj[p];
            str.push((v !== null && typeof v === "object") ?
                serialize(v, k) :
                encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
    }
    return str.join("&");
};

export const getParamsFromQueryString = (queryString) => {
    if(!!!queryString) return {};
    let u = queryString;
    u = u.replace("?", "");
    let f = u.split("=");
    let s = f.map(d => d.split("&"));
    let r = s.flat();

    let res = {};
    r.map((d, k) => {
        if(k % 2 === 0){
            let dd = decodeURIComponent(d);
            if(!Array.isArray(res[dd]))
                res[dd] = [];
            res[dd].push(decodeURIComponent(r[k + 1]));
        }
    });

    return res;
};
