import React from "react";
import { css } from "@emotion/core"
import Loader from "react-spinners/BeatLoader";

const override = css`
    display: blocl;
    margin: 0 auto;
    border-color: red;
    
`;

class SpinnerLoader extends React.Component {
    constructor(props) {
        let loading;
        loading = props.loading;
        super(props);
        this.state = {
            loading: loading
        };
    }

    render() {
        return (
            <div className={this.state.loading ? 'spinner-parent-disable' : ''}>
                <div className={"spinner-overlay-box"}>
                    <Loader
                        css={override}
                        size={this.props.size}
                        color={this.props.color}
                        margin={15}
                        loading={this.state.loading}
                    />
                </div>
            </div>
        );
    }
}

SpinnerLoader.defaultProps = {
    size: 30,
    color: "#4f77e2"
};

export default SpinnerLoader;
