import React, {useState, useEffect} from 'react';
import {HashRouter, Link} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {setTourBtn} from '../../store/actions/UserAction'
import * as DataApi from "../../api/Data";
import SearchForm from "../../components/SearchForm";

import banner1 from '../../assets/images/mapdata.png'
import banner2 from '../../assets/images/ggdatadream.png'
import banner3 from "../../assets/images/data_portal.png"

import icon1 from "../../assets/images/mo_left_icon10.png"
import icon2 from "../../assets/images/mo_left_icon11.png"
import icon3 from "../../assets/images/mo_left_icon12.png"
import icon4 from "../../assets/images/mo_left_icon13.png"
import icon5 from "../../assets/images/mo_left_icon14.png"
import icon6 from "../../assets/images/mo_left_icon15.png"
import icon7 from "../../assets/images/mo_left_icon16.png"
import icon8 from "../../assets/images/mo_left_icon17.png"
import icon9 from "../../assets/images/mo_left_icon18.png"
import icon10 from "../../assets/images/mo_left_icon19.png"

import DatasetCard from "../../components/DatasetCard";
import Tour from "reactour";
import TourMessage from "../common/tourMessage";
import { disableBodyScroll, enableBodyScroll} from "body-scroll-lock";
import {setChangeMain} from "../../store/actions/DataAction";
import Home from "../Home"
import IcoPlus from "../../assets/images/ico_main_plus.svg";
import * as IboardApi from "../../api/Board";

function Main(props){

    const userInfo = useSelector(state => state.user.info);
    const isLogin = useSelector(state => state.user.isLogin);
    const isTourOpen = useSelector(state => state.user.isTourOpen)

    let dispatch = useDispatch()
    let recommendDataDummy = require('./RecommendData.json');
    let [recommendData, setRcommendData] = useState(recommendDataDummy);
    let [popularKeyword, setPopularKeyword] = useState([]);
    let [popularDataset, setPopularDataset] = useState([]);
    let [popularDownloadDataset, setPopularDownloadDataset] = useState([]);
    let [latestData, setLatestData] = useState([]);
    let [totalCount, setTotalCount] = useState(0);

    let [pieTab, setPieTab] = useState("A");
    let [noticeList, setNoticeList] = useState([]);
    let [faqList, setFaqList] = useState([]);

    let [tab, setTab] = useState("A");

    const mainTourMsg = new TourMessage.MainPageTourMessage()
    let disableBody = target => disableBodyScroll(target)
    let enableBody = target => enableBodyScroll(target)

    const mainFlag = useSelector(state => state.data.changeMain);
    const changeMainFunction = () => {
        dispatch(setChangeMain(!mainFlag))
    }
    let service_link1 = "https://www.bigdata-map.kr/";
    let service_link2 = "https://data.gg.go.kr/portal/mainPage.do";
    let service_link3 = "https://insight.gg.go.kr/";

    let icon_link1 = "http://www.kedkorea.com/ci/CIINT01R0.do";
    let icon_link2 = "https://www.theimc.co.kr/web/main/main.php";
    let icon_link3 = "https://konai.com/";
    let icon_link4 = "https://www.hanyang.ac.kr/";
    let icon_link5 = "https://www.gjf.or.kr/gjf/index.do";
    let icon_link6 = "https://www.krihs.re.kr/";
    let icon_link7 = "https://kcd.co.kr/";
    let icon_link8 = "https://www.gcgf.or.kr/gcgf/index.do";
    let icon_link9 = "http://www.epumpkin.co.kr/";
    let icon_link10 = "https://www.kitech.re.kr/main/"

    const faq_board_id = "board_a2eb004d-73ca-4014-a9d2-c402675cf83f";
    const notice_board_id = "board_14ab0a7f-d410-48d7-91ca-e264d5121794";
    useEffect(() => {
        let ketwordParam = {
            time_unit: "d",
            howmany: 5
        };
        DataApi.readSearchPopularKeyword(ketwordParam).then(res => {
            setPopularKeyword(res.result.popular_list);
        });

        let popularDatasetParam = {
            type: "view",
            time_unit: "d",
            howmany: 10
        };
        DataApi.readSearchPopularData(popularDatasetParam).then(res => {
            setPopularDataset(res.result.popular_countlist);
        });

        let popularDownloadDatasetParam = {
            type: "download",
            time_unit: "d",
            howmany: 10
        };
        DataApi.readSearchPopularData(popularDownloadDatasetParam).then(res => {
            setPopularDownloadDataset(res.result.popular_countlist);
        });

        let latestDataParam = {
            howmany: 10
        };
        DataApi.readLatestData(latestDataParam).then(res => {
            // console.log(res.result.results);
            setLatestData(res.result.results);
        });

        DataApi.readDataCount().then(res => {
            setTotalCount(res.result.count);
        });
        let notice_params = {
            board_id: notice_board_id,
            page_size: "8",
            page: 0,
            user_id: "",
            search_value: "",
            search_type: "subject"
        };
        IboardApi.readTextList(notice_params).then(res => {
            let textList = res.result.textlist;
            setNoticeList(textList);
        })
        let faq_params = {
            board_id: faq_board_id,
            page_size: "8",
            page: 0,
            user_id: "",
            search_value: "",
            search_type: "subject"
        };
        IboardApi.readTextList(faq_params).then(res => {
            let textList = res.result.textlist;
            setFaqList(textList);
        })
    }, []);

    const closeTour = () => {
        dispatch(setTourBtn(false))
    }

    return (
        <div>
            <Home> </Home>
            {/*<button onClick={() => changeMainFunction()} className={"main-change-button"}>*/}
            {/*    <img src={IcChange} width={"14px"} height={"14px"} style={{"marginRight": "10px"}}/>*/}
            {/*    <p>메인화면 전환하기</p>*/}
            {/*</button>*/}
            <Tour
                steps={mainTourMsg.tourConfig}
                isOpen={isTourOpen}
                onRequestClose={closeTour}
                rounded={5}
                accentColor={"#004c9d"}
                onAfterOpen={disableBody}
                onBeforeClose={enableBody}
                closeWithMask={false}
            />
            <div className={"main-img"}>
                <div className={"main-data-search"}>
                    {/*<div className={"title"}>수집 데이터 {addComma(totalCount)} 개</div>*/}
                    <div className={"title"}>지역경제 데이터를 한곳에서 확인해 보세요!</div>
                    <div className={"search-wrap"} data-tut={"search_helper"}>
                        <SearchForm {...props}/>
                    </div>
                    <div className={"main-tags"} data-tut={"pop_keyword_helper"}>
                        {popularKeyword.length > 0 && <>
                            <span className={"main-tags-keyword"}>인기검색어 : </span>
                            {popularKeyword.map(keyword =>
                                <Link key={JSON.stringify(keyword)} to={"/dataset?word=" + keyword.keyword}>
                                    <span>{keyword.keyword}</span>
                                </Link>
                            )}
                        </>
                        }
                    </div>
                </div>
            </div>
            <div className={"main-icon-group"} data-tut={"category_helper"}>
                <ul>
                    <li onClick={() => props.history.push("/dataset")}>
                        <dl>
                            <dt className={"icon1"}></dt>
                            <dd>전체</dd>
                        </dl>
                    </li>
                    <li onClick={() => props.history.push("/dataset?category=지역화폐")}>
                        <dl>
                            <dt className={"icon2"}></dt>
                            {/*LC*/}
                            <dd>지역화폐</dd>
                        </dl>
                    </li>
                    <li onClick={() => props.history.push("/dataset?category=기업")}>
                        <dl>
                            <dt className={"icon3"}></dt>
                            {/*EN*/}
                            <dd>기업</dd>
                        </dl>
                    </li>
                    <li onClick={() => props.history.push("/dataset?category=지역트랜드")}>
                        <dl>
                            <dt className={"icon4"}></dt>
                            {/*SN*/}
                            <dd>지역트랜드</dd>
                        </dl>
                    </li>
                    <li onClick={() => props.history.push("/dataset?category=취업")}>
                        <dl>
                            <dt className={"icon5"}></dt>
                            {/*JB*/}
                            <dd>취업</dd>
                        </dl>
                    </li>
                    <li onClick={() => props.history.push("/dataset?category=지역현황")}>
                        <dl>
                            <dt className={"icon6"}></dt>
                            {/*GS*/}
                            <dd>지역현황</dd>
                        </dl>
                    </li>
                    <li onClick={() => props.history.push("/dataset?category=기업신용보증")}>
                        <dl>
                            <dt className={"icon7"}></dt>
                            {/*AC*/}
                            <dd>기업신용보증</dd>
                        </dl>
                    </li>
                    <li onClick={() => props.history.push("/dataset?category=영상")}>
                        <dl>
                            <dt className={"icon9"}></dt>
                            {/*AC*/}
                            <dd>영상</dd>
                        </dl>
                    </li>
                    <li onClick={() => props.history.push("/dataset?paytag=pay")}>
                        <dl>
                            <dt className={"icon-pay"}></dt>
                            {/*AC*/}
                            <dd>유료 데이터</dd>
                        </dl>
                    </li>
                    <li onClick={() => props.history.push("/dataset?paytag=free")}>
                        <dl>
                            <dt className={"icon-free"}></dt>
                            {/*AC*/}
                            <dd>무료 데이터</dd>
                        </dl>
                    </li>
                </ul>
            </div>

            {/* 모바일 데이터 목록 start */}
            <div className="main-data-list-mo">
                <div className={"tab-group"}>
                    <ul>
                        <li className={tab === "A" ? "selected" : ''} onClick={() => setTab("A")}>인기조회</li>
                        <li className={tab === "B" ? "selected" : ''} onClick={() => setTab("B")}>인기다운로드</li>
                        <li className={tab === "C" ? "selected" : ''} onClick={() => setTab("C")}>최신데이터</li>
                    </ul>
                    <div className="tab-panels">
                        <div id="tab1" className="tab-panel">
                            {tab === "A" && <ul className={"data-list"}>
                                {popularDataset.map((item, idx) =><li key={JSON.stringify(item)}>
                                    <span>{idx + 1}</span><Link to={"/dataset/"+item.data_id}>{item.title}</Link>
                                </li>)}
                            </ul>}
                            {tab === "B" && <ul className={"data-list"}>
                                {popularDownloadDataset.map((item, idx) =><li key={JSON.stringify(item)}>
                                    <span>{idx + 1}</span><Link to={"/dataset/"+item.data_id}>{item.title}</Link>
                                </li>)}
                            </ul>}
                            {tab === "C" && <ul className={"data-list"}>
                                {latestData.map((item, idx) =><li key={JSON.stringify(item)}>
                                    <span>{idx + 1}</span><Link to={"/dataset/"+item.id}>{item.title}</Link>
                                </li>)}
                            </ul>}
                        </div>
                    </div>
                </div>
            </div>
            {/* 모바일 데이터 목록 end */}
            <div className="main-data-list">
                <div className={"main-data-list-inner"} data-tut={"data_rank_helper"}>
                    <dl className={"data-list-block"}>
                        <dt>인기 조회 데이터</dt>
                        <dd>
                            <ul className={"data-list"}>
                                {popularDataset.map((item, idx) =><li key={JSON.stringify(item)}>
                                    <span>{idx + 1}</span><Link to={"/dataset/"+item.data_id}>{item.title}</Link>
                                </li>)}
                            </ul>
                        </dd>
                    </dl>
                    <dl className={"data-list-block"}>
                        <dt>인기 다운로드 데이터</dt>
                        <dd>
                            <ul className={"data-list"}>
                                {popularDownloadDataset.map((item, idx) =><li key={JSON.stringify(item)}>
                                    <span>{idx + 1}</span><Link to={"/dataset/"+item.data_id}>{item.title}</Link>
                                </li>)}
                            </ul>
                        </dd>
                    </dl>
                    <dl className={"data-list-block"}>
                        <dt>최신 데이터</dt>
                        <dd>
                            <ul className={"data-list"}>
                                {latestData.map((item, idx) =><li key={JSON.stringify(item)}>
                                    <span>{idx + 1}</span><Link to={"/dataset/"+item.id}>{item.title}</Link>
                                </li>)}
                            </ul>
                        </dd>
                    </dl>
                </div>
                {/* <ul>
                    <li>
                        <Link to="/dataset">데이터</Link>
                    </li>
                </ul> */}
            </div>
            <div className={"wrap-monthly-data"}>
                <div className={"wrap-monthly-data-inner"}>

                    <div className={"monthly-data-text"}>
                        <h2>이달의 추천 데이터</h2>
                        <p>매달 업데이트 되는 추천 데이터를 확인해보세요!</p>
                    </div>
                    <div className={"gallery-wrap"}>
                        <ul className="gallery-block">
                            {recommendData.map((row, idx) => <li key={JSON.stringify(row)} onClick={() => props.history.push('/dataset/'+row.package_id)}>
                                <DatasetCard key={idx}
                                             title={row.title}
                                             description={row.description}
                                             image={row.image}
                                />
                            </li>)}
                        </ul>
                    </div>

                    <div className={"new-main-fifth-section"}>
                        <div className={"new-main-fifth-section-wrap"}>
                            <p className={"new-main-fifth-section-section-title"} style={{"marginBottom": "20px"}}>바로가기</p>
                            <div className={"new-main-fifth-section-content-img"} onClick={() => window.open(service_link1)}>
                                <img src={banner1} style={{'top' : '1713px', 'left': '389px', 'width': '162px', 'height': '38px', 'marginLeft':'22px','marginRight':'230px', 'marginTop':'32px','marginBottom':'31px'}}/>
                                {/*<div className={"new-main-fifth-section-content-img-cover"}>*/}
                                {/*    <p className={"new-main-fifth-section-content-img-cover-text"}>분석 서비스 센터 Open</p>*/}
                                {/*</div>*/}
                                <p>  </p>
                            </div>
                            <div className={"new-main-fifth-section-content-img"}  onClick={() => window.open(service_link2)}>
                                <img src={banner2} style={{'top' : '1793px', 'left': '392px', 'width': '108px', 'height': '58px' , 'marginLeft':'22px','marginRight':'230px', 'marginTop':'10px','marginBottom':'33px'}}/>

                                {/*<div className={"new-main-fifth-section-content-img-cover"}>*/}
                                {/*    <p className={"new-main-fifth-section-content-img-cover-text"}>분석 서비스 센터 Open</p>*/}
                                {/*</div>*/}
                                <p>  </p>
                            </div>
                            <div className={"new-main-fifth-section-content-img"} onClick={() => window.open(service_link3)}>
                                <img src={banner3} style={{'top' : '1921px', 'left': '396px', 'width': '149px', 'height': '27px' , 'marginLeft':'22px','marginRight':'230px', 'marginTop':'37px','marginBottom':'37px'}}/>

                                {/*<div className={"new-main-fifth-section-content-img-cover"}>*/}
                                {/*    <p className={"new-main-fifth-section-content-img-cover-text"}>분석 서비스 센터 Open</p>*/}
                                {/*</div>*/}
                                <p>  </p>
                            </div>
                        </div>
                        <div className={"new-main-fifth-section-wrap"} style={{"marginLeft": "10px", "marginRight": "10px"}}>
                            <p className={"new-main-fourth-section-section-title"}> 공지사항</p>
                            <div className={"new-main-fourth-section-section-plus-btn"} onClick={() => props.history.push('/board/' + notice_board_id)}>
                                <img src={IcoPlus} width={"20px"} height={"20px"} style={{"opacity": "0.5"}}/>
                            </div>
                            <div className={"new-main-fifth-section-content-wrap"}>
                                {
                                    noticeList.map((item) =>
                                        <p
                                            key={item.id}
                                            onClick={() =>
                                                props.history.push("/board/" + notice_board_id + "/article/" + item.id)
                                            }
                                        >{item.subject}</p>
                                    )
                                }
                            </div>
                        </div>
                        <div className={"new-main-fifth-section-wrap"} style={{"marginLeft": "10px", "marginRight": "10px"}}>
                            <p className={"new-main-fifth-section-section-title"}> FAQ</p>
                            <div className={"new-main-fifth-section-section-plus-btn"} onClick={() => props.history.push('/board/' + faq_board_id)}>
                                <img src={IcoPlus} width={"20px"} height={"20px"} style={{"opacity": "0.5"}}/>
                            </div>
                            <div className={"new-main-fifth-section-content-wrap"} style={{"marginTop" : "10px"}}>
                                {
                                    faqList.map((item) => 
                                        <p
                                            key={item.id}
                                            onClick={() =>
                                                props.history.push("/board/" + faq_board_id + "/article/" + item.id)
                                            }
                                        >{item.subject}</p>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{"clear":"both"}}/>
                    <p 
                        className="new-main-fifth-section-section-title"
                        style={{textAlign: "left", font: "normal normal bold 20px/29px Noto Sans CJK KR", letterSpacing: "0px", color: "#262626", opacity: "1"}}
                    >제공기관</p>
                    <div className={"main-icon-group"} data-tut={"category_helper"} style={{"width" : "1300px" ,"padding":"0px 0 50px 0"}} >
                        <div style={{"marginLeft":"-50px","display":"flex"}}>
                            <li style={{"marginRight" : "20px", "marginLeft" : "45px" , "cursor": "pointer","height": "40px"}}> <a onClick={() => window.open(icon_link1)}><img style={{"width" : "150px"}} src={icon1}/></a></li>
                            <li style={{"marginRight" : "5px", "marginLeft" : "10px", "cursor": "pointer" ,"height": "40px"}}> <a onClick={() => window.open(icon_link2)}><img src={icon2}/></a></li>
                            <li style={{"marginRight" : "5px", "marginLeft" : "-5px", "cursor": "pointer","height": "40px"}}> <a onClick={() => window.open(icon_link3)}><img src={icon3}/></a></li>
                            <li style={{"marginRight" : "5px", "marginLeft" : "5px", "cursor": "pointer" ,"height": "40px"}}> <a onClick={() => window.open(icon_link4)}><img src={icon4}/></a></li>
                            <li style={{"marginRight" : "5px", "marginLeft" : "5px", "cursor": "pointer","height": "40px"}}> <a onClick={() => window.open(icon_link5)}><img src={icon5}/></a></li>
                            <li style={{"marginRight" : "5px", "marginLeft" : "5px", "cursor": "pointer","height": "40px"}}> <a onClick={() => window.open(icon_link6)}><img src={icon6}/></a></li>
                            <li style={{"marginRight" : "5px", "marginLeft" : "5px", "cursor": "pointer","height": "40px"}}> <a onClick={() => window.open(icon_link7)}><img src={icon7}/></a></li>
                            <li style={{"marginRight" : "5px", "marginLeft" : "5px", "cursor": "pointer","height": "40px"}}> <a onClick={() => window.open(icon_link8)}><img src={icon8}/></a></li>
                            <li style={{"marginRight" : "5px", "marginLeft" : "5px", "cursor": "pointer","height": "40px"}}> <a onClick={() => window.open(icon_link9)}><img src={icon9} style={{"width":"130px", "marginTop":"-3px"}}/></a></li>
                            <li style={{"marginRight" : "0px", "marginLeft" : "-10px", "cursor": "pointer","height": "40px"}}> <a onClick={() => window.open(icon_link10)}><img src={icon10}/></a></li>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Main;
