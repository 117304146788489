import React, { useEffect, useState } from 'react';
import NewHeader from '../common/NewHeader';
import NewFooter from '../common/NewFooter';

import * as DataApi from "../../api/Data";
import DatasetList from '../../components/DatasetList';
import {getParameterByName, getParamsFromQueryString, serialize} from '../../util/Url';
import Pagination from "../../components/Pagination";
import DatasetFilterNew from "../../components/DatasetFilterNew";
import Tour from "reactour";
import TourMessage from "../common/tourMessage";
import { disableBodyScroll, enableBodyScroll} from "body-scroll-lock";
import {useDispatch, useSelector} from "react-redux";
import {setTourBtn} from "../../store/actions/UserAction";
import SubNavNew from "../../components/SubNavNew";


import leftImage from '../../assets/images/prev.png';
import left2Image from '../../assets/images/first.png';
import rightImage from '../../assets/images/next.png';
import right2Image from '../../assets/images/last.png';

export default function() {
    let oldParam = {};
    let newParam = {};
    
    let subNav = [
        {
            display: '데이터셋',
            name: '/dataset'
        },
        {
            display: '융합 데이터',
            name: '/datasetMix'
        },
        {
            display: '통합 데이터',
            name: '/integratedDataset'
        }
    ];
    const ListDataset = (props) => {
    
        const getPage = () => {
            let qs = getParamsFromQueryString(props.history.location.search);
            let page = qs.hasOwnProperty("page") ? JSON.parse(JSON.stringify(Number(qs.page[0]))) : 1;
            return page;
        };
    
        let dispatch = useDispatch()
    
        let [dataList, setDataList] = useState([]);
        let [isLoaded, setIsLoaded] = useState(false);
        let [page, setPage] = useState(getPage());
        let [maxPage, setMaxPage] = useState(20);
        let [tags, setTags] = useState([]);
        let [formats, setFormats] = useState([]);
        let [category, setCategory] = useState([]);
        let [paytag, setPayTags] = useState([]);
        let [filters, setFilters] = useState([]);
        let [totalCount, setTotalCount] = useState(0);
        let [limit] = useState(10);
    
        // 투어
        const isTourOpen = useSelector(state => state.user.isTourOpen)
        const datasetTourMsg = new TourMessage.DataSetListPageTourMessage()
        let disableBody = target => disableBodyScroll(target)
        let enableBody = target => enableBodyScroll(target)
    
        const event=useSelector(state=>state.event.filter)
        const searchData = (params = false, page = 1) => {
            let p = {};
            if(params){
                p = params;
                oldParam = p;
            }else{
                p = oldParam;
            }
            p.page = getPage() -1;
            p.limit = limit;
            if(event!==""){
                p.center_code=event
            }
    
            DataApi.readSearchData(p).then(res => {
                setTotalCount(res.result.count);
                setFormats(res.result.search_facets.res_format.items);
                setTags(res.result.search_facets.tags.items);
                setCategory(res.result.search_facets.DataSet_DataSet_theme.items);
                setPayTags(res.result.search_facets.DataService_DataService_priceInfo.items);
                setDataList(res.result.results);
                // setPage(p.page + 1);
            });
        };
    
        useEffect(() => {
            setMaxPage(Math.ceil(totalCount / limit));
        }, [totalCount]);
    
        useEffect(() => {
            // searchData(false, page);
        }, [page]);
    
        const onPageMove = (page) => {
    
            let qs = getParamsFromQueryString(props.history.location.search);
            qs.page = [String(page)];
    
            props.history.push({
                pathname: "/dataset",
                search: serialize(qs)
            });
    
            // setPage(page);
        };
    
        const closeTour = () => {
            dispatch(setTourBtn(false))
        }
    
        const handleFilterChange = (params) => {
    
            if((oldParam.hasOwnProperty("tag") ? oldParam.tag.length : 0) +
                (oldParam.hasOwnProperty("category") ? oldParam.category.length : 0) +
                (oldParam.hasOwnProperty("word") ? oldParam.word.length : 0) +
                (oldParam.hasOwnProperty("format") ? oldParam.format.length : 0) +
                (oldParam.hasOwnProperty("paytag") ? oldParam.paytag.length : 0)
                !=
                (params.hasOwnProperty("tag") ? params.tag.length : 0) +
                (params.hasOwnProperty("category") ? params.category.length : 0) +
                (params.hasOwnProperty("word") ? params.word.length : 0) +
                (params.hasOwnProperty("format") ? params.format.length : 0) +
                (params.hasOwnProperty("paytag") ? params.paytag.length : 0)){
                params.page = String(1);
            }else params.page = String(getPage());
    
    
            if(isLoaded){
                props.history.push({
                    pathname: "/dataset",
                    search: serialize(params)
                });
            }
            else {
                props.history.replace({
                    pathname: "/dataset",
                    search: serialize(params)
                });
                setIsLoaded(true);
            }
    
            oldParam = JSON.parse(JSON.stringify(params));
    
    
            searchData(params);
        };
    


    return (
        <div className="renewal">
            <NewHeader />

            {/* <section className="lnb w1280 데이터셋">	
                <div className="lnb-tit">
                    <h2>데이터 셋</h2>
                </div>
                <div className="lnb-menu pc">
                    <ul className="box">
                        <li><Link to="/new-main" className="home">HOME</Link></li>
                        <li className="on"><Link to="/new-dataset">데이터 셋</Link></li>
                        <li><Link to="/datasetMix">융합 데이터</Link></li>
                        <li><Link to="/integratedDataset">통합 데이터 검색</Link></li>
                    </ul>
                </div>
            </section> */}

            <SubNavNew nav={subNav} history={props.history}/>
            
            <section className="contents 데이터셋">
                <div className="contents-wrap w1280 datasetWrap">
                    {/* 내용 */}
                    <div className="inner dataset">
                        {/*  */}
                        <div className="boxWrap on">
                            <div className="inner-tit">
                                <h6>데이터 상품
                                    <span>상세검색</span>
                                    <p>검색결과(749)</p>
                                </h6>
                                <a href="#void" title="더보기">&nbsp;</a>
                            </div>
                            <div className="inner-con">
                                <div className="category">
                                
                                    {/* 전체 카테고리 */}
                                    <div className="inputWrap all">
                                        <div className="title">
                                            <input type="checkbox" id="cbx_chkAll" />
                                            <label htmlFor="cbx_chkAll">전체 카테고리</label>
                                        </div>
                                        <ul>
                                            <li>
                                                <div>
                                                    <input type="checkbox" name="chk" id="all-1" value="지역현황" />
                                                    <label htmlFor="all-1">지역현황</label>
                                                </div>
                                                <div>
                                                    <input type="checkbox" name="chk" id="all-2" value="기업신용보증" />
                                                    <label htmlFor="all-2">기업신용보증</label>
                                                </div>
                                            </li>
                                            <li>
                                                <div>
                                                    <input type="checkbox" name="chk" id="all-3" value="지역트렌드" />
                                                    <label htmlFor="all-3">지역트렌드</label>
                                                </div>
                                                <div>
                                                    <input type="checkbox" name="chk" id="all-4" value="지역현황" />
                                                    <label htmlFor="all-4">소상공인</label>
                                                </div>
                                            </li>
                                            <li>
                                                <div>
                                                    <input type="checkbox" name="chk" id="all-5" value="지역현황" />
                                                    <label htmlFor="all-5">지역화폐</label>
                                                </div>
                                                <div>
                                                    <input type="checkbox" name="chk" id="all-6" value="지역현황" />
                                                    <label htmlFor="all-6">취업</label>
                                                </div>
                                            </li>
                                            <li>
                                                <div>
                                                    <input type="checkbox" name="chk" id="all-7" value="지역현황" />
                                                    <label htmlFor="all-7">기업</label>
                                                </div>
                                                <div>
                                                    <input type="checkbox" name="chk" id="all-8" value="지역현황" />
                                                    <label htmlFor="all-8">영상</label>
                                                </div>
                                            </li>
                                            <li>
                                                <div>
                                                    <input type="checkbox" name="chk" id="all-9" value="지역현황" />
                                                    <label htmlFor="all-9">친환경인프라</label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                    {/* 판매기관,업체 */}
                                    <div className="inputWrap organ">
                                        <div className="title">
                                            <input type="checkbox" id="cbx_chkORGAN" />
                                            <label htmlFor="cbx_chkORGAN">판매기관 · 업체</label>
                                        </div>
                                        <ul>
                                            <li>
                                                <div>
                                                    <input type="checkbox" name="chk-organ" id="organ-1" value="지역현황" />
                                                    <label htmlFor="organ-1">경기신용보증재단</label>
                                                </div>
                                                <div>
                                                    <input type="checkbox" name="chk-organ" id="organ-2" value="지역현황" />
                                                    <label htmlFor="organ-2">더아이엠씨</label>
                                                </div>
                                            </li>
                                            <li>
                                                <div>
                                                    <input type="checkbox" name="chk-organ" id="organ-3" value="지역현황" />
                                                    <label htmlFor="organ-3">국토연구원</label>
                                                </div>
                                                <div>
                                                    <input type="checkbox" name="chk-organ" id="organ-4" value="지역현황" />
                                                    <label htmlFor="organ-4">경기도경제과학진흥원</label>
                                                </div>
                                            </li>
                                            <li>
                                                <div>
                                                    <input type="checkbox" name="chk-organ" id="organ-5" value="지역현황" />
                                                    <label htmlFor="organ-5">코나아이</label>
                                                </div>
                                                <div>
                                                    <input type="checkbox" name="chk-organ" id="organ-6" value="지역현황" />
                                                    <label htmlFor="organ-6">한국평가데이터</label>
                                                </div>
                                            </li>
                                            <li>
                                                <div>
                                                    <input type="checkbox" name="chk-organ" id="organ-7" value="지역현황" />
                                                    <label htmlFor="organ-7">경기콘텐츠진흥원</label>
                                                </div>
                                                <div>
                                                    <input type="checkbox" name="chk-organ" id="organ-8" value="지역현황" />
                                                    <label htmlFor="organ-8">한양대</label>
                                                </div>
                                            </li>
                                            <li>
                                                <div>
                                                    <input type="checkbox" name="chk-organ" id="organ-9" value="지역현황" />
                                                    <label htmlFor="organ-9">한국신용데이터</label>
                                                </div>
                                                <div>
                                                    <input type="checkbox" name="chk-organ" id="organ-10" value="지역현황" />
                                                    <label htmlFor="organ-10">경기도일자리재단</label>
                                                </div>
                                            </li>
                                            <li>
                                                <div>
                                                    <input type="checkbox" name="chk-organ" id="organ-11" value="지역현황" />
                                                    <label htmlFor="organ-11">한국생산기술연구원</label>
                                                </div>
                                                <div>
                                                    <input type="checkbox" name="chk-organ" id="organ-12" value="지역현황" />
                                                    <label htmlFor="organ-12">펌프킨</label>
                                                </div>
                                            </li>
                                            
                                        </ul>
                                    </div>

                                    {/* 이용조건 */}
                                    <div className="inputWrap use">
                                        <div className="title">
                                            <input type="checkbox" id="cbx_chkUSE" />
                                            <label htmlFor="cbx_chkUSE">이용조건</label>
                                        </div>
                                        <ul>
                                            <li>
                                                <input type="checkbox" name="chk-use" id="use-1" value="지역현황" />
                                                <label htmlFor="use-1">무료</label>
                                            </li>
                                            <li>
                                                <input type="checkbox" name="chk-use" id="use-2" value="지역현황" />
                                                <label htmlFor="use-2">유료</label>
                                            </li>
                                        </ul>
                                    </div>

                                    {/* 파일포맷 */}
                                    <div className="inputWrap format">
                                        <div className="title">
                                            <input type="checkbox" id="cbx_chkFORMAT" />
                                            <label htmlFor="cbx_chkFORMAT">파일포맷</label>
                                        </div>
                                        <ul>
                                            <li>
                                                <input type="checkbox" name="chk-format" id="format-1" value="지역현황" />
                                                <label htmlFor="format-1">CSV</label>
                                            </li>
                                            <li>
                                                <input type="checkbox" name="chk-format" id="format-2" value="지역현황" />
                                                <label htmlFor="format-2">ZIP</label>
                                            </li>
                                        </ul>
                                    </div>
                                    
                                </div>{/* //카테고리 */}
                                
                                <div className="search">
                                    <div className="keyword">
                                        <a href="#void" className="reset">초기화</a>
                                        <div>
                                            <span>#한국평가데이터</span>
                                            <span>#유료</span>
                                            <span>#유료</span>
                                            <span>#유료</span>
                                            <span>#유료</span>
                                            <span>#한국평가데이터</span>
                                            <span>#유료</span>
                                            <span>#유료</span>
                                            <span>#유료</span>
                                            <span>#유료</span>
                                            <span>#유료</span>
                                            <span>#유료</span>
                                            <span>#유료</span>
                                            <span>#유료</span>
                                            <span>#한국평가데이터</span>
                                            <span>#유료</span>
                                            <span>#유료</span>
                                            <span>#유료</span>
                                            <span>#유료</span>
                                            <span>#유료</span>
                                            <span>#유료</span>
                                        </div>
                                    </div>
                                    <a href="#void" className="i-search">검색</a>
                                </div>{/* //검색바*/}
                            </div>
                        </div>
                    </div>
                        

                    <ul className="dataset-list">
                    
                        <li className="list-inner">
                            <a href="#void">
                                <img src="../images/dataset/kg-logo.JPG" />
                                
                                <div>
                                    <h3>경기도 일자리 청년통장정보</h3>
                                    <p>경기도 일자리 관련하여 수집하고 가공한 일자리 청년취업 취업 청년통장 정보</p>
                                    <p>사이즈 : 20MB</p>
                                </div>
                                
                                <h3>무료</h3>
                                
                                <div className="btnWrap">
                                    <p>갱신일 2021.12.14</p>
                                    <p>등록일 2020.01.20</p>
                                    <div className="btn">
                                        <span className="sheet" href="#void">SHEET</span>
                                        <span className="zip" href="#void">ZIP</span>
                                    </div>
                                </div>
                            </a>
                        </li>
                        
                        <li className="list-inner">
                            <a href="#void">
                                <img src="../images/dataset/kg-logo.JPG" />
                                
                                <div>
                                    <h3>개인 여신 현황 정보</h3>
                                    <p>경기도 일자리 관련하여 수집하고 가공한 일자리 청년취업 취업 청년통장 정보</p>
                                    <p>사이즈 : 20MB</p>
                                </div>
                                
                                <h3>무료</h3>
                                
                                <div className="btnWrap">
                                    <p>갱신일 2021.12.14</p>
                                    <p>등록일 2020.01.20</p>
                                    <div className="btn">
                                        <span className="sheet" href="#void">SHEET</span>
                                        <span className="csv" href="#void">CSV</span>
                                    </div>
                                </div>
                            </a>
                        </li>
                        
                        <li className="list-inner">
                            <a href="#void">
                                <img src="../images/dataset/kg-logo.JPG" />
                                
                                <div>
                                    <h3>차량별, 노선별 전비 분석-2021년 09월</h3>
                                    <p>경기도 일자리 관련하여 수집하고 가공한 일자리 청년취업 취업 청년통장 정보</p>
                                    <p>사이즈 : 20MB</p>
                                </div>
                                
                                <h3>유료</h3>
                                
                                <div className="btnWrap">
                                    <p>갱신일 2021.12.14</p>
                                    <p>등록일 2020.01.20</p>
                                    <div className="btn">
                                        <span className="sheet" href="#void">SHEET</span>
                                        <span className="zip" href="#void">ZIP</span>
                                    </div>
                                </div>
                            </a>
                        </li>
                        
                        <li className="list-inner">
                            <a href="#void">
                                <img src="../images/dataset/kg-logo.JPG" />
                                
                                <div>
                                    <h3>개인 부도 현황 정보</h3>
                                    <p>경기도 일자리 관련하여 수집하고 가공한 일자리 청년취업 취업 청년통장 정보</p>
                                    <p>사이즈 : 20MB</p>
                                </div>
                                
                                <h3>무료</h3>
                                
                                <div className="btnWrap">
                                    <p>갱신일 2021.12.14</p>
                                    <p>등록일 2020.01.20</p>
                                    <div className="btn">
                                        <span className="sheet" href="#void">SHEET</span>
                                        <span className="zip" href="#void">ZIP</span>
                                    </div>
                                </div>
                            </a>
                        </li>
                        
                        <li className="list-inner">
                            <a href="#void">
                                <img src="../images/dataset/kg-logo.JPG" />
                                
                                <div>
                                    <h3>경기도 일자리 청년통장정보</h3>
                                    <p>경기도 일자리 관련하여 수집하고 가공한 일자리 청년취업 취업 청년통장 정보</p>
                                    <p>사이즈 : 20MB</p>
                                </div>
                                
                                <h3>유료</h3>
                                
                                <div className="btnWrap">
                                    <p>갱신일 2021.12.14</p>
                                    <p>등록일 2020.01.20</p>
                                    <div className="btn">
                                        <span className="sheet" href="#void">SHEET</span>
                                        <span className="csv" href="#void">CSV</span>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>{/* //제공기관*/}
                    
                    
                    {/* 페이징 */}
                    <div className="page_box">	
                        <ul className="mobile">
                            <li><a className="btn prev" href="#void" alt="Prev" title="Prev"><img src={leftImage} alt="Prev" /></a></li>
                            <li><a className="btn next" href="#void" alt="Next" title="Next"><img src={rightImage} alt="Next" /></a></li>
                            <li>
                                <label htmlFor="page" className="col-lg-2 control-label">페이징</label>
                                <select id="page">
                                    <option value="">1</option>
                                    <option value="">2</option>
                                    <option value="">3</option>
                                </select>
                            </li>		
                        </ul>


                        {/* PC 페이징 */}
                        <ul className="pagination pc">
                            <li><a className="first" href="#void"><img src={left2Image} alt="First" /></a></li>
                            <li><a className="prev" href="#void"><img src={leftImage} alt="Prev" /></a></li>
                            <li><a href="#void" className="active" style={{  background: '#EEEEEE' }}>1</a></li>
                            <li><a href="#void">2</a></li>
                            <li><a href="#void">3</a></li>
                            <li><a className="next" href="#void"><img src={rightImage} alt="Next" /></a></li>
                            <li><a className="last" href="#void"><img src={right2Image} alt="Last" /></a></li>
                        </ul>
                    </div>
                    {/* 페이징 END */}		
                                
                </div>
            </section>

            <NewFooter />
        </div>
    );
}
}