import React from 'react';
import ResourcePreview from "./ResourcePreview";
import DatasetRatingScore from "./DatasetRatingScore";
import {byte} from "../util/Number";
import DownloadIco from "../assets/images/ic_download.svg"

const DatasetResource = ({resource, sampleInfo, schemaInfo, fileDownload, sampleFileDownload, schemaFileDownload, downloadLoading, sampleDownloadLoading, schemaDownloadLoading, dataInfo, handleOrderRequest, addFavoriteData, handleDataReport, downloadCount}) => {

    return (
        <>
            <ul className={"info-table"}>
                <li>
                    <strong>이 름</strong>
                    <span>{resource.name}</span>
                </li>
                {/*<li>*/}
                {/*    <strong>등록일자</strong>*/}
                {/*    <span>{yyyymmddhhiiss(new Date(resource.created), "-")}</span>*/}
                {/*</li>*/}
                <li>
                    <strong>포 맷</strong>
                    <span>{resource.format}(UTF-8)</span>
                </li>
                <li>
                    <strong>사이즈</strong>
                    <span>{byte(resource.size, 0)}</span>
                </li>
                <li>
                    <strong style={{"borderBottom": "none"}}>설 명</strong>
                    <span style={{"borderBottom": "none"}}>UTF-8을 지원하는 에디터로 열어야합니다.(예: Notepad++)</span>
                </li>
                <DatasetRatingScore dataInfo={dataInfo} resource={resource} style={{"marginTop": "0px", "marginBottom": "0px", "border": "none"}}/>
                <li>
                    {/*<strong>{resource.format.toUpperCase()}</strong>*/}
                    <strong style={{"borderTop": "none"}}>신청/다운</strong>
                    <span style={{"borderTop": "none"}}>
                    <div className={"ikan-button-wrap ikan-button-line"}>
                        <button onClick={() => handleOrderRequest()}
                        style={{"backgroundColor": "#003a78", "color": "#fff", "marginRight": "10px", "height": "36px", "fontSize": "15px", "borderRadius": "5px"}}>데이터 이용신청</button>

                        <button onClick={() => fileDownload(resource.id)}
                                style={{"backgroundColor": "#003a78", "color": "#fff", "marginRight": "10px", "height": "36px", "fontSize": "15px", "borderRadius": "5px"}}>
                            {downloadLoading ?
                                <div className="lds-facebook" style={{"margin-bottom": "-25px", "top": "-10px"}}>
                                    <div />
                                    <div />
                                    <div />
                                </div>
                            :"데이터 다운"
                            }</button>

                        {
                            sampleInfo.id === undefined ?
                                <button onClick={() => schemaFileDownload(schemaInfo.id)}
                                        style={{"border": "0.5px solid #003a78", "marginRight": "10px", "height": "36px", "fontSize": "15px", "borderRadius": "5px"}}>{schemaDownloadLoading ? <div className="lds-facebook" style={{"margin-bottom": "-25px", "top": "-10px"}}>
                                    <div />
                                    <div />
                                    <div />
                                </div> : "데이터 정보 다운"}</button>
                                :
                                <>
                                    <button onClick={() => sampleFileDownload(sampleInfo.id)}
                                            style={{"border": "0.5px solid #003a78", "marginRight": "10px", "height": "36px", "fontSize": "15px", "borderRadius": "5px"}}>{sampleDownloadLoading ? <div className="lds-facebook" style={{"margin-bottom": "-25px", "top": "-10px"}}>
                                        <div />
                                        <div />
                                        <div />
                                    </div> : "샘플 데이터 다운"}</button>
                                    <button onClick={() => schemaFileDownload(schemaInfo.id)}
                                            style={{"border": "0.5px solid #003a78", "marginRight": "10px", "height": "36px", "fontSize": "15px", "borderRadius": "5px"}}>{schemaDownloadLoading ? <div className="lds-facebook" style={{"margin-bottom": "-25px", "top": "-10px"}}>
                                        <div />
                                        <div />
                                        <div />
                                    </div> : "데이터 정보 다운"}</button>
                                </>
                        }
                    </div>
                        <div style={{"fontSize": "14px", "position": "relative", "top": "13px", "left": "10px", "float": "left", display:"flex"}}>
                            <img src={DownloadIco} width={"20px"} height={"14px"} alt={"download"}/>
                            <p style={{"marginLeft":"6px"}}>
                                다운로드 횟수: {downloadCount}
                            </p>
                        </div>
                    </span>
                </li>
            </ul>
            <div style={{"marginTop": "30px"}}>
                <ResourcePreview sampleId={sampleInfo.id}/>
            </div>
        </>
    )
};

export default DatasetResource;
