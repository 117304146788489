export const index = `2022 경기지역경제포털 빅데이터 활용 시각화 아이디어 공모전 참가 서약서

【 서약서 】

본인은 “2022 경기지역경제포털 빅데이터 활용 시각화 아이디어 공모전”에 참여하면서 다음 사항을 준수할 것을 서약합니다.

1. 본 공모전을 수행하는 과정에서 제공받은 데이터에 대하여 본 공모전의 목적으로만 사용하며, 종료 후에 즉시 삭제 및 파기하여 재활용하지 않는다. 또한, 경기도경제과학진흥원의 허락 없이 자신 또는 제3자를 위하여 사용하지 않는다.
 
2. 응모된 분석결과, 정책제언 등은 공개될 수 있으며 주최ㆍ주관기관의 홍보 및 사업화 등의 자료로 활용될 수 있다.

3. 참가자는 공모전에 응모한 결과물 등이 타인의 것을 고의로 도용한 것으로 인정되거나 또는 분석데이터를 포함한 분석 과정 상 부정한 방법이 확인된 경우에 수상 이후에도 수상 취소 및 상장 및 상금을 반환해야 한다.

4. 수상자인 경우에 한하여 상금 입금 전 참가자 본인의 계좌정보 및 관련자료를 경기도경제과학진흥원에 제공하여야 하며, 위 정보를 제공하지 않거나 허위의 정보를 제공한 경우에는 상금 지급 시 불이익이 발생할 수 있다.

5. 해당 공모전에 응모한 결과물 및 정책제언에 관하여 발생하는 권리는 출품한 참가자에게 있으며 주최 및 주관기관은 당선작에 대하여 사용권을 가진다.

6. 주최 및 주관기관은 수상자와 협의하여 당선작의 발표, 게시, 전시, 자료집, 2차 가공을 하거나 홍보자료 등으로 활용할 수 있다.

7. 공모전에 참가한 아이디어에 대한 저작권 관련 분쟁 책임 및 비용 부담은 참가자에게 있다.
 `
// export const index = `2021 한국기업데이터(KED) 빅데이터 시각화 아이디어 공모전 데이터 보안 서약서

// 【 보안 서약서 】

// 본인은 “2021 한국기업데이터(KED) 빅데이터 시각화 아이디어 공모전”에 참여하면서 다음 사항을 준수할 것을 서약합니다.

// 1. 본 공모전을 수행하는 과정에서 제공받은 데이터에 대하여 본 공모전의 목적으로만 사용하며, 종료 후에 즉시 삭제 및 파기하여 재활용하지 않습니다. 또한 한국기업데이터(주)의 허락 없이 자신 또는 제3자를 위하여 사용하지 않습니다.

// 2. 본 공모전 참여를 위해 제출된 자료는 반환되지 않으며, 수상작의 소유권은 한국기업데이터(주)에 귀속됩니다.

// 3. 본 공모전에 제출된 응모작들에 대한 저작권은 응모자에게 있으나, 주관기관은 응모작의 시연화면 또는 이와 유사한 결과물을 게시하거나, 출판,전시하는 방법으로 활용할 수 있음

// 4. 당선 후 허위신청, 표절 등 부정사실 적발시, 수상 취소 및 상금을 환수하고, 그 책임은 본인이 부담합니다.

// 5. 동일 자료로 타 기관 대회에서 입상하였거나, 타인의 저작권 침해시 지원이 취소됩니다.

// 6. 상기 사항을 위반할 경우 본인은 관련 법률에 따른 민․형사상 책임을 질 것을 서약합니다.
//  `