import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import SelectBox from "../SelectBox";

const SubNavNew = ({children, nav, ...props}) => {

    let [option] = useState(nav);

    useEffect(() => {

    }, []);

    return (
        <>
            <section className="lnb w1280 데이터셋">	
                <div className={option.length <= 6 ? "lnb-tit" : "lnb-tit font-small" }>
                    {option.map(i => 
                        (i.name===props.history.location.pathname && <h2 key={i.display}>{i.display}</h2>)
                    )}
                    {/* <h2>데이터 셋</h2> */}
                </div>
                <div className="lnb-menu pc">
                    <ul className={option.length <= 6 ? "box" : "box font-small" }>
                        {option.length != 0 && <li><Link to="/new-main" className="home">HOME</Link></li>}
                    {option.map(i =>
                        <li className={(i.name === props.history.location.pathname ? "active" : "")} key={i.display}>
                            {i.display === "데이터셋" ? 
                                <Link to={"/new-dataset"} style={{"textAlign": "center"}}>
                                    {i.display}
                                </Link>
                                :
                                <Link to={i.name} style={{"textAlign": "center"}}>
                                    {i.display}
                                </Link>                                
                            }
                        </li>)}                        
                    </ul>
                </div>
            </section>

        </>
    )
};

export default SubNavNew;
