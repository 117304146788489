import React from 'react';
import {Link} from "react-router-dom";
import {yyyymmdd} from "../../util/Date";
import {byte} from "../../util/Number";

import leftImage from '../../assets/images/prev.png';
import left2Image from '../../assets/images/first.png';
import rightImage from '../../assets/images/next.png';
import right2Image from '../../assets/images/last.png';

// kodata
import kodata from '../../assets/images/renewal/datasetOrgan/kodata.png';
// theimc
import imc from '../../assets/images/renewal/datasetOrgan/theimc.png';
// 코나아이
import konai from '../../assets/images/renewal/datasetOrgan/konai.jpg';
// 한양대학교
import hanyang from '../../assets/images/renewal/datasetOrgan/hyu.png';
// 경기도일자리재단
import gjf from '../../assets/images/renewal/datasetOrgan/gjf.jpg';
// 국토연구원
import KRIHS from '../../assets/images/renewal/datasetOrgan/krihs.jpg';
// korea credit data
import kcd from '../../assets/images/renewal/common/b_footer_7.png';
// 경기도신용보증재단
import gcgf from '../../assets/images/renewal/datasetOrgan/gcgf.png';
// pumpkin
import pumpkin from '../../assets/images/renewal/datasetOrgan/pumpkin.png';
// 한국생산기술연구원
import KITECH from '../../assets/images/renewal/datasetOrgan/kitech.jpg';
// 경기도경제과학진흥원
import gbsa from '../../assets/images/renewal/datasetOrgan/gbsa.png';
// 경기콘텐츠진흥원
import gca from '../../assets/images/renewal/datasetOrgan/gca.png';

function GetShowPrice(priceInfo, priceInquiry) {
    if(priceInquiry === 'true') {
        return '문의'
    }
    else {
        return priceInfo === '' || Number(priceInfo) === 0 ? " 무료" : " 유료"
    }
}

const DatasetList = ({list, totalCount}) => {
    return (
        <>
            {list.length > 0 ? 
                <div>
                    {list.map(item => <DatasetItem key={item.id} info={item}/>)}
                </div> 
            : 
                <div className={"ds-2-no-result"} style={{textAlign: "center", padding: 0}}>검색 결과가 없습니다.</div>}
        </>
    );
};
const searchInfoToWhat=(info,what)=>{
    let date="";
    info.map(i=> {
            if (i.key === what) {
                date=i.value.replaceAll("-",".")
                date=date.replace("년",".")
                date=date.replace("월",".")
                date=date.replace("일","")
            }
        }
    )
    return date
}

const CENTER_IMAGES = {
    '한국평가데이터㈜': kodata,
    '한국평가데이터(주)': kodata,
    '한국기업데이터㈜': kodata,
    '더아이엠씨': imc,
    '코나아이㈜': konai,
    '한양대': hanyang,
    '경기도일자리재단': gjf,
    '국토연구원': KRIHS,
    '경기신용보증재단': gcgf,
    '펌프킨': pumpkin,
    '한국생산기술연구원': KITECH,
    '경기도경제과학진흥원': gbsa,
    '한국신용데이터': kcd,
    '경기콘텐츠진흥원': gca,
}

const DatasetItem = ({info}) => {
    // 경기도 일자리 직업정보, 경기도 일자리 소비정보 갱신일자 변경
    let displayDate = searchInfoToWhat(info.extras,"DataSet_DataSet_modified");

    //let displayDate = searchInfoToWhat(info.extras,"DataSet_DataSet_modified")
    let displayCreatedDate = yyyymmdd(new Date(info.metadata_created), ".");

    // 기관이 ggdata인 테스트데이터? 제거
    if (!info.notes && info.organization.name === "ggdata") {
        return false;
    }

    let extraDict = info.extras.reduce(function(map, obj) {
        map[obj.key] = obj.value;
        return map;
    }, {});
    return (
        <ul className="dataset-list">        
            <li className="list-inner">
                <Link to={"/dataset/"+info.id}>
                {CENTER_IMAGES[extraDict['DataSet_DataSet_creator_name']] ?
                    <img src={CENTER_IMAGES[extraDict['DataSet_DataSet_creator_name']]} alt={extraDict['DataSet_DataSet_creator_name']}/>
                :
                    <span>{extraDict['DataSet_DataSet_creator_name']}</span>
                }
                    
                    <div>
                        <h3>{info.title}</h3>
                        <p dangerouslySetInnerHTML={{__html:info.notes.replace(/\r\n|\r|\n/g, "<br />").replace(/□ /g,"")}}></p>
                        {info.resources.map((resource) =>
                            <p key={resource.size}>사이즈: {byte(resource.size, 0)}</p>
                        )}                    
                    </div>
                    
                    <h3>{GetShowPrice(extraDict['DataService_DataService_priceInfo'], extraDict['Ikan_Extra_priceInquiry'])}</h3>
                    
                    <div className="btnWrap">
                        <p>갱신일 {displayDate}</p>
                        <p>등록일 {displayCreatedDate}</p>
                        <div className="btn">
                            {/* <span className="sheet" href="#void">SHEET</span>
                            <span className="zip" href="#void">ZIP</span> */}
                            {info.resources.map((resource, idx) => {
                                    return (
                                        <React.Fragment key={idx}>
                                            {
                                                idx > 0 ? null :
                                                resource.format === "CSV" || resource.format === "ZIP" ?
                                                    <span className={"sheet"} data-tut={"dataset_list_component1"} key={info.package_id}>SHEET</span>
                                                    :
                                                    null
                                            }
                                            <span data-tut={"dataset_list_component2"} key={resource.format + info.id + idx} className={resource.format.toLowerCase()}>{resource.format}</span>
                                        </React.Fragment>
                                    )
                                }
                            )}    
                        </div>
                    </div>
                </Link>
            </li>
        </ul>
    );
};

export default DatasetList;
