import {useDispatch, useSelector} from "react-redux";
import {logout, setUserInfo} from "../../store/actions/UserAction";
import {Link} from "react-router-dom";
import * as DataApi from "../../api/Data";
import swal from "sweetalert"
import React, { useEffect, useState } from 'react';
import Modal from "react-modal";
import { initGnb } from '../../assets/js/renewal';
import { accessLog } from '../../api/User.js';
import parser from 'ua-parser-js';

export default function() {

    const [gnbInit, setGnbInit] = useState(false);
    const userInfo = useSelector(state => state.user.info);
    const isLogin = useSelector(state => state.user.isLogin);
    const changeMain = useSelector(state => state.data.changeMain);
    const [competitionFlag, setCompetitionFlag] = useState(false);
    const [openapiFlag, setOpenApiFlag] = useState(false);
    
    const dispatch = useDispatch();

    useEffect(() => {
        DataApi.readIkanConfig()
            .then(res => {
                setCompetitionFlag(res.result.config_list.filter(config => {
                    return config.function_type === "competition"
                })[0].function_active)
                setOpenApiFlag(res.result.config_list.filter(config => {
                    return config.function_type === "openapi"
                })[0].function_active)
            })
    }, []);

    useEffect(() => {
        if (!gnbInit) {
            setGnbInit(true);
            initGnb();
        }
    }, [gnbInit]);

    const inno = (target) => {
        let service_link;
        const innoServiceLog = {1:"/location/landing" , 2:"/inno/contest" , 3:"/inno/map" , 4:"/kreport", 5:"/visualization"};
        const innoServiceLink = {1:"http://loc.bigdata-region.kr/location" 
                                ,2:"http://inno.bigdata-region.kr:8080/inno/ssn.do"
                                ,3:"http://inno.bigdata-region.kr:8080/inno/ssn.do"
                                ,4:"https://www.kreport.co.kr/"
                                ,5:"http://visualization.bigdata-region.kr:80"};    
        
        if ((target === 2 || target === 3) &&!isLogin) {
            swal("로그인 후 이용 가능한 서비스입니다.")
            window.location.href = "/#/login"
        } else if(target === 2 || target === 3){
            innoLog(innoServiceLog[target]);
            // 서비스 이동
            const user_id = userInfo.id;
            const user_token = userInfo.token;            

            service_link = innoServiceLink[target] + "?user_id=" + user_id + "&token=" + user_token + "&target=" + target;

            window.open(service_link);
        }else{
            innoLog(innoServiceLog[target]);
            service_link = innoServiceLink[target];
            window.open(service_link);            
        }
    }

    const innoLog = (path) => {
            // 로그남기기
            const ua = parser(window.navigator.userAgent);
            const log = {
                sessionId: sessionStorage.getItem('_sessionId') || '',
                ckanId: userInfo?.id_key || '',
                username: userInfo?.id || '',
                device: ua.device?.type || 'desktop',
                os: ua.os?.name || '',
                browser: ua.browser?.name || '',
                path: path,
                state: userInfo?.state || ''
            }
    
            accessLog(log).then((result) => {
                if (result.status_code === 'SUCCESS')
                    sessionStorage.setItem('_sessionId', result.result.sessionId);
            });
    }

    const competi = (target) => {
        if (!isLogin) {
            swal("로그인 후 이용 가능한 서비스입니다.")
            window.location.href = "/#/login"
        } else {
            window.location.href = "/#/competition"
        }
    }

        //모달
        const [modalIsOpen, setIsOpen] = useState(false);

        const customStyles = {
            overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                zIndex: 1060
            },
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '50%'
            }
        };
        let subtitle = undefined;

        const openModal = () => {
            setIsOpen(true);
        };

        const closeModal = () => {
            setIsOpen(false);
        };

        const goToLink = (link) => {
            window.location.href = link;
        }

    return (<>
            {setIsOpen &&
                <Modal
                    isOpen={modalIsOpen}
                    // onAfterOpen={afterOpenModal}
                    aria={{
                        labelledby: "heading",
                        describedby: "full_description"
                    }}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Inline Styles Modal Example"
                >
                    <div style={{"width": "100%", "borderBottom": "1px solid #cad0dd", "paddingBottom": "20px"}}>
                        <span id="heading" style={{"fontSize": "24px", "fontWeight": "500"}}>경기지역경제포털</span>
                        <span style={{"float": "right", "marginTop": "5px"}} onClick={() => closeModal()}> <p
                            className={"sitemap-close"}></p> </span>
                    </div>
                    <div id="full_description">
                        <div>
                            <ul className={"sitemap-wrap"}>
                                <li className={"sitemap-item"} style={{"margin-left": "0"}}>
                                    <p>데이터셋</p>
                                    <ul>
                                        <li>
                                            <Link to={"/new-dataset"} onClick={() => closeModal()}>데이터셋</Link>
                                        </li>
                                        <li>
                                            <Link to={"/integratedDataset"} onClick={() => closeModal()}>통합 데이터 검색</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className={"sitemap-item"}>
                                    <p>융합데이터</p>
                                    <ul>
                                        <li>
                                            <Link to={"/new-datasetMix"} onClick={() => closeModal()}>분야별 데이터</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className={"sitemap-item"}>
                                    <p>활용</p>
                                    <ul>
                                        <li>
                                            <Link to={"/use"} onClick={() => closeModal()}>활용 갤러리</Link>
                                        </li>
                                        <li>
                                            <a href="http://visualization.bigdata-region.kr:80" target="_blank">데이터 시각화</a>
                                        </li>
                                        <li>
                                            <Link to={"/analysisService"} onClick={() => closeModal()}>분석 서비스 신청</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className={"sitemap-item"} style={{"margin-left": "0", "margin-top": "10px"}}>
                                    <p>참여소통</p>
                                    <ul>
                                        <li>
                                            <Link to={"/board/board_14ab0a7f-d410-48d7-91ca-e264d5121794"}
                                                  onClick={() => closeModal()}>공지사항</Link>
                                        </li>
                                        <li>
                                            <Link to={"/board/board_942f2cfa-3294-42c5-ac68-b4f3abbed510"}
                                                  onClick={() => closeModal()}>묻고답하기</Link>
                                        </li>
                                        <li>
                                            <Link to={"/board/board_a2eb004d-73ca-4014-a9d2-c402675cf83f"}
                                                  onClick={() => closeModal()}>자주하는 질문</Link>
                                        </li>
                                        <li>
                                            <Link to={"/board/board_87b9692c-8959-4b3a-83c5-28e209ceca1f"}
                                                  onClick={() => closeModal()}>데이터 문의</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className={"sitemap-item"} style={{"margin-top": "10px"}}>
                                    <p>소개</p>
                                    <ul>
                                        <li>
                                            <Link to={"/whatwedo"} onClick={() => closeModal()}>소개</Link>
                                        </li>
                                        {/* <li>
                                            <Link to={"/allocation"} onClick={() => closeModal()}>데이터 배당</Link>
                                        </li> */}
                                        {/* <li>
                                            <Link to={"/event"} onClick={() => closeModal()}>이벤트</Link>
                                        </li> */}
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Modal>
            }
        <header>
            <div className="head w1280">
                <h1 className="logo">
                    <Link to="/new-main" title="홈으로 이동">경기지역경제포털</Link>
                </h1>
                <aside className="pc">
                    {!isLogin ? 
                    <div className="asideWrap">
                        <Link to="/login" className="log" title="로그인">로그인</Link>
                        <Link to="/join" className="log" title="회원가입">회원가입</Link>
                        <Link to="#void" className="log" title="사이트맵" onClick={(e) => {e.preventDefault(); openModal()}}>사이트맵</Link>
                    </div>
                    :
                    <div className="asideWrap">
                        <Link to="/mypage" className="log" title="나의정보">나의 정보</Link>
                        <Link to="#viod" onClick={() => dispatch(logout(userInfo))} className="log" title="로그아웃">로그아웃</Link>
                        <Link to="#void" className="log" title="사이트맵" onClick={(e) => {e.preventDefault(); openModal();}}>사이트맵</Link>
                    </div>
                    }
                </aside>
            </div>

            <nav className="pc box">
                <div className="navbg pc">
                    <div className="dep1 ani05"></div>
                    <div className="dep2"></div>
                </div>

                <div className="wrap">
                    <div className="info pc">
                        <dl>
                            <dd>미래 지역경제 성장을 위한<br/>빅데이터 융합 거래소</dd>
                        </dl>
                    </div>
                        
                    {/* orgH:1차메뉴 높이 / leftgap:왼쪽에서 여백 px,% 사용가능 */}
                    <div className="gnb box w1280" data-orgh="100" data-gap="120px" data-leftpos="">
                        <ul>
                            <li>
                                <Link to="/new-dataset">데이터셋</Link>
                                <ul>
                                    <li><Link className="menuLi" to="/new-dataset">데이터셋</Link></li>
                                    <li><Link to="/new-datasetMix">융합 데이터</Link></li>
                                    <li><Link to="/integratedDataset">통합 데이터 검색</Link></li>
                                </ul>
                            </li>
                            <li>
                                <Link to="/use" >데이터 활용</Link>
                                <ul>
                                    <li><Link to="/use">활용 갤러리</Link></li>
                                    {competitionFlag ? 
                                    <li><Link to="/competition" onClick={(e) => {e.preventDefault(); competi(e);}}>공모전</Link></li>
                                    :
                                    ""
                                    }
                                </ul>
                            </li>
                            <li>
                                <Link to="/analysisService" >서비스</Link>
                                <ul>
                                    <li><Link to="/analysisService">분석 서비스 신청</Link></li>
                                    <li><Link to="/void#" onClick={(e) => {e.preventDefault(); inno(1);}}>기업입지 추천 서비스</Link></li>
                                    <li><Link to="/void#" onClick={(e) => {e.preventDefault(); inno(3);}}>소상공인 투자환경 분석</Link></li>
                                    <li><Link to="/void#" onClick={(e) => {e.preventDefault(); inno(2);}}>분석경험 공유</Link></li>
                                    <li><Link to="/void#" onClick={(e) => {e.preventDefault(); inno(4);}}>기업 신용 분석</Link></li>
                                    <li><Link to="/void#" onClick={(e) => {e.preventDefault(); inno(5);}}>데이터 시각화</Link></li>
                                    <li><Link to="/StoreService">지역화폐 가맹점</Link></li>
                                </ul>
                            </li>
                            <li>
                                <Link to="/whatwedo" >플랫폼 소개</Link>
                                <ul>
                                    <li><Link to="/whatwedo">소개</Link></li>
                                    {/* <li><Link to="/Centers">센터 소개</Link></li> */}
                                </ul>
                            </li>
                            <li>
                                <Link to="/board/board_14ab0a7f-d410-48d7-91ca-e264d5121794">이용 안내</Link>
                                <ul>
                                    <li><Link to="/board/board_14ab0a7f-d410-48d7-91ca-e264d5121794">공지사항</Link></li>
                                    <li><Link to="/board/board_942f2cfa-3294-42c5-ac68-b4f3abbed510">묻고 답하기</Link></li>
                                    <li><Link to="/board/board_a2eb004d-73ca-4014-a9d2-c402675cf83f">자주하는 질문</Link></li>
                                    <li><Link to="/board/board_87b9692c-8959-4b3a-83c5-28e209ceca1f">데이터 문의</Link></li>
                                    <li><Link to="/event">이벤트</Link></li>
                                </ul>
                            </li>
                        </ul>
                    </div>{/* //gnb */}
                </div>{/* //wrap */}
            </nav>{/* //nav */}

            {/* 모바일 */}
            <nav className="mobile box" data-arrow="left">
                
                    {!isLogin ? 
                        <div className="closeWrap">
                            <Link to="#close" className="gnbClose" title="닫기" onClick={e => {e.preventDefault()}}>닫기</Link>
                            <Link to="/new-main" className="btn menuLi">HOME</Link>
                            <Link to="/join" className="btn menuLi">회원가입</Link>
                            <Link to="/login" className="btn menuLi">로그인</Link>
                        </div>  
                        :
                        <>              
                        <div className="closeWrap">
                            <Link to="#close" className="gnbClose" title="닫기" onClick={e => {e.preventDefault()}}>닫기</Link>
                            <Link to="/new-main" className="btn menuLi">HOME</Link>                        
                            <Link to="/mypage" className="btn menuLi">나의 정보</Link>
                            <Link to="#viod" className="btn menuLi" onClick={() => dispatch(logout(userInfo))} >로그아웃</Link>            
                        </div>
                        </>    
                    }
                
                <div className="gnb box w1280" data-orgh="100" data-gap="120px" data-leftpos="">
                    <ul>
                        <li>
                            <Link to="/new-dataset">데이터셋</Link>
                            <ul>
                                <li><Link className="menuLi" to="/new-dataset">데이터셋</Link></li>
                                <li><Link className="menuLi" to="/new-datasetMix">융합 데이터</Link></li>
                                <li><Link className="menuLi" to="/integratedDataset">통합 데이터 검색</Link></li>
                            </ul>
                        </li>
                        <li>
                            <Link to="/use">데이터 활용</Link>
                            <ul>
                                <li><Link className="menuLi" to="/use">활용 갤러리</Link></li>
                            </ul>
                        </li>
                        <li>
                            <Link to="/analysisService">서비스</Link>
                            <ul>
                                <li><Link className="menuLi" to="/analysisService">분석 서비스 신청</Link></li>
                                <li><a href="http://loc.bigdata-region.kr/location" target="_blank">기업입지 추천 서비스</a></li>
                                <li><Link className="menuLi" to="" onClick={(e) => {e.preventDefault(); inno(3)}}>소상공인 투자환경 분석</Link></li>
                                <li><Link className="menuLi" to="" onClick={(e) => {e.preventDefault(); inno(2)}}>분석경험 공유</Link></li>
                                <li><a href="https://www.kreport.co.kr/" target="_blank">기업 신용 분석</a></li>
                                <li><a href="http://visualization.bigdata-region.kr:80" target="_blank">데이터 시각화</a></li>
                            </ul>
                        </li>
                        <li>
                            <Link to="/whatwedo">플랫폼 소개</Link>
                            <ul>
                                <li><Link className="menuLi" to="/whatwedo">소개</Link></li>
                                {/* <li><Link to="/allocation">데이터 배당</Link></li> */}
                            </ul>
                        </li>
                        <li>
                            <Link to="board/board_14ab0a7f-d410-48d7-91ca-e264d5121794">이용 안내</Link>
                            <ul>
                                <li><Link className="menuLi" to="/board/board_14ab0a7f-d410-48d7-91ca-e264d5121794">공지사항</Link></li>
                                <li><Link className="menuLi" to="/board/board_942f2cfa-3294-42c5-ac68-b4f3abbed510">묻고 답하기</Link></li>
                                <li><Link className="menuLi" to="/board/board_a2eb004d-73ca-4014-a9d2-c402675cf83f">자주하는 질문</Link></li>
                                <li><Link className="menuLi" to="/board/board_87b9692c-8959-4b3a-83c5-28e209ceca1f">데이터 문의</Link></li>
                                <li><Link className="menuLi" to="/event">이벤트</Link></li>
                            </ul>
                        </li>
                    </ul>
                </div>{/* //gnb */}
            </nav>
            <div className="gnbCover mobile"></div>

            
            <aside className="mobile">
                <Link 
                    className="login" 
                    to={!isLogin? "/login" : "/mypage" }
                    title="로그인"
                >
                    로그인
                </Link>
                {/* <a className="logout" href="#void" title="회원가입">회원가입</a> */}
                <Link className="gnbView" to="#void" title="전체메뉴보기" onClick={(e) => e.preventDefault()}>메뉴</Link>
            </aside>
        </header>

        <Link to="#void" style={{ display: 'block', height: '0', overflow: 'hidden' }}>탭스톱</Link>

    </>);
}

