export const SET_META_INFO = 'SET_META_INFO';
export const SET_DATA_TITLE = 'SET_DATA_TITLE';
export const SET_CHANGE_MAIN = 'SET_CHANGE_MAIN';

export function setMetaInfo(metaInfo) {
    return {
        type: SET_META_INFO,
        metaInfo: metaInfo
    };
}

export function setDataTitle(dataTitle) {
    return {
        type: SET_DATA_TITLE,
        dataTitle: dataTitle
    };
}

export function setChangeMain(changeMain) {
    return {
        type: SET_CHANGE_MAIN,
        changeMain: changeMain
    };
}