import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import Button from "./Button";
import CheckBox from "./CheckBox";
import swal from "sweetalert";
import * as UserApi from "../api/User";
import {DaumPostcode} from "react-daum-postcode";
import SelectBox from "./SelectBox";
import * as DataApi from "../api/Data";



const UserUpdate = ({userInfo, deleteBtnName, modifyBtnName, ...props}) => {
    const [id, setId] = useState(userInfo.id);
    const [email, setEmail] = useState(userInfo.email);
    const [fullname, setFullName] = useState(userInfo.fullname);
    const [phone, setPhone] = useState(userInfo.phone)
    const [address, setAddress] = useState(userInfo.address)
    const [apikey, setApiKey] = useState(userInfo.apikey);
    const [isSamePassword, setIsSamePassword] = useState(true);
    let emailAgreeByUserInfo = userInfo.emailAgree;
    if(emailAgreeByUserInfo === undefined) emailAgreeByUserInfo = false;
    const [emailAgree, setEmailAgree] = useState(emailAgreeByUserInfo);
    const [detailAddress, setDetailAddress] = useState(userInfo.detail_address);
    const [postCode, setPostCode] = useState(userInfo.postcode);
    const [userType, setUserType] = useState(userInfo.user_type);
    const [userDesc, setUserDesc] = useState(userInfo.user_desc);
    const [onlyCompanySortation, setOnlyCompanySortation] = useState(userInfo.only_company_sortation);
    const [onlyCompanyType, setOnlyCompanyType] = useState(userInfo.only_company_type);
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [passwordChangePopup, setPasswordChangePopup] = useState(false);
    const [linkAppServicePopup, setLinkAppServicePopup] = useState(false);
    const [nowPassword, setNowPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [appServiceId, setAppServiceId] = useState("");
    const [appServicePassword, setAppServicePassword] = useState("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

    const [isCheckUserType, setIsCheckUserType] = useState(false);
    const [isCheckUserDesc, setIsCheckUserDesc] = useState(false);
    const [isCheckOnlyCompanySortation, setIsCheckOnlyCompanySortation] = useState(false);
    const [isCheckOnlyCompanyType, setIsCheckOnlyCompanyType] = useState(false);

    const [isCheckPhoneAuth, setIsCheckPhoneAuth] = useState(false);

    const [isShowPostCode, setIsShowPostCode] = useState(false);
    const CategoryUserType = useSelector(state => {
        return state.user.categorySortation
    });

    const companyCategory = [
        {
            display: '대기업',
            name: '대기업'
        },
        {
            display: '중견기업',
            name: '중견기업',
        },
        {
            display: '중소기업',
            name: '중소기업'
        },
        {
            display: '소기업',
            name: '소기업'
        },
        {
            display: '소상공인',
            name: '소상공인'
        },
        {
            display: '스타트업',
            name: '스타트업'
        }
    ];
    const [openapiFlag, setOpenApiFlag] = useState(false);
    const [pointFlag, setPointFlag] = useState(false);
    useEffect( () => {
        DataApi.readIkanConfig()
            .then(res => {
                setPointFlag(res.result.config_list.filter(config => {return config.function_type === "point"})[0].function_active)
                setOpenApiFlag(res.result.config_list.filter(config => {return config.function_type === "openapi"})[0].function_active)
            })
    }, []);

    const CheckUserCategory = (value) => {
        let find_key_by_value = Object.keys(CategoryUserType).find(key => CategoryUserType[key] === value);

        if(find_key_by_value === undefined) {
            return value
        }
        else {
            return find_key_by_value
        }
    };

    const userTypeOption = () => {
        let rst = [];

        for (let [name, display] of Object.entries(CategoryUserType)) {
            rst.push({
                display: display,
                name: name
            })
        }
        return rst;
    };

    const changePassword = (target, value) => {
        if(target === "password"){
            setPassword(value);

            if(passwordConfirm === value)
                setIsSamePassword(true);
            else setIsSamePassword(false);

        }else if(target === "passwordConfirm"){
            setPasswordConfirm(value);

            if(password === value)
                setIsSamePassword(true);
            else setIsSamePassword(false);
        }
    };

    const isSetPhoneNumber = (str) => {
        str = str.replace(/[^0-9]/g, '');
        var tmp = '';
        if(str.length < 4){
            setPhone(str)
        }else if(str.length < 7){
            tmp += str.substr(0, 3);
            tmp += '-';
            tmp += str.substr(3);
            setPhone(tmp)
        }else if(str.length < 11){
            tmp += str.substr(0, 3);
            tmp += '-';
            tmp += str.substr(3, 3);
            tmp += '-';
            tmp += str.substr(6);
            setPhone(tmp)
        }else{
            tmp += str.substr(0, 3);
            tmp += '-';
            tmp += str.substr(3, 4);
            tmp += '-';
            tmp += str.substr(7);
            setPhone(tmp)
        }
    };

    const setUserTypeOption = (name) => {
        setUserType(name);
        if (CategoryUserType[CheckUserCategory(name)] !== "기업") {
            setOnlyCompanySortation(null);
            setOnlyCompanyType(null);

            if (CategoryUserType[CheckUserCategory(name)] === "개인") {
                setUserDesc(null);
            }
        }
    };

    const openPhoneIdentification = () => {
        var params = {
            "mid": "kobigdaias",
            "mTxId": "ggbigdataauth_221011",
            "reqSvcCd": "01",
            "reservedMsg": "isUseToken=Y",
            "authHash": "3aa16af25a39aa5a5aa004b26f3fe7e0c40e19314209a04d2c081fc5fa2f3f48",
            "flgFixedUser": "N",
            "successUrl": process.env.REACT_APP_PHONE_AUTH_URL_SUCCESS,
            "failUrl": process.env.REACT_APP_PHONE_AUTH_URL_SUCCESS          
        };

        var form = document.createElement("form");
        form.method = "POST";
        form.target = "sa_popup";
        form.action = "https://sa.inicis.com/auth";

        for(let key in params){
            let hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", key);
            hiddenField.setAttribute("value", params[key]);
            form.appendChild(hiddenField);
        }

        document.body.appendChild(form);

		let _width = 400;
		let _height = 620;
		var xPos = (document.body.offsetWidth/2) - (_width/2); // 가운데 정렬
		xPos += window.screenLeft; // 듀얼 모니터일 때

		window.open("", "sa_popup", "width="+_width+", height="+_height+", left="+xPos+", menubar=yes, status=yes, titlebar=yes, resizable=yes");
        form.submit();
        form.parentNode.removeChild(form);

        window.onmessage = function(e){
            let confirm_type = e.data.type;
            if(confirm_type === "success") {
                swal("본인인증이 완료되었습니다.");
                setIsCheckPhoneAuth(true);
            }else{
                swal("본인인증에 실패하였습니다.");
            }
        };
        return;
    }

    const updateValue = {
        id: id,
        // apikey: apikey,
        email: email,
        emailAgree: emailAgree,
        fullname: fullname,
        address: address,
        phone: phone,
        detail_address: detailAddress,
        postcode: postCode,
        user_id_key: userInfo.id_key,
        center_code: userInfo.center_code,
        user_type: CheckUserCategory(userType),
        user_desc: userDesc,
        only_company_sortation: onlyCompanySortation,
        only_company_type: onlyCompanyType
    };

    const checkNull = (value) => {
        if (value === null || value === undefined || value === "" || value === " ") {
            return true;
        } else {
            return false;
        }
    };

    const updateUser = (updateValue) => {
        let user_type = CategoryUserType[CheckUserCategory(updateValue['user_type'])]
        if(!isCheckPhoneAuth) {
            swal('회원정보 변경 시 전화번호 인증을 해주세요.');
            return ;
        }
        if (user_type === "개인") {
            props.isUpdateUser(updateValue)
            return ;
        } else if (user_type === "기업") {
            if(checkNull(updateValue['user_desc']) || checkNull(updateValue['only_company_sortation']) || checkNull(updateValue['only_company_type'])) {
                swal('기업정보를 모두 입력해주세요.');
                return ;
            }
        } else {
            if (checkNull(updateValue['user_desc'])) {
                swal('구분상세를 입력해주세요.');
                return ;
            }
        }
        props.isUpdateUser(updateValue)
    };

    const handleChangePassword = () => {
        setPasswordChangePopup(false);
        if (newPassword?.length < 8 || !(newPassword.search(/[A-Z]/) >= 0 && newPassword.search(/[a-z]/) >= 0 && newPassword.search(/\d/) >= 0 && newPassword.search(/[#?!@$%^&*-]/) >= 0)) {
            swal("비밀번호는 8자 이상이어야 하며, 숫자/대문자/소문자/특수문자를 모두 포함해야 합니다.")
            setNowPassword("");
            setNewPassword("");
            setNewPasswordConfirm("");
            return;
        }        
        const onlyPasswordParams = {
            id: userInfo.id,
            email: userInfo.email,
            password: newPassword,
            old_password: nowPassword,
            name: userInfo.fullname
        };
        UserApi.updatePassword(onlyPasswordParams)
            .then(res => {
                console.log(res);
                swal("비밀번호 변경이 완료되었습니다.")
            })
            .catch(res => {
                console.log(res);
                let json_res = JSON.parse(res.response);
                if (json_res.result === 101) {
                    swal('비밀번호는 8자 이상이어야 합니다.')
                }
                else if (json_res.result === "incorrect old pw") {
                    swal('현재 비밀번호가 정확하지 않습니다.\n 다시 한번 확인해주세요.')
                }
            });
        setNowPassword("");
        setNewPassword("");
        setNewPasswordConfirm("");
    };

    const handleLinkAppService = (link_type) => {
        setLinkAppServicePopup(false);

        const params = {
            link_target_user_id: appServiceId,
            link_target_user_pw: appServicePassword,
            portal_user_id: userInfo.id,
            portal_user_id_key: userInfo.id_key,
            link_target_type: 'ireward',
        };

        UserApi.linkAppService(params)
            .then(res => {
                console.log(res);
                swal("앱 서비스와 연동이 완료 되었습니다.")
            })
            .catch(res => {
                let json_res = JSON.parse(res.response);
                console.log(json_res)
                if (json_res.result === "already account") {
                    swal('입력하신 계정은 이미 연동된 계정 입니다.')
                }
                else if (json_res.result === "FAIL_INVALID_PASSWORD") {
                    swal('연동 계정 비밀번호가 정확하지 않습니다.\n 다시 한번 확인해주세요.')
                }
                else if (json_res.result === "FAIL_USER_NOT_FOUND") {
                    swal('연동 계정이 정확하지 않습니다.\n 다시 한번 확인해주세요.')
                }
            });
        setAppServiceId("");
        setAppServicePassword("");
        props.isSetUserRedux()
    };

    const handleFindPostCode = () => {
        setIsShowPostCode(true);
    };

    const handleAddress = (data) => {
        console.log(data);
        setAddress(data.address);
        setPostCode(data.postcode === "" ? data.zonecode : data.postcode);
        setIsShowPostCode(false);
    };

    return (
        <>
            {isShowPostCode && <div className={"ds-2-daum-post-code-wrap usr-update"}>
                    <DaumPostcode
                        onComplete={data => handleAddress(data)}
                    />
                    <button className={"ds-2-daum-post-code-close-btn usr-update"}
                            onClick={() => setIsShowPostCode(false)}
                    >
                        닫기
                    </button>
            </div>}
            {passwordChangePopup && <div className={"ds-change-password-wrap"}>
                <div className={"ds-change-password-inner"}>
                    <h3 className={"ds-change-password-title"}>비밀번호 변경</h3>
                    <div>
                        <div className={"ds-change-password-input"}>
                            <input type="password" value={nowPassword}
                                   onChange={e => setNowPassword(e.target.value)} placeholder={"현재 비밀번호"} title={"현재 비밀번호"}/>
                        </div>
                        <div className={"ds-change-password-input"}>
                            <input type="password" value={newPassword}
                                   onChange={e => setNewPassword(e.target.value)} placeholder={"새로운 비밀번호"} title={"새로운 비밀번호"}/>
                        </div>
                        <div className={"ds-change-password-input"}>
                            <input type="password" value={newPasswordConfirm}
                                   onChange={e => setNewPasswordConfirm(e.target.value)} placeholder={"새로운 비밀번호 확인"} title={"새로운 비밀번호 확인"}/>
                        </div>
                    </div>
                    <div>
                        <button onClick={() => setPasswordChangePopup(false)}
                                className={"ds-change-password-btn-cancel"}>
                            취소
                        </button>
                        <button onClick={() => handleChangePassword()} disabled={!(newPasswordConfirm && newPassword && nowPassword)}
                                className={"ds-change-password-btn-ok"}>
                            확인
                        </button>
                    </div>
                </div>
            </div>}
            {linkAppServicePopup && <div className={"ds-change-password-wrap"}>
                <div className={"ds-change-password-inner"}>
                    <h3 className={"ds-change-password-title"}>앱서비스 연동</h3>
                    <div>
                        <div className={"ds-change-password-input"}>
                            <input type="text" value={appServiceId}
                                   onChange={e => setAppServiceId(e.target.value)} placeholder={"앱 아이디"} title={"앱 아이디"}/>
                        </div>
                        <div className={"ds-change-password-input"}>
                            <input type="password" value={appServicePassword}
                                   onChange={e => setAppServicePassword(e.target.value)} placeholder={"앱 비밀번호"} title={"앱 비밀번호"}/>
                        </div>
                    </div>
                    <div>
                        <button onClick={() => setLinkAppServicePopup(false)}
                                className={"ds-change-password-btn-cancel"}>
                            취소
                        </button>
                        <button onClick={() => handleLinkAppService()} disabled={!(appServiceId && appServicePassword)}
                                className={"ds-change-password-btn-ok"}>
                            확인
                        </button>
                    </div>
                </div>
            </div>}
            <div className={"user-form"}>
                <ul>
                    <li>
                        <strong>아이디</strong>
                        <span>{userInfo.id}</span>
                    </li>
                    <li>
                        <strong>이름</strong>
                        <span>{fullname}</span>
                    </li>
                    {openapiFlag &&
                        <li>
                            <strong>API 인증 키</strong>
                            {userInfo.open_api_key === null ?
                                <span>
                                        없음
                                    </span>
                                :
                                <span>
                                        {userInfo.open_api_key}
                                    </span>
                            }
                            <span>
                                ※ OpenAPI 서비스 활용을 위한 인증 키 입니다.
                            </span>
                        </li>
                    }
                    {pointFlag &&
                        <>
                            <li>
                                <strong>포인트</strong>
                                <span>{userInfo.point}</span>
                            </li>
                            <li>
                            <strong>연동 정보</strong>
                            {userInfo.appServiceId === null ?
                                <span>
                                        연동정보 없음
                                        <button style={{"margin-left":"10px"}} onClick={() => setLinkAppServicePopup(true)}>앱서비스 연동</button>
                                    </span>
                                :
                                <span>
                                        {userInfo.appServiceId}
                                    </span>
                            }

                            {/*{userInfo.appServiceId === null?*/}
                            {/*    <span>*/}
                            {/*        연동정보 없음*/}
                            {/*        <button style={{"margin-left":"10px"}} onClick={() => setLinkAppServicePopup(true)}>앱서비스 연동</button>*/}
                            {/*    </span>*/}
                            {/*:*/}
                            {/*    <span>*/}
                            {/*        {userInfo.appServiceId}*/}
                            {/*        <button style={{"margin-left":"10px"}} onClick={() => setLinkAppServicePopup(true)}>연동정보 변경</button>*/}
                            {/*    </span>*/}
                            {/*}*/}


                            <span>
                            ※ '경기 지역화폐 가맹점 검색' 앱과 연동 하시면 통합 포인트 사용이 가능 합니다.
                            </span>
                            </li>
                        </>
                    }

                    <li>
                        <strong>회원 구분</strong>
                        <span>
                            <div className={"AnalysisService-select-wrap"} style={{width: '300px'}}>
                                <SelectBox options={userTypeOption()}
                                           onChange={option => {
                                               setUserTypeOption(option.name);
                                           }}
                                           value={userType}
                                           placeholder={userType}
                                />
                            </div>

                            {/*<select className={"userType-select-wrap"} onChange={e => setUserType(e.target.value)} value={CheckUserCategory(userType)}>*/}
                            {/*    {userTypeOption().map((item, i) =>*/}
                            {/*        <option value={item.name} key={i}>{item.display}</option>*/}
                            {/*    )}*/}
                            {/*</select>*/}
                        </span>
                        <span className={"user-gbn"}>
                            ※ '회원 구분'이 개편되었으므로 수정해주시길 바랍니다.
                        </span>
                        {/*<span>{userInfo.user_type}</span>*/}
                    </li>
                    {
                        CategoryUserType[CheckUserCategory(userType)] === "개인" ?
                            null :
                            CategoryUserType[CheckUserCategory(userType)] === "기업" ?
                                <>
                                    <li>
                                        <strong>기업 구분</strong>
                                        <span>
                                        <div className={"AnalysisService-select-wrap"} style={{width: '300px'}}>
                                            <SelectBox options={companyCategory}
                                                       onChange={option => {
                                                           setOnlyCompanySortation(option.name);
                                                       }}
                                                       value={onlyCompanySortation}
                                                       placeholder={"기업구분"}
                                            />
                                        </div>
                                        </span>
                                    </li>
                                    <li>
                                        <strong>기업 분야</strong>
                                        <span>
                                        <input type={"text"} value={onlyCompanyType} onChange={e => setOnlyCompanyType(e.target.value)} title={"기업 분야"}/>
                                    </span>
                                    </li>
                                    <li>
                                        <strong>기업명</strong>
                                        <span>
                                        <input type={"text"} value={userDesc} onChange={e => setUserDesc(e.target.value)} title={"기업명"}/>
                                    </span>
                                    </li>
                                </>
                                :
                            <>
                                <li>
                                    <strong>구분 상세</strong>
                                    <span>
                                        <input type={"text"} value={userDesc} placeholder={"기관명을 입력해주세요."} onChange={e => setUserDesc(e.target.value)} title={"구분 상세"}/>
                                    </span>
                                </li>
                            </>
                    }

                    <li>
                        <strong>비밀번호</strong>
                        <span>
                        {/*<input type={"password"} placeholder={"PASSWORD"}*/}
                            {/*       value={password} onChange={e => changePassword("password", e.target.value)}/>*/}
                            <Button onClick={() => setPasswordChangePopup(true)}>비밀번호 변경</Button>
                    </span>

                    </li>
                    <li>
                        <strong>이메일</strong>
                        <span>
                        <span>{userInfo.email}</span>
                            {/*<Button>이메일 인증</Button>*/}
                            <div className={"checkbox-block"}>
                            <CheckBox label={"이메일 수신 동의 (수신 거부 시 일부 서비스에 제한이 있을 수 있습니다)"}
                                      id={"email"} checked={emailAgree} onChange={(bool) => setEmailAgree(bool)}/>
                        </div>
                    </span>

                    </li>
                    <li>
                        <strong>전화번호</strong>
                        <span>
                            <input type={"text"} value={phone} onChange={e => isSetPhoneNumber(e.target.value)} title={"전화번호"}/>
                                    {/*<div className={"checkbox-block"}>*/}
                                    {/*    <CheckBox label={"SMS 수신 동의 (수신 거부 시 일부 서비스에 제한이 있을 수 있습니다)"} id={"phone"}/>*/}
                                    {/*</div>*/}
                            <button className={"button auth-check-button"} onClick={() => openPhoneIdentification()}> 인증 </button>
                        </span>

                    </li>

                    <li>
                        <strong>우편번호</strong>
                        <span>
                            <button className={"ds-2-find-address usr-update"} onClick={() => handleFindPostCode()}>
                                주소찾기
                            </button>
                            <input type={"text"} value={postCode}
                                   className={"ds-2-find-address-input usr-update"}
                                   style={{marginTop: '-1px'}}
                                   disabled={true}
                                   title={"우편번호"}
                            />
                        </span>
                    </li>

                    <li>
                        <strong> 주소 </strong>
                        <span>
                            <input type={"text"} value={address}
                                   style={{marginTop: '-1px'}}
                                   disabled={true}
                                   title={"주소"}
                            />
                        </span>
                    </li>

                    <li>
                        <strong>상세주소</strong>
                        <span>
                            <input type={"text"} placeholder={"상세주소"} title={"상세주소"}
                                   onChange={e => setDetailAddress(e.target.value)}
                                   value={detailAddress}
                            />
                        </span>
                    </li>
                </ul>
                <div className={"btn-group"}>
                    <Button onClick={() => updateUser(updateValue)}>{modifyBtnName}</Button>
                </div>
                <div className={"btn-group btn-secession"}>
                    <Button onClick={() => props.isDeleteUser(userInfo.id_key)}>{deleteBtnName}</Button>
                </div>
            </div>
        </>
    );
};
UserUpdate.propTypes = {
    isUpdateUser: () => {

    },
    isDeleteUser: () => {

    },
    isChangePassword: () => {

    },
    isSetUserRedux: () => {

    }
};
export default UserUpdate;
