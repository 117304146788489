import React from 'react';
import kakao from '../../assets/images/kakao.png';
import facebook from '../../assets/images/facebook.png';
import feed_thumb from '../../assets/images/img_allocation_1@2x.png'
import CopyToClipBoard from '../CopyToClipBoard';

class ShareSNS extends React.Component {
    constructor(props) {
        let dataInfo;
        dataInfo = props.dataInfo
        super(props);
        this.state = {
            dataInfo: dataInfo,
        }
        this.shareFacebook = () => {
            window.FB.ui({
                method: 'feed',
                link: 'https://bigdata-region.kr/#/dataset/' + this.state.dataInfo.id,
                caption: this.state.dataInfo.title,
                name: this.state.dataInfo.title,
                description: this.state.dataInfo.title,
            }, function(response){})
        }
    }

    componentDidMount() {
        window.Kakao.Link.createDefaultButton({
            container: '#kakao-share',
            objectType: 'feed',
            content: {
                title: this.state.dataInfo.title,
                description: this.state.dataInfo.notes,
                imageUrl: feed_thumb,
                link: {
                    webUrl: 'https://bigdata-region.kr/#/dataset/' + this.state.dataInfo.id,
                },
            },
            // social: {
            //     likeCount: 10,
            //     commentCount: 20,
            //     sharedCount: 30,
            // },
            buttons: [
                {
                    title: '경기지역경제포털 이동',
                    link: {
                        webUrl: 'https://bigdata-region.kr/#/dataset/' + this.state.dataInfo.id,
                    },
                }
            ],
            success: function(response) {
                console.log(response);
            },
            fail: function(error) {
                console.log(error);
            }
        });
    }

    render() {
        return (
            <div>
                <div style={{"padding":"10px"}} className={"share-pop"}>
                    <div style={{"width":"auto", "text-align":"center"}} className={"share-pop-inner"}>
                        <dl id={"kakao-share"} style={{"cursor":"pointer"}}>
                            <dt>
                                <img id={"kakao-share"}src={kakao} width={"80px"} height={"80px"}/>
                            </dt>
                            <dd>
                                <p style={{"font-size":"15px"}}>카카오</p>
                            </dd>
                        </dl>
                        <dl style={{"cursor":"pointer"}} onClick={ () => this.shareFacebook()}>
                            <dt>
                                <img src={facebook} width={"80px"} height={"80px"}/>
                            </dt>
                            <dd>
                                <p style={{"font-size":"15px"}}>페이스북</p>
                            </dd>
                        </dl>
                    </div>
                    <CopyToClipBoard dataUrl={'https://bigdata-region.kr/#/dataset/' + this.state.dataInfo.id}/>
                </div>

            </div>

        );
    }
}
ShareSNS.defaultProps = {
    appKey: '3c6f07905afb46c5308d0139d9fb0d2a'
}
export default ShareSNS;
