import React, {useState, useEffect} from 'react';
import * as UserAPI from "../../api/User";
import ErrorPage404 from "../common/ErrorPage404";
import {yyyymmdd} from "../../util/Date"
import {useSelector} from "react-redux";
import swal from "sweetalert";
import DatasetComment from "../../components/DatasetComment";
import CommonRatingScore from "../../components/CommonRatingScore";
import SubNavNew from "../../components/SubNavNew";
const UseDetail = (props) => {

    let [data, setData] = useState(null);
    let [show404, setShow404] = useState(false);

    const userInfo = useSelector(state => state.user.info);
    const convertKor = {
        "app": "모바일 앱",
        "web": "웹 서비스",
        "idea": "아이디어",
        "week": "일주일 이상",
        "month": "한달 이상",
        "year": "일년 이상",
        "None": "-"
    };

    useEffect(() => {
        let params = {
            id: props.match.params.id
        };
        UserAPI.readUseGalleryDetail(params).then(res => {
            setData(res.result);
        }).catch(res => {
            setShow404(true);
        })
    }, []);
    let subNav = [
        {
            display: "활용 갤러리",
            name: "/use"
        }
        // {
        //     name: "기업입지 추천 서비스",
        //     onClick: () => {
        //         let service_link = "http://loc.bigdata-region.kr/location";
        //
        //         window.open(service_link);
        //     }
        // }
    ];
    const isDeleteUseGallery = (object_id) => {
        swal({
            text: "정말 삭제하시겠습니까?",
            buttons: [
                "취소",
                "확인",
            ],
        }).then(function (isConfirm) {
            if(isConfirm) {
                let params = {
                    id: object_id,
                };
                UserAPI.deleteUseGallery(params).then(res => {
                    swal('게시물 삭제가 완료되었습니다.');
                    window.location.href = "/#/use";
                });
            }
            else {
                swal.close();
            }
        })
    };

    if(show404)
        return <ErrorPage404/>;

    else
        return (
            <>
                <div className="renewal">
                <SubNavNew nav={subNav} history={props.history} />
                </div>
                {data !== null &&
                <div className={"use-wrap"}>
                    <div className={"use-service"}>
                        <h3>활용서비스</h3>
                        <div className={"use-service-inner"}>
                            <img src={data.thumbnail} alt={data.title}/>
                            <p style={{"width": "calc(100% - 260px)", "overflow": "hidden", "height": "auto"}} className="useDetail-p">
                                <dl>
                                    <dt>
                                        {data.title}
                                    </dt>
                                    <dd>
                                        <ul>
                                            <li className="userDetail-li">
                                                {/*1번째 라인*/}
                                                <div className={"use-gallery-detail-head-block"}>
                                                    서비스 유형
                                                </div>
                                                <div className={"use-gallery-detail-body-block"}>
                                                    {convertKor[data.type]}
                                                </div>
                                                <div className={"use-gallery-detail-head-block"}>
                                                    서비스 개발 기간
                                                </div>
                                                <div className={"use-gallery-detail-body-block"}>
                                                    {convertKor[data.dev_date]}
                                                </div>
                                                <div className={"use-gallery-detail-head-block"}>
                                                    서비스 링크
                                                </div>
                                                <div className={"use-gallery-detail-body-block"}>

                                                    {
                                                        data.link === "None" ?
                                                            "-"
                                                            :
                                                            <a href={data.link} target={"_blank"}>바로가기</a>
                                                    }
                                                </div>
                                                {/*/!*2번쨰 줄*!/*/}
                                                <div className={"use-gallery-detail-head-block"}>
                                                    서비스 제작자
                                                </div>
                                                <div className={"use-gallery-detail-body-block"}>
                                                    {data.creator}
                                                </div>
                                                <div className={"use-gallery-detail-head-block"}>
                                                    게시물 등록일
                                                </div>
                                                <div className={"use-gallery-detail-body-block"}>
                                                    {yyyymmdd(new Date(data.date.replace(" ","T")))}
                                                </div>
                                                <div className={"use-gallery-detail-head-block"}>
                                                    사용한 데이터
                                                </div>
                                                <div className={"use-gallery-detail-body-block"}>
                                                    {
                                                        data.link === "None" ?
                                                            "-"
                                                            :
                                                            <a onClick={() => props.history.push("/dataset/" + data.dataset_id)} style={{"cursor": "pointer"}}>바로가기</a>
                                                    }
                                                </div>
                                            </li>
                                        </ul>
                                    </dd>
                                </dl>
                            </p>
                        </div>
                    </div>
                    <div className={"use-way"}>
                        <h3>활용 서비스 소개</h3>
                        <div className={"use-way-text"} dangerouslySetInnerHTML={{__html: data.content}}/>
                    </div>
                    <div className={"use-comment"} style={{"margin-top": "50px"}}>
                        <h4>평점</h4>
                        <CommonRatingScore post_title={data.title} post_id={data.id} post_theme={'use_gallery'}/>
                    </div>
                    <div className={"use-comment"} style={{"margin-top": "50px"}}>
                        <h4>댓글</h4>
                        <DatasetComment postId={data.id} postTheme={"use_gallery"} postTitle={data.title} postPlaceholder={"게시물에 대한 댓글을 남겨주세요."}/>
                    </div>
                    <div className={"btn-group"}>
                        <div className={"ikan-button-wrap ikan-button-line"}>
                            <button onClick={() => props.history.push("/use")}>목록</button>
                        </div>
                        {
                            (userInfo.id === data.user_id) || userInfo.role === "superadmin" ?
                                <div className={"ikan-button-wrap ikan-button-line"} style={{"margin-left": "10px"}}>
                                    <button onClick={() => isDeleteUseGallery(data.id)}>삭제</button>
                                </div>
                                : null
                        }
                        {
                            (userInfo.id === data.user_id) || userInfo.role === "superadmin" ?
                                <div className={"ikan-button-wrap ikan-button-line"} style={{"margin-left": "10px"}}>
                                    <button onClick={() => props.history.push("/use/update/" + data.id)}>수정</button>
                                </div>
                                : null

                        }
                    </div>
                </div>
                }
            </>
        )
};

export default UseDetail;
