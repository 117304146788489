import React, {useState, useEffect, useRef} from 'react';
import SubNavNew from "../../components/SubNavNew";
import * as UserAPI from "../../api/User";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Pagination from "../../components/Pagination";
import SelectSearch from "react-select-search";
import {getParamsFromQueryString, serialize} from "../../util/Url";
import TourMessage from "../common/tourMessage";
import Tour from "reactour";
import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";
import {setTourBtn} from "../../store/actions/UserAction";

const Use = (props) => {
    const isLogin = useSelector(state => state.user.isLogin);
    const dispatch = useDispatch()

    // 투어
    const useGalleryTour = new TourMessage.UserGalleryPageTourMessage()
    const isTourOpen = useSelector(state => state.user.isTourOpen)
    let disableBody = target => disableBodyScroll(target)
    let enableBody = target => enableBodyScroll(target)

    const closeTour = () => {
        dispatch(setTourBtn(false))
    }
    //
    const subNav = [
        {
            display: "활용 갤러리",
            name: "/use"
        }
    ];

    const convertKor = {
        "app": "모바일 앱",
        "web": "웹 서비스",
        "idea": "아이디어",
        "week": "일주일 이상",
        "month": "한달 이상",
        "year": "일년 이상"
    };

    const useGallerySearchType = [
        {
            name: "제목",
            value: "subject"
        },
        {
            name: "제목+소개",
            value: "subject_and_text"
        }

    ];

    const [useGalleryList, setUseGalleryList] = useState([]);

    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [limit, setLimit] = useState(6);
    const [totCnt, setTotCnt] = useState(0);

    const [searchWord, setSearchWord] = useState("");
    const [searchType, setSearchType] = useState("subject")
    const [isLoaded, setIsLoaded] = useState(true);

    let qs = getParamsFromQueryString(props.history.location.search);
    let p_search_value = qs.hasOwnProperty("s_value") ? qs.s_value : "";
    let p_search_type = qs.hasOwnProperty("s_type") ? qs.s_type : "subject";

    const selectRef = useRef(null);
    useEffect( () => {
        if (selectRef !== null) {
            document.getElementsByClassName("board-search-select-box__input")[0].setAttribute("title", "검색종류")
        }
    }, [])

    useEffect( () => {
        setSearchWord(p_search_value)
        setSearchType(p_search_type)

        const params = {
            'page_num': page-1,
            'page_limit': limit,
            'value': p_search_value,
            'filter': p_search_type
        };

        UserAPI.readUseGalleryList(params).then(res => {
            setTotCnt(res.result.count);
            setUseGalleryList(res.result.list);
            setMaxPage(Math.ceil(res.result.count / limit));
        })
    }, [props.history.location.search]);

    useEffect(() => {
        setSearchWord(p_search_value)
        setSearchType(p_search_type)

        let params = {
            'page_num': page-1,
            'page_limit': limit,
            'value': p_search_value,
            'filter': p_search_type
        };

        UserAPI.readUseGalleryList(params).then(res => {
            setTotCnt(res.result.count);
            setUseGalleryList(res.result.list);
            setMaxPage(Math.ceil(res.result.count / limit));
        })
    }, [page]);


    const isSearchUserGallery = (searchWord) => {
        const set_params = {
            "s_type": searchType,
            "s_value": searchWord
        }

        if(isLoaded){
            props.history.push({
                pathname: window.location.search,
                search: serialize(set_params)
            });
        }
        else {
            props.history.replace({
                pathname: window.location.search,
                search: serialize(set_params)
            });
            setIsLoaded(false);
        }
        //
        // let params = {
        //     'page_num': page-1,
        //     'page_limit': limit,
        //     'value': p_search_value,
        //     'filter': p_search_type
        // };
        //
        // UserAPI.readUseGalleryList(params).then(res => {
        //     setTotCnt(res.result.count);
        //     setUseGalleryList(res.result.list);
        //     setMaxPage(Math.ceil(res.result.count / limit));
        //     console.log(res.result)
        // })
    }

    const pStyle = {
        fontSize : "18px",
        marginBottom : "5px",
    }

    const inputStyle = {
        width: "400px", 
        height: "46px",
        border : "1px solid rgb(204,204,204)",
        borderRadius: "4px",
        fontSize: "15px",
        marginBottom: "0px !important",
    }

    const searchWrapStyle = {
        display : "inline-block",
        width : "35%", 
        marginLeft : "10px",
        marginBottom : "0px",
    }

    const noRegDiv = {
        textAlign : "center",
        padding : "0px", 
        color : "#cccccc", 
        opacity : "1", 
        fontsize : "1em"
    }

    return (
        <>
        <Tour
            steps={useGalleryTour.tourConfig}
            isOpen={isTourOpen}
            onRequestClose={closeTour}
            rounded={5}
            accentColor={"#004c9d"}
            onAfterOpen={disableBody}
            onBeforeClose={enableBody}
        />
        <div className="renewal">
            <SubNavNew nav={subNav} history={props.history} />
        </div>
        <div className={"wrap-dataset"}>
            <div className={"list-title-mo"}>활용 갤러리</div>
            <div className={"useGalleryDesc"}>
                <p style={pStyle}>
                    활용갤러리는 경기지역경제포털 내에 있는 데이터를 활용한 개발제품(WEB, APP 등) 및 아이디어를 공유하는 곳입니다.
                </p>
                <p style={pStyle}>
                    향후 활용갤러리를 통해 자신의 아이디어나 개발한 제품에 대한 내용을 등록함으로써 다양한 홍보효과를 가질 수 있습니다.
                </p>
                <p style={pStyle}>
                    아래 활용갤러리는 데이터를 활용한 다양한 예시를 보여주고 있습니다.
                </p>
            </div>
            <div style={{"marginTop": "40px"}}>
                {
                    isLogin &&
                    <div className={"btn-group"} style={{"float": "right"}}>
                        <Link to={"/use/write"} className={"btn"} style={{"height": "46px"}}>등록하기</Link>
                    </div>
                }
                <div data-tut={"use_gallery_search"} style={{display:"flex",marginTop:"5px"}}>
                    <SelectSearch
                        options={useGallerySearchType}
                        onChange={option => {setSearchType(option)}}
                        className={"board-search-select-box"}
                        value={searchType}
                        ref={selectRef}
                    />
                    <div className={"search-wrap"} style={searchWrapStyle}>
                        <input 
                            title={"검색창"} 
                            type={"text"} 
                            id="board_search_input" 
                            placeholder={"찾고 싶은 내용을 입력해주세요!"}
                            value={searchWord} 
                            onChange={ e => setSearchWord(e.target.value)}
                            onKeyDown={e => e.keyCode === 13 && isSearchUserGallery(searchWord)} 
                            style={inputStyle}
                        />
                        <button onClick={() => isSearchUserGallery(searchWord)} style={{"height": "46px", "marginLeft": "10px"}}/>
                    </div>
                </div>
            </div>

            <div className={"use-wrap"}>
                {/*<div className={"search-wrap"}>*/}
                {/*    <select>*/}
                {/*        <option selected>전체</option>*/}
                {/*        <option></option>*/}
                {/*    </select>*/}
                {/*    <input type="text"></input>*/}
                {/*    <button></button>*/}
                {/*</div>*/}

                <div className={"gallery-wrap"}>
                    <ul className="gallery-block" data-tut={"use_gallery_body"}>
                        {
                            useGalleryList.length === 0 ?
                                <div style={noRegDiv}>
                                    등록된 게시물이 없습니다.
                                </div>
                                :
                                useGalleryList.map(res =>
                                    <li onClick={() => props.history.push('/use/'+res.id)} key={res.id}>
                                        <dl>
                                            <dt>
                                                <img src={res.thumbnail} alt={res.title}/>
                                            </dt>
                                            <dd>
                                                <strong>{res.title}</strong>
                                                <p style={{"width": "100%", "height": "21px"}}>서비스 유형 : {convertKor[res.type]}</p>
                                                <p style={{"width": "100%", "height": "21px"}}>개발자(회사): {res.creator}</p>
                                            </dd>
                                        </dl>
                                    </li>)
                        }

                    </ul>
                </div>
                {/*<div className={"paging-wrap"}>*/}
                {/*    <div className={"paging-button"}>*/}
                {/*        <button className="btn-rew"></button>*/}
                {/*        <button className="btn-back"></button>*/}
                {/*        <button>1</button>*/}
                {/*        <button>2</button>*/}
                {/*        <button>3</button>*/}
                {/*        <button>4</button>*/}
                {/*        <button>5</button>*/}
                {/*        <button>6</button>*/}
                {/*        <button>7</button>*/}
                {/*        <button>8</button>*/}
                {/*        <button>9</button>*/}
                {/*        <button>10</button>*/}
                {/*        <button className="btn-next"></button>*/}
                {/*        <button className="btn-ff"></button>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            {
                totCnt === 0 ?
                    null
                    :
                    <Pagination 
                        maxPage={maxPage}
                        page={page}
                        padding={2}
                        onPageMove={page => setPage(page)}
                        // customStyle={{"margintop": "20px !important"}}
                    />
            }

        </div>
        </>
    )
};

export default Use;
