import {get, post, getNew, postNew, deleteNew} from '../util/HttpRequest';

const IBOARD_API_URL = process.env.REACT_APP_IBOARD_API_URL;

/**
 * 게시판 생성
 * /create/board
 */
export const createBoard = (params = {}) => {
    let target = IBOARD_API_URL + "/create/board";
    return postNew(target, params);
};

/**
 * 게시판 삭제
 * /delete/board
 */
export const deleteBoard = (params = {}) => {
    let target = IBOARD_API_URL + "/delete/board";
    return postNew(target, params);
};

/**
 * 게시글 작성
 * /create/text
 */
export const createText = (params = {}) => {
    let target = IBOARD_API_URL + "/create/text";
    return postNew(target, params);
};

/**
 * 게시글 수정
 * /update/text
 */
export const updateText = (params = {}) => {
    let target = IBOARD_API_URL + "/update/text";
    return postNew(target, params);
};

/**
 * 게시글 삭제
 * /delete/text
 */
export const deleteText = (params = {}) => {
    let target = IBOARD_API_URL + "/delete/text";
    return postNew(target, params);
};

/**
 * 댓글 작성
 * /create/comment
 */
export const createComment = (params = {}) => {
    let target = IBOARD_API_URL + "/create/comment";
    return postNew(target, params);
};

/**
 * 댓글 수정
 * /update/comment
 */
export const updateComment = (params = {}) => {
    let target = IBOARD_API_URL + "/update/comment";
    return postNew(target, params);
};

/**
 * 댓글 삭제
 * /delete/comment
 */
export const deleteComment = (params = {}) => {
    let target = IBOARD_API_URL + "/delete/comment";
    return postNew(target, params);
};

/**
 * 게시판 목록
 * /read/boardlist
 */
export const readBoardList = (params = {}) => {
    let target = IBOARD_API_URL + "/read/boardlist";
    return postNew(target, params);
};

/**
 * 게시글 목록
 * /read/textlist
 */
export const readTextList = (params = {}) => {
    let target = IBOARD_API_URL + "/read/textlist";
    return postNew(target, params);
};

/**
 * 댓글 목록
 * /read/textlist
 */
export const readCommentList = (params = {}) => {
    let target = IBOARD_API_URL + "/read/commentlist";
    return postNew(target, params);
};


/**
 * 댓글 목록
 * /read/text
 */
export const readText = (params = {}) => {
    let target = IBOARD_API_URL + "/read/text";
    return postNew(target, params);
};
